import { Grid, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Close, styled, TextFiel } from "@mui/material"
import styleCss from "../Style/style"
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Edit from '@mui/icons-material/BorderColorOutlined';
import { authpostAxios } from "../Utilities/commonAxios";
import DeleteIcon from '@material-ui/icons/Delete';
import { Switch, FormControlLabel } from '@mui/material';
import { toast } from "react-toastify";
import { confirmationAlertwithButtonForUserCreation, errorCampaignAlert } from '../Utilities/sweetAlert';



const Roles = () => {
    const navigate = useNavigate();
    const [roleTypes, setRoleTypes] = useState([]);
    const getRolesTypeAPI = async () => {
        const response = await authpostAxios({
            url: `${process.env.REACT_APP_BACKENDURL}api/get-user-roles`,
            data: '',
        });
        setRoleTypes(response.data.data)
    };

    const handleEdit = async (roleId) => {
        navigate(`/edit-role/${btoa(roleId)}`)
    }
    const handleDelete = async (roleId) => {
        const confirmAlertResponse = await confirmationAlertwithButtonForUserCreation("Are you sure you want to delete the role type?")
        if (confirmAlertResponse.isConfirmed) {
        const response = await authpostAxios({
            url: `${process.env.REACT_APP_BACKENDURL}api/del-user-roles`,
            data: {roleId : roleId},
        })
        if(response.data.flag==1){
            toast.success(response.data.message);
            getRolesTypeAPI()
        }else{
            toast.error(response.data.message);
        }
    }
}
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
        navigate('/add_role')
    };
    const handleClose = () => {
        setOpen(false);
    };
    

    const [isActive, setIsActive] = useState(false);
    const handleCheckStatus = (roleActiveInactiveStatus) => {
        console.log("roleActiveInactiveStatus",roleActiveInactiveStatus);
        if(roleActiveInactiveStatus==1){
            return true; 
        }
        else{
            return false;
        }
    };

    const handleChangeActiveInactiveStatus = async (roleId)  =>{
        const confirmAlertResponse = await confirmationAlertwithButtonForUserCreation("Are you sure you want to update the role type?")
        if (confirmAlertResponse.isConfirmed) {
        const response = await authpostAxios({
            url: `${process.env.REACT_APP_BACKENDURL}api/upd-user-active-inactive-status`,
            data: {roleId : roleId},
        })
        if(response.data.flag==1){
            getRolesTypeAPI()
        }else{
            return false;
        }
    }
}

    useEffect(() => {
        getRolesTypeAPI()
    }, []);
    


    console.log("roleTypes", roleTypes);

    return (
        <>
            <Grid sx={styleCss.commonpaddingtop}>
                <Grid container>
                    <Grid md={12} sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Button variant="contained" onClick={handleClickOpen}>
                            Add Roles
                        </Button>
                    </Grid>
                </Grid>
                <Grid container sx={{ marginTop: '40px' }}>
                    <Grid md={12}>
                        <div style={{ width: '100%' }}>
                            <table className="styled-table">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Role Name</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {roleTypes && roleTypes.length != 0 &&
                                        roleTypes?.map((roles, index) => (
                                            <tr>
                                                <td>{roles.role_id}</td>
                                                <td>{(roles.role_name.charAt(0).toUpperCase() + roles.role_name.slice(1))}</td>
                                                <td> <FormControlLabel
                                                    control={
                                                        <Switch  // (roles.active_inactive_status==1) ?  true : false
                                                            checked={(roles.active_inactive_status==1) ?  true : false}
                                                            onChange={()=>{handleChangeActiveInactiveStatus(roles.role_id)}}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={roles.active_inactive_status==1 ? 'Active' : 'Inactive'}
                                                /></td>
                                                {/* <td>{roles.status === 1 ? <Typography align="center" sx={styleCss.activecell}>
                                                        Acive
                                                    </Typography> : <Typography align="center" sx={styleCss.inactivecell}>
                                                        Inactive
                                                    </Typography>}</td> */}

                                                <td><Edit onClick={() => handleEdit(roles.role_id)} style={{ color: '#4caf50', marginRight: '8px', cursor: 'pointer' }} />
                                                    <DeleteIcon onClick={() => handleDelete(roles.role_id)} style={{ color: '#f44336', cursor: 'pointer' }} /></td>
                                            </tr>

                                        ))}

                                </tbody>
                            </table>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Roles