import { Box, Grid, TextField,Tooltip, Typography } from "@mui/material"
import styleCss from "../Style/style"
import { PieChart } from '@mui/x-charts/PieChart';
import Slider from '@mui/material/Slider';
import React from "react";
import LocationIcon1 from '../img/camplocation-icon1.png'
import LocationIcon2 from '../img/camplocation-icon2.png'
import LocationIcon3 from '../img/camplocation-icon3.png'
import VoiceIcon from '../img/messageicon_media3.png'
import SliderRange from '@mui/material/Slider';
import MessageIcon3 from '../img/messageicon_media3.png'
import MicIcon from '@material-ui/icons/Mic';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';




const pieParams = { height: 150, margin: { right: 5 } };
const palette = ['#77943e', '#d9d9d9'];


const MAX = 10000;
const MIN = 0;
const marks = [
    {
        value: MIN,
        label: '',
    },
    {
        value: MAX,
        label: '',
    },
];


const IvrCampaign = ({formikValues, handleSubmit,formikSetFieldValue}) => {

    const handleChildChange = (event, newValue) => {
        formikSetFieldValue('ivr_budget', newValue);

        console.log(handleChildChange);
        formikSetFieldValue('ivr_text_value', newValue);
    };

    const setSlideVal = (value) => {
        formikSetFieldValue('ivr_budget', value);
        formikSetFieldValue('ivr_text_value', value);
    };



    const ValueLabelComponent = (props) => {
        const { children, open, value } = props;
        return (
            <Tooltip open={open} enterTouchDelay={0} placement="bottom" title={value} arrow>
                {children}
            </Tooltip>
        );
    };
    return (
        <>
            <Grid sx={styleCss.CampaignBox}>
                <Typography sx={{
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontWeight: 'normal',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    textAlign: 'left',
                    color: '#555',
                    marginTop: '10px',
                    marginBottom: '10px',
                }}>Campaign Objective</Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '1px', }}>
                    Traffic
                </Typography>
                <Typography sx={{
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    textAlign: 'left',
                    color: '#000',
                }}>
                    Drive visitors to your website or marketing landing pages.
                </Typography>
                <Grid sx={{
                    padding: '12px 20px',
                    backgroundColor: '#77943e',
                    marginTop: '15px'
                }}>
                    <Typography sx={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 500,
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        letterSpacing: 'normal',
                        textAlign: 'left',
                        color: '#fff',
                    }}> <VoiceChatIcon  style={{ fontSize: 21,verticalAlign: 'middle', marginRight: '10px' }} /> {formikValues?.ivr_campagin_name}</Typography>
                </Grid>
                <Grid sx={{ textAlign: 'center', marginTop: '15px', marginBottom: '12px' }}>
                    <PieChart
                        colors={palette}
                        series={[{ 
                            data: [
                                { name: 'Total ivr', value: formikValues.voice_bot_budget }, 
                                { name: 'ivr Budget', value: parseFloat((formikValues.voice_bot_budget / 2.50).toFixed(0)) }
                            ] 
                        }]}
                        {...pieParams}
                    />
                </Grid>
                <Grid container>
                    <Grid md={6} xs={6}>
                        <Typography sx={{ fontSize: '12px', fontWeight: 'normal', color: '#000', textAlign: 'center', fontFamily: 'Inter' }}>
                        Cost Per Ivr
                        </Typography>
                        <Typography sx={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'center',
                            color: '#77943e',
                            fontFamily: 'Inter',
                            marginTop: '5px'
                        }}>
                            Rs 2.50
                        </Typography>
                    </Grid>
                    <Grid md={6} xs={6}>
                        <Typography sx={{ fontSize: '12px', fontWeight: 'normal', color: '#000', textAlign: 'center', fontFamily: 'Inter' }}>
                            Total ivr : 
                        </Typography>
                        <Typography sx={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'center',
                            color: '#77943e',
                            fontFamily: 'Inter',
                            marginTop: '5px'
                        }}>
                             {(formikValues.ivr_budget / 2.50).toFixed(0)}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid md={12}>
                    <Typography sx={{
                        fontSize: '14px',
                        fontWeight: 'normal',
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        letterSpacing: 'normal',
                        textAlign: 'left',
                        color: '#555',
                        marginTop: '20px',
                        fontFamily: 'Inter',
                    }}>Budget</Typography>

                    <Box sx={{ width: '100%', marginTop: '10px' }}>
                        <SliderRange
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider-facebook"
                        name="ivr_text_value"
                        value={formikValues.ivr_text_value}
                        onChange={handleChildChange}
                        //onChange={(event, newValue) => formikValues('whatsapp_budget', newValue)}
                        min={100}
                        max={100000}
                        sx={{
                            '& .MuiSlider-thumb': {
                                color: '#77943e',
                            },
                            '& .MuiSlider-track': {
                                bgcolor: '#004f9f',
                                border: '1px solid transparent',
                            },
                            '& .MuiSlider-rail': {
                                bgcolor: '#77943e',
                            },

                        }}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography
                                variant="body2"
                                onClick={() => setSlideVal(MIN)}
                                sx={{ cursor: 'pointer', color: '#555', fontSize: '16px', fontFamily: 'Inter' }}
                            >
                                {MIN}
                            </Typography>
                            <Typography
                                variant="body2"
                                onClick={() => setSlideVal(MAX)}
                                sx={{ cursor: 'pointer', color: '#555', fontSize: '16px', fontFamily: 'Inter' }}
                            >
                                {MAX}
                            </Typography>
                        </Box>

                        <TextField
                        type="number"
                        name="ivr_budget"
                        label="Amount"
                        value={formikValues.ivr_budget}
                        onChange={(e) => {
                        const value = e.target.value;
                        formikSetFieldValue('ivr_budget', value);
                        formikSetFieldValue('ivr_text_value', value);
                        }}
                        sx={{ mt: 2, width: '100%' }}
                        />

                    </Box>
               </Grid>

                <Grid md={12} sx={{ borderBottom: '1px solid #d6d6d0', marginTop: '15px', marginBottom: '15px' }}></Grid>
                <Grid container>
                    
                    <Grid md={6} xs={6} sx={{ marginTop: '20px' }}>
                        <Grid sx={{
                            padding: '10px',
                            borderRadius: '30px',
                            backgroundColor: '#77943e',
                            width: '40px',
                            height: '40px',
                            display: 'grid',
                            placeContent: 'center',
                            marginBottom: '8px'
                        }}>
                            <img src={LocationIcon2} style={{width:'12px'}}/>
                        </Grid>
                        <Typography sx={{
                            fontSize: '15',
                            fontWeight: 'normal',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'left',
                            color: '#555',
                            fontFamily: 'Inter',
                        }}>Budget</Typography>
                        <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            color: '#000',
                            marginBottom:'10px'
                        }}>
                        Rs. {formikValues.ivr_budget}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default IvrCampaign