import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import Loginimg from '../img/loginbgimg.png'
import Logo from '../img/logo.png'
import { Link } from "react-router-dom";
import styleCss from '../Style/style';
import Header from "../Components/header"
import Mainsidemenu from "../Components/mainsidemenu"
import React, { useState } from "react";
import FullScreenLoader from "../Style/FullScreenLoader";
import { useSelector } from 'react-redux';

const NonAuthLayout = (props) => {
    return (
        <>
            <Grid className="bgcolorstyle">
                <Container>
                    <Grid container>
                        <Grid item md={12} style={styleCss.Loginlogo}>
                            <Link to="/">
                                <img src={Logo} />
                            </Link>
                        </Grid>
                    </Grid>

                    <Grid container rowSpacing={0} columnSpacing={3}
                        sx={{ alignItems: 'center', marginLeft: '0px', height: '100vh', placeItems: 'center' }}>
                        <Grid item md={6}>
                            <img src={Loginimg} style={{ zIndex: '9' }} />
                        </Grid>

                        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid style={{ width: '70%' }}>
                                {props.children}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </>
    )
}


const AuthLayout = (props) => {
    const { isLoading } = useSelector(state => state.loader)
    return (
        <>
            {isLoading && <FullScreenLoader />}
            <Header />
            <Container maxWidth="xxl" sx={{ position: 'relative' }}>
                <Grid container>
                    <Mainsidemenu />
                    <Grid item md={12}>
                        {props.children}
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export { NonAuthLayout, AuthLayout }