import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import service1 from "./Images/service1.png";
import bannersectionbg from "./Images/bannersectionbg.png";
import serviceicon1 from "./Images/serviceicon-1.png";
import serviceicon2 from "./Images/serviceicon-2.png";
import serviceicon3 from "./Images/serviceicon-3.png";
import serviceicon4 from "./Images/serviceicon-4.png";
import serviceicon5 from "./Images/serviceicon-5.png";
import serviceicon6 from "./Images/serviceicon-6.png";
import serviceicon7 from "./Images/serviceicon-7.png";
import Conversition from "./Images/convertion-inner-img.png";
import activationimg from "./Images/brand-activation-bg.png";
import serviceicon8 from "./Images/serviceicon-8.png";
import serviceicon9 from "./Images/serviceicon-9.png";
import serviceicon10 from "./Images/serviceicon-10.png";
import trand from "./Images/trend-bg.png";
import pro1 from "./Images/product1-icon.png";
import pro2 from "./Images/product2-icon.png";
import pro3 from "./Images/product3-icon.png";
import pro4 from "./Images/product4-icon.png";
import pro5 from "./Images/product5-icon.png";
import pro6 from "./Images/product6-icon.png";
import pro7 from "./Images/product7-icon.png";
import pro8 from "./Images/product8-icon.png";
import Landicon from "@mui/icons-material/AddIcCallOutlined";
import Messageicon from "@mui/icons-material/ForwardToInboxOutlined";
import Addressicon from "@mui/icons-material/AddLocationOutlined";
import * as Yup from "yup";
import { useFormik } from "formik";
import { nonAuthpostAxios } from "../Utilities/commonAxios";
import { toast } from "react-toastify";
import axios from "axios";

const backgroundImg = {
  height: "630px",
  backgroundImage: `url(${bannersectionbg})`,
  backgroundSize: "100% 100%",
  marginTop: "65px",
};

const Ourservice = {
  marginTop: "30px",
};

const headerHeading = {
  color: "#2f353e",
  fontSize: "50px",
  fontWeight: 600,
  marginBottom: "30px",
};

const sersubtext = {
  fontSize: 15,
  fontWeight: "normal",
  textAlign: "left",
  color: "#274bb5",
  marginBottom: "20px",
};

const Conversation = {
  marginTop: "30px",
  backgroundColor: "rgba(22, 166, 251, 0.05)",
  padding: "20px",
};

const Mediastyle = {
  fontSize: "20px",
  fontWeight: "normal",
  color: "#274bb5",
};

const Activation = {
  marginTop: "30px",
};
const Trendsection = {
  marginTop: "30px",
  backgroundColor: "rgba(252, 207, 253, 0.2)",
  padding: "30px",
};

const Ourproducts = {
  marginTop: "30px",
};
const Contactstyle = {
  marginTop: "30px",
  marginBottom: "0px",
  backgroundColor: "#f4f5fe",
  paddingTop: "20px",
  paddingBottom: "50px",
};

const HomeIndex = () => {
  const submitContactForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      email: Yup.string()
        .email("Email must be valid!")
        .required("Please enter valid email"),
      mobile: Yup.string()
        .min(10)
        .max(10)
        .required("Please enter mobile number"),
      message: Yup.string().required("Please enter message"),
    }),
    onSubmit: async (values, actions) => {
      // lead api
      await axios.post(
        "http://demo.careshub.in/api/lead_push",
        {
            first_name:values.name, 
            last_name:"",
            email:values.email,
            source:"2",
            sub_source:"4",
            mobile:values.mobile,
            alternate_number:values.mobile
        },
        {
          headers: {
            apikey: "mqlEFt3XnI2OG5TKCMqnr607WV1Kh1VC",
          },
        }
      );
      // api - contact us
      const { data: contactUsAPiResponse } = await nonAuthpostAxios({
        url: `${process.env.REACT_APP_BACKENDURL}api/contact-us`,
        data: {
          name: values.name,
          email: values.email,
          message: values.message,
        },
      });
      if (contactUsAPiResponse.flag === 1) {
        toast.success(contactUsAPiResponse.message);
        setTimeout(() => {
          actions.resetForm();
        }, 1000);
      } else {
        toast.error(contactUsAPiResponse.message);
      }
    },
  });
  return (
    <>
      <Grid style={backgroundImg}>
        <Container>
          <Grid container>
            <Grid item md={12}>
              <Typography
                sx={{
                  fontSize: 45,
                  fontWeight: 600,
                  color: "#2f353e",
                  textAlign: "center",
                  marginTop: "35px",
                  lineHeight: "55px",
                }}
              >
                We provide customer engagement and retention services across
                various channels
              </Typography>
              <Grid sx={{ textAlign: "center", marginTop: "15px" }}>
                <Button
                  variant="contained"
                  sx={{ textTransform: "capitalize" }}
                >
                  Book Demo
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid style={Ourservice} id="services">
        <Container>
          <Grid item md={12}>
            <Typography style={headerHeading}>Our Services</Typography>
          </Grid>
          <Grid container>
            <Grid item md={7}>
              <Typography
                sx={{
                  color: "#16a6fb",
                  fontSize: "25px",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                Digital Channels
              </Typography>
              <Typography sx={{ color: "#628498", lineHeight: "30px" }}>
                You may get closer to your customers by using the digital
                marketing channel. A digital marketing channel is a large
                platform where customers use multi-channel services. Through
                digital channel marketing customers can interact with company
                through the medium SMS, Whatsapp, and Email (single, Multi or
                Bulk). We provide manual and automated campaign services. An
                automated method of campaign service customers can get messages
                on their anniversaries, birthday, and festival. Our marketing
                services are.
              </Typography>
              <Grid container item spacing={2} sx={{ mt: 2 }}>
                <Grid item md={6}>
                  <Typography style={sersubtext}>
                    <img src={serviceicon1} style={{ marginRight: "8px" }} />{" "}
                    SEO (Search Engine Operation)
                  </Typography>
                  <Typography style={sersubtext}>
                    <img src={serviceicon2} style={{ marginRight: "8px" }} />{" "}
                    SMS Marketing
                  </Typography>
                  <Typography style={sersubtext}>
                    <img src={serviceicon3} style={{ marginRight: "8px" }} />{" "}
                    Whatsapp Marketing
                  </Typography>
                  <Typography style={sersubtext}>
                    <img src={serviceicon4} style={{ marginRight: "8px" }} />{" "}
                    Email Marketing
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography style={sersubtext}>
                    <img src={serviceicon5} style={{ marginRight: "8px" }} />{" "}
                    RCS(Rich Communication Services)
                  </Typography>
                  <Typography style={sersubtext}>
                    <img src={serviceicon6} style={{ marginRight: "8px" }} />{" "}
                    Voicebot
                  </Typography>
                  <Typography style={sersubtext}>
                    <img src={serviceicon7} style={{ marginRight: "8px" }} />{" "}
                    Chatbot
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={5}>
              <img src={service1} style={{ width: "100%" }} />
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid style={Conversation}>
        <Container>
          <Grid container item spacing={2}>
            <Grid item md={5}>
              <img src={Conversition} style={{ width: "100%" }} />
            </Grid>
            <Grid item md={7}>
              <Grid item md={12}>
                <Typography
                  sx={{
                    color: "#16a6fb",
                    fontSize: "25px",
                    fontWeight: "600",
                    marginBottom: "10px",
                    mb: 3,
                  }}
                >
                  Conversation Management
                </Typography>
              </Grid>
              <Grid item md={12} sx={{ mb: 3 }}>
                <Typography style={Mediastyle}>
                  Social Media marketing
                </Typography>
                <Typography sx={{ color: "#628498", lineHeight: "30px" }}>
                  Social media sites such as Facebook, Instagram, and others are
                  used to target customers. We can use marketing to promote our
                  brand and increase sales. Social media marketing is the most
                  widely used digital marketing method for engaging and
                  retaining customers.
                </Typography>
              </Grid>

              <Grid item md={12} sx={{ mb: 3 }}>
                <Typography style={Mediastyle}>
                  Reputation Management
                </Typography>
                <Typography sx={{ color: "#628498", lineHeight: "30px" }}>
                  Reputation management is an online marketing method for
                  spreading information about a firm and its products. You'll be
                  able to communicate with customers from anywhere. It's similar
                  to how a product or brand promotes itself on the internet.
                  It's one of the most essential marketing methods for a
                  business.
                </Typography>
              </Grid>
              <Grid item md={12} sx={{ mb: 3 }}>
                <Typography style={Mediastyle}>Competition Tracking</Typography>
                <Typography sx={{ color: "#628498", lineHeight: "30px" }}>
                  The process of analyzing and auditing a competitor's product
                  is known as competition tracking or monitoring. It is a
                  strategy for identifying the strengths and weaknesses of the
                  product or service they offer. We conduct a detailed
                  examination of the services or products offered by the major
                  competitors and work to refine your business. This analysis
                  and observation will assist you in the most efficient way to
                  grow your business or brand.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid style={Activation}>
        <Container>
          <Grid container item spacing={2}>
            <Grid item md={7}>
              <Grid item md={12}>
                <Typography
                  sx={{
                    color: "#16a6fb",
                    fontSize: "25px",
                    fontWeight: "600",
                    marginBottom: "10px",
                    mb: 3,
                  }}
                >
                  Brand Activation
                </Typography>
                <Typography
                  sx={{ color: "#628498", lineHeight: "30px", mb: 2 }}
                >
                  Brand activation is the process of increasing brand
                  recognition through marketing strategies such as promotions,
                  retail, events, CRM, and trade shows. Brand activation aids in
                  the development of a long-term relationship with the targeted
                  product and the achievement of sales success. The Brand
                  activation methods include.
                </Typography>
                <Typography style={sersubtext}>
                  <img src={serviceicon8} style={{ marginRight: "8px" }} />{" "}
                  Retail Media Marketing
                </Typography>
                <Typography style={sersubtext}>
                  <img src={serviceicon9} style={{ marginRight: "8px" }} />{" "}
                  Social Media Marketing
                </Typography>
                <Typography style={sersubtext}>
                  <img src={serviceicon10} style={{ marginRight: "8px" }} />{" "}
                  Digital Media and Channels Marketing
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={5}>
              <img src={activationimg} style={{ width: "100%" }} />
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid style={Trendsection}>
        <Container>
          <Grid container item spacing={2}>
            <Grid item md={5}>
              <img src={trand} style={{ width: "100%" }} />
            </Grid>
            <Grid item md={7}>
              <Grid item md={12}>
                <Typography
                  sx={{
                    color: "#16a6fb",
                    fontSize: "25px",
                    fontWeight: "600",
                    marginBottom: "10px",
                    mb: 3,
                  }}
                >
                  Trend analysis
                </Typography>
                <Typography
                  sx={{ color: "#628498", lineHeight: "30px", mb: 2 }}
                >
                  The primary objective of trend analysis is to predict the
                  company's future growth using historical data. This analysis
                  will aid in the digital forecasting of business growth. Trend
                  analysis demonstrates the brands or services' upward and
                  downward trend lines. We can compare multiple brands or
                  services and keep track of the data. The collected information
                  will be useful in identifying the business's strengths and
                  weaknesses. This will help you keep your market position and
                  stay ahead of your competitors.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid style={Ourproducts} id="product">
        <Container>
          <Grid container item spacing={2}>
            <Grid item md={12}>
              <Typography
                sx={{ color: "#2f353e", fontSize: "40px", fontWeight: 600 }}
              >
                Our Products
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography sx={{ color: "#628498", lineHeight: "30px", mb: 3 }}>
                Use our marketing service plan to increase your company's sales
                and revenue. Increase your customer conversations to achieve
                better and faster growth. The more effectively you market your
                goods, the higher your sales and revenue will be. The following
                services are the most efficient ways to contact clients at their
                location.
              </Typography>
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item md={3} sx={{ display: "flex", alignItems: "stretch" }}>
              <Card
                variant="outlined"
                sx={{ p: 2, width: "100%", textAlign: "center", boxShadow: 1 }}
              >
                <img src={pro1} />
                <Typography
                  sx={{
                    color: "#2f353e",
                    fontSize: "18px",
                    fontWeight: 500,
                    textAlign: "center",
                    mt: 1,
                  }}
                >
                  Cares
                </Typography>
              </Card>
            </Grid>
            <Grid item md={3} sx={{ display: "flex", alignItems: "stretch" }}>
              <Card
                variant="outlined"
                sx={{ p: 2, width: "100%", textAlign: "center", boxShadow: 1 }}
              >
                <img src={pro2} />
                <Typography
                  sx={{
                    color: "#2f353e",
                    fontSize: "18px",
                    fontWeight: 500,
                    textAlign: "center",
                    mt: 1,
                  }}
                >
                  Social Media Management
                </Typography>
              </Card>
            </Grid>
            <Grid item md={3} sx={{ display: "flex", alignItems: "stretch" }}>
              <Card
                variant="outlined"
                sx={{ p: 2, width: "100%", textAlign: "center", boxShadow: 1 }}
              >
                <img src={pro3} />
                <Typography
                  sx={{
                    color: "#2f353e",
                    fontSize: "18px",
                    fontWeight: 500,
                    textAlign: "center",
                    mt: 1,
                  }}
                >
                  Facebook
                </Typography>
              </Card>
            </Grid>
            <Grid item md={3} sx={{ display: "flex", alignItems: "stretch" }}>
              <Card
                variant="outlined"
                sx={{ p: 2, width: "100%", textAlign: "center", boxShadow: 1 }}
              >
                <img src={pro4} />
                <Typography
                  sx={{
                    color: "#2f353e",
                    fontSize: "18px",
                    fontWeight: 500,
                    textAlign: "center",
                    mt: 1,
                  }}
                >
                  LinkedIn
                </Typography>
              </Card>
            </Grid>
            <Grid
              item
              md={3}
              sx={{ display: "flex", alignItems: "stretch", mb: 3 }}
            >
              <Card
                variant="outlined"
                sx={{ p: 2, width: "100%", textAlign: "center", boxShadow: 1 }}
              >
                <img src={pro5} />
                <Typography
                  sx={{
                    color: "#2f353e",
                    fontSize: "18px",
                    fontWeight: 500,
                    textAlign: "center",
                    mt: 1,
                  }}
                >
                  Instagram
                </Typography>
              </Card>
            </Grid>
            <Grid
              item
              md={3}
              sx={{ display: "flex", alignItems: "stretch", mb: 3 }}
            >
              <Card
                variant="outlined"
                sx={{ p: 2, width: "100%", textAlign: "center", boxShadow: 1 }}
              >
                <img src={pro6} />
                <Typography
                  sx={{
                    color: "#2f353e",
                    fontSize: "18px",
                    fontWeight: 500,
                    textAlign: "center",
                    mt: 1,
                  }}
                >
                  Metaverse
                </Typography>
              </Card>
            </Grid>
            <Grid
              item
              md={3}
              sx={{ display: "flex", alignItems: "stretch", mb: 3 }}
            >
              <Card
                variant="outlined"
                sx={{ p: 2, width: "100%", textAlign: "center", boxShadow: 1 }}
              >
                <img src={pro7} />
                <Typography
                  sx={{
                    color: "#2f353e",
                    fontSize: "18px",
                    fontWeight: 500,
                    textAlign: "center",
                    mt: 1,
                  }}
                >
                  Customer CRM
                </Typography>
              </Card>
            </Grid>
            <Grid
              item
              md={3}
              sx={{ display: "flex", alignItems: "stretch", mb: 3 }}
            >
              <Card
                variant="outlined"
                sx={{ p: 2, width: "100%", textAlign: "center", boxShadow: 1 }}
              >
                <img src={pro8} />
                <Typography
                  sx={{
                    color: "#2f353e",
                    fontSize: "18px",
                    fontWeight: 500,
                    textAlign: "center",
                    mt: 1,
                  }}
                >
                  Retail
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid style={Contactstyle} id="contact">
        <Container>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Typography
                sx={{ color: "#2f353e", fontSize: "30px", fontWeight: 500 }}
              >
                Contact Us
              </Typography>
            </Grid>
            <Grid item md={8} sx={{ display: "flex", alignItems: "stretch" }}>
              <form onSubmit={submitContactForm.handleSubmit}>
                <Card
                  variant="outlined"
                  sx={{
                    p: 3,
                    width: "100%",
                    textAlign: "center",
                    boxShadow: 1,
                  }}
                >
                  <Grid container item spacing={2}>
                    <Grid item md={4} sx={{ mb: 3 }}>
                      <TextField
                        fullWidth
                        size="small"
                        id="name"
                        name="name"
                        label="Name"
                        onChange={submitContactForm.handleChange}
                        value={submitContactForm.values.name}
                      />
                      {submitContactForm.errors.name ? (
                        <Typography
                          variant="body2"
                          sx={{ color: "red", fontSize: 14, textAlign: "left" }}
                        >
                          {submitContactForm.errors.name}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item md={4} sx={{ mb: 3 }}>
                      <TextField
                        fullWidth
                        size="small"
                        id="mobile"
                        name="mobile"
                        label="Mobile Number"
                        onChange={submitContactForm.handleChange}
                        value={submitContactForm.values.mobile}
                      />
                      {submitContactForm.errors.mobile ? (
                        <Typography
                          variant="body2"
                          sx={{ color: "red", fontSize: 14, textAlign: "left" }}
                        >
                          {submitContactForm.errors.mobile}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item md={4} sx={{ mb: 3 }}>
                      <TextField
                        fullWidth
                        size="small"
                        type="email"
                        id="email"
                        name="email"
                        label="Email"
                        onChange={submitContactForm.handleChange}
                        value={submitContactForm.values.email}
                      />
                      {submitContactForm.errors.email ? (
                        <Typography
                          variant="body2"
                          sx={{ color: "red", fontSize: 14, textAlign: "left" }}
                        >
                          {submitContactForm.errors.email}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item md={12} sx={{ mb: 3 }}>
                      <TextField
                        id="message"
                        name="message"
                        label="Your Message"
                        onChange={submitContactForm.handleChange}
                        multiline
                        rows={2}
                        variant="outlined"
                        fullWidth
                        value={submitContactForm.values.message}
                      />
                      {submitContactForm.errors.message ? (
                        <Typography
                          variant="body2"
                          sx={{ color: "red", fontSize: 14, textAlign: "left" }}
                        >
                          {submitContactForm.errors.message}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item md={12} sx={{ textAlign: "end" }}>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ textTransform: "capitalize", width: "150px" }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </form>
            </Grid>
            <Grid item md={4} sx={{ display: "flex", alignItems: "stretch" }}>
              <Card
                variant="outlined"
                sx={{ p: 3, width: "100%", textAlign: "left", boxShadow: 1 }}
              >
                <Grid style={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignContent: "flex-start",
                      marginBottom: "20px",
                    }}
                  >
                    <Grid>
                      <Landicon />
                    </Grid>
                    <Grid sx={{ ml: 2, fontSize: "16px", fontWeight: 500 }}>
                      {" "}
                      044 - 61556555 / 7200468388
                    </Grid>
                  </Box>
                </Grid>
                <Grid style={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignContent: "flex-start",
                      marginBottom: "20px",
                    }}
                  >
                    <Grid>
                      <Messageicon />
                    </Grid>
                    <Grid sx={{ ml: 2, fontSize: "16px", fontWeight: 500 }}>
                      {" "}
                      sales@edupointsolutions.com
                    </Grid>
                  </Box>
                </Grid>
                <Grid style={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignContent: "flex-start",
                      marginBottom: "20px",
                    }}
                  >
                    <Grid>
                      <Addressicon />
                    </Grid>
                    <Grid sx={{ ml: 2 }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        EDUPOINT SOLUTIONS PRIVATE LIMITED
                      </Typography>
                      <Typography
                        sx={{ fontSize: "14px", fontWeight: "500", mb: 3 }}
                      >
                        32/20, GV Complex, Railway Colony, <br />
                        Aminjikarai, Chennai 600029.
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </>
  );
};

export default HomeIndex;
