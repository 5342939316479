import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { authLogin } from "../Reduxstore/authSlice";
import { useDispatch , useSelector } from 'react-redux';
import styleCss from '../Style/style';
import * as Yup from "yup";
import { toast } from "react-toastify";

const Login = () => {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loginForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Username"),
            password: Yup.string().required("Please Enter Password"),
        }),
        onSubmit: async (values) => {
            let loginResponseData = await dispatch(authLogin({ emailId: values.email, password: values.password }));
            console.log("loginResponseData",loginResponseData);
            if (loginResponseData.payload.flag === 1) {
                let loginRouter = loginResponseData?.payload?.data?.userDetails?.role_json[0]?.path;
                localStorage.removeItem('userToken');
                localStorage.setItem('userToken', loginResponseData.payload.data.userToken);
                navigate('/dashboard');
                toast.success(loginResponseData.payload.message);
            } else {
                localStorage.removeItem('userToken');
                toast.error(loginResponseData.payload.message);
            }
            return null; 
        }
    });

    return (
        <>
            <form onSubmit={loginForm.handleSubmit}>
                <Grid>
                    <Typography style={styleCss.WelcomText}>Welcome back,</Typography>
                    <Typography style={styleCss.signText}>Sign In to continue</Typography>

                    <TextField
                        id="email"
                        label="Enter your Email"
                        variant="outlined"
                        size="small"
                        name="email"
                        onChange={loginForm.handleChange}
                        fullWidth
                        sx={{ mb: 2 }}
                        InputLabelProps={{ sx: { color: '#92a2b1', fontSize: '15px', fontWeight: 400 } }}
                        inputProps={{ sx: { color: '#000000de', fontSize: '15px', fontWeight: 500 } }}
                    />

                    <TextField
                        id="password"
                        label="Enter your password"
                        variant="outlined"
                        name="password"
                        type="password"
                        onChange={loginForm.handleChange}
                        size="small"
                        fullWidth
                        sx={{ mb: 1 }}
                        InputLabelProps={{ sx: { color: '#92a2b1', fontSize: '15px', fontWeight: 400 } }}
                        inputProps={{ sx: { color: '#000000de', fontSize: '15px', fontWeight: 500 } }}
                    />

                    <Grid sx={{ textAlign: 'end' }}>
                        <Link to="/" style={styleCss.Forgotpass}>
                            Forgot Password?
                        </Link>
                    </Grid>

                    <Button 
                    type="submit" 
                    variant="contained" 
                    style={styleCss.SignBtn}>
                    Sign In
                    </Button>

                    <Typography style={styleCss.account}>
                        Don’t have an account ? 
                        <Link to="/register" style={{ fontWeight: 'bold', color: '#27292f' }}>
                            Sign up
                        </Link>
                    </Typography>
                </Grid>
            </form>
        </>
    );
}

export default Login;
