import React, { useState, useEffect } from 'react';
import { Box, Button} from "@mui/material";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authpostAxios } from "../Utilities/commonAxios";
import Typography from '@mui/material/Typography';
import styleCss from '../Style/style';
//import TwitterLogin from "react-twitter-login";
import axios from 'axios';
import { TwitterLoginButton } from 'react-twitter-auth';
import { toast } from "react-toastify";


  

function TwitterLoginComponent() {
	
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  useEffect(() => {
    const fetchAppId = async () => {
      try {
        const response = await authpostAxios({
          url: `${process.env.REACT_APP_BACKENDURL}api/generate_twitter_url`,
          data: {}
        });
        setMessage(response.data.message.Url);
      } catch (error) {
        console.error('Error fetching App ID from API:', error);
      }
    };
    fetchAppId();
    return () => {
    };
  }, []);

  console.log("message",message);

 return (
 <Button href={message} variant='outlined' style={styleCss.socialbtn}>Connect</Button>
  );
}

function TwitterLogin() {
  return (
    <div>
      <TwitterLoginComponent />
    </div>
  );
}

export default TwitterLoginComponent;

/*
const TwitterLoginComponent = () => {
	
  const handleTwitterLoginSuccess = (response) => {
	  console.log('Twitter login successful', response);

	  const params = new URLSearchParams(response);
	  const oauth_token = params.get('oauth_token');
	  const oauthTokenSecret = params.get('oauth_token_secret');

  
	  
	  axios.get('https://api.twitter.com/1.1/account/verify_credentials.json', {
      headers: {
        Authorization: `Bearer ${oauth_token}`
      }
    })
    .then(profileResponse => {
      // Extract profile details from the response
	  
	  console.log(profileResponse.data);
      const { email, profile_image_url, name } = profileResponse.data;

      // Handle the fetched data as needed
      console.log('Email:', email);
      console.log('Profile Image URL:', profile_image_url);
      console.log('Name:', name);

      // You can set state or perform any other actions here
    })
    .catch(error => {
      console.error('Error fetching user details:', error);
    });
	
  
  
  };

  const handleTwitterLoginFailure = (error) => {
    console.error('Twitter login failed', error);
    // Handle login failure
  };

  return (
    <TwitterLogin
      authCallback={handleTwitterLoginSuccess}
      consumerKey="8eHVA9AK8fPEBV22nGuIxb53a"
      consumerSecret="XYGbqQperaMF0OgaNHlFc9YIMEygru7nuPXFJ9CC4qxIsoeosc"
      callbackUrl="http://localhost:3001/dashboard" // Adjust callback URL
      onFailure={handleTwitterLoginFailure}
    />
  );
};

export default TwitterLoginComponent;
*/






