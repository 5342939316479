import { Box, Grid, List, ListItem, Typography, TextField, Button } from "@mui/material"
import Profilepicture from '../img/profilepicture.png';
import { Link } from "react-router-dom";
import { CheckCircle, ThumbUp, Favorite, EmojiEmotions, EmailOutlined, ShareOutlined, ReplyOutlined, FavoriteBorderOutlined } from '@mui/icons-material';
import WalletIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import FadeMenu from "./togglebutton";
import { useEffect, useState } from "react";
import moment from "moment";
import { confirmationAlertwithButton, confirmationAlert,confirmationAlertWithText } from "../Utilities/sweetAlert";
import { authpostAxios } from "../Utilities/commonAxios";
import { useDispatch } from "react-redux";
import noImage from '../img/no-image.jpg';
import { socialMediaLists } from "../Utilities/socialMediaLists";
import { manageLoader } from "../Reduxstore/loaderSlice";

const CommentsInbox = ({ singleCommentDetails, commentType, handleSubmitSelectedPageForm }) => {
    const dispatch = useDispatch()
    const [updatedCommentDetails, setUpdatedCommentDetails] = useState([])
    const [PostReactionCount, setPostReactionCount] = useState([])
    const [updatedType, setUpdatedType] = useState('')
   
    useEffect(() => {
        setUpdatedCommentDetails(singleCommentDetails)
        setUpdatedType(commentType)
        
    //  getReactionCountFromAPI()
    }, [singleCommentDetails, commentType])
    
    // submit comment form
    const [comment, setComment] = useState(null)
    const [commentValidationMessage, setCommentValidationMessage] = useState(false)
    const getReactionCountFromAPI = async () => {
        let postIdPrimaryKey,postId;
        if(updatedCommentDetails?.[0]?.social_media_id==1){
            postIdPrimaryKey = updatedCommentDetails?.[0]?.fb_post_id;
            postId = updatedCommentDetails?.[0]?.page_post_id;
        }else if(updatedCommentDetails?.[0]?.social_media_id==4){
            postIdPrimaryKey = updatedCommentDetails?.[0]?.instagram_post_id;
            postId = updatedCommentDetails?.[0]?.instagram_return_id;
        }
        await authpostAxios(
          {
            url: `${process.env.REACT_APP_BACKENDURL}api/get-fb-post-reaction-count`,
            data: { 
              "socialMediaId" : updatedCommentDetails?.[0]?.social_media_id,
              "postIdPrimaryKey": postIdPrimaryKey,
              "postId": postId,
            },
          },
          dispatch
        ).then((response) =>  setPostReactionCount(response?.data?.data)).catch((error)=> console.log(error))
      };
    const handleComment = async () => {
        if (comment === null || comment === "") {
            setCommentValidationMessage(true)
        } else {
            setCommentValidationMessage(false)
            const getStatus = await confirmationAlertwithButton("Yes, post it!")
            if (getStatus.isConfirmed) {
                dispatch(manageLoader(true))
                let commentResponse;
                if (updatedCommentDetails?.[0]?.social_media_id === 1) {
                    let formData = {
                        "social_media_page_id": updatedCommentDetails?.[0]?.social_media_page_id,
                        "social_media_id": updatedCommentDetails?.[0]?.social_media_id,
                        "parent_c_id": updatedCommentDetails?.[0]?.parent_c_id,
                        "fb_post_id": updatedCommentDetails?.[0]?.fb_post_id,
                        "parent_post_comment_id": updatedCommentDetails?.[0]?.parent_post_comment_id,
                        "page_post_id": updatedCommentDetails?.[0]?.page_post_id,
                        "message": comment,
                        "comment_title": updatedType,
                        "child_comment_id" : updatedCommentDetails?.[0]?.child_comment_id,
                    }
                    commentResponse = await authpostAxios({ 'url': `${process.env.REACT_APP_BACKENDURL}api/fb-child-comment-post`, 'data': formData }, dispatch);
                } else if (updatedCommentDetails?.[0]?.social_media_id === 4) {
                    let formData = {
                        "instagram_account_id": updatedCommentDetails?.[0]?.instagram_account_id,
                        "social_media_id": updatedCommentDetails?.[0]?.social_media_id,
                        "parent_c_id": updatedCommentDetails?.[0]?.parent_c_id,
                        "instagram_post_id": updatedCommentDetails?.[0]?.instagram_post_id,
                        "parent_post_comment_id": updatedCommentDetails?.[0]?.parent_post_comment_id,
                        "message": comment,
                        "comment_title": updatedType,
                        "child_comment_id" : updatedCommentDetails?.[0]?.child_comment_id,
                    }
                    commentResponse = await authpostAxios({ 'url': `${process.env.REACT_APP_BACKENDURL}api/insta-child-comment-post`, 'data': formData }, dispatch);
                }else if (updatedCommentDetails?.[0]?.social_media_id === 5) {
                    let formData = {
                        "youtube_account_id": updatedCommentDetails?.[0]?.youtube_account_id,
                        "social_media_id": updatedCommentDetails?.[0]?.social_media_id,
                        "parent_c_id": updatedCommentDetails?.[0]?.parent_c_id,
                        "youtube_channel_video_id": updatedCommentDetails?.[0]?.youtube_channel_video_id,
                        "parent_post_comment_id": updatedCommentDetails?.[0]?.parent_post_comment_id,
                        "screen_name": updatedCommentDetails?.[0]?.screen_name,
                        "profile": updatedCommentDetails?.[0]?.profile,
                        "social_auth_user_id" : updatedCommentDetails?.[0]?.social_auth_user_id,
                        "message": comment,
                        "comment_title": updatedType,
                        "child_comment_id" : updatedCommentDetails?.[0]?.child_comment_id,
                    }
                    commentResponse = await authpostAxios({ 'url': `${process.env.REACT_APP_BACKENDURL}api/youtube-child-comment-post`, 'data': formData }, dispatch);
                }
                 else {
                    commentResponse = {};
                }
                if (commentResponse?.data?.flag === 1) {
                    dispatch(manageLoader(false))
                    confirmationAlert(1)
                    setComment(null)
                    setCommentValidationMessage(false)
                    setUpdatedCommentDetails([])
                    setUpdatedType('')
                    handleSubmitSelectedPageForm()
                    
                } else {
                    dispatch(manageLoader(false))
                    confirmationAlertWithText(2,commentResponse?.data?.message?.error?.message)
                    
                }
                
            }
        }
    }
    useEffect(() =>{
        getReactionCountFromAPI()
    }, [updatedCommentDetails]);
    return (
        <>
            {updatedCommentDetails?.[0] && (
                <>
                    <Grid sx={{ padding: '15px' }}>
                        <Grid container>
                            <Grid md={12}>
                                <div style={{ width: '100%', }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexWrap: 'nowrap',
                                            p: 0,
                                            position: 'relative'
                                        }}
                                    >
                                        <Grid sx={{ marginRight: '6px', position: 'relative' }}>
                                            <Grid sx={{ position: 'relative' }}>
                                                {(updatedCommentDetails?.[0]?.social_media_id === 1 || updatedCommentDetails?.[0]?.social_media_id === 3) && (
                                                    <img src={(updatedCommentDetails?.[0]?.page_profile_image) ? (updatedCommentDetails?.[0]?.page_profile_image) : (noImage)}
                                                        style={{
                                                            width: '40px', height: '40px',
                                                            borderRadius: '5px', marginRight: '10px',
                                                        }}
                                                    />
                                                )}
                                                {(updatedCommentDetails?.[0]?.social_media_id === 5 || updatedCommentDetails?.[0]?.social_media_id === 4) && (
                                                    <img src={(updatedCommentDetails?.[0]?.profile !== "") ? (updatedCommentDetails?.[0]?.profile) : (noImage)}
                                                        style={{
                                                            width: '40px', height: '40px',
                                                            borderRadius: '5px', marginRight: '10px',
                                                        }}
                                                    />
                                                )}
                                                {updatedCommentDetails?.[0]?.social_media_id === 1 && (
                                                    <img src={socialMediaLists[updatedCommentDetails?.[0]?.social_media_id]['icon']}
                                                        style={{
                                                            width: '14px',
                                                            borderRadius: '50px',
                                                            position: 'absolute',
                                                            bottom: 0,
                                                            right: '3px',
                                                        }} />
                                                )}
                                                {updatedCommentDetails?.[0]?.social_media_id === 3 && (
                                                    <img src={socialMediaLists[updatedCommentDetails?.[0]?.social_media_id]['icon']}
                                                        style={{
                                                            width: '14px',
                                                            borderRadius: '50px',
                                                            position: 'absolute',
                                                            bottom: 0,
                                                            right: '3px',
                                                        }} />
                                                )}
                                                {updatedCommentDetails?.[0]?.social_media_id === 4 && (
                                                    <img src={socialMediaLists[updatedCommentDetails?.[0]?.social_media_id]['icon']}
                                                        style={{
                                                            width: '14px',
                                                            borderRadius: '50px',
                                                            position: 'absolute',
                                                            bottom: 0,
                                                            right: '3px',
                                                        }} />
                                                )}
                                                {updatedCommentDetails?.[0]?.social_media_id === 5 && (
                                                    <img src={socialMediaLists[updatedCommentDetails?.[0]?.social_media_id]['icon']}
                                                        style={{
                                                            width: '14px',
                                                            borderRadius: '50px',
                                                            position: 'absolute',
                                                            bottom: 0,
                                                            right: '3px',
                                                        }} />
                                                )}

                                            </Grid>
                                        </Grid>
                                        <Grid sx={{ flexGrow: '1' }}>
                                            <Typography sx={{
                                                fontSize: '13px',
                                                fontWeight: 500,
                                                textAlign: 'left',
                                                color: '#27292f !important',
                                            }}>
                                                {(updatedCommentDetails?.[0]?.social_media_id === 1 || updatedCommentDetails?.[0]?.social_media_id === 3) && (updatedCommentDetails?.[0]?.page_name)}

                                                {(updatedCommentDetails?.[0]?.social_media_id === 4 || updatedCommentDetails?.[0]?.social_media_id === 5) && (updatedCommentDetails?.[0]?.screen_name)}
                                                <CheckCircle sx={{ fontSize: '10px', color: '#1877f2' }} /> </Typography>
                                            <Typography sx={{
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                textAlign: 'left',
                                                color: '#87888e !important',
                                            }}>{(updatedCommentDetails?.[0]?.created_at) ? (moment(updatedCommentDetails?.[0]?.created_at).format('MMMM d, hh:mm A')) : ('')}</Typography>
                                        </Grid>
                                        {/* <Grid>
                                            <FadeMenu />
                                        </Grid> */}
                                    </Box>
                                    <Grid container>
                                        <Grid md={12}>
                                            <Typography sx={{
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                fontWeight: 'normal',
                                                textAlign: 'left',
                                                color: '#4b4b4b',
                                                marginTop: '10px',
                                            }}>{updatedCommentDetails?.[0]?.message}</Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                        {/* {(updatedCommentDetails?.[0]?.social_media_id === 1 && updatedCommentDetails?.[0]?.fb_post_type === 'image' && (
                            <>
                                <Grid container>
                                    <Grid sx={{ border: '1px solid #edeced', padding: '10px 10px 5px', backgroundColor: '#f8f9fc', width: '100%', borderRadius: '7px', marginTop: '15px' }}>
                                        <Grid container item spacing={1} sx={{ alignItems: 'center' }}>
                                            <Grid item md={4}>
                                                <Grid sx={{
                                                    position: 'relative', '&::after': {
                                                        content: '""',
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        width: '100%',
                                                        height: '96%',
                                                        background: '#11101063',
                                                        right: 0,
                                                        zIndex: 9,
                                                        overflow: 'hidden',
                                                        borderRadius: '5px',
                                                    }
                                                }}>
                                                     {updatedCommentDetails?.[0]?.post_social_media_image_url.includes(
                              "["
                            )
                              ? (JSON.parse(
                                  (updatedCommentDetails?.[0]?.post_social_media_image_url).replaceAll(
                                    /\\/g,
                                    "\\\\"
                                  )
                                )
                                  .slice(0, 1)
                                  .map((image, index) => (
                                    <>
                                      <img
                                        src={image}
                                        style={{
                                            width: '100%', height: '100%', borderRadius: '5px',
                                        }}
                                        key={index}
                                      />
                                    </>
                                  )))
                              : (updatedCommentDetails?.[0]?.post_social_media_image_url && (
                                  <img
                                    src={
                                        updatedCommentDetails?.[0]?.post_social_media_image_url
                                    }
                                    style={{
                                        width: '100%', height: '100%', borderRadius: '5px',
                                    }}
                                  />
                                ))}
                                                </Grid>
                                            </Grid>
                                            <Grid item md={8}>
                                                <Link to={"#"} style={{
                                                    fontSize: '12px',
                                                    fontWeight: 'normal',
                                                    fontStretch: 'normal',
                                                    textAlign: 'left',
                                                    color: '#1877f2',
                                                    textDecoration: 'unset'
                                                }}>View post on facebook</Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        ))}  */}

                        <Grid sx={{ marginTop: '10px' }}>
                            <Grid container>
                                <Grid md={6}>
                                    <List sx={{ display: 'inline-flex', padding: '0px' }}>
                                        <ListItem sx={{ p: 0, marginRight: '3px' }}>
                                            <ThumbUp sx={{
                                                fontSize: '13px', backgroundImage: 'linear-gradient(117deg, #33a5fd 47%, #1962f2)', color: '#fff', padding: '3px',
                                                borderRadius: '50px', zIndex: '9'
                                            }} />
                                            <Favorite sx={{
                                                fontSize: '13px', backgroundImage: 'linear-gradient(to bottom, #fa617d 0%, #e61d39 100%)', color: '#fff', padding: '3px',
                                                borderRadius: '50px', marginLeft: '-3px', zIndex: '1'
                                            }} />
                                            <EmojiEmotions sx={{
                                                fontSize: '19px', background: '#f7f4f4', color: '#FDC200', padding: '1px',
                                                borderRadius: '50px', marginLeft: '-3px'
                                            }} />
                                        </ListItem>
                                        <ListItem sx={{ p: 0 }}>
                                            <Typography sx={{
                                                fontSize: '13px',
                                                fontWeight: 'normal',
                                                textAlign: 'left',
                                                color: '#27292f',
                                            }}>{PostReactionCount}</Typography>
                                        </ListItem>
                                    </List>
                                </Grid>
                                {/* <Grid md={6} sx={{ textAlign: 'end' }}>
                                    <List sx={{ display: 'inline-flex', padding: '0px' }}>
                                        <ListItem sx={{ p: 0, marginRight: '15px' }}>
                                            <Link to={'/'} style={{ textDecoration: 'unset' }}>
                                                <Typography sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 'normal',
                                                    textAlign: 'left',
                                                    color: '#27292f',
                                                }}>82 <EmailOutlined sx={{ fontSize: '18px', verticalAlign: 'sub', color: '#27292f' }} /></Typography>
                                            </Link>
                                        </ListItem>

                                        <ListItem sx={{ p: 0, }}>
                                            <Link to={'/'} style={{ textDecoration: 'unset' }}>
                                                <Typography sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 'normal',
                                                    textAlign: 'left',
                                                    color: '#27292f',
                                                }}><ShareOutlined sx={{ fontSize: '18px', verticalAlign: 'sub', color: '#27292f' }} /> 82</Typography>
                                            </Link>
                                        </ListItem>
                                    </List>
                                </Grid> */}
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid md={12} sx={{ marginTop: 2 }}>
                                <Typography sx={{
                                    fontSize: '13px',
                                    fontWeight: 'bold',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    lineHeight: 2.31,
                                    letterSpacing: 'normal',
                                    textAlign: 'left',
                                    color: '#27292f',
                                    fontFamily: 'Inter',
                                }}>
                                    Comments
                                </Typography>
                            </Grid>
                            <Grid style={{
                                padding: '11px 13px 11px 12px',
                                borderRadius: '4px',
                                border: 'solid 1px #edeced',
                                width: '100%',
                            }}>
                                <Grid md={12}>
                                    <Grid style={{ width: '100%', }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'nowrap',
                                                p: 0,
                                                position: 'relative'
                                            }}
                                        >
                                            <Grid sx={{ marginRight: '6px', position: 'relative' }}>
                                                <Grid sx={{ position: 'relative' }}>
                                                    {(updatedCommentDetails?.[0]?.social_media_id === 1 || updatedCommentDetails?.[0]?.social_media_id === 5) && (
                                                        <img src={(updatedCommentDetails?.[0]?.profile_url) ? (updatedCommentDetails?.[0]?.profile_url) : (noImage)}
                                                            style={{
                                                                width: '25px', height: '25px',
                                                                borderRadius: '50px', marginRight: '10px',
                                                            }}
                                                        />
                                                    )}
                                                    {(updatedCommentDetails?.[0]?.social_media_id === 3 || updatedCommentDetails?.[0]?.social_media_id === 4) && (
                                                         <img src={(updatedCommentDetails?.[0]?.profile_url !== "") ? (updatedCommentDetails?.[0]?.profile_url) : (noImage)}
                                                         style={{
                                                            width: '25px', height: '25px',
                                                            borderRadius: '50px', marginRight: '10px',
                                                        }}
                                                     />
                                                    )}
                                                    {/* {(updatedCommentDetails?.[0]?.social_media_id === 5) && (
                                                        <img src={(updatedCommentDetails?.[0]?.profile_url) ? (updatedCommentDetails?.[0]?.profile_url) : (noImage)}
                                                            style={{
                                                                width: '25px', height: '25px',
                                                                borderRadius: '50px', marginRight: '10px',
                                                            }}
                                                        />
                                                    )} */}
                                                    {updatedCommentDetails?.[0]?.social_media_id === 1 && (
                                                        <img src={socialMediaLists[updatedCommentDetails?.[0]?.social_media_id]['icon']}
                                                            style={{
                                                                width: '12px',
                                                                borderRadius: '50px',
                                                                position: 'absolute',
                                                                bottom: 0,
                                                                right: '3px',
                                                            }} />
                                                    )}
                                                    {updatedCommentDetails?.[0]?.social_media_id === 3 && (
                                                        <img src={socialMediaLists[updatedCommentDetails?.[0]?.social_media_id]['icon']}
                                                            style={{
                                                                width: '12px',
                                                                borderRadius: '50px',
                                                                position: 'absolute',
                                                                bottom: 0,
                                                                right: '3px',
                                                            }} />
                                                    )}
                                                    {updatedCommentDetails?.[0]?.social_media_id === 4 && (
                                                        <img src={socialMediaLists[updatedCommentDetails?.[0]?.social_media_id]['icon']}
                                                            style={{
                                                                width: '12px',
                                                                borderRadius: '50px',
                                                                position: 'absolute',
                                                                bottom: 0,
                                                                right: '3px',
                                                            }} />
                                                    )}
                                                     {updatedCommentDetails?.[0]?.social_media_id === 5 && (
                                                        <img src={socialMediaLists[updatedCommentDetails?.[0]?.social_media_id]['icon']}
                                                            style={{
                                                                width: '12px',
                                                                borderRadius: '50px',
                                                                position: 'absolute',
                                                                bottom: 0,
                                                                right: '3px',
                                                            }} />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid sx={{ flexGrow: '1' }}>
                                                <Typography sx={{
                                                    fontSize: '13px',
                                                    fontWeight: 500,
                                                    textAlign: 'left',
                                                    color: '#27292f !important',
                                                }}>{updatedCommentDetails?.[0]?.profile_name}</Typography>
                                            </Grid>
                                            {/* <Grid>
                                                <FadeMenu />
                                            </Grid> */}
                                        </Box>
                                        <Grid container>
                                            <Grid md={12}>
                                                <Typography sx={{
                                                    fontFamily: 'Inter',
                                                    fontSize: '12px',
                                                    fontWeight: 'normal',
                                                    textAlign: 'left',
                                                    color: '#686868',
                                                    marginTop: '10px',
                                                }}>{updatedCommentDetails?.[0]?.comments}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            {/* <Grid md={12} sx={{ textAlign: 'end', marginTop: '25px' }}>
                                                <Link to={'/'}>
                                                    <WalletIcon style={{ color: '#27292f', marginRight: '10px', fontSize: '20px' }} />
                                                </Link>
                                                <Link to={'/'}>
                                                    <FavoriteBorderOutlined style={{ color: '#27292f', marginRight: '10px', fontSize: '20px' }} />
                                                </Link>
                                                <Link to={'/'}>
                                                    <ReplyOutlined style={{ color: '#27292f', fontSize: '20px' }} />
                                                </Link>
                                            </Grid> */}
                                            {updatedCommentDetails?.[0]?.social_media_id !== 3 && (
                                                <>
                                                    <Grid md={12} sx={{ marginTop: '10px', border: '1px solid #edeced', padding: '10px 10px 5px', }}>
                                                        <div style={{ width: '100%', }}>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexWrap: 'nowrap',
                                                                    p: 0,
                                                                    position: 'relative',
                                                                    alignItems: 'center'
                                                                }}
                                                            >
                                                                <Grid sx={{ marginRight: '6px', }}>
                                                                    {updatedCommentDetails?.[0]?.social_media_id === 1 && (
                                                                        <img src={(updatedCommentDetails?.[0]?.page_profile_image) ? (updatedCommentDetails?.[0]?.page_profile_image) : (Profilepicture)}
                                                                            style={{
                                                                                width: '30px', height: '30px',
                                                                                borderRadius: '50px', verticalAlign: 'middle',
                                                                            }}
                                                                        />
                                                                    )}
                                                                    {updatedCommentDetails?.[0]?.social_media_id === 5 && (
                                                                        <img src={(updatedCommentDetails?.[0]?.profile) ? (updatedCommentDetails?.[0]?.profile) : (Profilepicture)}
                                                                            style={{
                                                                                width: '30px', height: '30px',
                                                                                borderRadius: '50px', verticalAlign: 'middle',
                                                                            }}
                                                                        />
                                                                    )}
                                                                    {updatedCommentDetails?.[0]?.social_media_id === 4 && (
                                                                        <img src={(updatedCommentDetails?.[0]?.profile) ? (updatedCommentDetails?.[0]?.profile) : (Profilepicture)}
                                                                            style={{
                                                                                width: '30px', height: '30px',
                                                                                borderRadius: '50px', verticalAlign: 'middle',
                                                                            }}
                                                                        />
                                                                    )}

                                                                </Grid>
                                                                <Grid>
                                                                    <Typography sx={{
                                                                        fontSize: '13px',
                                                                        fontWeight: 500,
                                                                        textAlign: 'left',
                                                                        color: '#27292f !important',
                                                                        fontFamily: 'Inter',
                                                                    }}>
                                                                        {updatedCommentDetails?.[0]?.social_media_id === 1 && updatedCommentDetails?.[0]?.page_name}
                                                                        {(updatedCommentDetails?.[0]?.social_media_id === 4 || updatedCommentDetails?.[0]?.social_media_id === 5) && updatedCommentDetails?.[0]?.screen_name}
                                                                        <CheckCircle sx={{ fontSize: '10px', color: '#1877f2' }} /> </Typography>
                                                                </Grid>
                                                            </Box>
                                                            <Grid container>
                                                                <Grid md={12} sx={{ marginTop: '10px' }}>
                                                                    <TextField
                                                                        value={comment}
                                                                        id="comment"
                                                                        name="comment"
                                                                        label=""
                                                                        multiline
                                                                        rows={2}
                                                                        onChange={(e) => setComment(e.target.value)}
                                                                        sx={{ width: '100%' }}
                                                                    />
                                                                    {(commentValidationMessage && (comment === null || comment === "")) && (
                                                                        <>
                                                                            <Typography sx={{
                                                                                fontSize: '13px',
                                                                                fontWeight: 500,
                                                                                textAlign: 'left',
                                                                                color: 'red !important',
                                                                                fontFamily: 'Inter',
                                                                            }}>Please enter comment</Typography>
                                                                        </>
                                                                    )}
                                                                </Grid>
                                                                <Grid md={12} sx={{ marginTop: '5px', textAlign: 'end', }}>
                                                                    <Button variant="contained" sx={{ background: '#38cb89', color: '#fff', padding: '5px 15px', textTransform: 'capitalize' }} onClick={handleComment}>Send</Button>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    </Grid>
                                                </>
                                            )}
                                            {/* <Grid md={4}></Grid>
                                            <Grid md={4} sx={{ marginTop: '22px', textAlign: 'center' }}>
                                                <Grid sx={{ position: 'relative' }}>
                                                    <Button variant="outlined" sx={{
                                                        background: '#f7f9fb', borderRadius: '50px',
                                                        border: '1px solid #f7f9fb', color: '#6a7b8f', padding: '5px 11px', textTransform: 'capitalize'
                                                    }}>
                                                        View Threads
                                                    </Button>
                                                    <span style={{
                                                        background: 'rgb(39, 41, 47)',
                                                        borderRadius: '50px',
                                                        display: 'inline-block',
                                                        padding: '5px 2px',
                                                        color: 'rgb(255, 255, 255)',
                                                        position: 'absolute',
                                                        top: '-11px',
                                                        height: '13px',
                                                        width: '18px',
                                                        fontSize: '11px',
                                                        right: 0,
                                                    }}>30</span>
                                                </Grid>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )
            }
        </>
    )
}

export default CommentsInbox