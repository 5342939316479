import { Box, TextField,Grid, Tooltip, Typography } from "@mui/material"
import Campaign1 from "../img/facebook-campaign.png"
import styleCss from "../Style/style"
import { PieChart } from '@mui/x-charts/PieChart';
import SliderRange from '@mui/material/Slider';
import React from "react";
import LocationIcon1 from '../img/camplocation-icon1.png'
import LocationIcon2 from '../img/camplocation-icon2.png'
import LocationIcon3 from '../img/camplocation-icon3.png'

const pieParams = { height: 150, margin: { right: 5 } };
const palette = ['#1877F2', '#d9d9d9'];


const MAX = 10000;
const MIN = 0;
const marks = [
    {
        value: MIN,
        label: '',
    },
    {
        value: MAX,
        label: '',
    },
];

function PaiseToRupees(paise) {
    return paise / 100;
  }
  
const FacebookCampaign = ({formikValues, handleSubmit,socialfbcampigndetaillist,formikSetFieldValue}) => {

    const ValueLabelComponent = (props) => {
        const { children, open, value } = props;
        return (
            <Tooltip open={open} enterTouchDelay={0} placement="bottom" title={value} arrow>
                {children}
            </Tooltip>
        );
    };

    const handleChildChange = (event, newValue) => {
        formikSetFieldValue('facebook_budget', newValue);

        console.log(handleChildChange);
        formikSetFieldValue('facebook_text_value', newValue);
    };

    const setSlideVal = (value) => {
        formikSetFieldValue('facebook_budget', value);
        formikSetFieldValue('facebook_text_value', value);
    };

   // const selectedCampaignName = "";
    const selectedCampaignName = socialfbcampigndetaillist.find(campaign => campaign.facebook_ad_campagin_publishing_id == formikValues.fb_campagin_name)?.fb_campagin_name || 'None';

    const formatDate = (dateString, locale = 'en-US') => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return new Intl.DateTimeFormat(locale, options).format(date);
      };

      console.log('11111111111111111111111111111111111111111111111111111111111');
    const adDetails = socialfbcampigndetaillist.length > 0 ? socialfbcampigndetaillist[0] : {};
    console.log(adDetails);
    console.log(socialfbcampigndetaillist);
    console.log(adDetails);
    const ad_budget = adDetails?.ad_budget ?? '';
    const fb_campagin_name = adDetails?.fb_campaign_name ?? '';
    const ad_startdate = adDetails?.ad_startdate ?? '';
    const ad_enddate = adDetails.ad_enddate ?? '';
    const fb_ads_reach_gender_type = adDetails.fb_ads_reach_gender_type ?? '';
    const age_min = adDetails?.fb_targeting?.age_min ?? '';
    const age_max = adDetails.fb_targeting?.age_max ?? '';
    const users_lower_bound =  adDetails?.users_lower_bound ??  '';
    const users_upper_bound =  adDetails?.users_upper_bound ?? '';
    const account_name = adDetails?.account_name ?? '';
    const page_name = adDetails?.page_name ?? '';
    const campaign_objective = adDetails?.campaign_objective ?? '';
    const fb_ads_website_url = adDetails?.fb_ads_website_url ?? '';

  
    let campaignobjective = ''; // Define outside the blocks

    if (campaign_objective === 'grow_customer_base') {
        campaignobjective = 'Grow Customer Base'; 
    } else if (campaign_objective === 'get_more_website_visitors') {
        campaignobjective = 'Website Visitors';
    }
    
    let countryName = '';
    let city = '';
    let state = '';

  // Check if fb_ads_reach_location exists and is not empty
  if (adDetails?.fb_ads_reach_location) {
    try {
      // Parse the JSON string into a JavaScript array of objects
      const data = JSON.parse(adDetails.fb_ads_reach_location);

      // Extract data from the first object if available
      if (Array.isArray(data) && data.length > 0) {
        countryName = data[0].f_country_name || '';
        city = data[0].f_name || '';  // Assuming f_name is the field for city
        state = data[0].f_region || '';
      }
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  }

  // Log variables to ensure they have the correct values
  console.log('Country Name:', countryName);
  console.log('City:', city);
  console.log('State:', state);

   
  const boundConvertIntoValues = (value) =>{
    if (value >= 1_000_000_000) {
        return (value / 1_000_000_000).toFixed(1) + 'B'; 
      } else if (value >= 1_000_000) {
        return (value / 1_000_000).toFixed(1) + 'M'; 
      } else if (value >= 1_000) {
        return (value / 1_000).toFixed(1) + 'K'; 
      } else {
        return value.toString(); 
      }
}


const geo_locations = '';
    //const geo_locations = adDetails?.fb_ads_reach_location?.geo_locations?.countries[0] ?? '';


    return (
        <>
      
            <Grid sx={styleCss.CampaignBox}>
                <Typography sx={{
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontWeight: 'normal',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    textAlign: 'left',
                    color: '#555',
                    marginTop: '10px',
                    marginBottom: '10px',
                }}>Campaign Objective</Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '1px', }}>
                    Traffic
                </Typography>
                <Typography sx={{
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    textAlign: 'left',
                    color: '#000',
                }}>
                    Drive visitors to your website or marketing landing pages.
                </Typography>
                <Grid sx={{
                    padding: '12px 20px',
                    backgroundColor: '#1877F2',
                    marginTop: '15px'
                }}>
                    <Typography sx={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 500,
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        letterSpacing: 'normal',
                        textAlign: 'left',
                        color: '#fff',
                    }}><img src={Campaign1} style={{
                        verticalAlign: 'middle', marginRight: '10px', 
                    }} /> {fb_campagin_name}</Typography>
                </Grid>

                <Grid sx={{ textAlign: 'center', marginTop: '15px', marginBottom: '12px' }}>
                    <PieChart
                        colors={palette}
                        series={[{ data: [{ value: 10 }, { value: 15 },] }]}
                        {...pieParams}
                    />
                </Grid>
                         { /*      
                <Grid md={12}>
                    <Typography sx={{
                        fontSize: '14px',
                        fontWeight: 'normal',
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        letterSpacing: 'normal',
                        textAlign: 'left',
                        color: '#555',
                        marginTop: '20px',
                        fontFamily: 'Inter',
                    }}>Budget</Typography>
                    <Box sx={{ width: '100%', marginTop: '10px' }}>
                      
                      <SliderRange
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider-facebook"
                        name="facebook_budget"
                        value={formikValues.facebook_text_value}
                        onChange={handleChildChange}
                        min={100}
                        max={100000}
                        />
          
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography
                                variant="body2"
                                onClick={() => setSlideVal(MIN)}
                                sx={{ cursor: 'pointer', color: '#555', fontSize: '16px', fontFamily: 'Inter' }}
                            >
                                {MIN}
                            </Typography>
                            <Typography
                                variant="body2"
                                onClick={() => setSlideVal(MAX)}
                                sx={{ cursor: 'pointer', color: '#555', fontSize: '16px', fontFamily: 'Inter' }}
                            >
                                {MAX}
                            </Typography>
                        </Box>

                        <TextField
                        type="number"
                        name="facebook_text_value"
                        label="Amount"
                        value={formikValues.facebook_text_value}
                        onChange={(e) => {
                        const value = e.target.value;
                        formikSetFieldValue('facebook_text_value', value);
                        formikSetFieldValue('facebook_budget', value);
                        }}
                        sx={{ mt: 2, width: '100%' }}
                        />

                    </Box>
                </Grid>
                */ }

                    <Grid md={12}>
                        <Typography sx={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: '#555',
                            marginTop: '20px',
                            fontFamily: 'Inter',
                            marginTop: '12px'
                        }}>
                            {account_name}
                        </Typography>
                    </Grid>
                    <Grid md={12}>
                        <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: '#000', fontFamily: 'Inter', textAlign: 'center', }}>
                           Page Name :  {page_name}
                        </Typography>
                    </Grid>
                    <Grid md={12}>
                        <Typography sx={{ fontSize: '14px', fontWeight: 'normal', color: '#000', fontFamily: 'Inter',  textAlign: 'center',}}>
                            Objective : {campaignobjective}
                        </Typography>
                    </Grid>
                    <Grid md={12}>
                        <Typography sx={{ fontSize: '14px', fontWeight: 'normal', color: '#000', fontFamily: 'Inter',  textAlign: 'center',}}>
                            Website link : {fb_ads_website_url}
                        </Typography>
                    </Grid>


                <Grid md={12} sx={{ borderBottom: '1px solid #d6d6d0', marginTop: '15px', marginBottom: '15px' }}></Grid>
                <Grid container>

                    <Grid md={3} xs={3}>
                        <Grid sx={{
                            padding: '10px',
                            borderRadius: '30px',
                            backgroundColor: '#1877F2',
                            width: '40px',
                            height: '40px',
                            display: 'grid',
                            placeContent: 'center',
                            marginBottom: '8px'
                        }}>
                            <img src={LocationIcon3} style={{width:'19px'}}/>
                        </Grid>
                    </Grid>
                    <Grid md={3} xs={3}>
                        <Typography sx={{
                            fontSize: '15px',
                            fontWeight: 'normal',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'left',
                            color: '#555',
                            fontFamily: 'Inter',
                        }}>Leads</Typography>

                        <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            color: '#000',
                            paddingTop: '4px',
                            marginBottom: '20px'
                        }}>
                           10,000
                        </Typography>
                    </Grid>

                    <Grid md={3} xs={3} >
                        <Grid sx={{
                            padding: '10px',
                            borderRadius: '30px',
                            backgroundColor: '#1877F2',
                            width: '40px',
                            height: '40px',
                            display: 'grid',
                            placeContent: 'center',
                            marginBottom: '8px'
                        }}>
                            <img src={LocationIcon3} style={{width:'19px'}}/>
                        </Grid>
                    </Grid>
                    <Grid md={3} xs={3}>
                    <Typography sx={{
                            fontSize: '15px',
                            fontWeight: 'normal',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'left',
                            color: '#555',
                            fontFamily: 'Inter',
                        }}>Click</Typography>

                    <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            color: '#000',
                            paddingTop: '4px'
                        }}>
                           5
                        </Typography>
                    </Grid>

                    <Grid md={3} xs={3} sx={{ marginTop: '10px',marginBottom: '20px' }}>
                        <Grid sx={{
                            padding: '10px',
                            borderRadius: '30px',
                            backgroundColor: '#1877F2',
                            width: '40px',
                            height: '40px',
                            display: 'grid',
                            placeContent: 'center',
                            marginBottom: '8px'
                        }}>
                            <img src={LocationIcon3} style={{width:'19px'}}/>
                        </Grid>
                    </Grid>
                    <Grid md={3} xs={3}>
                    <Typography sx={{
                            fontSize: '15px',
                            fontWeight: 'normal',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'left',
                            color: '#555',
                            fontFamily: 'Inter',
                        }}>Reach</Typography>
                    <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            color: '#000',
                            paddingTop: '4px'
                        }}>
                           5
                        </Typography>
                    </Grid>

                    <Grid md={3} xs={3} sx={{ marginTop: '10px' }}>
                        <Grid sx={{
                            padding: '10px',
                            borderRadius: '30px',
                            backgroundColor: '#1877F2',
                            width: '40px',
                            height: '40px',
                            display: 'grid',
                            placeContent: 'center',
                            marginBottom: '8px'
                        }}>
                            <img src={LocationIcon2} style={{width:'12px'}}/>
                        </Grid>
                    </Grid>
                    <Grid md={3} xs={3}>
                    <Typography sx={{
                            fontSize: '15px',
                            fontWeight: 'normal',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'left',
                            color: '#555',
                            fontFamily: 'Inter',
                        }}>Budget</Typography>

                          <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            color: '#000',
                            paddingTop: '4px'
                        }}>
                           Rs. {PaiseToRupees(ad_budget)}
                        </Typography>
                    </Grid>


                    <Grid md={3} xs={3}>
                        <Grid sx={{
                            padding: '10px',
                            borderRadius: '30px',
                            backgroundColor: '#1877F2',
                            width: '40px',
                            height: '40px',
                            display: 'grid',
                            placeContent: 'center',
                            marginBottom: '8px'
                        }}>
                            <img src={LocationIcon1} style={{width:'19px'}}/>
                        </Grid>
                     </Grid>

                    <Grid md={3} xs={3}>
                    <Typography sx={{
                            fontSize: '15px',
                            fontWeight: 'normal',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'left',
                            color: '#555',
                            fontFamily: 'Inter',
                        }}>Location</Typography>
                        <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            color: '#000',
                        }}>
                        {city !== '' ? city : ''}
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            color: '#000',
                        }}>
                        {state !== '' ? state : ''} 
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            color: '#000',
                        }}>
                        {countryName !== '' ? countryName : ''}
                        </Typography>
                    </Grid>
                    
                    <Grid md={3} xs={3} sx={{ textAlign: 'center' }}>
                        <Grid sx={{
                            padding: '10px',
                            borderRadius: '30px',
                            backgroundColor: '#1877F2',
                            width: '40px',
                            height: '40px',
                            display: 'grid',
                            placeContent: 'center',
                            marginBottom: '8px'
                        }}>
                            <img src={LocationIcon2} style={{width:'12px'}}/>
                        </Grid>
                    </Grid>
                    <Grid md={3} xs={3}>
                        <Typography sx={{
                            fontSize: '15px',
                            fontWeight: 'normal',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'left',
                            color: '#555',
                            fontFamily: 'Inter',
                        }}>Age</Typography>
                        <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            color: '#000',
                        }}>
                            {age_min}-{age_max}
                        </Typography>
                    </Grid>

                    <Grid md={3} xs={3} sx={{ marginTop: '20px' }}>
                        <Grid sx={{
                            padding: '10px',
                            borderRadius: '30px',
                            backgroundColor: '#1877F2',
                            width: '40px',
                            height: '40px',
                            display: 'grid',
                            placeContent: 'center',
                            marginBottom: '8px'
                        }}>
                            <img src={LocationIcon1} style={{width:'19px'}}/>
                        </Grid>
                    </Grid>

                    <Grid md={3} xs={3} sx={{ marginTop: '20px' }}>
                        
                        <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            color: '#000',
                        }}>
                       Date
                        </Typography>
                      <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            color: '#000',
                        }}>
                        {formatDate(ad_startdate)} - {formatDate(ad_enddate)}
                        </Typography>
                    </Grid>

                    <Grid md={3} xs={3} sx={{ textAlign: 'center',marginTop: '20px' }}>
                        <Grid sx={{
                            padding: '10px',
                            borderRadius: '30px',
                            backgroundColor: '#1877F2',
                            width: '40px',
                            height: '40px',
                            display: 'grid',
                            placeContent: 'center',
                            marginBottom: '8px'
                        }}>
                            <img src={LocationIcon2} style={{width:'12px'}}/>
                        </Grid>
                    </Grid>

                    <Grid md={3} xs={3} sx={{ textAlign: 'center',marginTop: '20px' }}>
                        <Typography sx={{
                            fontSize: '15px',
                            fontWeight: 'normal',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'left',
                            color: '#555',
                            fontFamily: 'Inter',
                        }}>Gender</Typography>
                        <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            color: '#000',
                        }}>
                            All
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
          
        </>
    )
}

export default FacebookCampaign