import {  Grid, Typography } from "@mui/material"

const ErrorPage = () => {
    return (
        <>
            <Grid className="second-section">
                <Grid item md={12} sx={{textAlign:'center'}}>
                   <Typography sx={{fontSize:'55px', color:"#000", fontWeight:600, marginBottom:'0px'}}>
                   404 
                   </Typography>
                       <Typography sx={{fontSize:'20px', color:'#000', fontWeight:500}}>
                       Not found
                       </Typography>
                       <Typography sx={{fontSize:'15px', color:'#000', pt:1}}>
                       The resource requested could not be found on this server!
                       </Typography>
                </Grid>
            </Grid>
        </>
    )
}


export default ErrorPage