import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import Socialicon1 from "../img/sub-icon-1.svg";
import Likeimg1 from "../img/social-icon-2.png";
import Likeimg2 from "../img/sub-icon-4.png";

const socialImageList = {
  fb: Socialicon1,
  insta: Likeimg1,
  linkedin: Likeimg2,
};

export const PostDetailsModal = ({
  handlePopupClose,
  isOpen,
  dialogTitle,
  popupData,
  selectedSocialMediaType,
}) => {
  return (
    <Dialog
      onClose={handlePopupClose}
      aria-labelledby="scroll-dialog-title"
      open={isOpen}
      fullWidth
      scroll={"paper"}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
      <Typography sx={{fontSize:'14px', textTransform:'capitalize', fontWeight:'600', fontFamily:'Inter'}}>
        <img
          src={socialImageList[selectedSocialMediaType]}
          style={{
            width: "18px",
            verticalAlign: "middle",
            marginRight: "10px",
            marginBottom: "2px",
          }}
        />
          {dialogTitle}
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handlePopupClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers>
        <Typography
          sx={{ fontWeight: "600", fontSize: 13, mt: 1, mb: 1,fontFamily:'Inter' }}
        >
          Image:
        </Typography>
        <Typography variant="body2" sx={{ fontSize: 16, mt: 1, mb: 2,fontFamily:'Inter' }}>
          {popupData?.post_social_media_image_url && popupData?.post_social_media_image_url.includes("[")
            ? (JSON.parse(
              (popupData?.post_social_media_image_url).replaceAll(
                /\\/g,
                "\\\\"
              )
            ).map((image, index) => (
              <>
                <img
                  src={image}
                  style={{
                    width: "50px",
                    borderRadius: "5px",
                    height: "35px",
                    overflow: "hidden",
                    verticalAlign: "middle",
                    marginRight: '10px',
                  }}
                  key={index}
                />
              </>
            )))
            : (popupData?.post_social_media_image_url && (
              <img
                src={popupData?.post_social_media_image_url.split('|')[0]}
                style={{
                  width: "50px",
                  borderRadius: "5px",
                  height: "35px",
                  overflow: "hidden",
                  verticalAlign: "middle",
                }}
              />
            ))}
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontWeight: "600", fontSize: 13, mt: 1,fontFamily:'Inter' }}
        >
          Posts:
        </Typography>
        <Typography sx={{ fontSize: 14, fontWeight: '500', mt: 1,fontFamily:'Inter' }}>
          {popupData.message}
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontWeight: "600", fontSize: 13, mt: 1, mb: 1,fontFamily:'Inter' }}
        >
          Post Type:
        </Typography>
        <Typography variant="body2" sx={{ fontSize: 16, mt: 1, mb: 1,fontFamily:'Inter' }}>
          {(selectedSocialMediaType === "fb"
            ? popupData.fb_post_type
            : popupData.post_type
          )?.toLowerCase()}
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontWeight: "600", fontSize: 13, mt: 1, mb: 1,fontFamily:'Inter' }}
        >
          Error Message:
        </Typography>
        <Typography variant="body2" sx={{ fontSize: 16, mt: 1, mb: 1,fontFamily:'Inter' }}>
          {(selectedSocialMediaType === "fb"
            ? popupData.fb_post_type
            : popupData.post_type
          )?.toLowerCase()}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handlePopupClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
