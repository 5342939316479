import { Container, Grid, Typography, } from "@mui/material"
import Footerimg from './Images/footer-logo.png'
import { List, ListItem } from '@mui/material';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const Footersection = {
    marginTop: '10px',
    backgroundColor: '#274bb5',
    padding: '20px',
}
const menustyle = {
    textDecoration: 'unset', fontWeight: 500, fontSize: '15px', width: 'unset', color: '#fff', paddingLeft: '0px', cursor: 'pointer'
}
const Footersectionbottom = {
    backgroundColor: '#011c63',
    padding: '20px',
}
const Footer = () => {
    return (
        <>
            <Grid style={Footersection}>
                <Container>
                    <Grid container item spacing={2}>
                        <Grid item md={4} component={Link} to="/">
                            <img src={Footerimg} />
                        </Grid>
                        <Grid item md={4}>
                            <Typography sx={{ color: '#fff', fontSize: '20px', fontWeight: 500 }}>
                                Explore
                            </Typography>
                            <List sx={{ display: 'block', }}>
                                <ListItem style={menustyle} component={Link} to='/'>
                                    Home
                                </ListItem>
                                <ListItem style={menustyle} component={HashLink} to="/#services" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                                    Services
                                </ListItem>
                                <ListItem style={menustyle} component={HashLink} to="/#product" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                                    Product
                                </ListItem>
                                <ListItem style={menustyle} component={HashLink} to="/#contact" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                                    Contact
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item md={4}>
                            <Typography sx={{ color: '#fff', fontSize: '20px', fontWeight: 500 }}>
                                Legal
                            </Typography>
                            <List sx={{ display: 'block', }}>
                                <ListItem style={menustyle} component={Link} to='/terms_conditions'>
                                    Terms & condition
                                </ListItem>
                                <ListItem style={menustyle} component={Link} to='/privacy_policy'>
                                    Privacy Policy
                                </ListItem>
                                <ListItem style={menustyle} component={Link} to='/faq'>
                                    FAQ
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Grid style={Footersectionbottom}>
                <Typography sx={{ color: '#fff', textAlign: 'center' }}>© Copyright 2022 CARES, Inc. All Right Reserved</Typography>
            </Grid>
        </>
    )
}

export default Footer