import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import styleCss from '../Style/style';
import PropTypes from 'prop-types';
import PinterestIcon from '@mui/icons-material/Pinterest';
import Social1 from '../img/social-icon-1.svg';
import Social2 from '../img/social-icon-2.png';
import Social3 from '../img/social-icon-3.svg';
import Social4 from '../img/social-icon-4.svg';
import Social5 from '../img/social-icon-5.svg';
import Social6 from '../img/social-icon-6.svg';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import '../Style/Loader.css'


const buttonStyle = {
  backgroundColor: '#0d6efd', // Change the background color
  color: 'white', // Change the text color
  // borderRadius: '5px', // Add border-radius for rounded corners
  padding: '10px 20px', // Adjust padding for the desired size
  fontSize: '15px', // Adjust font size for the desired size
  cursor: 'pointer',
  border: '1px'
};



const FacebookLoginButton = ({ onLoginSuccess, onLoginFailure, appId, social_media_app_id }) => {
  console.log("Sankar 2");

  const [loading, setLoading] = useState(false);


  const urlParams = new URLSearchParams(window.location.search);
  const responseFacebook = (response) => {
    setLoading(false);
    if (response.accessToken) {
      onLoginSuccess(response);
    } else {
      onLoginFailure(response);
    }
  };
  const handleClick = () => {
    setLoading(true); // Turn on loading indicator
  };


  const CustomFacebookButton = ({ onClick }) => (
    <Button variant='outlined' style={styleCss.socialbtn} onClick={() => { handleClick(); onClick(); }}>
      {loading ? 'Loading...' : 'Connect'}
    </Button>
  )
  return (
    <>
      {(appId !== null && !urlParams.get('code')) && (
        <div>
          <FacebookLogin
            appId={appId}
            autoLoad={false}
            fields="name,email,picture"
            callback={responseFacebook}
            render={(renderProps) => <CustomFacebookButton onClick={renderProps.onClick} />}
            scope="email,business_management,public_profile,pages_show_list,pages_manage_posts,pages_read_engagement,pages_read_user_content,pages_manage_engagement,pages_manage_metadata,instagram_basic,instagram_content_publish,instagram_manage_insights,instagram_manage_comments,pages_messaging,instagram_manage_messages,ads_read,pages_manage_ads,leads_retrieval,ads_management"
          />
        </div>
      )}
    </>
  );
};

export default FacebookLoginButton;