import { Grid, Box, Divider } from "@mui/material"
import styleCss from "../Style/style"
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import FacebookIcon from '../img/sub-iconnew-1.svg';
import Profileicon from '../img/list-img.jpg'
import { Typography } from "@material-ui/core";
import Pagination from '@mui/material/Pagination';
import React, { useEffect, useState } from 'react';
import InstaIcon from '../img/leads-insta.png';
import GoogleIcon from '../img/sub-icon-6.png';
import { authAxios, authpostAxios } from "../Utilities/commonAxios";
import { useDispatch } from 'react-redux';
import moment from "moment";


const Leadssocial = () => {
    const dispatch = useDispatch();
    const [fbmetaLeads, setFbMetaLeads] = useState([])
    const [instametaLeads, setInstaMetaLeads] = useState([])
    const [paginationValue, setPaginationValue] = useState(1);
    const [tabValue, setTabValue] = useState('1');

    const getAPIUr = (tabValue) => {
        if (tabValue == '1') {
            return `${process.env.REACT_APP_BACKENDURL}api/get-fb-meta-leads`
        } else if (tabValue == '2') {
            return `${process.env.REACT_APP_BACKENDURL}api/get-ig-meta-leads`
        }
    }
    const getMetaLeadsFromAPI = async () => {
        setFbMetaLeads([]);
        await authpostAxios(
            {
                url: getAPIUr(tabValue),
                data: {
                    paginationValue: paginationValue,
                },
            },
            dispatch
        ).then((response) => {
            if (response?.data?.flag == 1) {
                setFbMetaLeads(response?.data?.data[0]);
                setInstaMetaLeads(response?.data?.data[1]);
            } else {
                setFbMetaLeads([]);
                setInstaMetaLeads([]);
            }

        });
    };
    useEffect(() => {
        getMetaLeadsFromAPI()
    }, [paginationValue, tabValue]);

    useEffect(() => {
        setPaginationValue(1);
    }, [tabValue]);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };


    const handlePaginationChange = (event, value) => {
        setPaginationValue(value);
    };


    return (
        <>
            <Grid style={{ ...styleCss.commonpaddingtop, }}>
                <Grid container item columnSpacing={2}>
                    <Grid item md={12}>
                        <Box sx={{ width: '100%', }}>
                            <TabContext value={tabValue}>
                                <Box>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab label="Facebook" value="1" sx={{
                                            fontFamily: 'Inter, sans-serif',
                                            fontSize: '13px',
                                            '&.Mui-selected': {
                                                backgroundColor: '#e7e4e4',
                                                fontSize: '14px',
                                            },
                                        }} />
                                        <Tab label="Instagram" value="2" sx={{ fontFamily: 'inter', fontSize: '13px', '&.Mui-selected': { backgroundColor: '#e7e4e4', fontSize: '14px', } }} />
                                        <Tab label="Google Buisness" value="3" sx={{ fontFamily: 'inter', fontSize: '13px', '&.Mui-selected': { backgroundColor: '#e7e4e4', fontSize: '14px', } }} />
                                    </TabList>
                                </Box>

                                <TabPanel value="1">
                                    <Grid container item spacing={2}>
                                        {fbmetaLeads?.length > 0 &&
                                            fbmetaLeads?.map((list, index) => (
                                                <Grid item md={3} xs={12}>
                                                    <Grid sx={{
                                                        ...styleCss.Listgroupbox, background: '#fff'
                                                    }}>
                                                        <Grid sx={{ padding: '10px 10px 0px 10px' }}>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'flex-start',
                                                                    marginTop: '3px',
                                                                }}
                                                            >
                                                                <Grid sx={{ marginRight: '15px', position: 'relative' }}>
                                                                    <img src={list?.page_profile}
                                                                        style={{ width: '50px', height: '50px', borderRadius: '5px' }}
                                                                    />
                                                                    <img src={FacebookIcon}
                                                                        style={{
                                                                            position: 'absolute',
                                                                            right: '-8px',
                                                                            bottom: '8px',
                                                                            width: '15px',
                                                                        }} />
                                                                </Grid>
                                                                <Grid sx={{ alignSelf: 'center' }}>
                                                                    <Typography
                                                                        style={{
                                                                            color: '#1877f2',
                                                                            fontFamily: 'Inter',
                                                                            fontSize: '13px',
                                                                            fontWeight: 600,
                                                                        }}
                                                                    >
                                                                        {list?.page_name}
                                                                    </Typography>
                                                                    <Typography style={{
                                                                        color: '#7e7e7e',
                                                                        fontFamily: 'Inter',
                                                                        fontSize: '11px',
                                                                        fontWeight: 600,
                                                                    }}>
                                                                        Facebook
                                                                    </Typography>
                                                                </Grid>
                                                            </Box>
                                                            {/* {list?.leadgen_json_data.length > 0 &&
                                                                list?.leadgen_json_data?.map((leadData, index) => ( */}
                                                            <Grid>
                                                                <Typography style={{
                                                                    color: '#000',
                                                                    fontFamily: 'Inter',
                                                                    fontSize: '13px',
                                                                    fontWeight: 400,
                                                                    marginTop: '5px',
                                                                    marginBottom: '5px',
                                                                }}>
                                                                    <span style={{
                                                                        color: '#7e7e7e',
                                                                        fontFamily: 'Inter',
                                                                        fontSize: '12px',
                                                                    }}>Lead Created  :</span>{moment(list?.lead_created_at).subtract(1, 'years').subtract(1, 'months').set('second', 30).format('DD MMM YYYY HH:mm:ss')}</Typography>
                                                            </Grid>
                                                            {/* ))} */}
                                                        </Grid>
                                                        <Divider />
                                                        {list?.leadgen_json_data.length > 0 &&
                                                            list?.leadgen_json_data?.map((leadData, index) => (
                                                                <React.Fragment key={index}>
                                                                    <Grid sx={{ padding: '0px 10px 0px 10px' }}>
                                                                        <Grid>
                                                                            <Typography style={{
                                                                                color: '#000',
                                                                                fontFamily: 'Inter',
                                                                                fontSize: '13px',
                                                                                fontWeight: 400,
                                                                                marginTop: '5px',
                                                                                marginBottom: '7px',
                                                                            }}>
                                                                                <span style={{
                                                                                    color: '#7e7e7e',
                                                                                    fontFamily: 'Inter',
                                                                                    fontSize: '12px',
                                                                                }}>{leadData.name}:</span> {leadData.values}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Divider />
                                                                </React.Fragment>
                                                            ))}
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        <Grid md={12} sx={{ marginTop: '20px', justifyContent: 'flex-end', display: 'flex' }}>
                                            <Pagination count={10} shape="rounded" page={paginationValue} onChange={handlePaginationChange} />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value="2">
                                    <Grid container item spacing={2}>
                                        {fbmetaLeads?.length > 0 &&
                                            fbmetaLeads?.map((list, index) => (
                                                <Grid item md={3} xs={12}>
                                                    <Grid sx={{
                                                        ...styleCss.Listgroupbox, background: '#fff'
                                                    }}>
                                                        <Grid sx={{ padding: '10px 10px 0px 10px' }}>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'flex-start',
                                                                    marginTop: '3px',
                                                                }}
                                                            >
                                                                <Grid sx={{ marginRight: '15px', position: 'relative' }}>
                                                                    <img src={list?.page_profile}
                                                                        style={{ width: '50px', height: '50px', borderRadius: '5px' }}
                                                                    />
                                                                    <img src={InstaIcon} style={{
                                                                        position: 'absolute',
                                                                        right: '-8px',
                                                                        bottom: '8px',
                                                                        width: '15px',
                                                                    }} />
                                                                </Grid>
                                                                <Grid sx={{ alignSelf: 'center' }}>
                                                                    <Typography
                                                                        style={{
                                                                            color: '#d32eb3',
                                                                            fontFamily: 'Inter',
                                                                            fontSize: '13px',
                                                                            fontWeight: 600,
                                                                        }}
                                                                    >
                                                                        {list?.page_name}
                                                                    </Typography>
                                                                    <Typography style={{
                                                                        color: '#7e7e7e',
                                                                        fontFamily: 'Inter',
                                                                        fontSize: '11px',
                                                                        fontWeight: 600,
                                                                    }}>
                                                                        Instagram
                                                                    </Typography>
                                                                </Grid>
                                                            </Box>
                                                            <Grid>
                                                                <Typography style={{
                                                                    color: '#000',
                                                                    fontFamily: 'Inter',
                                                                    fontSize: '13px',
                                                                    fontWeight: 400,
                                                                    marginTop: '5px',
                                                                    marginBottom: '5px',
                                                                }}>
                                                                    <span style={{
                                                                        color: '#7e7e7e',
                                                                        fontFamily: 'Inter',
                                                                        fontSize: '12px',
                                                                    }}>Lead Created at  :</span>{moment(list?.lead_created_at).subtract(1, 'years').subtract(1, 'months').set('second', 30).format('DD MMM YYYY HH:mm:ss')}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Divider />
                                                        {list?.leadgen_json_data.length > 0 &&
                                                            list?.leadgen_json_data?.map((leadData, index) => (
                                                                <React.Fragment key={index}>
                                                                    <Grid sx={{ padding: '0px 10px 0px 10px' }}>
                                                                        <Grid>
                                                                            <Typography style={{
                                                                                color: '#000',
                                                                                fontFamily: 'Inter',
                                                                                fontSize: '13px',
                                                                                fontWeight: 400,
                                                                                marginTop: '5px',
                                                                                marginBottom: '7px',
                                                                            }}>
                                                                                <span style={{
                                                                                    color: '#7e7e7e',
                                                                                    fontFamily: 'Inter',
                                                                                    fontSize: '12px',
                                                                                }}>{leadData.name}:</span> {leadData.values}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Divider />
                                                                </React.Fragment>
                                                            ))}
                                                        {/* <Grid sx={{ padding: '0px 10px 0px 10px' }}>
                                                            <Grid>
                                                                <Typography style={{
                                                                    color: '#000',
                                                                    fontFamily: 'Inter',
                                                                    fontSize: '13px',
                                                                    fontWeight: 400,
                                                                    marginTop: '5px',
                                                                    marginBottom: '7px',
                                                                }}>
                                                                    <span style={{
                                                                        color: '#7e7e7e',
                                                                        fontFamily: 'Inter',
                                                                        fontSize: '12px',
                                                                    }}>Location:</span> Indonasia</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Divider />
                                                        <Grid sx={{ padding: '0px 10px 0px 10px' }}>
                                                            <Grid>
                                                                <Typography style={{
                                                                    color: '#000',
                                                                    fontFamily: 'Inter',
                                                                    fontSize: '13px',
                                                                    fontWeight: 400,
                                                                    marginTop: '5px',
                                                                    marginBottom: '5px',
                                                                }}>
                                                                    <span style={{
                                                                        color: '#7e7e7e',
                                                                        fontFamily: 'Inter',
                                                                        fontSize: '12px',
                                                                    }}>Location:</span> Indonasia</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Divider />
                                                        <Grid sx={{ padding: '0px 10px 0px 10px' }}>
                                                            <Grid>
                                                                <Typography style={{
                                                                    color: '#000',
                                                                    fontFamily: 'Inter',
                                                                    fontSize: '13px',
                                                                    fontWeight: 400,
                                                                    marginTop: '5px',
                                                                    marginBottom: '15px',
                                                                }}>
                                                                    <span style={{
                                                                        color: '#7e7e7e',
                                                                        fontFamily: 'Inter',
                                                                        fontSize: '12px',
                                                                    }}>Location:</span> Indonasia</Typography>
                                                            </Grid>
                                                        </Grid> */}
                                                    </Grid>

                                                </Grid>
                                            ))}
                                        {/* <Grid item md={3} xs={12}>
                                            <Grid sx={{
                                                ...styleCss.Listgroupbox, background: '#fff'
                                            }}>
                                                <Grid sx={{ padding: '10px 10px 0px 10px' }}>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'flex-start',
                                                            marginTop: '3px',
                                                        }}
                                                    >
                                                        <Grid sx={{ marginRight: '15px', position: 'relative' }}>
                                                            <img src={Profileicon}
                                                                style={{ width: '50px', height: '50px', borderRadius: '5px' }}
                                                            />
                                                            <img src={InstaIcon} style={{
                                                                position: 'absolute',
                                                                right: '-8px',
                                                                bottom: '8px',
                                                                width: '15px',
                                                            }} />
                                                        </Grid>
                                                        <Grid sx={{ alignSelf: 'center' }}>
                                                            <Typography
                                                                style={{
                                                                    color: '#d32eb3',
                                                                    fontFamily: 'Inter',
                                                                    fontSize: '13px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                Thomas Djons
                                                            </Typography>
                                                            <Typography style={{
                                                                color: '#7e7e7e',
                                                                fontFamily: 'Inter',
                                                                fontSize: '11px',
                                                                fontWeight: 600,
                                                            }}>
                                                                Instagram
                                                            </Typography>
                                                        </Grid>
                                                    </Box>
                                                    <Grid>
                                                        <Typography style={{
                                                            color: '#000',
                                                            fontFamily: 'Inter',
                                                            fontSize: '13px',
                                                            fontWeight: 400,
                                                            marginTop: '5px',
                                                            marginBottom: '5px',
                                                        }}>
                                                            <span style={{
                                                                color: '#7e7e7e',
                                                                fontFamily: 'Inter',
                                                                fontSize: '12px',
                                                            }}>Email:</span> example@gmail.com</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                                <Grid sx={{ padding: '0px 10px 0px 10px' }}>
                                                    <Grid>
                                                        <Typography style={{
                                                            color: '#000',
                                                            fontFamily: 'Inter',
                                                            fontSize: '13px',
                                                            fontWeight: 400,
                                                            marginTop: '5px',
                                                            marginBottom: '5px',
                                                        }}>
                                                            <span style={{
                                                                color: '#7e7e7e',
                                                                fontFamily: 'Inter',
                                                                fontSize: '12px',
                                                            }}>Phone:</span> 1238545644</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                                <Grid sx={{ padding: '0px 10px 0px 10px' }}>
                                                    <Grid>
                                                        <Typography style={{
                                                            color: '#000',
                                                            fontFamily: 'Inter',
                                                            fontSize: '13px',
                                                            fontWeight: 400,
                                                            marginTop: '5px',
                                                            marginBottom: '7px',
                                                        }}>
                                                            <span style={{
                                                                color: '#7e7e7e',
                                                                fontFamily: 'Inter',
                                                                fontSize: '12px',
                                                            }}>Location:</span> Indonasia</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                                <Grid sx={{ padding: '0px 10px 0px 10px' }}>
                                                    <Grid>
                                                        <Typography style={{
                                                            color: '#000',
                                                            fontFamily: 'Inter',
                                                            fontSize: '13px',
                                                            fontWeight: 400,
                                                            marginTop: '5px',
                                                            marginBottom: '5px',
                                                        }}>
                                                            <span style={{
                                                                color: '#7e7e7e',
                                                                fontFamily: 'Inter',
                                                                fontSize: '12px',
                                                            }}>Location:</span> Indonasia</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                                <Grid sx={{ padding: '0px 10px 0px 10px' }}>
                                                    <Grid>
                                                        <Typography style={{
                                                            color: '#000',
                                                            fontFamily: 'Inter',
                                                            fontSize: '13px',
                                                            fontWeight: 400,
                                                            marginTop: '5px',
                                                            marginBottom: '15px',
                                                        }}>
                                                            <span style={{
                                                                color: '#7e7e7e',
                                                                fontFamily: 'Inter',
                                                                fontSize: '12px',
                                                            }}>Location:</span> Indonasia</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid> */}

                                        <Grid md={12} sx={{ marginTop: '20px', justifyContent: 'flex-end', display: 'flex' }}>
                                            <Pagination count={10} shape="rounded" page={paginationValue} onChange={handlePaginationChange} />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value="3">
                                    <Grid container item spacing={2}>
                                        <Grid item md={3} xs={12}>
                                            <Grid sx={{
                                                ...styleCss.Listgroupbox, background: '#fff'
                                            }}>
                                                <Grid sx={{ padding: '10px 10px 0px 10px' }}>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'flex-start',
                                                            marginTop: '3px',
                                                        }}
                                                    >
                                                        <Grid sx={{ marginRight: '15px', position: 'relative' }}>
                                                            <img src={Profileicon}
                                                                style={{ width: '50px', height: '50px', borderRadius: '5px' }}
                                                            />
                                                            <img src={GoogleIcon} style={{
                                                                position: 'absolute',
                                                                right: '-8px',
                                                                bottom: '8px',
                                                                width: '15px',
                                                            }} />
                                                        </Grid>
                                                        <Grid sx={{ alignSelf: 'center' }}>
                                                            <Typography
                                                                style={{
                                                                    color: '#437ce9',
                                                                    fontFamily: 'Inter',
                                                                    fontSize: '13px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                Thomas Djons
                                                            </Typography>
                                                            <Typography style={{
                                                                color: '#7e7e7e',
                                                                fontFamily: 'Inter',
                                                                fontSize: '11px',
                                                                fontWeight: 600,
                                                            }}>
                                                                Google Buisness
                                                            </Typography>
                                                        </Grid>
                                                    </Box>
                                                    <Grid>
                                                        <Typography style={{
                                                            color: '#000',
                                                            fontFamily: 'Inter',
                                                            fontSize: '13px',
                                                            fontWeight: 400,
                                                            marginTop: '5px',
                                                            marginBottom: '5px',
                                                        }}>
                                                            <span style={{
                                                                color: '#7e7e7e',
                                                                fontFamily: 'Inter',
                                                                fontSize: '12px',
                                                            }}>Email:</span> example@gmail.com</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                                <Grid sx={{ padding: '0px 10px 0px 10px' }}>
                                                    <Grid>
                                                        <Typography style={{
                                                            color: '#000',
                                                            fontFamily: 'Inter',
                                                            fontSize: '13px',
                                                            fontWeight: 400,
                                                            marginTop: '5px',
                                                            marginBottom: '5px',
                                                        }}>
                                                            <span style={{
                                                                color: '#7e7e7e',
                                                                fontFamily: 'Inter',
                                                                fontSize: '12px',
                                                            }}>Phone:</span> 1238545644</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                                <Grid sx={{ padding: '0px 10px 0px 10px' }}>
                                                    <Grid>
                                                        <Typography style={{
                                                            color: '#000',
                                                            fontFamily: 'Inter',
                                                            fontSize: '13px',
                                                            fontWeight: 400,
                                                            marginTop: '5px',
                                                            marginBottom: '7px',
                                                        }}>
                                                            <span style={{
                                                                color: '#7e7e7e',
                                                                fontFamily: 'Inter',
                                                                fontSize: '12px',
                                                            }}>Location:</span> Indonasia</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                                <Grid sx={{ padding: '0px 10px 0px 10px' }}>
                                                    <Grid>
                                                        <Typography style={{
                                                            color: '#000',
                                                            fontFamily: 'Inter',
                                                            fontSize: '13px',
                                                            fontWeight: 400,
                                                            marginTop: '5px',
                                                            marginBottom: '5px',
                                                        }}>
                                                            <span style={{
                                                                color: '#7e7e7e',
                                                                fontFamily: 'Inter',
                                                                fontSize: '12px',
                                                            }}>Location:</span> Indonasia</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                                <Grid sx={{ padding: '0px 10px 0px 10px' }}>
                                                    <Grid>
                                                        <Typography style={{
                                                            color: '#000',
                                                            fontFamily: 'Inter',
                                                            fontSize: '13px',
                                                            fontWeight: 400,
                                                            marginTop: '5px',
                                                            marginBottom: '15px',
                                                        }}>
                                                            <span style={{
                                                                color: '#7e7e7e',
                                                                fontFamily: 'Inter',
                                                                fontSize: '12px',
                                                            }}>Location:</span> Indonasia</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Grid sx={{
                                                ...styleCss.Listgroupbox, background: '#fff'
                                            }}>
                                                <Grid sx={{ padding: '10px 10px 0px 10px' }}>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'flex-start',
                                                            marginTop: '3px',
                                                        }}
                                                    >
                                                        <Grid sx={{ marginRight: '15px', position: 'relative' }}>
                                                            <img src={Profileicon}
                                                                style={{ width: '50px', height: '50px', borderRadius: '5px' }}
                                                            />
                                                            <img src={GoogleIcon} style={{
                                                                position: 'absolute',
                                                                right: '-8px',
                                                                bottom: '8px',
                                                                width: '15px',
                                                            }} />
                                                        </Grid>
                                                        <Grid sx={{ alignSelf: 'center' }}>
                                                            <Typography
                                                                style={{
                                                                    color: '#437ce9',
                                                                    fontFamily: 'Inter',
                                                                    fontSize: '13px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                Thomas Djons
                                                            </Typography>
                                                            <Typography style={{
                                                                color: '#7e7e7e',
                                                                fontFamily: 'Inter',
                                                                fontSize: '11px',
                                                                fontWeight: 600,
                                                            }}>
                                                                Google Buisness
                                                            </Typography>
                                                        </Grid>
                                                    </Box>
                                                    <Grid>
                                                        <Typography style={{
                                                            color: '#000',
                                                            fontFamily: 'Inter',
                                                            fontSize: '13px',
                                                            fontWeight: 400,
                                                            marginTop: '5px',
                                                            marginBottom: '5px',
                                                        }}>
                                                            <span style={{
                                                                color: '#7e7e7e',
                                                                fontFamily: 'Inter',
                                                                fontSize: '12px',
                                                            }}>Email:</span> example@gmail.com</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                                <Grid sx={{ padding: '0px 10px 0px 10px' }}>
                                                    <Grid>
                                                        <Typography style={{
                                                            color: '#000',
                                                            fontFamily: 'Inter',
                                                            fontSize: '13px',
                                                            fontWeight: 400,
                                                            marginTop: '5px',
                                                            marginBottom: '5px',
                                                        }}>
                                                            <span style={{
                                                                color: '#7e7e7e',
                                                                fontFamily: 'Inter',
                                                                fontSize: '12px',
                                                            }}>Phone:</span> 1238545644</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                                <Grid sx={{ padding: '0px 10px 0px 10px' }}>
                                                    <Grid>
                                                        <Typography style={{
                                                            color: '#000',
                                                            fontFamily: 'Inter',
                                                            fontSize: '13px',
                                                            fontWeight: 400,
                                                            marginTop: '5px',
                                                            marginBottom: '7px',
                                                        }}>
                                                            <span style={{
                                                                color: '#7e7e7e',
                                                                fontFamily: 'Inter',
                                                                fontSize: '12px',
                                                            }}>Location:</span> Indonasia</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                                <Grid sx={{ padding: '0px 10px 0px 10px' }}>
                                                    <Grid>
                                                        <Typography style={{
                                                            color: '#000',
                                                            fontFamily: 'Inter',
                                                            fontSize: '13px',
                                                            fontWeight: 400,
                                                            marginTop: '5px',
                                                            marginBottom: '5px',
                                                        }}>
                                                            <span style={{
                                                                color: '#7e7e7e',
                                                                fontFamily: 'Inter',
                                                                fontSize: '12px',
                                                            }}>Location:</span> Indonasia</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                                <Grid sx={{ padding: '0px 10px 0px 10px' }}>
                                                    <Grid>
                                                        <Typography style={{
                                                            color: '#000',
                                                            fontFamily: 'Inter',
                                                            fontSize: '13px',
                                                            fontWeight: 400,
                                                            marginTop: '5px',
                                                            marginBottom: '15px',
                                                        }}>
                                                            <span style={{
                                                                color: '#7e7e7e',
                                                                fontFamily: 'Inter',
                                                                fontSize: '12px',
                                                            }}>Location:</span> Indonasia</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid md={12} sx={{ marginTop: '20px', justifyContent: 'flex-end', display: 'flex' }}>
                                            <Pagination count={10} shape="rounded" />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Grid>
                </Grid >
            </Grid >
        </>
    )
}

export default Leadssocial