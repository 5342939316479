import React from 'react';
import { GoogleOAuthProvider, GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { useLocation } from 'react-router-dom';
import { authpostAxios, nonAuthpostAxios } from "../Utilities/commonAxios";
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { Button } from "@mui/material";
import styleCss from '../Style/style';
import { toast } from "react-toastify";


function GoogleLoginButton() {
  return (
    <div>
      <GoogleOAuthProvider clientId="537621112167-04fjvq03vcjc6mp072qop85sm1bnanvn.apps.googleusercontent.com">
        <GoogleLoginComponent />
      </GoogleOAuthProvider>
    </div>
  );
}

function GoogleLoginComponent() {
  const dispatch = useDispatch();
  const login = useGoogleLogin({
    scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/yt-analytics.readonly https://www.googleapis.com/auth/youtube.force-ssl https://www.googleapis.com/auth/youtube.upload',
    onSuccess: async (tokenResponse) => {
      try {
        // Fetch user information from Google OAuth userinfo endpoint
        const userInfoResponse = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        });

        // Extract user information from response data
        const userInfo = userInfoResponse.data;

        // Send tokenResponse and userInfo to backend server
        const response = await authpostAxios({
          url: `${process.env.REACT_APP_BACKENDURL}api/youtube-login`,
          method: 'POST',
          data: {
            tokenResponse,
            userInfo,
          },
        }, dispatch);
        if (response?.data?.flag === 1) {
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
        }
        console.log('Response from backend:', response);

      } catch (error) {
        console.error('Error sending authentication response to backend:', error);
      }
    },
    prompt: 'select_account',
  });

  const CustomLinkedinButton = () => (
    <Button variant='outlined' style={styleCss.socialbtn} onClick={() => login()}>
      Connect
    </Button>
  );

  return (
    <CustomLinkedinButton />
  );
  
}

/*
function GoogleLoginComponent() {
  const dispatch = useDispatch();
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        // Fetch user information from Google OAuth userinfo endpoint
        const userInfoResponse = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        });

        // Extract user information from response data
        const userInfo = userInfoResponse.data;

        // Send tokenResponse and userInfo to backend server
        const response = await authpostAxios({
          url: `${process.env.REACT_APP_BACKENDURL}api/youtube-login`,
          method: 'POST',
          data: {
            tokenResponse,
            userInfo,
          },
        }, dispatch);
        if (response?.data?.flag === 1) {
          toast.success(response?.data?.message)
        } else {
          toast.error(response?.data?.message)
        }
        console.log('Response from backend:', response);

      } catch (error) {
        console.error('Error sending authentication response to backend:', error);
      }
    },
    prompt: 'select_account',
  });
  */
  /*
  const CustomLinkedinButton = () => (
    <Button variant='outlined' style={styleCss.socialbtn} onClick={() => login()}>
      Connect
    </Button>
  );

  return (
    <CustomLinkedinButton />
  );
 
}
 */

export default GoogleLoginButton;
