import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { socialAuthLogin } from "../Reduxstore/authSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const SSOLogin = () => {
  const navigate = useNavigate();
  const { apiUserToken } = useParams();
  const dispatch = useDispatch();
  const getSocialLogin = async () => {
    const { payload: socialLoginResponse } = await dispatch(
      socialAuthLogin({ apiUserToken })
    );
    if (socialLoginResponse.flag === 1) {
      localStorage.setItem("userToken", socialLoginResponse.data.userToken);
      navigate("/dashboard");
      toast.success(socialLoginResponse.message);
    } else {
      toast.error(socialLoginResponse.message);
    }
  };
  useEffect(() => {
    getSocialLogin();
  }, [apiUserToken]);
};

export default SSOLogin;
