import moment from "moment";
export const dateDiff = (date) => {
    if ((moment()).diff(moment(date), 'hours') < 1) {
        return `${(moment()).diff(moment(date), 'minutes')} min ago`;
    } else if ((moment()).diff(moment(date), 'days') < 1) {
        return `${(moment()).diff(moment(date), 'hours')} hours ago`;
    } else if ((moment()).diff(moment(date), 'weeks') < 1) {
        return `${(moment()).diff(moment(date), 'days')} days ago`;
    } else if ((moment()).diff(moment(date), 'months') < 1) {
        return `${(moment()).diff(moment(date), 'weeks')} week ago`;
    } else if ((moment()).diff(moment(date), 'years') < 1) {
        return `${(moment()).diff(moment(date), 'months')} month ago`;
    } else if ((moment()).diff(moment(date), 'years') > 0) {
        return `${(moment()).diff(moment(date), 'years')} years ago`;
    }
}


export const dateTimeDiff = (date) => {
    const now = moment();
    const duration = moment.duration(now.diff(date));
    if (duration['_data'].years > 0) {
        return `${duration['_data'].years} y ago`;
    } else if (duration['_data'].days > 0) {
        return `${duration['_data'].days} d ago`;
    } else if (duration['_data'].hours > 0) {
        return `${duration['_data'].hours} h ago`;
    } else if (duration['_data'].minutes > 0) {
        return `${duration['_data'].minutes} m ago`;
    } else if (duration['_data'].seconds > 0) {
        return `${duration['_data'].seconds} s ago`;
    }
}


