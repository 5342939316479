import axios from "axios";
import { manageUserSession } from "../Reduxstore/authSlice";

export const nonAuthpostAxios = async (input) => {
  return await axios.post(input.url, input.data, { headers: { 'Content-Type': 'application/json', 'mode': 'cors' } })
}

export const authpostAxios = async (input, dispatch) => {
  let authpostAxiosResponse;
  if (input.data === "") {
    authpostAxiosResponse = await axios.post(input.url, {}, {
      headers: { 'Content-Type': 'application/json', 'mode': 'cors', 'Authorization': `Bearer ${localStorage.getItem('userToken')}` }
    })
  } else {
    authpostAxiosResponse = await axios.post(input.url, input.data, {
      headers: { 'Content-Type': 'application/json', 'mode': 'cors', 'Authorization': `Bearer ${localStorage.getItem('userToken')}` }
    })
  }
  const { statusCode } = authpostAxiosResponse.data;
  if (statusCode !== 200) {
    dispatch(manageUserSession('expired'));
  }
  return authpostAxiosResponse;
}

export const authAxios = async (input, dispatch) => {
  let authgetAxiosResponse;
  authgetAxiosResponse = await axios.get(input.url, {}, {
    headers: { 'Content-Type': 'application/json', 'mode': 'cors', 'Authorization': `Bearer ${localStorage.getItem('userToken')}` }
  })
  const { statusCode } = authgetAxiosResponse.data;
  if (statusCode !== 200) {
    dispatch(manageUserSession('expired'));
  }
  return authgetAxiosResponse;
}
