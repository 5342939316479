import { Grid, Typography } from "@mui/material";
import styleCss from "../Style/style";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Link, useNavigate } from "react-router-dom";
import ShortcutOutlinedIcon from "@mui/icons-material/ShortcutOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import noImage from "../img/no-image.jpg";
import Socialselect from "../SocialPost/socialSelect";
import { useFormik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { authpostAxios } from "../Utilities/commonAxios";
import React, { useState, useEffect } from "react";
import { dateDiff,dateTimeDiff } from "../Utilities/customDate";
import { socialMediaLists } from "../Utilities/socialMediaLists";
import moment from "moment";
import _ from "lodash";
import { confirmationAlertwithButtonForUserCreation } from '../Utilities/sweetAlert';


const Readinbox = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [readCommentLists, setReadCommentLists] = useState([]);
  const submitSocialForm = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      facebookSelectAll: false,
      facebookSelectedPages: [],
      instaSelectAll: false,
      instaSelectedAccounts: [],
      linkedInPageSelectAll: false,
      linkedInSelectedPages: [],
      linkedInAccountSelectAll: false,
      linkedInSelectedAccounts: [],
      youtubeAccountSelectAll: false,
      youtubeSelectedAccounts: [],
      twitterAccountSelectAll: false,
      twitterSelectedAccounts: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      //console.log("values",values);
      let fbComments = [];
      if (values.facebookSelectedPages.length > 0) {
        let fbCommentsResponse = await authpostAxios({
          'url': `${process.env.REACT_APP_BACKENDURL}api/get-fb-readed-comments`,
          'data': {
            'socialMediaId': 1,
            'socialMediaPageId': ((values.facebookSelectedPages.length > 0) ? (_.join((_.map(values.facebookSelectedPages, data => data.social_media_page_id)), ',')) : (''))
          }
        }, dispatch)
        if (fbCommentsResponse?.data?.data !== null) fbComments = fbCommentsResponse?.data?.data;
      } else {
        fbComments = [];
      }
      let instaComments = [];
      if (values.instaSelectedAccounts.length > 0) {
        let instaCommentsResponse = await authpostAxios({
          'url': `${process.env.REACT_APP_BACKENDURL}api/get-insta-readed-comments`,
          'data': {
            'socialMediaId': 4,
            'socialMediaAccountId': ((values.instaSelectedAccounts.length > 0) ? (_.join((_.map(values.instaSelectedAccounts, data => data.social_media_account_id)), ',')) : (''))
          }
        }, dispatch)
        if (instaCommentsResponse?.data?.data !== null) instaComments = instaCommentsResponse?.data?.data;
      } else {
        instaComments = []
      }
      let youtubeComments = [];
      if (values.youtubeSelectedAccounts.length > 0) {
        let youtubeCommentsResponse = await authpostAxios({
          'url': `${process.env.REACT_APP_BACKENDURL}api/get-youtube-readed-comment`,
          'data': {
            'socialMediaId': 5,
            'socialMediaAccountId': ((values.youtubeSelectedAccounts.length > 0) ? (_.join((_.map(values.youtubeSelectedAccounts, data => data.social_media_account_id)), ',')) : (''))
          }
        }, dispatch)
        if (youtubeCommentsResponse?.data?.data !== null) youtubeComments = youtubeCommentsResponse?.data?.data
      } else {
        youtubeComments = []
      }
      setReadCommentLists([...fbComments, ...instaComments, ...youtubeComments])

    }
  });



  useEffect(() => {
    submitSocialForm.submitForm()
  }, [submitSocialForm.values.facebookSelectedPages, submitSocialForm.values.instaSelectedAccounts, submitSocialForm.values.linkedInSelectedPages, submitSocialForm.values.youtubeSelectedAccounts])

  const handleSocialMediaCommentDelete = async (commentTitle, parentChildId, socialMediaId) => {
    const confirmationAlertResponse = await confirmationAlertwithButtonForUserCreation("Are you sure you want to delete the comment")
    if (confirmationAlertResponse.isConfirmed) {
      await authpostAxios(
        {
          url: `${process.env.REACT_APP_BACKENDURL}api/get-parent-child-read-comment`,
          data: {
            commentTitle,
            socialMediaId,
            parentChildId
          }
        }, dispatch).then(updResponse => {
          if (updResponse.data?.flag === 1) {
            toast.success(updResponse.data.message)
            submitSocialForm.submitForm()
          } else {
            toast.error(updResponse.data.message)
          }
        })
    }
  }


  


  return (
    <>
      <Grid style={styleCss.commonpaddingtop}>
        <form onSubmit={submitSocialForm.handleSubmit}>
          <Grid
            container
            sx={{ border: "solid 1px #edeced", borderRadius: "7px" }}
          >
            <Grid
              item
              md={2}
              sx={{ borderRight: "1px solid #ddd", padding: "5px" }}
            >
              <Socialselect formik={submitSocialForm} />
            </Grid>
            <Grid item md={10} sx={{ padding: "10px" }}>
              <Typography style={styleCss.Inboxstyle} sx={{ mb: 2 }}>
                Read Inbox
              </Typography>
              <Grid container item columnSpacing={1}>
                {readCommentLists && readCommentLists.length != 0 &&
                  readCommentLists?.map((readCommentList, index) => (
                    <Grid item md={3} sx={{ position: "relative" }} key={index}>
                      <Grid
                        sx={{
                          border: "1px solid #dddddd6b",
                          borderRadius: "3px",
                          padding: "5px",
                          marginBottom: "10px",
                          display: "flex",
                          alignContent: "stretch",
                          maxWidth: 380,
                          height: 150,
                          background: "#0970f712",
                        }}
                      >
                        <Grid container item sx={{ height: "0px" }}>
                          <Grid item md={2} sx={{ alignItems: "center" }}>
                            <Grid style={{ position: "relative" }}>
                              <img
                                src={(readCommentList?.url) ? (readCommentList?.url) : (noImage)}
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "50px",
                                  marginRight: "10px",
                                }}
                              />
                              <img
                                src={(socialMediaLists[readCommentList?.social_media_id]['icon']) ? (socialMediaLists[readCommentList?.social_media_id]['icon']) : (noImage)}
                                style={{
                                  width: "14px",
                                  borderRadius: "50px",
                                  position: "absolute",
                                  bottom: 0,
                                  left: "22px",
                                }}
                              />

                            </Grid>
                          </Grid>
                          <Grid item md={8} sx={{ alignItems: "center" }}>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                fontWeight: 500,
                                textAlign: "left",
                                color: "#27292f !important",
                                fontFamily: "Inter",
                              }}
                            >
                              {readCommentList.comment_name}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                fontWeight: 500,
                                textAlign: "left",
                                color: "#27292f !important",
                                fontFamily: "Inter",
                              }}
                            >
                              {dateDiff(readCommentList.date_comment_post)}
                            </Typography>
                          </Grid>
                          <Grid item md={2} sx={{ textAlign: "end" }}>
                            <Link style={{ color: "#767e87" }} to="#">
                              <DeleteOutlinedIcon onClick={() => { handleSocialMediaCommentDelete(readCommentList?.commandtitle, readCommentList?.c_id, readCommentList?.social_media_id,) }} sx={{ width: "20px" }} />{" "}
                            </Link>
                          </Grid>
                          <Grid md={12} style={{ marginTop: "5px" }}>
                            <Typography
                              sx={{
                                color: "#4b4c4f",
                                fontSize: "11px",
                                fontWeight: 600,
                                textAlign: "left",
                                marginRight: "3px",
                                fontFamily: "Inter",
                              }}
                            >
                              {readCommentList.comment_name}
                            </Typography>
                            <Typography
                              sx={{
                                color: "#767e87",
                                fontFamily: "Inter",
                                fontSize: "12px",
                                fontWeight: "normal",
                                textAlign: "left",
                              }}
                            >
                              <span style={{ color: "#4b4c4f" }}>
                                Commented on:
                              </span>{readCommentList.comments.length >60  ? `${(readCommentList.comments).substring(0, 60)}...etc` : readCommentList.comments }
                            </Typography>
                            <Typography
                              sx={{
                                color: "#767e87",
                                fontFamily: "Inter",
                                fontSize: "12px",
                                fontWeight: "normal",
                                textAlign: "left",
                              }}
                            >
                              <span style={{ color: "#4b4c4f" }}>
                                Comment Type:
                              </span>{readCommentList.commandtitle}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            sx={{
                              marginTop: "5px",
                              textAlign: "end",
                              position: "absolute",
                              bottom: 10,
                              right: 5,
                            }}
                          >
                            <Grid md={10} sx={{ textAlign: "start" }}>
                              <Typography
                                sx={{
                                  color: "#87888e !important",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  paddingLeft: "20px",
                                  marginTop: "15px",
                                }}
                              >
                                {(readCommentList?.date_comment_post) ? moment(readCommentList?.date_comment_post).subtract(1, 'days').format("MMMM D, h:mm A") : ('')}
                              </Typography>
                            </Grid>
                            <Grid md={2} sx={{ textAlign: "end" }}>

                              <Link to={`/socialtimeline/${btoa(readCommentList?.c_id)}/${btoa(readCommentList?.social_media_id)}/${btoa(readCommentList?.commandtitle)}`} style={{ color: "#767e87" }}>
                                <RemoveRedEyeOutlinedIcon
                                  sx={{ width: "20px" }}
                                />
                              </Link>
                            </Grid>
                            
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  )
};

export default Readinbox;