import React from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { Box } from "@mui/material";

const WorldMap = () => {
  return (
    <Box className="map-style">
      <ComposableMap>
        <Geographies geography="/geoData.json">
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography key={geo.rsmKey} geography={geo} />
            ))
          }
        </Geographies>
      </ComposableMap>
    </Box>
  );
};

export default WorldMap;