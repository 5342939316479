const styleCss = {
    Loginlogo: {
        paddingTop: '10px',
        paddingBottom: '10px',
        zIndex: 9,
    },
    WelcomText: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'Left',
        color: '#27292f',
    },
    signText: {
        fontSize: '24px',
        fontWeight: '600',
        textAlign: 'left',
        color: '#27292f',
        marginBottom: '15px',
    },

    Forgotpass: {
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        color: '#27292f'
    },

    SignBtn: {
        fontSize: '14px',
        fontWeight: '500',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#fff',
        borderRadius: '3px',
        backgroundColor: '#27292f',
        width: '100%',
        marginTop: '15px',
        textTransform: 'capitalize',
        padding: '10px',
    },
    account: {
        fontSize: '13px',
        fontWeight: 'normal',
        textAlign: 'center',
        color: '#858687',
        marginTop: '10px',
    },
    linkstyle: {
        color: 'rgb(22, 166, 251)',
        fontSize: '13px',
        fontWeight: 500,
        textDecoration: 'none',
    },

    Headerstyle: {
        backgroundColor: 'rgb(38, 40, 46)',
        padding: '10px',
        position: 'fixed',
        width: '100%',
        zIndex: '9999',
        top: '0px',
        left: '0px',
    },
    liststyle: {
        display: 'inline-block',
        padding: '0px 0px 0px 15px',
        width: 'unset',
    },
    profilestyle: {
        width: '40px',
        height: '40px',
        overflow: 'hidden',
        objectFit: 'cover',
        objectPosition: 'top',
        marginRight: '10px',
        borderRadius: '50px',
    },
    sidemenustyle: {
        backgroundColor: '#fff',
        width: '65px',
        height: '100vh',
        padding: '75px 10px 10px 0px',
        position: 'fixed',
        left: '0px',
        textAlign: 'center',
        boxShadow: '5px 0px 10px 5px rgba(0, 0, 0, 0.05)',
        borderRight: '1px solid #edeced',
    },
    commonpaddingtop: {
        paddingTop: '90px',
        paddingLeft: '70px',
    },
    tabstyle: {
        border: '1px solid #ddd',
        borderRadius: '50px',
        width: '65%',
        minHeight: '40px',
    },
    socialmediaboxstyle: {
        borderRadius: '5px',
        border: 'solid 1px #edeced',
        backgroundColor: '#fff',
        textAlign: 'center',
        padding: '20px 10px',
        width: '100%',
        transition: 'transform 250ms',
    },
    socialtext: {
        fontSize: '15px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#27292f',
        paddingTop: '10px',
        marginBottom: '0px',
        fontFamily: 'Inter',
    },
    socialbtn: {
        padding: '6px 18px',
        borderRadius: '3px',
        border: 'solid 1px #edeced',
        backgroundColor: '#f7f9fb',
        fontSize: '13px',
        fontWeight: '600',
        textAlign: 'left',
        color: '#6a7b8f',
        marginTop: '10px',
        textTransform: 'capitalize',
    },
    pagesadded: {
        fontSize: '13px',
        fontWeight: '500',
        textAlign: 'center',
        color: '#92959d',
        fontFamily: 'Inter',
        paddingTop: '10px',
    },
    cardboxstyle: {
        border: 'solid 1px #edeced',
        borderRadius: '5px',
        textAlign: 'left',
        width: '100%',
    },
    Inboxstyle: {
        fontSize: '14px',
        fontWeight: '600',
        textAlign: 'left',
        color: '#27292f',
        fontFamily: 'Inter',
    },
    Withoutbox: {
        height: '100vh',
        backgroundColor: '#f7f7f7',
        display: 'grid',
        placeContent: 'center',

    },

    Commonbtn: {
        background: '#38cb89',
        color: '#fff',
        borderRadius: '5px',
        fontFamily: 'Inter',
        textTransform: 'capitalize'
    },

    tablecell: {
        fontSize: '14px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '2.14',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#717277',
        fontFamily: 'Inter',
        padding: '10px 3px',
    },
    tableheading: {
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '2.14',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#27292f',
        fontFamily: 'Inter',
        verticalAlign: 'middle',
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '200px',
        marginBottom: '-6px',
    },
    tableheading1: {
        textAlign: 'left',
        width: '150px',
    },
    headingsub: {
        fontSize: '13px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#717277',
        display: 'block',
        fontFamily: 'Inter',
    },
    allsocialicon: {
        width: '15px',
        height: '15px',
        verticalAlign: 'middle',
        marginRight: '-3px',
        zIndex: '9',
        display: 'inline',
        position: 'relativ'
    },
    bondstyletable: {
        fontWeight: '600',
        fontSize: '14px',
        fontFamily: 'Inter',
    },
    facebookacc: {
        boxShadow: '0 3px 6px 0 rgb(0 0 0 / 8%)',
        border: 'solid 1px #efefef',
        borderRadius: '5px',
        overflow: 'hidden'
    },
    facebookinter: {
        backgroundColor: '-webkit-radial-gradient(bottom, #73D6F5 12%, #1E90FF);',
        background: 'radial-gradient(at bottom, #73D6F5 12%, #1E90FF)',
        padding: '10px',
        height: '150px',
        display: 'grid',
        placeContent: 'center',
        position: 'relative',
    },
    cardfooter: {
        border: '1px solid #ddd',
        padding: '10px',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px'
    },
    innertextstyle: {
        color: '#fff',
        fontFamily: 'Inter',
        fontSize: '20px',
        fontWeight: '600',
    },
    innerposttextstyle: {
        textAlign: 'center',
        color: '#0f0f10',
        fontSize: '20px',
        fontWeight: 600,
        position: 'absolute',
        top: '75px',
        left: 0,
        right: 0,
        margin: 0,
        bottom: 0,
    },
    clientHead: {
        fontWeight: 600,
        fontFamily: 'Inter',
        fontSize: '13px',
        padding: '13px 5px',
        textAlign: 'center',
        color: '#252424',
        background: '#f9f4f4',
    },
    clientsubHead: {
        fontWeight: 500,
        fontFamily: 'Inter',
        fontSize: '13px',
        padding: '13px 5px',
        textAlign: 'center',
        color: '#252424',
    },

    mediatypeRightBox: {
        padding: '12px',
        backgroundColor: '#fff',
        boxShadow: 'rgba(0, 0, 0, 0.05) 5px 0px 10px 5px',
    },

    socialBoxBorder: {
        padding: '10px 12px',
        borderRadius: '6px',
        border: 'solid 1px #000',
        textAlign: 'center',
        display: 'grid',
        placeContent: 'center',
    },
    drobDownstyle: {
        border: '1px solid #adadad',
        padding: '8px 15px 8px 25px',
        width: '100%',
        borderRadius: '5px',
        fontFamily: 'Inter',
        fontSize: '15px',
        fontWeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#555',
    },
    datepikerStyle: {
        border: '1px solid #adadad',
        padding: '10px 2px',
        width: '100%',
        borderRadius: '5px',
        fontFamily: 'Inter',
        fontSize: '15px',
        fontWeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#555',
        marginBottom: '10px',
    },

    mediaIconstyle1: {
        position: 'absolute',
        left: '10px',
        margin: 'auto',
        bottom: '0',
        top: '0',
    },
    googleIconStyle: {
        position: 'absolute',
        left: '10px',
        margin: 'auto',
        bottom: '0',
        top: '0px',
        width: '17px'
    },
    mediaIconstyle: {
        position: 'absolute',
        left: '10px',
        margin: 'auto',
        bottom: '0',
        top: '0',
        width: '14px'
    },
    CampaignBox: {
        backgroundColor: '#fff',
        boxShadow: 'rgba(0, 0, 0, 0.05) 5px 0px 10px 5px',
        width: '100%',
        padding: '10px 13px'
    },

    roolstable: {
        backgroundColor: '#ddd', fontSize: '15px', fontWeight: 600, fontFamily: 'Inter, sans-serif',
    },
    roolscell: {
        fontSize: '14px', fontWeight: 600, fontFamily: 'Inter, sans-serif',
    },
    activecell: {
        fontSize: '14px', fontWeight: 600, fontFamily: 'Inter, sans-serif', backgroundColor: '#e8fadd', color: '#72e128', textAlign: 'center',
        borderRadius: '10px',
        padding: '5px'
    },
    inactivecell: {
        fontSize: '14px', fontWeight: 600, fontFamily: 'Inter, sans-serif', backgroundColor: '#e8e9ed', color: '#6d788d', textAlign: 'center',
        borderRadius: '50px',
        padding: '5px'
    },
    pending: {
        fontSize: '14px', fontWeight: 600, fontFamily: 'Inter, sans-serif', backgroundColor: '#fff3dd', color: '#fdb528', textAlign: 'center',
        borderRadius: '50px',
        padding: '5px'
    },
    newcardboxstyle:{
        border: 'solid 1px #edeced',
        borderRadius: '5px',
        textAlign: 'left',
        width: '100%',
        padding:'17px 20px 0px',
        // alignItems: 'center'
    },
    Listgroupbox:{
        backgroundColor: '#fff',
        transition: 'all 0.5s ease-in-out',
        position: 'relative',
        border: '0rem solid transparent',
        borderRadius: '0.75rem',
        boxShadow: '0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)',
        border:'1px solid #eaeaea',
    }
}


export default styleCss