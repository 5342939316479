
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import 'moment/locale/en-gb';
import { Grid } from '@mui/material';
import styleCss from '../Style/style';
import { authpostAxios } from '../Utilities/commonAxios';
import { useDispatch } from "react-redux";
import { Facebook, Instagram, Twitter,LinkedIn,YouTube } from '@mui/icons-material';

const localizer = momentLocalizer(moment);
const MyCalendar = () => {
    const dispatch = useDispatch()
    const [eventsLists, setEventsLists] = useState([]);
    const formatDateTime = (date, type) => {
        let dateObject = (moment(date).format('YYYY-MM-DD-H-m')).split("-")
        return new Date(Number(dateObject[0]), (Number(dateObject[1]) - 1), Number(dateObject[2]), Number(dateObject[3]), ((type === 1) ? (Number(dateObject[4])) : (Number(dateObject[4]) + 1))
        )
    }
    const icons = {
        'Facebook': <Facebook />,
        'Instagram': <Instagram />,
        'Twitter': <Twitter />,
        'Linkedin': <LinkedIn /> ,
        'Youtube': <YouTube />        
    }
    useEffect(() => {
        const getEvents = async () => {
            await authpostAxios({ 'url': `${process.env.REACT_APP_BACKENDURL}api/get-calender-post`, 'data': {} }, dispatch).then(response => {
                let newLists = [];
                response?.data?.data.map(event => {
                    newLists.push({
                        'title': event.social_media_names,
                        'start': formatDateTime(event.created_at, 1),
                        'end': formatDateTime(event.created_at, 2),
                        'message': event.message,
                        'icon': icons[event.social_media_name],
                        'status': event.Name,
                        'social_media_name': event.social_media_name,
                        'user_name' : event.user_name
                    })
                })
                setEventsLists(newLists)
            })
        }
        getEvents()
    }, [])
    const MyEvent = ({ event }) => {
        return (
            <div>
                {event.icon}
                {/* <strong>{event.social_media_name}</strong> */}
                <p>{event.message}</p>
                <p>Status: {event.status}</p>
                <p>Posted By: {event.user_name}</p>
            </div>
        );
    };

    // Function to customize event styles
    const getEventStyle = (event, start, end, isSelected) => {
        return {
            // Customize title color
            color: 'green', // Set title color to green
            backgroundColor: 'yellow', // Set background color of the event
            // You can add more styles here as needed
        };
    };

    // Custom Event component to display the event message, status, and icon
    const EventComponent = ({ event }) => (
        <div>
            {event.icon} {/* Icon */}
            <strong>{event.title}</strong> {/* Event title */}
            <p>{event.message}</p> {/* Display the event message */}
            <p>Status: {event.status}</p> {/* Display the event status */}
            <p>Posted By: {event.user_name}</p>
        </div>
    );

    return (
        <>
            <Grid style={styleCss.commonpaddingtop} sx={{ mb: 3 }}>
                <Calendar
                    localizer={localizer}
                    events={eventsLists}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ flex: 1, height: '100vh', }}
                    components={{ event: MyEvent }}
                />
            </Grid>
        </>
    );
};

export default MyCalendar;