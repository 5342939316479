import { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  Grid,
  TextField,
  Alert
} from "@mui/material";
import { confirmationAlertwithButton, confirmationAlert } from "./sweetAlert";
import { authpostAxios } from "./commonAxios";
import { useDispatch } from "react-redux";
import { manageLoader } from '../Reduxstore/loaderSlice';


export const ReplyCommentModal = ({
  handlePopupClose,
  isOpen,
  dialogTitle,
  popupData,
  commentType,
  setIsOpen,
}) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState("");
  const handleSubmitComment = async () => {
    if (comment !== "" && comment !== null) {
      setIsOpen(false);
      const getStatus = await confirmationAlertwithButton("Yes, post it!");
      if (getStatus.isConfirmed) {
        setIsOpen(false);
        dispatch(manageLoader(true))
        let commentResponse, formData;
        if (popupData?.social_media_id === 1) {
          formData = {
            social_media_page_id: popupData?.social_media_page_id,
            social_media_id: popupData?.social_media_id,
            parent_c_id: popupData?.parent_c_id,
            fb_post_id: popupData?.fb_post_id,
            parent_post_comment_id: popupData?.parent_post_comment_id,
            page_post_id: popupData?.page_post_id,
            message: comment,
            comment_title: commentType,
            child_comment_id: popupData?.child_comment_id
          };
          commentResponse = await authpostAxios({ 'url': `${process.env.REACT_APP_BACKENDURL}api/fb-child-comment-post`, 'data': formData }, dispatch);
        } else if (popupData?.social_media_id === 4) {
          formData = {
            social_media_id: popupData?.social_media_id,
            parent_c_id: popupData?.parent_c_id,
            instagram_post_id: popupData?.instagram_post_id,
            parent_post_comment_id: popupData?.parent_comment_id,
            page_post_id: popupData?.page_post_id,
            instagram_account_id: popupData?.social_media_account_id,
            message: comment,
            comment_title: commentType,
            child_comment_id: popupData?.child_comment_id
          };
          commentResponse = await authpostAxios({ 'url': `${process.env.REACT_APP_BACKENDURL}api/insta-child-comment-post`, 'data': formData }, dispatch);
        }else if (popupData?.social_media_id === 5) {
           formData = {
              youtube_account_id: popupData?.youtube_account_id,
              social_media_id: popupData?.social_media_id,
              parent_c_id: popupData?.parent_c_id,
              youtube_channel_video_id: popupData?.fb_post_id,
              parent_post_comment_id: popupData?.parent_post_comment_id,
              screen_name: popupData?.screen_name,
              profile: commentType = "parent" ? popupData?.parent_profile_url : popupData?.child_profile_url,
              social_auth_user_id : popupData?.social_auth_user_id,
              message: comment,
              comment_title: commentType,
              child_comment_id: popupData?.child_comment_id
          }
          commentResponse = await authpostAxios({ 'url': `${process.env.REACT_APP_BACKENDURL}api/youtube-child-comment-post`, 'data': formData }, dispatch);
      }
        else {
          commentResponse = {};
        }
        if (commentResponse?.data?.flag === 1) {
          setComment(null);
          dispatch(manageLoader(false))
          confirmationAlert(1)
        } else {
          setComment(null);
          dispatch(manageLoader(false))
          confirmationAlert(2)
        }
      } else {
        setIsOpen(true);
      }
    }
  };

  useEffect(() => {
    dispatch(manageLoader(false))

  }, []);
  return (
    <>
      <Dialog
        onClose={handlePopupClose}
        aria-labelledby="scroll-dialog-title"
        open={isOpen}
        fullWidth
        scroll={"paper"}
      >
        <DialogTitle sx={{ textAlign: "center" }}>{dialogTitle}</DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid md={12} sx={{ marginTop: "10px" }}>
              <TextField
                value={comment}
                id="comment"
                name="comment"
                label=""
                multiline
                rows={2}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
                sx={{ width: "100%" }}
              />
              {(comment === null || comment === "") && (
                <>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: 500,
                      textAlign: "left",
                      color: "red !important",
                      fontFamily: "Inter",
                    }}
                  >
                    Please enter comment
                  </Typography>
                </>
              )}
            </Grid>
            <Grid md={12} sx={{ marginTop: "5px", textAlign: "end" }}>
              <Button
                variant="contained"
                sx={{
                  background: "#38cb89",
                  color: "#fff",
                  padding: "5px 15px",
                  textTransform: "capitalize",
                }}
                onClick={handleSubmitComment}
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
