import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  Table,
} from "@mui/material";
import styleCss from "../Style/style";
import { Link, useParams } from "react-router-dom";
import { authpostAxios } from "../Utilities/commonAxios";
import { useDispatch } from "react-redux";
import { PostListTable } from "./postListTable";
import { PostDetailsModal } from "../Utilities/postDetailsModal";
import { Sync } from "@mui/icons-material";
import { toast } from "react-toastify";

const ViewAccountPost = () => {
  let requestData = {};
  let apiLocalFBUrl = "",
    apiLiveFBUrl = "";
  const dispatch = useDispatch();
  const { enPageAccountId, enMediaId } = useParams();
  const [pageAccountId, mediaId] = [atob(enPageAccountId), atob(enMediaId)];
  const [postLists, setPostLists] = useState([]);
  const [postListStatus, setPostListStatus] = useState({});
  if (mediaId === "1") {
    requestData = {
      socialMediaPageId: pageAccountId,
      socialMediaId: mediaId,
    };
    apiLocalFBUrl = `${process.env.REACT_APP_BACKENDURL}api/get-fb-livefeed`;
    apiLiveFBUrl = `${process.env.REACT_APP_BACKENDURL}api/fb-livefeed`;
  } else if (mediaId === "4") {
    //console.log("pageAccountId",pageAccountId);
    requestData = {
      socialMediaAccountId: pageAccountId,
      socialMediaId: mediaId,
    };
    apiLocalFBUrl = `${process.env.REACT_APP_BACKENDURL}api/get-insta-livefeed`;
    apiLiveFBUrl = `${process.env.REACT_APP_BACKENDURL}api/insta-livefeed`;
  }
  const getLocalFBPostListAPI = async () => {
    await authpostAxios(
      {
        url: apiLocalFBUrl,
        data: requestData,
      },
      dispatch
    ).then((response) => {
      setPostLists(response?.data?.data);
      setPostListStatus({
        flag: response?.data?.flag,
        message: response?.data?.message,
      });
    });
  };
  const getLiveFBPostListAPI = async () => {
    await authpostAxios(
      {
        url: apiLiveFBUrl,
        data: requestData,
      },
      dispatch
    );
  };
  useEffect(() => {
    getLocalFBPostListAPI();
  }, []);
  // Post Modal
  const [isOpen, setIsOpen] = useState(false);
  const [popupData, setPopupData] = useState("");
  const [selectedSocialMediaType, setSelectedSocialMediaType] = useState("");
  const handlePopupOpen = (data, socialMedia) => {
    setPopupData(data);
    setIsOpen(true);
    setSelectedSocialMediaType(socialMedia);
  };
  const handlePopupClose = () => setIsOpen(false);
  const handleFetchPost = async () => {
    await getLiveFBPostListAPI();
    await getLocalFBPostListAPI();
    if (postListStatus?.flag === 1) toast.success(postListStatus?.message);
    else toast.error(postListStatus?.message);
  };
  return (
    <>
      <Grid style={styleCss.commonpaddingtop}>
        <Grid
          sx={{
            border: "solid 1px #edeced",
            borderRadius: "7px",
            padding: "20px",
            width: "100%",
          }}
        >
          <Grid item sx={{ marginTop: "5px" }}>
            <Grid container>
              <Grid item md={12} sx={{ textAlign: "end" }}>
                <Button
                  variant="contained"
                  sx={{
                    background: "#0dcaf0",
                    marginRight: "10px",
                    textTransform: "capitalize",
                    fontFamily: "Inter",
                  }}
                  onClick={handleFetchPost}
                >
                  <Sync style={{ fontSize: "19px" }} /> Fetch Post
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    background: "#0b5ed7",
                    marginRight: "10px",
                    textTransform: "capitalize",
                    fontFamily: "Inter",
                  }}
                >
                  Fetch Parent Comment
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    background: "#157347",
                    marginRight: "10px",
                    textTransform: "capitalize",
                    fontFamily: "Inter",
                  }}
                >
                  Fetch Child Comment
                </Button>
              </Grid>
              <Grid md={12} sx={{ marginBottom: "15px" }}></Grid>
              <Box sx={{ width: "100%" }}>
                <PostListTable
                  mediaId={mediaId}
                  pageAccountId={pageAccountId}
                  lists={postLists}
                  handlePopupOpen={handlePopupOpen}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <PostDetailsModal
        handlePopupClose={handlePopupClose}
        isOpen={isOpen}
        dialogTitle="VIEW POST DETAILS"
        popupData={popupData}
        selectedSocialMediaType={selectedSocialMediaType}
      />
    </>
  );
};

export default ViewAccountPost;
