import {
  Radio, RadioGroup, Grid, Switch, Card, Tooltip, Box, FormLabel, Typography, TextField, Table, Checkbox, TableBody, Button, TableCell, TableContainer, TableHead, TableRow, Paper, InputLabel, MenuItem, FormControl, FormControlLabel, Select,
} from "@mui/material";

import styleCss from "../Style/style"
import React, { useState, useEffect } from "react";
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import { Formik, Form, Field, useFormik, ErrorMessage } from 'formik';

import * as Yup from 'yup';
import { authpostAxios } from "../Utilities/commonAxios";
import { useAutocomplete } from '@mui/base/useAutocomplete';
import { styled } from '@mui/system';
import Select2 from 'react-select';
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CommentIcon from '@mui/icons-material/Comment';
import ShareIcon from '@mui/icons-material/Share';
import { fileToBase64 } from "../Utilities/fileConversion";
import Swal from 'sweetalert2';
import '../Style/Loader.css'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs'; // Import Dayjs
import 'dayjs/locale/en'; 
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import '../Style/table_style.css';
import FacebookIcon from '@material-ui/icons/Facebook';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import { manageLoader } from '../Reduxstore/loaderSlice'
import { useDispatch } from 'react-redux';

function PaiseToRupees(paise) {
  return paise / 100;
}

function formatDate(timestamp) {
  const date = new Date(timestamp);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short'
  };
  return date.toLocaleDateString('en-US', options);
}


const Socialmediaadlisting = () => {

  const dispatch = useDispatch();
  const [value, setValue] = React.useState('');
  const [loading, setLoading] = useState(false);


  

  const [facebookadsList, setfacebookadsList] = useState([]);

  const getfacebookadsListFromAPI = async () => {
    try {
      const response = await authpostAxios({
        url: `${process.env.REACT_APP_BACKENDURL}api/get-fb-ads-campaign`,
        data: {  }
      });
      setfacebookadsList(response.data?.data || []);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  
console.log(facebookadsList);


  let ImagePlaceholder= "";
  let title = "";
  let campaign = "";
  let adSet = "";
  let adCreative = "";
  let status = "";

  const handleClick = (dataId) => {
    console.log(10);
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to proceed?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, do it!',
      cancelButtonText: 'No, cancel!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        dispatch(manageLoader(true))
        // Data to be sent to the backend
        const data = { id: dataId };
        try {
          const response = await authpostAxios({
            url: `${process.env.REACT_APP_BACKENDURL}api/fb-ads-campaign-publish`,
            data
          });

          console.log(response.data);
          console.log(response.data.flag);
          let return_data = response.data || [];
          console.log(return_data);
          console.log('testing');

          if (return_data.flag == 1) {
            Swal.fire(
              'Success!',
              'Your Request has been processed.',
              'success'
            ).then(() => {
              window.location.reload();
            });
          } else {
            Swal.fire(
              'Error!',
              'There was an issue with your request.',
              'error'
            );
          }
        } catch (error) {
          console.error("Error fetching data: ", error);
          Swal.fire(
            'Error!',
            'There was an error with your request.',
            'error'
          );
        } finally {
          dispatch(manageLoader(false))
        }
      }
    });
  };



   useEffect(() => {
    //console.log(facebookadsList);
    getfacebookadsListFromAPI()
    dispatch(manageLoader(false))
  }, []);
  return (

<Grid container style={styleCss.commonpaddingtop}>
<Grid item xs={6}>
  <h2 style={{ textAlign: 'left' }}>Facebook Ads Campaign</h2>
</Grid>
<Grid item xs={6} style={{ textAlign: 'right' }}>
<Link to="/socialmediaads" style={{ textDecoration: 'none' }}>
  <Button variant="contained" startIcon={<AddIcon />}>
    Add Campaign
  </Button>
</Link>
</Grid>

<Grid item xs={12}>
<div style={{ width: '100%', height: 'calc(100vh - 20px)', overflowY: 'auto' }}>
<table className="table" style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th>S.No</th>
          <th>Social Media</th>
          <th>Action</th>
          <th>Campaign</th>
          <th>Ad Set</th>
          <th>Ad Creative</th>
          <th>Ad Name</th>
          <th>Budget</th>
          <th>Bid Amount</th>
          <th>Start date</th>
          <th>End date</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
      {facebookadsList.map((ad, index) => (
        <tr>
          <td>{index + 1}</td>
          <td>
          {ad.social_media_id === 1 && (
                <FacebookIcon style={{color:'#1877F2'}} />
              )}
          </td>
          <td>
          <div>
          {ad.facebook_ad_final_return_id ? (
            <div>Ad Created</div> 
          ) : (

            <Button
            variant="contained"
            style={{
              backgroundColor: '#add8e6', // Set a mild blue color (light blue)
              color: 'black', // Change text color to black for better contrast
              fontSize: '10px', // Set the font size to small
              fontWeight: 'bold', // Make the font bold
              textTransform: 'none', // Prevent text from being converted to uppercase
              borderRadius: '0', // Remove border radius for a square button
              padding: '6px 12px', // Adjust padding for a better button size
              boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)' // Add a subtle shadow effect
            }}
            size="small"
            onClick={() => handleClick(ad.facebook_ad_posting_id)}
          >
            Create Ads Now
          </Button>
          )}
        </div>
          </td>
          <td>
          <div>
          <div>{ad.fb_campaign_name}</div>
          <div>
            Budget: Rs {PaiseToRupees(ad.ad_budget)}<br />
            Start Date: {formatDate(ad.ad_startdate)}<br />
            End Date: {formatDate(ad.ad_enddate)}
          </div>
        </div>
          </td>
          <td>{ad.adset_name}</td>
          <td>
            {ad.adcreatives_name}
          </td>
          <td>{ad.fb_ads_name}<br /><br />
          {ad.preview_shareable_link}
          </td>
          <td>Rs {PaiseToRupees(ad.ad_budget)}</td>
          <td>Rs {PaiseToRupees(ad.ad_bid_amount)}</td> 
          <td>{formatDate(ad.ad_startdate)}</td>
          <td>{formatDate(ad.ad_enddate)}</td>

          <td> 
          {ad?.facebook_ad_final_return_id && (
            <Button variant="outlined" color="error" size="small">
             PAUSED
            </Button>
          )}
          </td>
        </tr>
        ))}
        {/* Add more rows as needed */}
      </tbody>
    </table>
</div>



      </Grid>
    </Grid>
  )
}

export default Socialmediaadlisting