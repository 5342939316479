import { Grid, Button, TextField, FormControl, InputLabel, FormLabel, RadioGroup, Radio, Select, MenuItem } from "@mui/material";
import { Formik, Form, Field, useFormik, ErrorMessage } from 'formik';
import React, { useState, useEffect } from "react";
import styleCss from "../Style/style"
import { authpostAxios } from "../Utilities/commonAxios";
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import '../Style/Loader.css'

import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { confirmationAlertwithButtonForUserCreation } from '../Utilities/sweetAlert';



const EditUser = () => {
    const { enUserid } = useParams()
    const userId = atob(enUserid)
    const navigate = useNavigate()

    const [userRoles, setuserRoles] = useState([]);
    const [userDetails, setuserDetails] = useState([])
    const getUserDetailsAPI = async () => {
            console.log("getRoleTypeAPI")
            const response = await authpostAxios({
                url: `${process.env.REACT_APP_BACKENDURL}api/get-user-details`,
                data: { user_id: userId },
            });
            setuserDetails(response.data.data[0])

        };
    useEffect(() => {
        getUserDetailsAPI()
    }, [userId]);

    const submitSocialForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            user_first_name: (userDetails) ? (userDetails?.first_name) : (''),
            user_last_name: (userDetails) ? (userDetails?.last_name) : (''),
            user_email: (userDetails) ? (userDetails?.email) : (''),
            user_password: (userDetails) ? (userDetails?.password) : (''),
            role_type: (userDetails) ? (`${userDetails?.role_id}`) : ('')
        },
        validationSchema: Yup.object({
            user_first_name: Yup.string().required('Please Enter the first Name'),
            user_last_name: Yup.string().required('Please Enter the last Name'),
            user_email: Yup.string().required('Please Enter the uesr email'),
            user_password: Yup.string().required('Please Enter the the password'),
            role_type: Yup.string().required('Please select role'),
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log(values);
            if (!submitSocialForm.dirty) {
            } else {
                const confirmAlert = await confirmationAlertwithButtonForUserCreation("Are you sure you want to create the user?")
                if (confirmAlert.isConfirmed) {
                    let AddUserResponse = await authpostAxios({
                        url: `${process.env.REACT_APP_BACKENDURL}api/upd-user-details`,
                        data: {
                            user_id : userId,
                            userFirstName: values.user_first_name,
                            userLastName: values.user_last_name,
                            userEmail: values.user_email,
                            userPassword: values.user_password,
                            userRole: values.role_type
                        },
                    });
                    if (AddUserResponse?.data?.flag == 1) {
                        toast.success(AddUserResponse?.data?.message)
                        getUserDetailsAPI()
                    } else {
                        toast.error(AddUserResponse?.data?.message)
                    }
                }
            }
        },
    });

    const getRoleTyesAPI = async () => {
        try {
            const userRoleResponse = await authpostAxios({
                url: `${process.env.REACT_APP_BACKENDURL}api/get-user-roles`,
                data: '',
            });
            setuserRoles(userRoleResponse.data.data)

        } catch (error) {
            setuserRoles([])

        }
    }
    useEffect(() => {
        getRoleTyesAPI()
    }, []);

    console.log(userRoles);

    return (
        <form onSubmit={submitSocialForm.handleSubmit}>
            <Grid container style={styleCss.commonpaddingtop}>

                <Grid container xs={12} spacing={2} style={{ marginBottom: '10px' }}>
                    <Grid item xs={6}>
                        <h2 style={{ textAlign: 'left' }}>Create User</h2>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <Link to="/list_user" style={{ textDecoration: 'none' }}>
                            <Button variant="contained">
                                User list
                            </Button>
                        </Link>
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                label="First Name"
                                id="user_first_name"
                                name="user_first_name"
                                value={submitSocialForm.values.user_first_name}
                                onChange={submitSocialForm.handleChange}
                                InputLabelProps={{
                                    sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
                                    shrink: true
                                }}
                            />
                        </FormControl>
                        <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>
                            {submitSocialForm.errors.user_first_name}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                label="Last Name"
                                id="user_last_name"
                                name="user_last_name"
                                value={submitSocialForm.values.user_last_name}
                                onChange={submitSocialForm.handleChange}
                                InputLabelProps={{
                                    sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
                                    shrink: true
                                }}
                            />
                        </FormControl>
                        <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>
                            {submitSocialForm.errors.user_last_name}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                label="Email Id"
                                id="user_email"
                                name="user_email"
                                value={submitSocialForm.values.user_email}
                                onChange={submitSocialForm.handleChange}
                                InputLabelProps={{
                                    sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
                                    shrink: true
                                }}
                            />
                        </FormControl>
                        <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>
                            {submitSocialForm.errors.user_email}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                label="Password"
                                id="user_password"
                                name="user_password"
                                value={submitSocialForm.values.user_password}
                                onChange={submitSocialForm.handleChange}
                                InputLabelProps={{
                                    sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
                                    shrink: true
                                }}
                            />
                        </FormControl>
                        <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>
                            {submitSocialForm.errors.user_password}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <InputLabel id="select-label">Select Role</InputLabel>
                            <Select
                                labelId="select-label"
                                id="demo-simple-select"
                                label="Roles"
                                name="role_type"
                                value={submitSocialForm.values.role_type}
                                onChange={submitSocialForm.handleChange}
                            >
                                {userRoles.map((roles,key) => (
                                    <MenuItem key={key} value={roles.role_id}>
                                        {roles.role_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>
                            {submitSocialForm.errors.role_type}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginTop: '5px', marginLeft: '5px' }}>
                    <Button type="submit" variant="contained" color="primary" style={{ marginTop: '16px' }}>
                        Save
                    </Button>
                </Grid>
            </Grid>
        </form>


    )
}

export default EditUser