import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  IconButton,
} from "@mui/material";

import { Favorite, Share, Bookmark } from '@mui/icons-material';
import styleCss from "../Style/style";
import Listimg from "../img/list-img.jpg";
import { Link } from "react-router-dom";
import Socialicon1 from "../img/sub-icon-1.svg";
import Likeimg1 from "../img/social-icon-2.png";
import Likeimg2 from "../img/sub-icon-4.png";
import Likeimg3 from "../img/sub-icon-3.png";
import Stack from "@mui/material/Stack";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import { authpostAxios } from "../Utilities/commonAxios";
import { useDispatch } from "react-redux";
import moment from "moment";
import { PostDetailsModal } from "../Utilities/postDetailsModal";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ReportSociallist = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [getPostLists, setGetPostLists] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const reportLink = (value)=>{
    if(value === 0){
      return `${process.env.REACT_APP_BACKENDURL}api/get-fb-post-list`;
    } else if(value === 1){
      return `${process.env.REACT_APP_BACKENDURL}api/get-insta-post-list`;
    } else if(value === 2){
      return `${process.env.REACT_APP_BACKENDURL}api/get-linkedin-post-list`;
    }
  }
  useEffect(() => {
    const getPostListsFromAPI = async () => {
      await authpostAxios(
        {
          url: reportLink(value),
          data: {},
        },
        dispatch
      ).then((response) => {
        setGetPostLists(response?.data?.data);
      });
    };
    getPostListsFromAPI();
  }, [value]);
  // Post Modal
  const [isOpen, setIsOpen] = useState(false);
  const [popupData, setPopupData] = useState("");
  const handlePopupOpen = (data,socialMedia) => {
    setPopupData(data)
    setIsOpen(true)
    setSelectedSocialMediaType(socialMedia)
  };
  const handlePopupClose = () => setIsOpen(false);
  const [selectedSocialMediaType, setSelectedSocialMediaType] = useState("");
  return (
    <>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            minHeight: "15px",
            height: "38px",
            padding: "0px",
            "& .css-1aquho2-MuiTabs-indicator": {
              background: "unset",
              padding: "0",
            },
            "& .css-1l0dw58-MuiTabs-root": {
              minHeight: "15px",
            },
            "& .MuiTab-textColorPrimary.Mui-selected": {
              backgroundColor: "#e9eaea",
              borderRadius: "3px",
            },
            "& .css-1h9z7r5-MuiButtonBase-root-MuiTab-root": {
              minHeight: "30px",
            },
          }}
        >
          <Tab label="Facebook Ads Leads" className="innertab-style" />
          <Tab label="Instagram Ads Leads" className="innertab-style" />
          <Tab label="LinkedIn" className="innertab-style" />
          {/* <Tab label="Youtube" className='innertab-style' />
                    <Tab label="Google Buisness" className='innertab-style' /> */}
        </Tabs>
      </Box>

      <Box sx={{ width: "100%", marginTop: "20px" }}>
        <CustomTabPanel value={value} index={0}>
          <Grid md={12} sx={{ marginBottom: "15px" }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={styleCss.tablecell}
                      sx={{ padding: "10px 15px !important" }}
                    >
                      Posts
                    </TableCell>
                    <TableCell style={styleCss.tablecell}>Page</TableCell>
                    <TableCell style={styleCss.tablecell}></TableCell>
                    
                    <TableCell style={styleCss.tablecell}>Post Type</TableCell>
                    <TableCell style={styleCss.tablecell}>Date</TableCell>
                    {/* <TableCell style={styleCss.tablecell}>
                      Like/Reactions
                    </TableCell>
                    <TableCell style={styleCss.tablecell}>Comments</TableCell> */}
                    <TableCell style={styleCss.tablecell}>Status</TableCell>
                    {/* <TableCell style={styleCss.tablecell}>Actions</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getPostLists?.length > 0 &&
                    getPostLists?.map((getPostList, index) => (
                      <>
                        <TableRow key={index}>
                          
                        <TableCell sx={{ padding: "10px 3px" }}>
                            <Typography style={styleCss.tableheading}>
                              {getPostList?.message}
                            </Typography>
                            <Typography style={styleCss.headingsub}>
                              <Link
                                to={"#"}
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                  color: "#1877f2",
                                  textDecoration: "none",
                                  fontFamily: "Inter",
                                }} onClick={() => handlePopupOpen(getPostList,"fb")}
                              >
                                {" "}
                                Click to View
                              </Link>
                            </Typography>
                          </TableCell>

                          <TableCell
                            sx={{ padding: "10px 3px", textAlign: "center" }}
                          >
                             {getPostList?.post_social_media_image_url && getPostList?.post_social_media_image_url.includes(
                              "["
                            )
                              ? (JSON.parse(
                                  (getPostList?.post_social_media_image_url).replaceAll(
                                    /\\/g,
                                    "\\\\"
                                  )
                                )
                                  .slice(0, 1)
                                  .map((image, index) => (
                                    <>
                                      <img
                                        src={image}
                                        style={{
                                          width: "50px",
                                          borderRadius: "5px",
                                          height: "35px",
                                          overflow: "hidden",
                                          verticalAlign: "middle",
                                        }}
                                        key={index}
                                      />
                                    </>
                                  )))
                              : (getPostList?.post_social_media_image_url && (
                                  <img
                                    src={
                                      getPostList?.post_social_media_image_url
                                    }
                                    style={{
                                      width: "50px",
                                      borderRadius: "5px",
                                      height: "35px",
                                      overflow: "hidden",
                                      verticalAlign: "middle",
                                    }}
                                  />
                                ))}
                          </TableCell>
                          <TableCell sx={{ padding: "10px 3px" }}>
                            <Typography style={styleCss.tableheading}>
                              {getPostList?.page_profile_image && (
                                <img
                                  src={getPostList?.page_profile_image}
                                  style={{
                                    width: "14px",
                                    verticalAlign: "middle",
                                    marginRight: "5px",
                                  }}
                                />
                              )}
                              {getPostList?.page_name}
                              <img
                                src={Socialicon1}
                                style={{
                                  width: "14px",
                                  verticalAlign: "middle",
                                  marginLeft: "5px",
                                }}
                              />
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ padding: "10px 3px" }}>
                            <Typography style={styleCss.tableheading}>
                              {getPostList?.fb_post_type?.toLowerCase()}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ padding: "10px 3px" }}>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "left",
                                color: "#27292f",
                                fontFamily: "Inter",
                              }}
                            >
                              {moment(getPostList?.created_at).format(
                                "DD-MM-YYYY hh:mm A"
                              )}
                            </Typography>
                          </TableCell>
                          {/* <TableCell sx={{ padding: "10px 3px" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#27292f",
                          fontFamily: "Inter",
                        }}
                      >
                        <Tooltip
                          title={
                            <Grid container item>
                              <Grid item md={2}>
                                <img
                                  src={Likeimg2}
                                  style={{
                                    width: "15px",
                                    marginRight: "10px",
                                  }}
                                />
                              </Grid>
                              <Grid item md={10}>
                                <Typography
                                  sx={{ fontSize: "12px", paddingLeft: "7px" }}
                                >
                                  256 Likes{" "}
                                </Typography>
                              </Grid>
                            </Grid>
                          }
                          placement="right-start"
                        >
                          <span style={{ marginLeft: "10px" }}>317</span>
                        </Tooltip>
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ padding: "10px 3px" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#27292f",
                          fontFamily: "Inter",
                        }}
                      >
                        425
                      </Typography>
                    </TableCell> */}
                          <TableCell
                            sx={{ padding: "10px 3px", textAlign: "center" }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "left",
                                color:
                                  getPostList?.post_status === "Published"
                                    ? "green"
                                    : "red",
                                fontFamily: "Inter",
                              }}
                            >
                              {getPostList?.post_status}
                            </Typography>
                          </TableCell>
                          {/* <TableCell
                      sx={{ padding: "10px 3px", textAlign: "center" }}
                    >
                      <Link to={"/"}>
                        <DeleteOutlineOutlined
                          sx={{
                            color: "#27292f",
                            fontSize: "18px",
                            background: "#f3f3f3",
                            padding: "10px",
                            borderRadius: "5px",
                            padding: "8px",
                          }}
                        />
                      </Link>
                    </TableCell> */}
                        </TableRow>
                      </>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid sx={{ marginTop: "15px", float: "right" }}>
              <Stack spacing={2}>
                <Pagination count={Math.ceil(getPostLists.length / 5)} />
              </Stack>
            </Grid>
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Grid md={12} sx={{ marginBottom: "15px" }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={styleCss.tablecell}
                      sx={{ padding: "10px 15px !important" }}
                    >
                      Posts
                    </TableCell>
                    <TableCell style={styleCss.tablecell}></TableCell>
                    <TableCell style={styleCss.tablecell}>Account</TableCell>
                    <TableCell style={styleCss.tablecell}>Post Type</TableCell>
                    <TableCell style={styleCss.tablecell}>Date</TableCell>
                    {/* <TableCell style={styleCss.tablecell}>
                      Like/Reactions
                    </TableCell>
                    <TableCell style={styleCss.tablecell}>Comments</TableCell> */}
                    <TableCell style={styleCss.tablecell}>Status</TableCell>
                    {/* <TableCell style={styleCss.tablecell}>Actions</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getPostLists?.length > 0 &&
                    getPostLists?.map((getPostList, index) => (
                      <>
                        <TableRow key={index}>
                          <TableCell
                            sx={{ padding: "10px 3px", textAlign: "center" }}
                          >
                             {getPostList?.post_social_media_image_url && getPostList?.post_social_media_image_url.includes(
                              "["
                            )
                              ? (JSON.parse(
                                  (getPostList?.post_social_media_image_url).replaceAll(
                                    /\\/g,
                                    "\\\\"
                                  )
                                )
                                  .slice(0, 1)
                                  .map((image, index) => (
                                    <>
                                      <img
                                        src={image}
                                        style={{
                                          width: "50px",
                                          borderRadius: "5px",
                                          height: "35px",
                                          overflow: "hidden",
                                          verticalAlign: "middle",
                                        }}
                                        key={index}
                                      />
                                    </>
                                  )))
                              : (getPostList?.post_social_media_image_url && (
                                  <img
                                    src={
                                      getPostList?.post_social_media_image_url
                                    }
                                    style={{
                                      width: "50px",
                                      borderRadius: "5px",
                                      height: "35px",
                                      overflow: "hidden",
                                      verticalAlign: "middle",
                                    }}
                                  />
                                ))}
                          </TableCell>
                          <TableCell sx={{ padding: "10px 3px" }}>
                            <Typography style={styleCss.tableheading}>
                              {getPostList?.message}
                            </Typography>
                            <Typography style={styleCss.headingsub}>
                            <Link
                                to={"#"}
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                  color: "#1877f2",
                                  textDecoration: "none",
                                  fontFamily: "Inter",
                                }} onClick={() => handlePopupOpen(getPostList,"insta")}
                              >
                                {" "}
                                Click to View
                              </Link>
                              </Typography>
                          </TableCell>
                          <TableCell sx={{ padding: "10px 3px" }}>
                            <Typography style={styleCss.tableheading}>
                              {getPostList?.profile && (
                                <img
                                  src={getPostList?.profile}
                                  style={{
                                    width: "14px",
                                    verticalAlign: "middle",
                                    marginRight: "5px",
                                  }}
                                />
                              )}
                              {getPostList?.account_name}
                              <img
                                src={Likeimg1}
                                style={{
                                  width: "14px",
                                  verticalAlign: "middle",
                                  marginLeft: "5px",
                                }}
                              />
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ padding: "10px 3px" }}>
                            <Typography style={styleCss.tableheading}>
                              {getPostList?.post_type?.toLowerCase()}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ padding: "10px 3px" }}>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "left",
                                color: "#27292f",
                                fontFamily: "Inter",
                              }}
                            >
                              {moment(getPostList?.created_at).format(
                                "DD-MM-YYYY hh:mm A"
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{ padding: "10px 3px", textAlign: "center" }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "left",
                                color:
                                  getPostList?.post_status === "Published"
                                    ? "green"
                                    : "red",
                                fontFamily: "Inter",
                              }}
                            >
                              {getPostList?.post_status}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid sx={{ marginTop: "15px", float: "right" }}>
              <Stack spacing={2}>
                <Pagination count={Math.ceil(getPostLists.length / 5)} />
              </Stack>
            </Grid>
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Grid md={12} sx={{ marginBottom: "15px" }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={styleCss.tablecell}
                      sx={{ padding: "10px 15px !important" }}
                    >
                      Posts
                    </TableCell>
                    <TableCell style={styleCss.tablecell}></TableCell>
                    <TableCell style={styleCss.tablecell}>Page</TableCell>
                    <TableCell style={styleCss.tablecell}>Post Type</TableCell>
                    <TableCell style={styleCss.tablecell}>Date</TableCell>
                    {/* <TableCell style={styleCss.tablecell}>
                      Like/Reactions
                    </TableCell>
                    <TableCell style={styleCss.tablecell}>Comments</TableCell> */}
                    <TableCell style={styleCss.tablecell}>Status</TableCell>
                    {/* <TableCell style={styleCss.tablecell}>Actions</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getPostLists?.length > 0 &&
                    getPostLists?.map((getPostList, index) => (
                      <>
                        <TableRow key={index}>
                          <TableCell
                            sx={{ padding: "10px 3px", textAlign: "center" }}
                          >
                             {getPostList?.post_social_media_image_url && getPostList?.post_social_media_image_url.includes(
                              "["
                            )
                              ? (JSON.parse(
                                  (getPostList?.post_social_media_image_url).replaceAll(
                                    /\\/g,
                                    "\\\\"
                                  )
                                )
                                  .slice(0, 1)
                                  .map((image, index) => (
                                    <>
                                      <img
                                        src={image}
                                        style={{
                                          width: "50px",
                                          borderRadius: "5px",
                                          height: "35px",
                                          overflow: "hidden",
                                          verticalAlign: "middle",
                                        }}
                                        key={index}
                                      />
                                    </>
                                  )))
                              : (getPostList?.post_social_media_image_url && (
                                  <img
                                    src={
                                      getPostList?.post_social_media_image_url
                                    }
                                    style={{
                                      width: "50px",
                                      borderRadius: "5px",
                                      height: "35px",
                                      overflow: "hidden",
                                      verticalAlign: "middle",
                                    }}
                                  />
                                ))}
                          </TableCell>
                          <TableCell sx={{ padding: "10px 3px" }}>
                            <Typography style={styleCss.tableheading}>
                              {getPostList?.message}
                            </Typography>
                            <Typography style={styleCss.headingsub}>
                            <Link
                                to={"#"}
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                  color: "#1877f2",
                                  textDecoration: "none",
                                  fontFamily: "Inter",
                                }} onClick={() => handlePopupOpen(getPostList,"linkedin")}
                              >
                                {" "}
                                Click to View
                              </Link>
                              </Typography>
                          </TableCell>
                          <TableCell sx={{ padding: "10px 3px" }}>
                            <Typography style={styleCss.headingsub}>
                              {getPostList?.page_profile_image && (
                                <img
                                  src={getPostList?.page_profile_image}
                                  style={{
                                    width: "14px",
                                    verticalAlign: "middle",
                                    marginRight: "5px",
                                  }}
                                />
                              )}
                              {getPostList?.page_name}
                              <img
                                src={Likeimg2}
                                style={{
                                  width: "14px",
                                  verticalAlign: "middle",
                                  marginLeft: "5px",
                                }}
                              />
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ padding: "10px 3px" }}>
                            <Typography style={styleCss.headingsub}>
                              {getPostList?.post_type?.toLowerCase()}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ padding: "10px 3px" }}>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "left",
                                color: "#27292f",
                                fontFamily: "Inter",
                              }}
                            >
                              {moment(getPostList?.created_at).format(
                                "DD-MM-YYYY hh:mm A"
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{ padding: "10px 3px", textAlign: "center" }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "left",
                                color:
                                  getPostList?.post_status === "Published"
                                    ? "green"
                                    : "red",
                                fontFamily: "Inter",
                              }}
                            >
                              {getPostList?.post_status}
                            </Typography>
                          </TableCell>
                          {/* <TableCell
                      sx={{ padding: "10px 3px", textAlign: "center" }}
                    >
                      <Link to={"/"}>
                        <DeleteOutlineOutlined
                          sx={{
                            color: "#27292f",
                            fontSize: "18px",
                            background: "#f3f3f3",
                            padding: "10px",
                            borderRadius: "5px",
                            padding: "8px",
                          }}
                        />
                      </Link>
                    </TableCell> */}
                        </TableRow>
                      </>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid sx={{ marginTop: "15px", float: "right" }}>
              <Stack spacing={2}>
                <Pagination count={Math.ceil(getPostLists.length / 5)} />
              </Stack>
            </Grid>
          </Grid>
        </CustomTabPanel>
        {/* <CustomTabPanel value={value} index={3}>
                    <Grid md={12} sx={{ marginBottom: '15px' }}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={styleCss.tablecell} sx={{ padding: '10px 15px !important' }}>
                                            Posts
                                        </TableCell>
                                        <TableCell style={styleCss.tablecell}>
                                        </TableCell>
                                        <TableCell style={styleCss.tablecell}>
                                            Date
                                        </TableCell>
                                        <TableCell style={styleCss.tablecell}>
                                            Like/Reactions
                                        </TableCell>
                                        <TableCell style={styleCss.tablecell}>
                                            Comments
                                        </TableCell>
                                        <TableCell style={styleCss.tablecell}>
                                            Status
                                        </TableCell>
                                        <TableCell style={styleCss.tablecell}>
                                            Actions
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ padding: '10px 3px', textAlign: 'center' }}>
                                            <img src={Listimg} style={{
                                                width: '50px',
                                                borderRadius: '5px',
                                                height: '35px',
                                                overflow: 'hidden',
                                                verticalAlign: 'middle',
                                            }} />
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography style={styleCss.tableheading}>
                                                Welcome to our shop! Gran
                                            </Typography>
                                            <Typography style={styleCss.headingsub}>Kolathur tea shop
                                                <Link to={'/'} style={{
                                                    fontSize: '12px',
                                                    fontWeight: 'normal',
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'left',
                                                    color: '#1877f2',
                                                    textDecoration: 'none',
                                                    fontFamily: 'Inter',
                                                }}> View on </Link> <img src={Likeimg1} style={{ width: '14px', verticalAlign: 'middle', }} /></Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#27292f',
                                                fontFamily: 'Inter',
                                            }}>
                                                Fri, Oct 18, 2022
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#27292f',
                                                fontFamily: 'Inter',
                                            }}>
                                                <Tooltip
                                                    title={
                                                        <Grid container item>
                                                            <Grid item md={2}>
                                                                <img src={Likeimg2} style={{
                                                                    width: '15px',
                                                                    marginRight: '10px'
                                                                }} />
                                                            </Grid>
                                                            <Grid item md={10}>
                                                                <Typography sx={{ fontSize: '12px', paddingLeft: '7px' }}>256 Likes </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    placement="right-start"
                                                >
                                                    <span style={{ marginLeft: '10px' }}>317</span>
                                                </Tooltip>
                                            </Typography>

                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#27292f',
                                                fontFamily: 'Inter',
                                            }}>
                                                425
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px', textAlign: 'center' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: 'green',
                                                fontFamily: 'Inter',
                                            }}>
                                                Success
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px', textAlign: 'center' }}>
                                            <Link to={"/"}>
                                                <DeleteOutlineOutlined sx={{ color: '#27292f', fontSize: '18px', background: '#f3f3f3', padding: '10px', borderRadius: '5px', padding: '8px' }} />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ padding: '10px 3px', textAlign: 'center' }}>
                                            <img src={Listimg} style={{
                                                width: '50px',
                                                borderRadius: '5px',
                                                height: '35px',
                                                overflow: 'hidden',
                                                verticalAlign: 'middle',
                                            }} />
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography style={styleCss.tableheading}>
                                                Welcome to our shop! Gran
                                            </Typography>
                                            <Typography style={styleCss.headingsub}>Kolathur tea shop
                                                <Link to={'/'} style={{
                                                    fontSize: '12px',
                                                    fontWeight: 'normal',
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'left',
                                                    color: '#1877f2',
                                                    textDecoration: 'none',
                                                    fontFamily: 'Inter',
                                                }}> View on </Link> <img src={Socialicon1} style={{ width: '14px', verticalAlign: 'middle', }} /></Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#27292f',
                                                fontFamily: 'Inter',
                                            }}>
                                                Fri, Oct 18, 2022
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#27292f',
                                                fontFamily: 'Inter',
                                            }}>
                                                <Tooltip
                                                    title={
                                                        <Grid container item>
                                                            <Grid item md={2}>
                                                                <img src={Likeimg2} style={{
                                                                    width: '15px',
                                                                    marginRight: '10px'
                                                                }} />
                                                            </Grid>
                                                            <Grid item md={10}>
                                                                <Typography sx={{ fontSize: '12px', paddingLeft: '7px' }}>256 Likes </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    placement="right-start"
                                                >
                                                    <span style={{ marginLeft: '10px' }}>317</span>
                                                </Tooltip>
                                            </Typography>

                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#27292f',
                                                fontFamily: 'Inter',
                                            }}>
                                                425
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px', textAlign: 'center' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#f3884c',
                                                fontFamily: 'Inter',
                                            }}>
                                                In progress
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px', textAlign: 'center' }}>
                                            <Link to={"/"}>
                                                <DeleteOutlineOutlined sx={{ color: '#27292f', fontSize: '18px', background: '#f3f3f3', padding: '10px', borderRadius: '5px', padding: '8px' }} />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ padding: '10px 3px', textAlign: 'center' }}>
                                            <img src={Listimg} style={{
                                                width: '50px',
                                                borderRadius: '5px',
                                                height: '35px',
                                                overflow: 'hidden',
                                                verticalAlign: 'middle',
                                            }} />
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography style={styleCss.tableheading}>
                                                Welcome to our shop! Gran
                                            </Typography>
                                            <Typography style={styleCss.headingsub}>Kolathur tea shop
                                                <Link to={'/'} style={{
                                                    fontSize: '12px',
                                                    fontWeight: 'normal',
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'left',
                                                    color: '#1877f2',
                                                    textDecoration: 'none',
                                                    fontFamily: 'Inter',
                                                }}> View on </Link> <img src={Socialicon1} style={{ width: '14px', verticalAlign: 'middle', }} /></Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#27292f',
                                                fontFamily: 'Inter',
                                            }}>
                                                Fri, Oct 18, 2022
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#27292f',
                                                fontFamily: 'Inter',
                                            }}>
                                                <Tooltip
                                                    title={
                                                        <Grid container item>
                                                            <Grid item md={2}>
                                                                <img src={Likeimg2} style={{
                                                                    width: '15px',
                                                                    marginRight: '10px'
                                                                }} />
                                                            </Grid>
                                                            <Grid item md={10}>
                                                                <Typography sx={{ fontSize: '12px', paddingLeft: '7px' }}>256 Likes </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    placement="right-start"
                                                >
                                                    <span style={{ marginLeft: '10px' }}>317</span>
                                                </Tooltip>
                                            </Typography>

                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#27292f',
                                                fontFamily: 'Inter',
                                            }}>
                                                425
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px', textAlign: 'center' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: 'red',
                                                fontFamily: 'Inter',
                                            }}>
                                                Failed
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px', textAlign: 'center' }}>
                                            <Link to={"/"}>
                                                <DeleteOutlineOutlined sx={{ color: '#27292f', fontSize: '18px', background: '#f3f3f3', padding: '10px', borderRadius: '5px', padding: '8px' }} />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Grid sx={{ marginTop: '15px', float: 'right' }}>
                            <Stack spacing={2}>
                                <Pagination count={5} />
                            </Stack>
                        </Grid>
                    </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={4}>
                    <Grid md={12} sx={{ marginBottom: '15px' }}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={styleCss.tablecell} sx={{ padding: '10px 15px !important' }}>
                                            Posts
                                        </TableCell>
                                        <TableCell style={styleCss.tablecell}>
                                        </TableCell>
                                        <TableCell style={styleCss.tablecell}>
                                            Date
                                        </TableCell>
                                        <TableCell style={styleCss.tablecell}>
                                            Like/Reactions
                                        </TableCell>
                                        <TableCell style={styleCss.tablecell}>
                                            Comments
                                        </TableCell>
                                        <TableCell style={styleCss.tablecell}>
                                            Status
                                        </TableCell>
                                        <TableCell style={styleCss.tablecell}>
                                            Actions
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ padding: '10px 3px', textAlign: 'center' }}>
                                            <img src={Listimg} style={{
                                                width: '50px',
                                                borderRadius: '5px',
                                                height: '35px',
                                                overflow: 'hidden',
                                                verticalAlign: 'middle',
                                            }} />
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography style={styleCss.tableheading}>
                                                Welcome to our shop! Gran
                                            </Typography>
                                            <Typography style={styleCss.headingsub}>Kolathur tea shop
                                                <Link to={'/'} style={{
                                                    fontSize: '12px',
                                                    fontWeight: 'normal',
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'left',
                                                    color: '#1877f2',
                                                    textDecoration: 'none',
                                                    fontFamily: 'Inter',
                                                }}> View on </Link> <img src={Likeimg1} style={{ width: '14px', verticalAlign: 'middle', }} /></Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#27292f',
                                                fontFamily: 'Inter',
                                            }}>
                                                Fri, Oct 18, 2022
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#27292f',
                                                fontFamily: 'Inter',
                                            }}>
                                                <Tooltip
                                                    title={
                                                        <Grid container item>
                                                            <Grid item md={2}>
                                                                <img src={Likeimg2} style={{
                                                                    width: '15px',
                                                                    marginRight: '10px'
                                                                }} />
                                                            </Grid>
                                                            <Grid item md={10}>
                                                                <Typography sx={{ fontSize: '12px', paddingLeft: '7px' }}>256 Likes </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    placement="right-start"
                                                >
                                                    <span style={{ marginLeft: '10px' }}>317</span>
                                                </Tooltip>
                                            </Typography>

                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#27292f',
                                                fontFamily: 'Inter',
                                            }}>
                                                425
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px', textAlign: 'center' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: 'green',
                                                fontFamily: 'Inter',
                                            }}>
                                                Success
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px', textAlign: 'center' }}>
                                            <Link to={"/"}>
                                                <DeleteOutlineOutlined sx={{ color: '#27292f', fontSize: '18px', background: '#f3f3f3', padding: '10px', borderRadius: '5px', padding: '8px' }} />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ padding: '10px 3px', textAlign: 'center' }}>
                                            <img src={Listimg} style={{
                                                width: '50px',
                                                borderRadius: '5px',
                                                height: '35px',
                                                overflow: 'hidden',
                                                verticalAlign: 'middle',
                                            }} />
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography style={styleCss.tableheading}>
                                                Welcome to our shop! Gran
                                            </Typography>
                                            <Typography style={styleCss.headingsub}>Kolathur tea shop
                                                <Link to={'/'} style={{
                                                    fontSize: '12px',
                                                    fontWeight: 'normal',
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'left',
                                                    color: '#1877f2',
                                                    textDecoration: 'none',
                                                    fontFamily: 'Inter',
                                                }}> View on </Link> <img src={Socialicon1} style={{ width: '14px', verticalAlign: 'middle', }} /></Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#27292f',
                                                fontFamily: 'Inter',
                                            }}>
                                                Fri, Oct 18, 2022
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#27292f',
                                                fontFamily: 'Inter',
                                            }}>
                                                <Tooltip
                                                    title={
                                                        <Grid container item>
                                                            <Grid item md={2}>
                                                                <img src={Likeimg2} style={{
                                                                    width: '15px',
                                                                    marginRight: '10px'
                                                                }} />
                                                            </Grid>
                                                            <Grid item md={10}>
                                                                <Typography sx={{ fontSize: '12px', paddingLeft: '7px' }}>256 Likes </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    placement="right-start"
                                                >
                                                    <span style={{ marginLeft: '10px' }}>317</span>
                                                </Tooltip>
                                            </Typography>

                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#27292f',
                                                fontFamily: 'Inter',
                                            }}>
                                                425
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px', textAlign: 'center' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#f3884c',
                                                fontFamily: 'Inter',
                                            }}>
                                                In progress
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px', textAlign: 'center' }}>
                                            <Link to={"/"}>
                                                <DeleteOutlineOutlined sx={{ color: '#27292f', fontSize: '18px', background: '#f3f3f3', padding: '10px', borderRadius: '5px', padding: '8px' }} />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ padding: '10px 3px', textAlign: 'center' }}>
                                            <img src={Listimg} style={{
                                                width: '50px',
                                                borderRadius: '5px',
                                                height: '35px',
                                                overflow: 'hidden',
                                                verticalAlign: 'middle',
                                            }} />
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography style={styleCss.tableheading}>
                                                Welcome to our shop! Gran
                                            </Typography>
                                            <Typography style={styleCss.headingsub}>Kolathur tea shop
                                                <Link to={'/'} style={{
                                                    fontSize: '12px',
                                                    fontWeight: 'normal',
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'left',
                                                    color: '#1877f2',
                                                    textDecoration: 'none',
                                                    fontFamily: 'Inter',
                                                }}> View on </Link> <img src={Socialicon1} style={{ width: '14px', verticalAlign: 'middle', }} /></Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#27292f',
                                                fontFamily: 'Inter',
                                            }}>
                                                Fri, Oct 18, 2022
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#27292f',
                                                fontFamily: 'Inter',
                                            }}>
                                                <Tooltip
                                                    title={
                                                        <Grid container item>
                                                            <Grid item md={2}>
                                                                <img src={Likeimg2} style={{
                                                                    width: '15px',
                                                                    marginRight: '10px'
                                                                }} />
                                                            </Grid>
                                                            <Grid item md={10}>
                                                                <Typography sx={{ fontSize: '12px', paddingLeft: '7px' }}>256 Likes </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    placement="right-start"
                                                >
                                                    <span style={{ marginLeft: '10px' }}>317</span>
                                                </Tooltip>
                                            </Typography>

                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#27292f',
                                                fontFamily: 'Inter',
                                            }}>
                                                100000
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px', textAlign: 'center' }}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: 'red',
                                                fontFamily: 'Inter',
                                            }}>
                                                Failed
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: '10px 3px', textAlign: 'center' }}>
                                            <Link to={"/"}>
                                                <DeleteOutlineOutlined sx={{ color: '#27292f', fontSize: '18px', background: '#f3f3f3', padding: '10px', borderRadius: '5px', padding: '8px' }} />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Grid sx={{ marginTop: '15px', float: 'right' }}>
                            <Stack spacing={2}>
                                <Pagination count={5} />
                            </Stack>
                        </Grid>
                    </Grid>
                </CustomTabPanel> */}
      </Box>
      <PostDetailsModal 
        handlePopupClose={handlePopupClose}
        isOpen={isOpen}
         dialogTitle="View post details" 
        popupData={popupData}
        selectedSocialMediaType={selectedSocialMediaType}
      />
    </>
  );
};

export default ReportSociallist;
