import React, { useEffect, useState,useRef } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDispatch } from "react-redux";
import { authpostAxios } from "../Utilities/commonAxios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { fileToBase64 } from "../Utilities/fileConversion";
import { updateCompanyProfile } from '../Reduxstore/authSlice';


const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  padding: 1,
  boxSizing: "border-box",
  ObjectFit: "contain",
  placeItems: "center",
  color: "#000",
  paddingRight: "10px",
  fontWeight: 500,
  marginTop: 1.5,
};
const img = {
  display: "block",
  width: "80px",
  height: "80px",
  objectFit: "contain",
};
export default function CompanyProfile() {
  const dispatch = useDispatch();
  // initial company data
  const [userCompanyDetails, setUserCompanyDetails] = useState([]);
  const getUserCompanyDetailsFromAPI = async ()=>{
    await authpostAxios(
      {
        'url': `${process.env.REACT_APP_BACKENDURL}api/usercompany-details`,
        'data': ""
    },dispatch).then(response=>setUserCompanyDetails(response?.data?.data?.results[0]))
  }
  const submitCompanyForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyname: (userCompanyDetails)?(userCompanyDetails.companyname):(""),
      phone: (userCompanyDetails)?(userCompanyDetails.mobileno):(""),
      emailid: (userCompanyDetails)?(userCompanyDetails.emailid):(""),
      timezone: ((userCompanyDetails) ? (`${userCompanyDetails.timezoneid}`) : ("")),
      address1: (userCompanyDetails)?(userCompanyDetails.address1):(""),
      address2: (userCompanyDetails)?(userCompanyDetails.address2):(""),
      country: (userCompanyDetails)?(`${userCompanyDetails.countryid}`):(""),
      state: (userCompanyDetails)?(`${userCompanyDetails.stateid}`):(""),
      city: (userCompanyDetails)?(`${userCompanyDetails.cityid}`):(""),
      postalcode: (userCompanyDetails)?(userCompanyDetails.pincode):(""),
      businesscategory: (userCompanyDetails)?(`${userCompanyDetails.businesstype}`):(""),
      companysize: (userCompanyDetails)?(`${userCompanyDetails.companysize}`):(""),
      gstno: (userCompanyDetails)?(userCompanyDetails.gstnumber):(""),
      panno: (userCompanyDetails)?(userCompanyDetails.pannumber):(""),
      companylogo:"",
      companylogoExistPath: (userCompanyDetails)?(userCompanyDetails.companylogo):(""),
    },
    validationSchema: Yup.object({
      companyname: Yup.string().required("Please Enter Company name"),
      phone: Yup.string()
        .min(10, "Please Enter valid Mobile Number")
        .max(10, "Please Enter valid Mobile Number")
        .required("Please Enter Mobile Number"),
      emailid: Yup.string().required("Please Enter Email"),
      timezone: Yup.string().required("Please Select Timezone"),
      address1: Yup.string().required("Please Enter Address1"),
      address2: Yup.string().required("Please Enter Address2"),
      country: Yup.string().required("Please Select Country"),
      state: Yup.string().required("Please Select State"),
      city: Yup.string().required("Please Select City"),
      postalcode: Yup.string()
        .min(6, "Please Enter valid Postal Code")
        .max(6, "Please Enter valid Postal Code")
        .required("Please Enter Postal Code"),
      businesscategory: Yup.string().required(
        "Please Select Business Category"
      ),
      companysize: Yup.string().required("Please Select Company Size"),
      gstno: Yup.string().required("Please Enter GST No"),
      panno: Yup.string().required("Please Enter PAN No"),
      companylogo: Yup.string().when(['companylogoExistPath'],([companylogoExistPath],schema)=>
        (companylogoExistPath === "")?(schema.required('Please upload company logo')):(schema)
      )
    }),
    onSubmit: async (values) => {
      if (!submitCompanyForm.dirty) {
        toast.error("No data has changed!");
      } else {
        let formData = {
          companyname: values.companyname,
          mobileno: values.phone,
          emailid: values.emailid,
          timezoneid: values.timezone,
          address1: values.address1,
          address2: values.address2,
          countryid: values.country,
          stateid: values.state,
          cityid: values.city,
          pincode: values.postalcode,
          businesstype: values.businesscategory,
          companysize: values.companysize,
          pannumber: values.panno,
          gstnumber: values.gstno,
          companylogo:
            values.companylogo === ""
              ? ""
              : await fileToBase64(values.companylogo),
          companylogoExistPath: values.companylogoExistPath
        };
        let { data: usercompanyResponse } = await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/usercompany-register`,
                'data': formData
            },dispatch)
        if (usercompanyResponse.flag === 1) {
            submitCompanyForm.setFieldValue('companylogo', '')
            // getUserCompanyDetailsFromAPI()
            dispatch(updateCompanyProfile(usercompanyResponse.data))
            toast.success(usercompanyResponse.message)
        } else {
            toast.error(usercompanyResponse.message)
        }
      }
    },
  });
  // country
  const [countryLists, setCountryLists] = useState([]);
  const getCountriesFromAPI = async () => {
    await authpostAxios(
      {
        url: `${process.env.REACT_APP_BACKENDURL}api/get-country`,
        data: "",
      },
      dispatch
    ).then((countryResponse) => setCountryLists(countryResponse.data?.data));
  };
  // state
  const [stateLists, setStateLists] = useState([]);
  const getStateFromAPI = async () => {
    await authpostAxios(
      {
        url: `${process.env.REACT_APP_BACKENDURL}api/get-state`,
        data: {
          countryid: submitCompanyForm.values.country,
        },
      },
      dispatch
    ).then((stateResponse) => setStateLists(stateResponse.data?.data));
  };
  // city
  const [cityLists, setCityLists] = useState([]);
  const getCityFromAPI = async () => {
    await authpostAxios(
      {
        url: `${process.env.REACT_APP_BACKENDURL}api/get-city`,
        data: {
          stateid: submitCompanyForm.values.state,
        },
      },
      dispatch
    ).then((cityResponse) => setCityLists(cityResponse.data?.data));
  };
  // timezone
  const [timezoneLists, setTimezoneLists] = useState([]);
  const getTimeZoneFromAPI = async () => {
    await authpostAxios(
      {
        url: `${process.env.REACT_APP_BACKENDURL}api/get-timezone`,
        data: "",
      },
      dispatch
    ).then((timeZoneResponse) => setTimezoneLists(timeZoneResponse.data?.data));
  };
  // business Category
  const [businessCategoryLists, setBusinessCategoryLists] = useState([]);
  const getbusinessCategoryFromAPI = async () => {
    await authpostAxios(
      {
        url: `${process.env.REACT_APP_BACKENDURL}api/get-businesscategory`,
        data: "",
      },
      dispatch
    ).then((businessCategoryResponse) =>
      setBusinessCategoryLists(businessCategoryResponse.data?.data)
    );
  };
  // company Size
  const [companySizeLists, setCompanySizeLists] = useState([]);
  const getcompanySizeFromAPI = async () => {
    await authpostAxios(
      {
        url: `${process.env.REACT_APP_BACKENDURL}api/get-companysize`,
        data: "",
      },
      dispatch
    ).then((companySizeResponse) =>
      setCompanySizeLists(companySizeResponse.data?.data)
    );
  };
  useEffect(() => {
    getUserCompanyDetailsFromAPI();
    getCountriesFromAPI();
    getTimeZoneFromAPI();
    getbusinessCategoryFromAPI();
    getcompanySizeFromAPI();
  }, []);
  // for state
  useEffect(() => {
    if (
      submitCompanyForm.values.country !== "undefined" &&
      submitCompanyForm.values.country !== ""
    ) {
      setStateLists([]);
      setCityLists([]);
      getStateFromAPI();
    }
  }, [submitCompanyForm?.values?.country]);
  // for city
  useEffect(() => {
    if (
      submitCompanyForm.values.state !== "undefined" &&
      submitCompanyForm.values.state !== ""
    ) {
      setCityLists([]);
      getCityFromAPI();
    }
  }, [submitCompanyForm?.values?.state]);
  // image preview
  const [selectedCompanyLogo, setSelectedCompanyLogo] = useState();
  return (
    <>
      <Grid md={12} sx={{ mb: 2 }}>
        <form onSubmit={submitCompanyForm.handleSubmit}>
          <Grid
            sx={{
              boxShadow: "#919eab4d 0 0 2px, #919eab1f 0 12px 24px -4px",
              border: "1px solid #ddd",
              padding: "25px",
              borderRadius: "5px",
            }}
          >
            <Grid container item columnSpacing={2}>
              <Grid item md={6}>
                <TextField
                  size="small"
                  label="Company Name"
                  name="companyname"
                  value={submitCompanyForm.values.companyname}
                  onChange={submitCompanyForm.handleChange}
                  fullWidth
                  sx={{ mb: 2 }}
                  InputLabelProps={{
                    sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
                    shrink: true
                  }}
                  inputProps={{
                    sx: {
                      color: "#000000de",
                      fontSize: "15px",
                      fontWeight: 500,
                      fontFamily: "Inter",
                    },
                  }}
                />
                {submitCompanyForm.errors.companyname ? (
                  <Typography
                    variant="body2"
                    sx={{ color: "red", fontSize: 14 }}
                  >
                    {submitCompanyForm.errors.companyname}
                  </Typography>
                ) : null}
                <TextField
                  fullWidth
                  label="Company Email ID"
                  name="emailid"
                  value={submitCompanyForm.values.emailid}
                  onChange={submitCompanyForm.handleChange}
                  size="small"
                  sx={{ mb: 2 }}
                  InputLabelProps={{
                    sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
                    shrink: true
                  }}
                  inputProps={{
                    sx: {
                      color: "#000000de",
                      fontSize: "15px",
                      fontWeight: 500,
                      fontFamily: "Inter",
                    }
                  }}
                />
                {submitCompanyForm.errors.emailid ? (
                  <Typography
                    variant="body2"
                    sx={{ color: "#000", fontSize: 14 }}
                  >
                    {submitCompanyForm.errors.emailid}
                  </Typography>
                ) : null}
                <TextField
                  fullWidth
                  label="Address line 1"
                  name="address1"
                  value={submitCompanyForm.values.address1}
                  onChange={submitCompanyForm.handleChange}
                  size="small"
                  sx={{ mb: 2 }}
                  InputLabelProps={{
                    sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
                    shrink: true
                  }}
                  inputProps={{
                    sx: {
                      color: "#000000de",
                      fontSize: "15px",
                      fontWeight: 500,
                      fontFamily: "Inter",
                    },
                  }}
                  multiline
                  rows={3}
                />
                {submitCompanyForm.errors.address1 ? (
                  <Typography
                    variant="body2"
                    sx={{ color: "red", fontSize: 14 }}
                  >
                    {submitCompanyForm.errors.address1}
                  </Typography>
                ) : null}
                <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                  <InputLabel
                    id="country"
                    sx={{ color: "#92a2b1", fontSize: "15px", fontWeight: 400 }}
                  >
                    Country{" "}
                  </InputLabel>
                  <Select
                    name="country"
                    labelId="country"
                    label="Country"
                    value={submitCompanyForm.values.country}
                    onChange={submitCompanyForm.handleChange}
                    sx={{
                      color: "#000000de",
                      fontSize: "15px",
                      fontWeight: 500,
                    }}
                    input={<OutlinedInput label="Select Country" />}
                  >
                    {countryLists
                      ? countryLists.map((countryList, key) => (
                        <MenuItem value={countryList.countryid} key={key}>
                          {countryList.countryname}
                        </MenuItem>
                      ))
                      : null}
                  </Select>
                </FormControl>
                {submitCompanyForm.errors.country ? (
                  <Typography
                    variant="body2"
                    sx={{ color: "red", fontSize: 14 }}
                  >
                    {submitCompanyForm.errors.country}
                  </Typography>
                ) : null}
                <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                  <InputLabel
                    id="city"
                    sx={{ color: "#92a2b1", fontSize: "15px", fontWeight: 400 }}
                  >
                    City{" "}
                  </InputLabel>
                  <Select
                    name="city"
                    labelId="city"
                    label="City"
                    value={submitCompanyForm.values.city}
                    onChange={submitCompanyForm.handleChange}
                    sx={{
                      color: "#000000de",
                      fontSize: "15px",
                      fontWeight: 500,
                    }}
                    input={<OutlinedInput label="Select City" />}
                  >
                    {cityLists
                      ? cityLists.map((cityList, key) => (
                        <MenuItem value={cityList.cityid} key={key}>
                          {cityList.cityname}
                        </MenuItem>
                      ))
                      : null}
                  </Select>
                </FormControl>
                {submitCompanyForm.errors.city ? (
                  <Typography
                    variant="body2"
                    sx={{ color: "red", fontSize: 14 }}
                  >
                    {submitCompanyForm.errors.city}
                  </Typography>
                ) : null}
                <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                  <InputLabel
                    id="businesscategory"
                    sx={{ color: "#92a2b1", fontSize: "15px", fontWeight: 400 }}
                  >
                    Business Category{" "}
                  </InputLabel>
                  <Select
                    name="businesscategory"
                    labelId="businesscategory"
                    label="Business Category"
                    value={submitCompanyForm.values.businesscategory}
                    onChange={submitCompanyForm.handleChange}
                    sx={{
                      color: "#000000de",
                      fontSize: "15px",
                      fontWeight: 500,
                    }}
                    input={<OutlinedInput label="Select Business Category" />}
                  >
                    {businessCategoryLists
                      ? businessCategoryLists.map(
                        (businessCategoryList, key) => (
                          <MenuItem
                            value={businessCategoryList.business_category_id}
                            key={key}
                          >
                            {businessCategoryList.business_category_name}
                          </MenuItem>
                        )
                      )
                      : null}
                  </Select>
                </FormControl>
                {submitCompanyForm.errors.businesscategory ? (
                  <Typography
                    variant="body2"
                    sx={{ color: "red", fontSize: 14 }}
                  >
                    {submitCompanyForm.errors.businesscategory}
                  </Typography>
                ) : null}
                <TextField
                  size="small"
                  label="GST No"
                  name="gstno"
                  value={submitCompanyForm.values.gstno}
                  onChange={submitCompanyForm.handleChange}
                  fullWidth
                  sx={{ mb: 2 }}
                  InputLabelProps={{
                    sx: { color: "#92a2b1", fontSize: 15, fontWeight: 400 },
                    shrink: true
                  }}
                  inputProps={{
                    sx: { color: "#000000de", fontSize: 15, fontWeight: 400 },
                  }}
                />
                {submitCompanyForm.errors.gstno ? (
                  <Typography
                    variant="body2"
                    sx={{ color: "red", fontSize: 14 }}
                  >
                    {submitCompanyForm.errors.gstno}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item md={6}>
                <TextField
                  size="small"
                  label="Phone number"
                  name="phone"
                  value={submitCompanyForm.values.phone}
                  onChange={submitCompanyForm.handleChange}
                  fullWidth
                  sx={{ mb: 2 }}
                  InputLabelProps={{
                    sx: { color: "#92a2b1", fontSize: 15, fontWeight: 400 },
                    shrink: true
                  }}
                  inputProps={{
                    sx: { color: "#000000de", fontSize: 15, fontWeight: 400 },
                  }}
                />
                {submitCompanyForm.errors.phone ? (
                  <Typography
                    variant="body2"
                    sx={{ color: "red", fontSize: 14 }}
                  >
                    {submitCompanyForm.errors.phone}
                  </Typography>
                ) : null}
                <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                  <InputLabel
                    id="timezone"
                    sx={{ color: "#92a2b1", fontSize: "15px", fontWeight: 400 }}
                  >
                    Default Timezone{" "}
                  </InputLabel>
                  <Select
                    name="timezone"
                    labelId="timezone"
                    label="Default Timezone"
                    value={submitCompanyForm.values.timezone}
                    onChange={submitCompanyForm.handleChange}
                    sx={{
                      color: "#000000de",
                      fontSize: "15px",
                      fontWeight: 500,
                    }}
                    input={<OutlinedInput label="Select Default Timezone" />}
                  >
                    {timezoneLists
                      ? timezoneLists.map((timezoneList, key) => (
                        <MenuItem value={timezoneList.timezone_id} key={key}>
                          {timezoneList.timezone_name}
                        </MenuItem>
                      ))
                      : null}
                  </Select>
                </FormControl>
                {submitCompanyForm.errors.timezone ? (
                  <Typography
                    variant="body2"
                    sx={{ color: "red", fontSize: 14 }}
                  >
                    {submitCompanyForm.errors.timezone}
                  </Typography>
                ) : null}
                <TextField
                  fullWidth
                  label="Address line 2"
                  name="address2"
                  value={submitCompanyForm.values.address2}
                  onChange={submitCompanyForm.handleChange}
                  size="small"
                  sx={{ mb: 2 }}
                  InputLabelProps={{
                    sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
                    shrink: true
                  }}
                  inputProps={{
                    sx: {
                      color: "#000000de",
                      fontSize: "15px",
                      fontWeight: 500,
                      fontFamily: "Inter",
                    },
                  }}
                  multiline
                  rows={3}
                />
                {submitCompanyForm.errors.address2 ? (
                  <Typography
                    variant="body2"
                    sx={{ color: "red", fontSize: 14 }}
                  >
                    {submitCompanyForm.errors.address2}
                  </Typography>
                ) : null}
                <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                  <InputLabel
                    id="state"
                    sx={{ color: "#92a2b1", fontSize: "15px", fontWeight: 400 }}
                  >
                    State{" "}
                  </InputLabel>
                  <Select
                    name="state"
                    labelId="state"
                    label="State"
                    value={submitCompanyForm.values.state}
                    onChange={submitCompanyForm.handleChange}
                    sx={{
                      color: "#000000de",
                      fontSize: "15px",
                      fontWeight: 500,
                    }}
                    input={<OutlinedInput label="Select State" />}
                  >
                    {stateLists
                      ? stateLists.map((stateList, key) => (
                        <MenuItem value={stateList.stateid} key={key}>
                          {stateList.statename}
                        </MenuItem>
                      ))
                      : null}
                  </Select>
                </FormControl>
                {submitCompanyForm.errors.state ? (
                  <Typography
                    variant="body2"
                    sx={{ color: "red", fontSize: 14 }}
                  >
                    {submitCompanyForm.errors.state}
                  </Typography>
                ) : null}
                <TextField
                  fullWidth
                  label="Zipcode / Postal code"
                  name="postalcode"
                  value={submitCompanyForm.values.postalcode}
                  onChange={submitCompanyForm.handleChange}
                  size="small"
                  sx={{ mb: 2 }}
                  InputLabelProps={{
                    sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
                    shrink: true
                  }}
                  inputProps={{
                    sx: {
                      color: "#000000de",
                      fontSize: "15px",
                      fontWeight: 500,
                      fontFamily: "Inter",
                    },
                  }}
                />
                {submitCompanyForm.errors.postalcode ? (
                  <Typography
                    variant="body2"
                    sx={{ color: "red", fontSize: 14 }}
                  >
                    {submitCompanyForm.errors.postalcode}
                  </Typography>
                ) : null}
                <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                  <InputLabel
                    id="companysize"
                    sx={{ color: "#92a2b1", fontSize: "15px", fontWeight: 400 }}
                  >
                    Company Size{" "}
                  </InputLabel>
                  <Select
                    name="companysize"
                    labelId="companysize"
                    label="Company size"
                    value={submitCompanyForm.values.companysize}
                    onChange={submitCompanyForm.handleChange}
                    sx={{
                      color: "#000000de",
                      fontSize: "15px",
                      fontWeight: 500,
                    }}
                    input={<OutlinedInput label="Select Company size" />}
                  >
                    {companySizeLists
                      ? companySizeLists.map((companySizeList, key) => (
                        <MenuItem
                          value={companySizeList.company_size_id}
                          key={key}
                        >
                          {companySizeList.company_size}
                        </MenuItem>
                      ))
                      : null}
                  </Select>
                </FormControl>
                {submitCompanyForm.errors.companysize ? (
                  <Typography
                    variant="body2"
                    sx={{ color: "red", fontSize: 14 }}
                  >
                    {submitCompanyForm.errors.companysize}
                  </Typography>
                ) : null}
                <TextField
                  fullWidth
                  label="Pan No"
                  name="panno"
                  value={submitCompanyForm.values.panno}
                  onChange={submitCompanyForm.handleChange}
                  size="small"
                  sx={{ mb: 2 }}
                  InputLabelProps={{
                    sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
                    shrink: true
                  }}
                  inputProps={{
                    sx: {
                      color: "#000000de",
                      fontSize: "15px",
                      fontWeight: 500,
                      fontFamily: "Inter",
                    },
                  }}
                />
                {submitCompanyForm.errors.panno ? (
                  <Typography
                    variant="body2"
                    sx={{ color: "red", fontSize: 14 }}
                  >
                    {submitCompanyForm.errors.panno}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item md={6} sx={{ mb: 2 }}>
                <input
                  type="file"
                  name="companylogo"
                  id="companylogo"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={(e) => {
                    submitCompanyForm.setFieldValue(
                      "companylogo",
                      e.currentTarget.files[0]
                    );
                    setSelectedCompanyLogo(e.currentTarget.files[0]);
                  }}
                />
                <Button
                  component="label"
                  htmlFor="companylogo"
                  role={undefined}
                  variant="outlined"
                  tabIndex={-1}
                  fullWidth
                  startIcon={<CloudUploadIcon />}
                  sx={{
                    textTransform: "capitalize",
                    fontFamily: "Inter",
                    background: "#fff",
                    border: "1px solid #cfd7df",
                    color: "#6e747a",
                  }}
                  onChange={(e) => {
                    submitCompanyForm.setFieldValue(
                      "companylogo",
                      e.currentTarget.files[0]
                    );
                    setSelectedCompanyLogo(e.currentTarget.files[0]);
                  }}
                >
                  Company Logo Choose file
                </Button>

                {selectedCompanyLogo ? (
                  <Grid sx={thumb}>
                    <img
                      src={URL.createObjectURL(selectedCompanyLogo)}
                      style={img}
                    />
                  </Grid>
                ) : (
                  submitCompanyForm.values.companylogoExistPath && (
                    <Grid sx={thumb}>
                      <img
                        src={submitCompanyForm.values.companylogoExistPath}
                        style={img}
                      />
                    </Grid>
                  )
                )}
                {submitCompanyForm.errors.companylogo ? (
                  <Typography
                    variant="body2"
                    sx={{ color: "red", fontSize: 14 }}
                  >
                    {submitCompanyForm.errors.companylogo}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item md={12}>
                <Grid sx={{ textAlign: "end" }}>
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: "capitalize",
                      marginRight: "20px",
                      fontFamily: "Inter",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ textTransform: "capitalize", fontFamily: "Inter" }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
