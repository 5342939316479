import { Accordion, AccordionSummary, Tab, Tabs, Grid, Typography, Fade, AccordionDetails, Box, Checkbox, FormControlLabel, Button, Select, FormControl, InputLabel, MenuItem } from "@mui/material"
import React, { useState } from "react";
import Facebooksub from '../img/sub-icon-1.svg';
import Listimg from '../img/list-img.jpg';
import { Link } from "react-router-dom";
import Instasub from '../img/sub-icon-2.png';
import Twittersub from '../img/sub-icon-3.png';
import Linkedinsub from '../img/sub-icon-4.png';
import Channelsub from '../img/sub-icon-5.png';
import Googlesub from '../img/sub-icon-6.png';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import 'quill-emoji/dist/quill-emoji.js';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import { ExpandMore, ThumbUpOutlined, StayCurrentPortraitOutlined, ComputerOutlined, CheckCircle, ReplyAllOutlined, SendOutlined, RepeatOutlined, ShareOutlined, FavoriteBorderOutlined, WarningAmberOutlined, ChatBubbleOutlineOutlined, BookmarkBorderOutlined, FileUploadOutlined } from '@mui/icons-material/';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const Socialpreview = () => {
    const [editorValue, setEditorValue] = useState()
    const toolbarOptions = [
        ['bold', 'italic', 'underline'],
        ['emoji'], // Add an emoji button
    ];
    
    const [expanded, setExpanded] = React.useState(false);
    const handleExpansion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [age, setAge] = React.useState('');

    const handleChange1 = (event) => {
        setAge(event.target.value);
    };
    return (
        <>
            <Grid container sx={{ mt: 1 }}>
                <Grid md={4}>
                    <Typography sx={{ fontFamily: 'Inter', fontSize: '14px', fontWeight: '500', alignItems: 'center' }}>Preview</Typography>
                </Grid>
                <Grid md={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                    <Tabs value={value} onChange={handleChange}
                        sx={{
                            '& .Mui-selected, .css-1aquho2-MuiTabs-indicator': {
                                backgroundColor: '#eeeff0',
                                height: 'unset', fontSize: '13px', minWidth: '45px', flexDirection: 'row', borderRadius: '4px'
                            },
                            '& .css-1pkckr5-MuiButtonBase-root-MuiTab-root': {
                                minWidth: '45px',
                                minHeight: '35px',
                            },
                            '& .css-qmhldy-MuiButtonBase-root-MuiTab-root>.MuiTab-iconWrapper': {
                                marginBottom: '0',
                            }
                        }}
                    >
                        <Tab icon={<ComputerOutlined />} {...a11yProps(0)}
                            sx={{ padding: '0px 13px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', }}
                        />
                        <Tab icon={<StayCurrentPortraitOutlined />} {...a11yProps(1)}
                            sx={{ padding: '0px 13px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }} />
                    </Tabs>
                </Grid>
            </Grid>
            <Grid container>
                <Grid md={12} sx={{ mb: '2' }}>
                    <Grid sx={{ '& .css-19kzrtu': { padding: '0px 0px' } }}>
                        <CustomTabPanel value={value} index={0}>
                            <Accordion variant="0"
                                expanded={expanded}
                                onChange={handleExpansion}
                                slots={{ transition: Fade }}
                                slotProps={{ transition: { timeout: 400 } }}
                                sx={{
                                    '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                                    '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel7-content"
                                    id="panel7-header"
                                    sx={{ padding: '5px 7px', borderBottom: '1px solid #ddd', '& .css-o4b71y-MuiAccordionSummary-content.Mui-expanded': { margin: '10px 0' } }}
                                >
                                    <div style={{ width: '100%', }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'nowrap',
                                                p: 0,
                                            }}
                                        >
                                            <Grid sx={{ marginRight: '10px', }}>
                                                <img src={Facebooksub} />
                                            </Grid>
                                            <Grid>
                                                <Typography sx={{
                                                    fontSize: '13px',
                                                    fontWeight: 500,
                                                    textAlign: 'left',
                                                    color: '#27292f !important',
                                                }}>Facebook</Typography>
                                                <Typography sx={{
                                                    fontSize: '13px',
                                                    fontWeight: 500,
                                                    textAlign: 'left',
                                                    color: '#87888e !important',
                                                }}>Pages</Typography>
                                            </Grid>
                                        </Box>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: '10px 10px' }}>
                                    <div style={{ width: '100%', }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'nowrap',
                                                p: 0,
                                            }}
                                        >
                                            <Grid sx={{ marginRight: '10px', }}>
                                                <img src={Listimg} style={{
                                                    borderRadius: '50px',
                                                    width: '32.5px',
                                                    height: '32.5px',
                                                    objectFit: 'cover',
                                                }} />
                                            </Grid>
                                            <Grid>
                                                <Typography sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 500,
                                                    textAlign: 'left',
                                                    color: '#27292f',
                                                    marginBottom: '1px',
                                                    fontFamily: 'Inter'
                                                }}>Kolathur tea shop <CheckCircle
                                                        sx={{ fontSize: '13px', color: '#1877f2', verticalAlign: 'middle' }} /></Typography>
                                                <Typography sx={{
                                                    fontSize: '12px',
                                                    fontWeight: 'normal',
                                                    textAlign: 'left',
                                                    color: '#717277',
                                                    fontFamily: 'Inter'
                                                }}>November 29, 11:43</Typography>
                                            </Grid>
                                        </Box>
                                    </div>
                                    <Grid md={12}>
                                        <Typography sx={{
                                            fontSize: '12px',
                                            fontWeight: 'normal',
                                            textAlign: 'left',
                                            color: '#27292f',
                                            marginBottom: '10px',
                                            fontFamily: 'Inter',
                                            marginTop: '7px',
                                        }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum</Typography>
                                    </Grid>
                                    <Grid md={12}>
                                        <img src={Listimg} style={{
                                            borderRadius: '5px',
                                            width: '100%',
                                            height: '200px',
                                            objectFit: 'cover',
                                            objectPosition: 'top',
                                            overflow: 'hidden',
                                        }} />
                                    </Grid>
                                    <Grid md={12} sx={{ mt: 1 }}>
                                        <iframe width="100%" height="300" src="https://www.youtube.com/embed/nLQ-9vfEjUI"
                                            title="React video player | Custom video player in react js" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                    </Grid>
                                    <Grid container item spacing={2} sx={{}}>
                                        <Grid item md={4} sx={{ textAlign: 'start' }}>
                                            <Link to={"/"} style={{
                                                textDecoration: 'none',
                                                fontSize: '13px',
                                                fontWeight: 'normal',
                                                color: '#27292f',
                                            }}>
                                                <ThumbUpOutlined sx={{ verticalAlign: 'middle', fontSize: '20px', marginRight: '3px' }} /> Like
                                            </Link>
                                        </Grid>
                                        <Grid item md={4} sx={{ textAlign: 'end' }}>
                                            <Link to={"/"} style={{
                                                textDecoration: 'none',
                                                fontSize: '13px',
                                                fontWeight: 'normal',
                                                textAlign: 'left',
                                                color: '#27292f',
                                            }}>
                                                <ChatBubbleOutlineOutlined sx={{ verticalAlign: 'middle', fontSize: '20px', marginRight: '3px' }} /> Comment
                                            </Link>
                                        </Grid>
                                        <Grid item md={4} sx={{ textAlign: 'end' }}>
                                            <Link to={"/"} style={{
                                                textDecoration: 'none',
                                                fontSize: '13px',
                                                fontWeight: 'normal',
                                                color: '#27292f',
                                            }}>
                                                <ReplyAllOutlined sx={{ verticalAlign: 'middle', fontSize: '20px', marginRight: '3px' }} /> Share
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion variant="0"
                                expanded={expanded}
                                onChange={handleExpansion}
                                slots={{ transition: Fade }}
                                slotProps={{ transition: { timeout: 400 } }}
                                sx={{
                                    '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                                    '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel8-content"
                                    id="panel8-header"
                                    sx={{ padding: '5px 7px', borderBottom: '1px solid #ddd', '& .css-o4b71y-MuiAccordionSummary-content.Mui-expanded': { margin: '10px 0' } }}
                                >
                                    <div style={{ width: '100%', }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'nowrap',
                                                p: 0,
                                            }}
                                        >
                                            <Grid sx={{ marginRight: '10px', }}>
                                                <img src={Instasub} />
                                            </Grid>
                                            <Grid>
                                                <Typography sx={{
                                                    fontSize: '13px',
                                                    fontWeight: 500,
                                                    textAlign: 'left',
                                                    color: '#27292f !important',
                                                }}>Instagram</Typography>
                                                <Typography sx={{
                                                    fontSize: '13px',
                                                    fontWeight: 500,
                                                    textAlign: 'left',
                                                    color: '#87888e !important',
                                                }}>Business Profile</Typography>
                                            </Grid>
                                        </Box>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: '10px 10px' }}>
                                    <div style={{ width: '100%', }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'nowrap',
                                                p: 0,
                                            }}
                                        >
                                            <Grid sx={{ marginRight: '10px', }}>
                                                <img src={Listimg} style={{
                                                    borderRadius: '50px',
                                                    width: '32.5px',
                                                    height: '32.5px',
                                                    objectFit: 'cover',
                                                }} />
                                            </Grid>
                                            <Grid>
                                                <Typography sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 500,
                                                    textAlign: 'left',
                                                    color: '#27292f',
                                                    marginBottom: '1px',
                                                    fontFamily: 'Inter'
                                                }}>Kolathur tea shop </Typography>
                                            </Grid>
                                        </Box>
                                    </div>

                                    <Grid md={12}>
                                        <img src={Listimg} style={{
                                            borderRadius: '5px',
                                            width: '100%',
                                            height: '200px',
                                            objectFit: 'cover',
                                            objectPosition: 'top',
                                            overflow: 'hidden',
                                            marginTop: '15px',
                                        }} />
                                    </Grid>
                                    <Grid container item spacing={2} sx={{}}>
                                        <Grid item md={8} sx={{ textAlign: 'start' }}>
                                            <Link to={"/"} style={{
                                                textDecoration: 'none',
                                                color: '#27292f',
                                            }}>
                                                <FavoriteBorderOutlined sx={{ verticalAlign: 'middle', fontSize: '20px', marginRight: '10px' }} />
                                            </Link>
                                            <Link to={"/"} style={{
                                                textDecoration: 'none',
                                                textAlign: 'left',
                                                color: '#27292f',
                                            }}>
                                                <ChatBubbleOutlineOutlined sx={{ verticalAlign: 'middle', fontSize: '20px', marginRight: '10px' }} />
                                            </Link>
                                            <Link to={"/"} style={{
                                                textDecoration: 'none',
                                                textAlign: 'left',
                                                color: '#27292f',
                                            }}>
                                                <SendOutlined sx={{ verticalAlign: 'middle', fontSize: '20px', }} />
                                            </Link>
                                        </Grid>
                                        <Grid item md={4} sx={{ textAlign: 'end' }}>
                                            <Link to={"/"} style={{
                                                textDecoration: 'none',
                                                fontSize: '13px',
                                                fontWeight: 'normal',
                                                color: '#27292f',
                                            }}>
                                                <BookmarkBorderOutlined sx={{ verticalAlign: 'middle', fontSize: '20px', }} />
                                            </Link>
                                        </Grid>
                                    </Grid>
                                    <Grid md={12}>
                                        <Typography sx={{
                                            fontSize: '12px',
                                            fontWeight: 'normal',
                                            textAlign: 'left',
                                            color: '#27292f',
                                            marginBottom: '10px',
                                            fontFamily: 'Inter',
                                            marginTop: '7px',
                                        }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum</Typography>
                                    </Grid>
                                    <Grid md={12}>
                                        <Typography sx={{
                                            fontSize: '12px',
                                            fontWeight: 'normal',
                                            textAlign: 'left',
                                            color: '#717277',
                                            fontFamily: 'Inter'
                                        }}>November 29, 11:43</Typography>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion variant="0"
                                expanded={expanded}
                                onChange={handleExpansion}
                                slots={{ transition: Fade }}
                                slotProps={{ transition: { timeout: 400 } }}
                                sx={{
                                    '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                                    '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel9-content"
                                    id="panel9-header"
                                    sx={{ padding: '5px 7px', borderBottom: '1px solid #ddd', '& .css-o4b71y-MuiAccordionSummary-content.Mui-expanded': { margin: '10px 0' } }}
                                >
                                    <div style={{ width: '100%', }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'nowrap',
                                                p: 0,
                                            }}
                                        >
                                            <Grid sx={{ marginRight: '10px', }}>
                                                <img src={Twittersub} />
                                            </Grid>
                                            <Grid>
                                                <Typography sx={{
                                                    fontSize: '13px',
                                                    fontWeight: 500,
                                                    textAlign: 'left',
                                                    color: '#27292f !important',
                                                }}>Twitter</Typography>
                                                <Typography sx={{
                                                    fontSize: '13px',
                                                    fontWeight: 500,
                                                    textAlign: 'left',
                                                    color: '#87888e !important',
                                                }}>Pages</Typography>
                                            </Grid>
                                        </Box>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: '10px 10px' }}>
                                    <div style={{ width: '100%', }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'nowrap',
                                                p: 0,
                                            }}
                                        >
                                            <Grid sx={{ marginRight: '10px', }}>
                                                <img src={Listimg} style={{
                                                    borderRadius: '50px',
                                                    width: '32.5px',
                                                    height: '32.5px',
                                                    objectFit: 'cover',
                                                }} />
                                            </Grid>
                                            <Grid>
                                                <Typography sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 500,
                                                    textAlign: 'left',
                                                    color: '#27292f',
                                                    marginBottom: '1px',
                                                    fontFamily: 'Inter'
                                                }}>Kolathur tea shop <span style={{ color: '#717277', fontSize: '12px', }}> @Kolathur tea shop  </span></Typography>
                                                <Typography sx={{
                                                    fontSize: '12px',
                                                    fontWeight: 'normal',
                                                    textAlign: 'left',
                                                    color: '#717277',
                                                    fontFamily: 'Inter'
                                                }}>November 29, 11:43</Typography>
                                            </Grid>
                                        </Box>
                                    </div>
                                    <Grid md={12}>
                                        <Typography sx={{
                                            fontSize: '12px',
                                            fontWeight: 'normal',
                                            textAlign: 'left',
                                            color: '#27292f',
                                            marginBottom: '10px',
                                            fontFamily: 'Inter',
                                            marginTop: '7px',
                                        }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsummm</Typography>
                                    </Grid>
                                    <Grid md={12}>
                                        <img src={Listimg} style={{
                                            borderRadius: '5px',
                                            width: '100%',
                                            height: '200px',
                                            objectFit: 'cover',
                                            objectPosition: 'top',
                                            overflow: 'hidden',
                                        }} />
                                    </Grid>
                                    <Grid container item spacing={2} sx={{}}>

                                        <Grid item md={3} sx={{ textAlign: 'start' }}>
                                            <Link to={"/"} style={{
                                                textDecoration: 'none',
                                                fontSize: '13px',
                                                fontWeight: 'normal',
                                                textAlign: 'left',
                                                color: '#27292f',
                                            }}>
                                                <ChatBubbleOutlineOutlined sx={{ verticalAlign: 'middle', fontSize: '20px', marginRight: '3px' }} />
                                            </Link>
                                        </Grid>
                                        <Grid item md={3} sx={{ textAlign: 'center' }}>
                                            <Link to={"/"} style={{
                                                textDecoration: 'none',
                                                fontSize: '13px',
                                                fontWeight: 'normal',
                                                color: '#27292f',
                                            }}>
                                                <RepeatOutlined sx={{ verticalAlign: 'middle', fontSize: '20px', marginRight: '3px' }} />
                                            </Link>
                                        </Grid>
                                        <Grid item md={3} sx={{ textAlign: 'end' }}>
                                            <Link to={"/"} style={{
                                                textDecoration: 'none',
                                                fontSize: '13px',
                                                fontWeight: 'normal',
                                                color: '#27292f',
                                            }}>
                                                <FavoriteBorderOutlined sx={{ verticalAlign: 'middle', fontSize: '20px', marginRight: '3px' }} />
                                            </Link>
                                        </Grid>
                                        <Grid item md={3} sx={{ textAlign: 'end' }}>
                                            <Link to={"/"} style={{
                                                textDecoration: 'none',
                                                fontSize: '13px',
                                                fontWeight: 'normal',
                                                color: '#27292f',
                                            }}>
                                                <FileUploadOutlined sx={{ verticalAlign: 'middle', fontSize: '20px', marginRight: '3px' }} />
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion variant="0"
                                expanded={expanded}
                                onChange={handleExpansion}
                                slots={{ transition: Fade }}
                                slotProps={{ transition: { timeout: 400 } }}
                                sx={{
                                    '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                                    '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel10-content"
                                    id="panel10-header"
                                    sx={{ padding: '5px 7px', borderBottom: '1px solid #ddd', '& .css-o4b71y-MuiAccordionSummary-content.Mui-expanded': { margin: '10px 0' } }}
                                >
                                    <div style={{ width: '100%', }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'nowrap',
                                                p: 0,
                                            }}
                                        >
                                            <Grid sx={{ marginRight: '10px', }}>
                                                <img src={Linkedinsub} />
                                            </Grid>
                                            <Grid>
                                                <Typography sx={{
                                                    fontSize: '13px',
                                                    fontWeight: 500,
                                                    textAlign: 'left',
                                                    color: '#27292f !important',
                                                }}>linkedin</Typography>
                                                <Typography sx={{
                                                    fontSize: '13px',
                                                    fontWeight: 500,
                                                    textAlign: 'left',
                                                    color: '#87888e !important',
                                                }}>Pages</Typography>
                                            </Grid>
                                        </Box>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: '10px 10px' }}>
                                    <div style={{ width: '100%', }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'nowrap',
                                                p: 0,
                                            }}
                                        >
                                            <Grid sx={{ marginRight: '10px', }}>
                                                <img src={Listimg} style={{
                                                    borderRadius: '50px',
                                                    width: '32.5px',
                                                    height: '32.5px',
                                                    objectFit: 'cover',
                                                }} />
                                            </Grid>
                                            <Grid>
                                                <Typography sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 500,
                                                    textAlign: 'left',
                                                    color: '#27292f',
                                                    marginBottom: '1px',
                                                    fontFamily: 'Inter'
                                                }}>Kolathur tea shop <span style={{ color: '#717277', fontSize: '12px', }}> @Kolathur tea shop  </span></Typography>
                                                <Typography sx={{
                                                    fontSize: '12px',
                                                    fontWeight: 'normal',
                                                    textAlign: 'left',
                                                    color: '#717277',
                                                    fontFamily: 'Inter'
                                                }}>November 29, 11:43</Typography>
                                            </Grid>
                                        </Box>
                                    </div>
                                    <Grid md={12}>
                                        <Typography sx={{
                                            fontSize: '12px',
                                            fontWeight: 'normal',
                                            textAlign: 'left',
                                            color: '#27292f',
                                            marginBottom: '10px',
                                            fontFamily: 'Inter',
                                            marginTop: '7px',
                                        }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum</Typography>
                                    </Grid>
                                    <Grid md={12}>
                                        <img src={Listimg} style={{
                                            borderRadius: '5px',
                                            width: '100%',
                                            height: '200px',
                                            objectFit: 'cover',
                                            objectPosition: 'top',
                                            overflow: 'hidden',
                                        }} />
                                    </Grid>
                                    <Grid container item>
                                        <Grid item md={3} sx={{ textAlign: 'start' }}>
                                            <Link to={"/"} style={{
                                                textDecoration: 'none',
                                                fontSize: '13px',
                                                fontWeight: 'normal',
                                                color: '#27292f',
                                            }}>
                                                <ThumbUpOutlined sx={{ verticalAlign: 'middle', fontSize: '20px', marginRight: '3px' }} /> Like
                                            </Link>
                                        </Grid>
                                        <Grid item md={3} sx={{ textAlign: 'start' }}>
                                            <Link to={"/"} style={{
                                                textDecoration: 'none',
                                                fontSize: '13px',
                                                fontWeight: 'normal',
                                                textAlign: 'left',
                                                color: '#27292f',
                                            }}>
                                                <ChatBubbleOutlineOutlined sx={{ verticalAlign: 'middle', fontSize: '20px', marginRight: '3px' }} /> Comment
                                            </Link>
                                        </Grid>
                                        <Grid item md={3} sx={{ textAlign: 'end' }}>
                                            <Link to={"/"} style={{
                                                textDecoration: 'none',
                                                fontSize: '13px',
                                                fontWeight: 'normal',
                                                color: '#27292f',
                                            }}>
                                                <RepeatOutlined sx={{ verticalAlign: 'middle', fontSize: '20px', marginRight: '3px' }} /> Repost
                                            </Link>
                                        </Grid>
                                        <Grid item md={3} sx={{ textAlign: 'end' }}>
                                            <Link to={"/"} style={{
                                                textDecoration: 'none',
                                                fontSize: '13px',
                                                fontWeight: 'normal',
                                                color: '#27292f',
                                            }}>
                                                <SendOutlined sx={{ verticalAlign: 'middle', fontSize: '20px', marginRight: '3px' }} /> Send
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion variant="0"
                                expanded={expanded}
                                onChange={handleExpansion}
                                slots={{ transition: Fade }}
                                slotProps={{ transition: { timeout: 400 } }}
                                sx={{
                                    '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                                    '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel11-content"
                                    id="panel11-header"
                                    sx={{ padding: '5px 7px', borderBottom: '1px solid #ddd', '& .css-o4b71y-MuiAccordionSummary-content.Mui-expanded': { margin: '10px 0' } }}
                                >
                                    <div style={{ width: '100%', }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'nowrap',
                                                p: 0,
                                            }}
                                        >
                                            <Grid sx={{ marginRight: '10px', }}>
                                                <img src={Channelsub} />
                                            </Grid>
                                            <Grid>
                                                <Typography sx={{
                                                    fontSize: '13px',
                                                    fontWeight: 500,
                                                    textAlign: 'left',
                                                    color: '#27292f !important',
                                                }}>Channel</Typography>
                                                <Typography sx={{
                                                    fontSize: '13px',
                                                    fontWeight: 500,
                                                    textAlign: 'left',
                                                    color: '#87888e !important',
                                                }}>Pages</Typography>
                                            </Grid>
                                        </Box>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: '10px 10px' }}>
                                    <Grid md={12} sx={{ mt: 1 }}>
                                        <iframe width="100%" height="300" src="https://www.youtube.com/embed/nLQ-9vfEjUI"
                                            title="React video player | Custom video player in react js" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                    </Grid>
                                    <div style={{ width: '100%', }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'nowrap',
                                                p: 0,
                                            }}
                                        >
                                            <Grid sx={{ marginRight: '10px', }}>
                                                <img src={Listimg} style={{
                                                    borderRadius: '50px',
                                                    width: '32.5px',
                                                    height: '32.5px',
                                                    objectFit: 'cover',
                                                }} />
                                            </Grid>
                                            <Grid>
                                                <Typography sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 500,
                                                    textAlign: 'left',
                                                    color: '#27292f',
                                                    marginBottom: '1px',
                                                    fontFamily: 'Inter'
                                                }}>Kolathur tea shop </Typography>
                                                <Typography sx={{
                                                    fontSize: '12px',
                                                    fontWeight: 'normal',
                                                    textAlign: 'left',
                                                    color: '#717277',
                                                    fontFamily: 'Inter'
                                                }}>November 29, 11:43</Typography>
                                            </Grid>
                                        </Box>
                                    </div>
                                    <Grid md={12}>
                                        <Typography sx={{
                                            fontSize: '12px',
                                            fontWeight: 'normal',
                                            textAlign: 'left',
                                            color: '#27292f',
                                            marginBottom: '10px',
                                            fontFamily: 'Inter',
                                            marginTop: '7px',
                                        }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum</Typography>
                                    </Grid>
                                    <Grid md={12}>
                                        <Typography
                                            sx={{
                                                fontSize: '13px',
                                                fontWeight: '600',
                                                textAlign: 'left',
                                                color: '#f14040',
                                                marginbBottom: '10px',
                                            }}><WarningAmberOutlined sx={{ verticalAlign: 'middle', fontSize: '17px' }} /> Youtube requires a video</Typography>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion variant="0"
                                expanded={expanded}
                                onChange={handleExpansion}
                                slots={{ transition: Fade }}
                                slotProps={{ transition: { timeout: 400 } }}
                                sx={{
                                    '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                                    '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel12-content"
                                    id="panel12-header"
                                    sx={{ padding: '5px 7px', borderBottom: '1px solid #ddd', '& .css-o4b71y-MuiAccordionSummary-content.Mui-expanded': { margin: '10px 0' } }}
                                >
                                    <div style={{ width: '100%', }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'nowrap',
                                                p: 0,
                                            }}
                                        >
                                            <Grid sx={{ marginRight: '10px', }}>
                                                <img src={Googlesub} />
                                            </Grid>
                                            <Grid>
                                                <Typography sx={{
                                                    fontSize: '13px',
                                                    fontWeight: 500,
                                                    textAlign: 'left',
                                                    color: '#27292f !important',
                                                }}>Google business</Typography>
                                                <Typography sx={{
                                                    fontSize: '13px',
                                                    fontWeight: 500,
                                                    textAlign: 'left',
                                                    color: '#87888e !important',
                                                }}>Pages</Typography>
                                            </Grid>
                                        </Box>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: '10px 10px' }}>
                                    <div style={{ width: '100%', }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'nowrap',
                                                p: 0,
                                            }}
                                        >
                                            <Grid sx={{ marginRight: '10px', }}>
                                                <img src={Listimg} style={{
                                                    borderRadius: '50px',
                                                    width: '32.5px',
                                                    height: '32.5px',
                                                    objectFit: 'cover',
                                                }} />
                                            </Grid>
                                            <Grid container>
                                                <Grid md={10}>
                                                    <Typography sx={{
                                                        fontSize: '14px',
                                                        fontWeight: 500,
                                                        textAlign: 'left',
                                                        color: '#27292f',
                                                        marginBottom: '1px',
                                                        fontFamily: 'Inter'
                                                    }}>Kolathur tea shop </Typography>

                                                    <Typography sx={{
                                                        fontSize: '13px',
                                                        fontWeight: 'normal',
                                                        textAlign: 'left',
                                                        color: '#27292f',
                                                        fontFamily: 'Inter'
                                                    }}>Industrial estate</Typography>
                                                    <Typography sx={{
                                                        fontSize: '12px',
                                                        fontWeight: 'normal',
                                                        textAlign: 'left',
                                                        color: '#717277',
                                                        fontFamily: 'Inter'
                                                    }}>November 29, 11:43</Typography>
                                                </Grid>
                                                <Grid md={2} sx={{ textAlign: 'end' }}>
                                                    <Link to={"/"} style={{ textAlign: 'end', }}>
                                                        <ShareOutlined sx={{ color: 'rgb(39, 41, 47)', fontSize: '17px' }} />
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </div>

                                    <Grid md={12}>
                                        <img src={Listimg} style={{
                                            borderRadius: '5px',
                                            width: '100%',
                                            height: '200px',
                                            objectFit: 'cover',
                                            objectPosition: 'top',
                                            overflow: 'hidden',
                                            marginTop: '10px'
                                        }} />
                                    </Grid>
                                    <Grid md={12}>
                                        <Typography sx={{
                                            fontSize: '12px',
                                            fontWeight: 'normal',
                                            textAlign: 'left',
                                            color: '#27292f',
                                            marginBottom: '10px',
                                            fontFamily: 'Inter',
                                            marginTop: '7px',
                                        }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum</Typography>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                        </CustomTabPanel>

                        <CustomTabPanel value={value} index={1}>
                            Mobile Preview
                        </CustomTabPanel>
                    </Grid>
                </Grid>


            </Grid>
        </>
    )
}

export default Socialpreview