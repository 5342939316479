import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";
import styleCss from "../Style/style";
import moment from "moment";
import { Link } from "react-router-dom";
import Socialicon1 from "../img/sub-icon-1.svg";
import Likeimg1 from "../img/social-icon-2.png";
import Likeimg2 from "../img/sub-icon-4.png";
import Likeimg3 from "../img/sub-icon-3.png";
import NoImage from "../img/no-image.jpg";
import Eyeiconview from '@mui/icons-material/RemoveRedEyeOutlined';


export const PostListTable = ({ mediaId, lists, handlePopupOpen, pageAccountId }) => {
  const tableData = (mediaId, lists) => {
    if (mediaId === '1') {
      return (
        <>
          {lists?.length > 0 &&
            lists?.map((list, index) => (
              <>
                <TableRow key={index}>
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    {list?.post_social_media_image_url &&
                      list?.post_social_media_image_url.includes(
                        "["
                      )
                      ? JSON.parse(
                        (list?.post_social_media_image_url).replaceAll(
                          /\\/g,
                          "\\\\"
                        )
                      )
                        .slice(0, 1)
                        .map((image, index) => (
                            <>
                            image ? (
                              <img
                                src={image}
                                style={{
                                  width: "50px",
                                  borderRadius: "5px",
                                  height: "35px",
                                  overflow: "hidden",
                                  verticalAlign: "middle",
                                }}
                                key={index}
                              />
                              : <img
                                src={NoImage}
                                style={{
                                  width: "50px",
                                  borderRadius: "5px",
                                  height: "35px",
                                  overflow: "hidden",
                                  verticalAlign: "middle",
                                }}
                                key={index}
                              />
                              )
                            </>

                          ))
                          : list?.post_social_media_image_url && 
                        <img
                          src={
                            list?.post_social_media_image_url?.split('|')[0]
                          }
                          style={{
                            width: "50px",
                            borderRadius: "5px",
                            height: "35px",
                            overflow: "hidden",
                            verticalAlign: "middle",
                          }}
                        /> 
                      }
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography style={styleCss.tableheading}>
                      {list?.message}
                    </Typography>
                    <Typography style={styleCss.headingsub}>
                      <Link
                        to={"#"}
                        style={{
                          fontSize: "12px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#1877f2",
                          textDecoration: "none",
                          fontFamily: "Inter",
                        }}
                        onClick={() =>
                          handlePopupOpen(list, "fb")
                        }
                      >
                        {" "}
                        Click to View
                      </Link>
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography style={styleCss.tableheading}>
                      {/* {list?.page_profile_image && (
                                <img
                                  src={list?.page_profile_image}
                                  style={{
                                    width: "14px",
                                    verticalAlign: "middle",
                                    marginRight: "5px",
                                  }}
                                />
                              )} */}
                      {list?.page_name}
                      <img
                        src={Socialicon1}
                        style={{
                          width: "14px",
                          verticalAlign: "middle",
                          marginLeft: "5px",
                        }}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography style={styleCss.tableheading}>
                      {list?.fb_post_type?.toLowerCase()}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#27292f",
                        fontFamily: "Inter",
                      }}
                    >
                      {moment(list?.created_at).format(
                        "DD-MM-YYYY hh:mm:ss A"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ padding: "10px 3px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color:
                          list?.post_status === "Published"
                            ? "green"
                            : "red",
                        fontFamily: "Inter",
                      }}
                    >
                      {list?.post_status}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px", textAlign: "center" }}>
                    <Link to={`/view-post-details/${btoa(list?.fb_post_id)}/${btoa(list?.social_media_id)}/${btoa(pageAccountId)}`} style={{ color: '#27292f' }}><Eyeiconview /> </Link>
                  </TableCell>
                </TableRow>
              </>
            ))}
        </>
      )
    } else if (mediaId === "4") {
      return (
        <>
          {lists?.length > 0 &&
            lists?.map((list, index) => (
              <>
                <TableRow key={index}>
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    {list?.post_social_media_image_url &&
                      list?.post_social_media_image_url.includes(
                        "["
                      )
                      ? JSON.parse(
                        (list?.post_social_media_image_url).replaceAll(
                          /\\/g,
                          "\\\\"
                        )
                      )
                        .slice(0, 1)
                        .map((image, index) => (
                          <>
                            <img
                              src={image}
                              style={{
                                width: "50px",
                                borderRadius: "5px",
                                height: "35px",
                                overflow: "hidden",
                                verticalAlign: "middle",
                              }}
                              key={index}
                            />
                          </>
                        ))
                      : list?.post_social_media_image_url && (
                        <img
                          src={
                            list?.post_social_media_image_url
                          }
                          style={{
                            width: "50px",
                            borderRadius: "5px",
                            height: "35px",
                            overflow: "hidden",
                            verticalAlign: "middle",
                          }}
                        />
                      )}
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography style={styleCss.tableheading}>
                      {list?.message}
                    </Typography>
                    <Typography style={styleCss.headingsub}>
                      <Link
                        to={"#"}
                        style={{
                          fontSize: "12px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#1877f2",
                          textDecoration: "none",
                          fontFamily: "Inter",
                        }}
                        onClick={() =>
                          handlePopupOpen(list, "insta")
                        }
                      >
                        {" "}
                        Click to View
                      </Link>
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography style={styleCss.tableheading}>
                      {list?.screen_name}
                      <img
                        src={Likeimg1}
                        style={{
                          width: "14px",
                          verticalAlign: "middle",
                          marginLeft: "5px",
                        }}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography style={styleCss.tableheading}>
                      {list?.post_type?.toLowerCase()}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#27292f",
                        fontFamily: "Inter",
                      }}
                    >
                      {moment(list?.instagram_created_at).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color:
                          list?.post_status === "Published"
                            ? "green"
                            : "red",
                        fontFamily: "Inter",
                      }}
                    >
                      {list?.post_status}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px", textAlign: "center" }}>
                    <Link to={`/view-post-details/${btoa(list?.instagram_post_id)}/${btoa(list?.social_media_id)}/${btoa(pageAccountId)}`} style={{ color: '#27292f' }}><Eyeiconview /> </Link>
                  </TableCell>
                </TableRow>
              </>
            ))}
        </>
      )
    } else if (mediaId === "3") {
      return (
        <>
          {lists?.length > 0 &&
            lists?.map((list, index) => (
              <>
                <TableRow key={index}>
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    {list?.post_social_media_image_url &&
                      list?.post_social_media_image_url.includes(
                        "["
                      )
                      ? JSON.parse(
                        (list?.post_social_media_image_url).replaceAll(
                          /\\/g,
                          "\\\\"
                        )
                      )
                        .slice(0, 1)
                        .map((image, index) => (
                          <>
                            <img
                              src={image}
                              style={{
                                width: "50px",
                                borderRadius: "5px",
                                height: "35px",
                                overflow: "hidden",
                                verticalAlign: "middle",
                              }}
                              key={index}
                            />
                          </>
                        ))
                      : list?.post_social_media_image_url && (
                        <img
                          src={
                            list?.post_social_media_image_url
                          }
                          style={{
                            width: "50px",
                            borderRadius: "5px",
                            height: "35px",
                            overflow: "hidden",
                            verticalAlign: "middle",
                          }}
                        />
                      )}
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography style={styleCss.tableheading}>
                      {list?.message}
                    </Typography>
                    <Typography style={styleCss.headingsub}>
                      <Link
                        to={"#"}
                        style={{
                          fontSize: "12px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#1877f2",
                          textDecoration: "none",
                          fontFamily: "Inter",
                        }}
                        onClick={() =>
                          handlePopupOpen(list, "linkedin")
                        }
                      >
                        {" "}
                        Click to View
                      </Link>
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography style={styleCss.headingsub}>
                      {list?.page_profile_image && (
                        <img
                          src={list?.page_profile_image}
                          style={{
                            width: "14px",
                            verticalAlign: "middle",
                            marginRight: "5px",
                          }}
                        />
                      )}
                      {list?.page_name}
                      <img
                        src={Likeimg2}
                        style={{
                          width: "14px",
                          verticalAlign: "middle",
                          marginLeft: "5px",
                        }}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography style={styleCss.headingsub}>
                      {list?.post_type?.toLowerCase()}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#27292f",
                        fontFamily: "Inter",
                      }}
                    >
                      {moment(list?.created_at).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color:
                          list?.post_status === "Published"
                            ? "green"
                            : "red",
                        fontFamily: "Inter",
                      }}
                    >
                      {list?.post_status}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px", textAlign: "center" }}>
                  </TableCell>
                </TableRow>
              </>
            ))}
        </>
      )
    }
  }
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={styleCss.tablecell}
                sx={{ padding: "10px 15px !important" }}
              >
                Posts
              </TableCell>
              <TableCell style={styleCss.tablecell}></TableCell>
              <TableCell style={styleCss.tablecell}>Page</TableCell>
              <TableCell style={styleCss.tablecell}>Post Type</TableCell>
              <TableCell style={styleCss.tablecell}>Date</TableCell>
              <TableCell style={styleCss.tablecell}>Status</TableCell>
              <TableCell style={styleCss.tablecell}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData(mediaId, lists)}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
