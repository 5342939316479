import {
  Radio, RadioGroup, Grid, Switch, Card, Tooltip, Box, FormLabel, Typography, TextField, Table, Checkbox, TableBody, Button, TableCell, TableContainer, TableHead, TableRow, Paper, InputLabel, MenuItem, FormControl, FormControlLabel, Select,
} from "@mui/material";

import styleCss from "../Style/style"
import React, { useState, useEffect } from "react";
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import { Formik, Form, Field, useFormik, ErrorMessage } from 'formik';

import * as Yup from 'yup';
import { authpostAxios } from "../Utilities/commonAxios";
import { useAutocomplete } from '@mui/base/useAutocomplete';
import { styled } from '@mui/system';
import Select2 from 'react-select';
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CommentIcon from '@mui/icons-material/Comment';
import ShareIcon from '@mui/icons-material/Share';
import { fileToBase64 } from "../Utilities/fileConversion";
import Swal from 'sweetalert2';
import '../Style/Loader.css'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs'; // Import Dayjs
import 'dayjs/locale/en'; 
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import '../Style/table_style.css';
import FacebookIcon from '@material-ui/icons/Facebook';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';


function PaiseToRupees(paise) {
  return paise / 100;
}

function formatDate(timestamp) {
  const date = new Date(timestamp);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short'
  };
  return date.toLocaleDateString('en-US', options);
}


const Fbadlisting = () => {

  const [value, setValue] = React.useState('');


  

  const [facebookadsList, setfacebookadsList] = useState([]);

  const getfacebookadsListFromAPI = async () => {
    try {
      const response = await authpostAxios({
        url: `${process.env.REACT_APP_BACKENDURL}api/get_fb_ads_campaign`,
        data: {  }
      });
      setfacebookadsList(response.data?.data || []);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };



  let ImagePlaceholder= "";
  let title = "";
  let campaign = "";
  let adSet = "";
  let adCreative = "";
  let status = "";
  const data = [
    {
      id: 1,
      socialMedia: 'Item 1',
      campaign: {
        name: 'Campaign A',
        budget: '$1000', // Example budget amount
        startDate: '2024-07-01', // Example start date
        endDate: '2024-07-31', // Example end date
        adSet: 'Ad Set 1',
        adCreative: 'Creative 1',
        status: 'Active'
      },
      actions: [
        { id: 1, name: 'View' },
        { id: 2, name: 'Edit' }
      ]
    },
    // Add more data objects as needed
  ];


  const handleClick = () => {

    console.log(1);
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to proceed?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, do it!',
      cancelButtonText: 'No, cancel!'
    }).then((result) => {
      if (result.isConfirmed) {
        // Handle the confirmed action here
        Swal.fire(
          'Confirmed!',
          'Your action has been confirmed.',
          'success'
        );
      }
    });
  };


   useEffect(() => {
    console.log(facebookadsList);
    getfacebookadsListFromAPI()
  }, []);
  return (

<Grid container style={styleCss.commonpaddingtop}>
<Grid item xs={6}>
  <h2 style={{ textAlign: 'left' }}>Facebook Ads Campaign</h2>
</Grid>
<Grid item xs={6} style={{ textAlign: 'right' }}>
<Link to="/socialmediaads" style={{ textDecoration: 'none' }}>
  <Button variant="contained" startIcon={<AddIcon />}>
    Add Campaign
  </Button>
</Link>
</Grid>

<Grid item xs={12}>
<div style={{ width: '100%' }}>
<table className="table">
      <thead>
        <tr>
          <th>S.No</th>
          <th>Social Media</th>
          <th>Campaign</th>
          <th>Ad Set</th>
          <th>Ad Creative</th>
          <th>Budget</th>
          <th>Bid Amount</th>
          <th>Start date</th>
          <th>End date</th>
          <th>Action</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
      {facebookadsList.map((ad, index) => (
        <tr>
          <td>{index + 1}</td>
          <td>
          {ad.social_media_id === 1 && (
                <FacebookIcon style={{color:'#1877F2'}} />
              )}
          </td>
          <td>
          <div>
          <div>{ad.fb_campagin_name}</div>
          <div>
            Budget: Rs {PaiseToRupees(ad.ad_budget)}<br />
            Start Date: {formatDate(ad.ad_startdate)}<br />
            End Date: {formatDate(ad.ad_enddate)}
          </div>
        </div>
          </td>
          <td>{ad.adset_name}</td>
          <td>
            {ad.ad_final_name}<br/><br/>
            {ad.preview_sharable_link}
          </td>
          <td>Rs {PaiseToRupees(ad.ad_budget)}</td>
          <td>Rs {PaiseToRupees(ad.ad_bid_amount)}</td> 
          <td>{formatDate(ad.ad_startdate)}</td>
          <td>{formatDate(ad.ad_enddate)}</td>
          <td>
          <Button
          variant="outlined"
          style={{
            color: 'blue', // Set the text color to blue
            borderColor: 'blue', // Set the border color to blue
            fontSize: '10px' // Set the font size to small
          }}
          size="small"
          onClick={handleClick}
        >
          Create Ads Now
        </Button>
          </td>
          <td> 
          {ad?.ad_final_status_text && (
            <Button variant="outlined" color="error" size="small">
              {ad.ad_final_status_text}
            </Button>
          )}
          </td>
        </tr>
        ))}
        {/* Add more rows as needed */}
      </tbody>
    </table>
</div>
      </Grid>
    </Grid>
  )
}

export default Fbadlisting