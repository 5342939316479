import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import styleCss from '../Style/style';
import PropTypes from 'prop-types';
import PinterestIcon from '@mui/icons-material/Pinterest';
import Social1 from '../img/social-icon-1.svg';
import Social2 from '../img/social-icon-2.png';
import Social3 from '../img/social-icon-3.svg';
import Social4 from '../img/social-icon-4.svg';
import Social5 from '../img/social-icon-5.svg';
import Social6 from '../img/social-icon-6.svg';
import FacebookLoginButton from '../Components/FacebookLoginButton';
import LinkedInLoginButton from '../Components/LinkedInLoginButton';
import GoogleLoginButton from '../Components/GoogleLoginButton';
import TwitterLogin from '../Components/TwitterLoginButton';



import { authpostAxios, nonAuthpostAxios } from "../Utilities/commonAxios";
import { toast } from "react-toastify";
import ListImage from '../img/list-img.jpg';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { confirmationAlertwithButtonForUserCreation } from '../Utilities/sweetAlert';



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const Socialbox = () => {

    const navigate = useNavigate();
    const [callbackData, setCallbackData] = useState(null);
    const location = useLocation();
    const [oauthToken, setOauthToken] = useState('');
    const [oauthVerifier, setOauthVerifier] = useState('');
    const [oauthTokenSecret, setOauthTokenSecret] = useState('');
    const [profile, setProfile] = useState(null);
    const [twitter_login_url, settwitter_login_url] = useState(null);


    useEffect(() => {
        // Get the URL search parameters
        const params = new URLSearchParams(window.location.search);

        // Get the value of oauth_token and oauth_verifier
        const oauthToken = params.get('oauth_token');
        const oauthVerifier = params.get('oauth_verifier');
        const tokenSecret = params.get('oauth_token_secret');

        // Send the oauthToken to your Node.js API
        const fetchTwitter = async () => {
            try {
                const response = await authpostAxios({
                    url: `${process.env.REACT_APP_BACKENDURL}api/twitter-login`,
                    data: { 'oauthToken': oauthToken, 'oauthVerifier': oauthVerifier }
                }, dispatch)
                settwitter_login_url(response);
                if (response?.data?.flag === 1) {
                    toast.success(response?.data?.message)
                } else {
                    toast.error(response?.data?.message)
                }
                navigate('/dashboard')
            } catch (error) {
                console.error('Error fetching App ID from API:', error);
            }
        };

        if (oauthToken && oauthVerifier) {
            fetchTwitter();
        }


        // You can now use these values in your React component as needed
    }, []);








    const dispatch = useDispatch()
    const [appId, setAppId] = useState(null);
    const [social_media_app_id, setsocial_media_app_id] = useState(null);

    const [linkappId, setLinkAppId] = useState(null);
    const [lincallbg, setLincallbg] = useState(null);


    const fetchAppId = async () => {
        try {
            const response = await authpostAxios({
                url: `${process.env.REACT_APP_BACKENDURL}api/get-facebook-accessdetails`,
                data: { 'socialMediaId': 1 }
            }, dispatch)
            setAppId(response?.data?.data[0]?.consumer_key);
            setsocial_media_app_id(response?.data?.data[0]?.social_media_app_id);
        } catch (error) {
            console.error('Error fetching App ID from API:', error);
        }
    };

    const fetchlinAppId = async () => {
        try {
            const response = await authpostAxios({
                url: `${process.env.REACT_APP_BACKENDURL}api/get-linedind-accessdetails`,
                data: { 'socialMediaId': 3 }
            }, dispatch)
            setLinkAppId(response?.data?.data[0]?.consumer_key);
            setLincallbg(response?.data?.data[0]?.callback);
        } catch (error) {
            console.error('Error fetching App ID from API:', error);
        }
    };





    const getFacebookPageDetails = async (accessToken, getdata) => {
        try {
            // Get user's pages
            const pagesResponse = await fetch(
                `https://graph.facebook.com/v19.0/me/accounts?fields=cover,emails,picture,id,name,url,username,access_token&access_token=${accessToken}`
            );
            const pagesData = await pagesResponse.json();
            const pagesArray = [];
            pagesData.data.forEach((page) => {
                const pageDetails = {
                    id: page.id,
                    name: page.name,
                    access_token: page.access_token,
                    cover: page.cover,
                    picture: page.picture,
                    facebook_main_data: getdata,
                    // Add other properties as needed
                };
                pagesArray.push(pageDetails);
            });
            const pageapiResponse = await authpostAxios({
                url: `${process.env.REACT_APP_BACKENDURL}api/fb-page-push`,
                data: { facebookPagesData: pagesArray, facebookMainData: getdata }
            }, dispatch)
            if (pageapiResponse?.data?.flag === 1) {
                toast.success(pageapiResponse?.data?.message)
            } else {
                toast.error(pageapiResponse?.data?.message)
            }
        } catch (error) {
            console.error('Error fetching user\'s pages:', error);
        }
    };

    const onFacebookLoginSuccess = async (response) => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/fb-login`,
                data: {
                    facebookResponse: response,
                    socialMediaId: 1,
                    social_media_app_id: 1,
                }
            }, dispatch).then(fbLoginResponse => {
                if (fbLoginResponse.data?.flag === 1) {
                    getFacebookPageDetails(response.accessToken, fbLoginResponse.data.data);
                }
            })
    }

    const onFacebookLoginFailure = (response) => {
        console.error('Social Media Connect Failure:', response);
        toast.success(response)
    };
    // Manage Social Media
    const [fbMediaList, setFbMediaList] = useState([])
    const [instaMediaList, setInstaMediaList] = useState([])
    const [linkedInMediaList, setLinkedInMediaList] = useState([])
    const [youtubeMediaList, setyoutubeMediaList] = useState([])
    const [twitterProfileList, settwitterProfileList] = useState([])
    const [LinkedInAccountMediaList, setLinkedInAccountMediaList] = useState([])
    const manageSocialMedia = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/manage-socialmedia`,
                data: {}
            }, dispatch).then(response => {
                setFbMediaList(response?.data?.data?.[0])
                setInstaMediaList(response?.data?.data?.[2])
                setLinkedInMediaList(response?.data?.data?.[1])
                setyoutubeMediaList(response?.data?.data?.[3])
                settwitterProfileList(response?.data?.data?.[4])
                setLinkedInAccountMediaList(response?.data?.data?.[5])
            })
    }
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        fetchAppId()
        manageSocialMedia()
        fetchlinAppId()
    }, []);


    const handleSocialMediaPageAccountDelete = async (id, socialMediaId, linkedInType) => {
        let url, data;
        console.log("id", id);
        console.log(socialMediaId);
        const confirmationAlertResponse = await confirmationAlertwithButtonForUserCreation("Are you sure you want to delete the page")
        if (confirmationAlertResponse.isConfirmed) {
            if (socialMediaId == 1) {
                url = `${process.env.REACT_APP_BACKENDURL}api/upd-fb-page-inactive`
                data = {
                    socialMediaId: socialMediaId,
                    socialMediaPageId: id,
                }
            } else if (socialMediaId == 4) {
                url = `${process.env.REACT_APP_BACKENDURL}api/upd-insta-account-inactive`
                data = {
                    socialMediaId: socialMediaId,
                    socialAuthUserId: id,
                }
            } else if (socialMediaId == 2) {
                url = `${process.env.REACT_APP_BACKENDURL}api/upd-twitter-account-inactive`
                data = {
                    socialMediaId: socialMediaId,
                    socialAuthUserId: id,
                }
            } else if (socialMediaId == 5) {
                url = `${process.env.REACT_APP_BACKENDURL}api/upd-youtube-account-inactive`
                data = {
                    socialMediaId: socialMediaId,
                    socialAuthUserId: id,
                }
            } else if (socialMediaId == 3) {
                url = `${process.env.REACT_APP_BACKENDURL}api/upd-linkedin-page-account-inactive`
                data = {
                    socialMediaId: socialMediaId,
                    socialAuthUserId: id,
                    accountType: linkedInType
                }
            }

            console.log(url)
            await authpostAxios(
                {
                    'url': url,
                    data: data
                }, dispatch).then(updResponse => {
                    if (updResponse.data?.flag === 1) {
                        toast.success(updResponse.data.message)
                        manageSocialMedia()
                    } else {
                        toast.error(updResponse.data.message)
                    }
                })
        }

    }
    return (
        <>
            <Grid style={styleCss.commonpaddingtop}>
                <Grid container item rowSpacing={2}>
                    <Grid item md={4}></Grid>
                    <Grid item md={4} style={{ justifyContent: 'center', display: 'flex' }}>
                        <Tabs value={value} onChange={handleChange} style={styleCss.tabstyle}
                            sx={{
                                '& .css-heg063-MuiTabs-flexContainer': { justifyContent: 'center' },
                                '& .css-qmhldy-MuiButtonBase-root-MuiTab-root': {
                                    minHeight: '35px',
                                }, '& .css-1g2y1qb-MuiButtonBase-root-MuiTab-root': { minHeight: '40px' },
                                '& .css-1aquho2-MuiTabs-indicator': { backgroundColor: '#27292f' },
                            }}>
                            <Tab label="Connect Social" {...a11yProps(0)} sx={{ padding: '0px 13px', }} />
                            <Tab label="Manage" {...a11yProps(1)} sx={{ padding: '0px 13px', }} />
                        </Tabs>
                    </Grid>
                    <Grid item md={12} sx={{ mt: 2 }}>
                        <CustomTabPanel value={value} index={0}>
                            <Grid container item columnSpacing={3} rowSpacing={3}>
                                <Grid item md={3} style={{ display: 'flex', alignItems: 'stretch', }}>
                                    <Grid style={styleCss.socialmediaboxstyle} sx={{ '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 0 1.25rem rgb(30 34 40 / 22%)', } }}>
                                        <img src={Social1} /> + <img src={Social2} />
                                        <Grid>
                                            <Typography style={styleCss.socialtext}> Facebook</Typography>
                                            <Grid>
                                                <FacebookLoginButton onLoginSuccess={onFacebookLoginSuccess} onLoginFailure={onFacebookLoginFailure} appId={appId} social_media_app_id={social_media_app_id} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={3} style={{ display: 'flex', alignItems: 'stretch', }}>
                                    <Grid style={styleCss.socialmediaboxstyle} sx={{ '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 0 1.25rem rgb(30 34 40 / 22%)', } }}>
                                        <img src={Social3} />
                                        <Grid>
                                            <Typography style={styleCss.socialtext}> Twitter</Typography>
                                        </Grid>
                                        <Grid>
                                            <TwitterLogin />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={3} style={{ display: 'flex', alignItems: 'stretch', }}>
                                    <Grid style={styleCss.socialmediaboxstyle} sx={{ '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 0 1.25rem rgb(30 34 40 / 22%)', } }}>
                                        <img src={Social4} />
                                        <Grid>
                                            <Typography style={styleCss.socialtext}> LinkedIn</Typography>
                                        </Grid>
                                        <Grid>

                                            <LinkedInLoginButton customer_key={linkappId} callback={lincallbg} />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item md={3} style={{ display: 'flex', alignItems: 'stretch', }}>
                                    <Grid style={styleCss.socialmediaboxstyle} sx={{ '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 0 1.25rem rgb(30 34 40 / 22%)', } }}>
                                        <img src={Social5} />
                                        <Grid>
                                            <Typography style={styleCss.socialtext}> Youtube</Typography>
                                        </Grid>
                                        <Grid>
                                            <GoogleLoginButton />

                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={3} style={{ display: 'flex', alignItems: 'stretch', }}>
                                    <Grid style={styleCss.socialmediaboxstyle} sx={{ '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 0 1.25rem rgb(30 34 40 / 22%)', } }}>
                                        <img src={Social6} />
                                        <Grid>
                                            <Typography style={styleCss.socialtext}> Google Buisness</Typography>
                                        </Grid>
                                        <Grid>
                                            <Button variant='outlined' style={styleCss.socialbtn}>Connect</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <Grid container item columnSpacing={3}>
                                <Grid item md={3}>
                                    <Accordion variant='0' sx={{ width: '100%', padding: '0', }}>
                                        <AccordionSummary
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                            sx={{ display: 'flex', alignItems: 'stretch', padding: '0', '& .css-eqpfi5-MuiAccordionSummary-content.Mui-expanded': { margin: '0px', } }}
                                        >
                                            <Grid style={styleCss.socialmediaboxstyle} sx={{ '&:hover': { boxShadow: '0 0 1.25rem rgb(30 34 40 / 22%)', } }}>
                                                <img src={Social1} />
                                                <Grid>
                                                    <Typography style={styleCss.socialtext}>Facebook</Typography>
                                                </Grid>
                                                <Grid>
                                                    <Typography style={styleCss.pagesadded}>{fbMediaList.length} Pages added</Typography>
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{
                                            border: '1px solid rgb(237, 236, 237)', padding: '5px 16px 4px',
                                        }}>
                                            {fbMediaList && fbMediaList.length > 0 && fbMediaList?.map((list, index) => (
                                                <Grid container item columnSpacing={1} sx={{ paddingTop: '10px' }} key={index}>
                                                    <Grid item md={2} sx={{ alignSelf: 'center', }}>
                                                        <img src={((list?.page_profile_image) ? (list?.page_profile_image) : (ListImage))} style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                                    </Grid>
                                                    <Grid item md={8} sx={{ alignSelf: 'center', }}>
                                                        <Typography sx={{ color: '#27292f', fontSize: '13px', fontFamily: 'Inter', fontWeight: '500', fontSize: '13px' }}>{list.page_name}</Typography>
                                                    </Grid>
                                                    <Grid item md={2} sx={{ textAlign: 'end' }}>
                                                        <DeleteOutlineIcon onClick={() => { handleSocialMediaPageAccountDelete(list?.social_media_page_id, list?.social_media_id, '') }} sx={{ color: '#27292f', alignSelf: 'center', }} />
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item md={3}>
                                    <Accordion variant='0' sx={{ width: '100%', padding: '0' }}>
                                        <AccordionSummary
                                            aria-controls="panel2-content"
                                            id="panel2-header"
                                            sx={{ display: 'flex', alignItems: 'stretch', padding: '0', '& .css-eqpfi5-MuiAccordionSummary-content.Mui-expanded': { margin: '0' } }}
                                        >
                                            <Grid style={styleCss.socialmediaboxstyle} sx={{ '&:hover': { boxShadow: '0 0 1.25rem rgb(30 34 40 / 22%)', } }}>
                                                <img src={Social2} />
                                                <Grid>
                                                    <Typography style={styleCss.socialtext}>Instagram</Typography>
                                                </Grid>
                                                <Grid>
                                                    <Typography style={styleCss.pagesadded}>{instaMediaList.length} Profiles added</Typography>
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{
                                            border: '1px solid rgb(237, 236, 237)', padding: '5px 16px 4px',
                                        }}>
                                            {instaMediaList && instaMediaList.length > 0 && instaMediaList?.map((list, index) => (
                                                <Grid container item columnSpacing={1} sx={{ paddingTop: '10px' }} key={index}>
                                                    <Grid item md={2} sx={{ alignSelf: 'center', }}>
                                                        <img src={(list?.profile) ? (list?.profile) : (ListImage)} style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                                    </Grid>
                                                    <Grid item md={8} sx={{ alignSelf: 'center', }}>
                                                        <Typography sx={{ color: '#27292f', fontSize: '13px', fontFamily: 'Inter', fontWeight: '500', fontSize: '13px' }}>{list.screen_name}</Typography>
                                                    </Grid>
                                                    <Grid item md={2} sx={{ textAlign: 'end' }}>
                                                        <DeleteOutlineIcon onClick={() => { handleSocialMediaPageAccountDelete(list?.social_auth_user_id, list?.social_media_id, '') }} sx={{ color: '#27292f', alignSelf: 'center', }} />
                                                    </Grid>
                                                </Grid>
                                            ))}

                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item md={3}>
                                    <Accordion variant='0' sx={{ width: '100%', padding: '0' }}>
                                        <AccordionSummary
                                            aria-controls="panel3-content"
                                            id="panel3-header"
                                            sx={{ display: 'flex', alignItems: 'stretch', padding: '0', '& .css-eqpfi5-MuiAccordionSummary-content.Mui-expanded': { margin: '0' } }}
                                        >
                                            <Grid style={styleCss.socialmediaboxstyle} sx={{ '&:hover': { boxShadow: '0 0 1.25rem rgb(30 34 40 / 22%)', } }}>
                                                <img src={Social3} />
                                                <Grid>
                                                    <Typography style={styleCss.socialtext}>Twitter</Typography>
                                                </Grid>
                                                <Grid>
                                                    <Typography style={styleCss.pagesadded}>{twitterProfileList.length} Profiles added</Typography>
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{
                                            border: '1px solid rgb(237, 236, 237)', padding: '5px 16px 4px',
                                        }}>
                                            {twitterProfileList && twitterProfileList.length > 0 && twitterProfileList?.map((list, index) => (
                                                <Grid container item columnSpacing={1} sx={{ paddingTop: '10px' }} key={index}>
                                                    <Grid item md={2} sx={{ alignSelf: 'center', }}>
                                                        <img src={(list?.profile) ? (list?.profile) : (ListImage)} style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                                    </Grid>
                                                    <Grid item md={8} sx={{ alignSelf: 'center', }}>
                                                        <Typography sx={{ color: '#27292f', fontSize: '13px', fontFamily: 'Inter', fontWeight: '500', fontSize: '13px' }}>{list.screen_name}</Typography>
                                                    </Grid>
                                                    <Grid item md={2} sx={{ textAlign: 'end' }}>

                                                        <DeleteOutlineIcon onClick={() => { handleSocialMediaPageAccountDelete(list?.social_auth_user_id, list?.social_media_id, '') }} sx={{ color: '#27292f', alignSelf: 'center', }} />

                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item md={3}>
                                    <Accordion variant='0' sx={{ width: '100%', padding: '0' }}>
                                        <AccordionSummary
                                            aria-controls="panel4-content"
                                            id="panel4-header"
                                            sx={{ display: 'flex', alignItems: 'stretch', padding: '0', '& .css-eqpfi5-MuiAccordionSummary-content.Mui-expanded': { margin: '0' } }}
                                        >
                                            <Grid style={styleCss.socialmediaboxstyle} sx={{ '&:hover': { boxShadow: '0 0 1.25rem rgb(30 34 40 / 22%)', } }}>
                                                <img src={Social4} />
                                                <Grid>
                                                    <Typography style={styleCss.socialtext}>LinkedIn</Typography>
                                                </Grid>
                                                <Grid>
                                                    <Typography style={styleCss.pagesadded}>{linkedInMediaList.length} Pages added</Typography>
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{
                                            border: '1px solid rgb(237, 236, 237)', padding: '5px 16px 4px',
                                        }}>
                                            {linkedInMediaList && linkedInMediaList.length > 0 && linkedInMediaList?.map((list, index) => (
                                                <Grid container item columnSpacing={1} sx={{ paddingTop: '10px' }} key={index}>
                                                    <Grid item md={2} sx={{ alignSelf: 'center', }}>
                                                        <img src={(list?.page_profile_image) ? (list?.page_profile_image) : (ListImage)} style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                                    </Grid>
                                                    <Grid item md={8} sx={{ alignSelf: 'center', }}>
                                                        <Typography sx={{ color: '#27292f', fontSize: '13px', fontFamily: 'Inter', fontWeight: '500', fontSize: '13px' }}>{list.page_name}</Typography>
                                                    </Grid>
                                                    <Grid item md={2} sx={{ textAlign: 'end' }}>

                                                        <DeleteOutlineIcon onClick={() => { handleSocialMediaPageAccountDelete(list?.social_media_page_id, list?.social_media_id, 'pages') }} sx={{ color: '#27292f', alignSelf: 'center', }} />

                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item md={3}>
                                    <Accordion variant='0' sx={{ width: '100%', padding: '0' }}>
                                        <AccordionSummary
                                            aria-controls="panel4-content"
                                            id="panel4-header"
                                            sx={{ display: 'flex', alignItems: 'stretch', padding: '0', '& .css-eqpfi5-MuiAccordionSummary-content.Mui-expanded': { margin: '0' } }}
                                        >
                                            <Grid style={styleCss.socialmediaboxstyle} sx={{ '&:hover': { boxShadow: '0 0 1.25rem rgb(30 34 40 / 22%)', } }}>
                                                <img src={Social4} />
                                                <Grid>
                                                    <Typography style={styleCss.socialtext}>LinkedIn</Typography>
                                                </Grid>
                                                <Grid>
                                                    <Typography style={styleCss.pagesadded}>{LinkedInAccountMediaList.length} Profiles added</Typography>
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{
                                            border: '1px solid rgb(237, 236, 237)', padding: '5px 16px 4px',
                                        }}>
                                            {LinkedInAccountMediaList && LinkedInAccountMediaList.length > 0 && LinkedInAccountMediaList?.map((list, index) => (
                                                <Grid container item columnSpacing={1} sx={{ paddingTop: '10px' }} key={index}>
                                                    <Grid item md={2} sx={{ alignSelf: 'center', }}>
                                                        <img src={(list?.profile) ? (list?.profile) : (ListImage)} style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                                    </Grid>
                                                    <Grid item md={8} sx={{ alignSelf: 'center', }}>
                                                        <Typography sx={{ color: '#27292f', fontSize: '13px', fontFamily: 'Inter', fontWeight: '500', fontSize: '13px' }}>{list.screen_name}</Typography>
                                                    </Grid>
                                                    <Grid item md={2} sx={{ textAlign: 'end' }}>
                                                        <DeleteOutlineIcon onClick={() => { handleSocialMediaPageAccountDelete(list?.social_auth_user_id, list?.social_media_id, 'profile') }} sx={{ color: '#27292f', alignSelf: 'center', }} />
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item md={3}>

                                    <Accordion variant='0' sx={{ width: '100%', padding: '0' }}>
                                        <AccordionSummary
                                            aria-controls="panel5-content"
                                            id="panel5-header"
                                            sx={{ display: 'flex', alignItems: 'stretch', padding: '0', '& .css-eqpfi5-MuiAccordionSummary-content.Mui-expanded': { margin: '0' } }}
                                        >
                                            <Grid style={styleCss.socialmediaboxstyle} sx={{ '&:hover': { boxShadow: '0 0 1.25rem rgb(30 34 40 / 22%)', } }}>
                                                <img src={Social5} />
                                                <Grid>
                                                    <Typography style={styleCss.socialtext}>Youtube</Typography>
                                                </Grid>
                                                <Grid>
                                                    <Typography style={styleCss.pagesadded}>{youtubeMediaList.length} Channel added</Typography>
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{
                                            border: '1px solid rgb(237, 236, 237)', padding: '5px 16px 4px',
                                        }}>
                                            {youtubeMediaList && youtubeMediaList.length > 0 && youtubeMediaList?.map((list, index) => (
                                                <Grid container item columnSpacing={1} sx={{ paddingTop: '10px' }} key={index}>
                                                    <Grid item md={2} sx={{ alignSelf: 'center', }}>
                                                        <img src={(list?.profile) ? (list?.profile) : (ListImage)} style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                                    </Grid>
                                                    <Grid item md={8} sx={{ alignSelf: 'center', }}>
                                                        <Typography sx={{ color: '#27292f', fontSize: '13px', fontFamily: 'Inter', fontWeight: '500', fontSize: '13px' }}>{list.screen_name}</Typography>
                                                    </Grid>
                                                    <Grid item md={2} sx={{ textAlign: 'end' }}>
                                                        <DeleteOutlineIcon onClick={() => { handleSocialMediaPageAccountDelete(list?.social_auth_user_id, list?.social_media_id, '') }} sx={{ color: '#27292f', alignSelf: 'center', }} />
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                {/* <Grid item md={3}>
                                    <Accordion variant='0' sx={{ width: '100%', padding: '0' }}>
                                        <AccordionSummary
                                            aria-controls="panel6-content"
                                            id="panel6-header"
                                            sx={{ display: 'flex', alignItems: 'stretch', padding: '0', '& .css-eqpfi5-MuiAccordionSummary-content.Mui-expanded': { margin: '0' } }}
                                        >
                                            <Grid style={styleCss.socialmediaboxstyle} sx={{ '&:hover': { boxShadow: '0 0 1.25rem rgb(30 34 40 / 22%)', } }}>
                                                <img src={Social6} />
                                                <Grid>
                                                    <Typography style={styleCss.socialtext}>Google Buisness</Typography>
                                                </Grid>
                                                <Grid>
                                                    <Typography style={styleCss.pagesadded}>Profile</Typography>
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{
                                            border: '1px solid rgb(237, 236, 237)', padding: '5px 16px 4px',
                                        }}>
                                            <Grid container item columnSpacing={1} sx={{ paddingTop: '10px' }}>
                                                <Grid item md={2} sx={{ alignSelf: 'center', }}>
                                                    <img src={ListImage} style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                                </Grid>
                                                <Grid item md={8} sx={{ alignSelf: 'center', }}>
                                                    <Typography sx={{ color: '#27292f', fontSize: '13px', fontFamily: 'Inter', fontWeight: '500', fontSize: '13px' }}>Pages added</Typography>
                                                </Grid>
                                                <Grid item md={2} sx={{ textAlign: 'end' }}>
                                                    <Link to={"#"}>
                                                        <DeleteOutlineIcon sx={{ color: '#27292f', alignSelf: 'center', }} />
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                            <Grid container item columnSpacing={1} sx={{ paddingTop: '10px' }}>
                                                <Grid item md={2} sx={{ alignSelf: 'center', }}>
                                                    <img src={ListImage} style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                                </Grid>
                                                <Grid item md={8} sx={{ alignSelf: 'center', }}>
                                                    <Typography sx={{ color: '#27292f', fontSize: '13px', fontFamily: 'Inter', fontWeight: '500', fontSize: '13px' }}>Pages added</Typography>
                                                </Grid>
                                                <Grid item md={2} sx={{ textAlign: 'end' }}>
                                                    <Link to={"#"}>
                                                        <DeleteOutlineIcon sx={{ color: '#27292f', alignSelf: 'center', }} />
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid> */}
                            </Grid>
                        </CustomTabPanel>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Socialbox
