import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";
import styleCss from "../Style/style";
import moment from "moment";
import { Link } from "react-router-dom";
import Socialicon1 from "../img/sub-icon-1.svg";
import Likeimg1 from "../img/social-icon-2.png";
import Likeimg2 from "../img/sub-icon-4.png";
import Likeimg3 from "../img/sub-icon-3.png";
import youtube from '../img/sub-icon-5.png';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';


export const PostListTable = ({ mediaType, lists, handlePopupOpen }) => {
  const tableData = (mediaType, lists) => {
    if (mediaType === "fb") {
      return (
        <>
          {lists?.length > 0 &&
            lists?.map((list, index) => (
              <>
                <TableRow key={index}>
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    {list?.post_social_media_image_url &&
                      list?.post_social_media_image_url.includes(
                        "["
                      )
                      ? JSON.parse(
                        (list?.post_social_media_image_url).replaceAll(
                          /\\/g,
                          "\\\\"
                        )
                      )
                        .slice(0, 1)
                        .map((image, index) => (
                          <>
                            <img
                              src={image}
                              style={{
                                width: "50px",
                                borderRadius: "5px",
                                height: "35px",
                                overflow: "hidden",
                                verticalAlign: "middle",
                              }}
                              key={index}
                            />
                          </>
                        ))
                      : list?.post_social_media_image_url && (
                        <img
                          src={
                            list?.post_social_media_image_url
                          }
                          style={{
                            width: "50px",
                            borderRadius: "5px",
                            height: "35px",
                            overflow: "hidden",
                            verticalAlign: "middle",
                          }}
                        />
                      )}
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography style={styleCss.tableheading}>
                      {list?.message}
                    </Typography>
                    <Typography style={styleCss.headingsub}>
                      <Link
                        to={"#"}
                        style={{
                          fontSize: "12px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#1877f2",
                          textDecoration: "none",
                          fontFamily: "Inter",
                        }}
                        onClick={() =>
                          handlePopupOpen(list, "fb")
                        }
                      >
                        {" "}
                        Click to View
                      </Link>
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography style={styleCss.tableheading}>
                      {list?.page_profile_image && (
                        <img
                          src={list?.page_profile_image}
                          style={{
                            width: "14px",
                            verticalAlign: "middle",
                            marginRight: "5px",
                          }}
                        />
                      )}
                      {list?.page_name}
                      <img
                        src={Socialicon1}
                        style={{
                          width: "14px",
                          verticalAlign: "middle",
                          marginLeft: "5px",
                        }}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px", textAlign: "center" }}>
                    <Typography style={styleCss.tableheading1}>
                      {list?.fb_post_type?.toLowerCase()}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px", textAlign: "center" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#27292f",
                        fontFamily: "Inter",
                      }}
                    >
                      {moment(list?.created_at).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color:
                          list?.post_status === "Published"
                            ? "green"
                            : "red",
                        fontFamily: "Inter",
                      }}
                    >
                      {list?.post_status}

                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "center",
                        color: '#27292f'
                        ,
                      }}
                    >
                      {list?.post_status === "Published" ? list?.reactions : ''}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "center",
                        color: '#27292f'
                        ,
                      }}
                    >
                      {list?.post_status === "Published" ? list?.parent_comments_counts : ''}

                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: '#27292f'
                        ,
                      }}
                    >
                      {list?.name}
                      <img
                        src={list?.user_profie}
                        style={{
                          width: "14px",
                          verticalAlign: "middle",
                          marginLeft: "5px",
                        }} />
                    </Typography>
                  </TableCell>
                </TableRow >
              </>
            ))
          }
        </>
      )
    } else if (mediaType === "insta") {
      return (
        <>
          {lists?.length > 0 &&
            lists?.map((list, index) => (
              <>
                <TableRow key={index}>
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    {list?.post_social_media_image_url &&
                      list?.post_social_media_image_url.includes(
                        "["
                      )
                      ? JSON.parse(
                        (list?.post_social_media_image_url).replaceAll(
                          /\\/g,
                          "\\\\"
                        )
                      )
                        .slice(0, 1)
                        .map((image, index) => (
                          <>
                            <img
                              src={image}
                              style={{
                                width: "50px",
                                borderRadius: "5px",
                                height: "35px",
                                overflow: "hidden",
                                verticalAlign: "middle",
                              }}
                              key={index}
                            />
                          </>
                        ))
                      : list?.post_social_media_image_url && (
                        <img
                          src={
                            list?.post_social_media_image_url
                          }
                          style={{
                            width: "50px",
                            borderRadius: "5px",
                            height: "35px",
                            overflow: "hidden",
                            verticalAlign: "middle",
                          }}
                        />
                      )}
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography style={styleCss.tableheading}>
                      {list?.message}
                    </Typography>
                    <Typography style={styleCss.headingsub}>
                      <Link
                        to={"#"}
                        style={{
                          fontSize: "12px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#1877f2",
                          textDecoration: "none",
                          fontFamily: "Inter",
                        }}
                        onClick={() =>
                          handlePopupOpen(list, "insta")
                        }
                      >
                        {" "}
                        Click to View
                      </Link>
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography style={styleCss.tableheading}>
                      {list?.profile && (
                        <img
                          src={list?.profile}
                          style={{
                            width: "14px",
                            verticalAlign: "middle",
                            marginRight: "5px",
                          }}
                        />
                      )}
                      {list?.account_name}
                      <img
                        src={Likeimg1}
                        style={{
                          width: "14px",
                          verticalAlign: "middle",
                          marginLeft: "5px",
                        }}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography style={styleCss.tableheading1}>
                      {list?.post_type?.toLowerCase()}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#27292f",
                        fontFamily: "Inter",

                      }}
                    >
                      {moment(list?.created_at).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color:
                          list?.post_status === "Published"
                            ? "green"
                            : "red",
                        fontFamily: "Inter",
                      }}
                    >
                      {list?.post_status}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "center",
                        color: '#27292f'
                        ,
                      }}
                    >
                      {list?.post_status === "Published" ? list?.parent_comments_count : ''}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: '#27292f'
                        ,
                      }}
                    >
                      {list?.name}
                      <img
                        src={list?.user_profile}
                        style={{
                          width: "14px",
                          verticalAlign: "middle",
                          marginLeft: "5px",
                        }} />
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            ))}
        </>
      )
    } else if (mediaType === "linkedin") {
      return (
        <>
          {lists?.length > 0 &&
            lists?.map((list, index) => (
              <>
                <TableRow key={index}>
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    {list?.post_social_media_image_url &&
                      list?.post_social_media_image_url.includes(
                        "["
                      )
                      ? JSON.parse(
                        (list?.post_social_media_image_url).replaceAll(
                          /\\/g,
                          "\\\\"
                        )
                      )
                        .slice(0, 1)
                        .map((image, index) => (
                          <>
                            <img
                              src={image}
                              style={{
                                width: "50px",
                                borderRadius: "5px",
                                height: "35px",
                                overflow: "hidden",
                                verticalAlign: "middle",
                              }}
                              key={index}
                            />
                          </>
                        ))
                      : list?.post_social_media_image_url && (
                        <img
                          src={
                            list?.post_social_media_image_url
                          }
                          style={{
                            width: "50px",
                            borderRadius: "5px",
                            height: "35px",
                            overflow: "hidden",
                            verticalAlign: "middle",
                          }}
                        />
                      )}
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography style={styleCss.tableheading}>
                      {list?.message}
                    </Typography>
                    <Typography style={styleCss.headingsub}>
                      <Link
                        to={"#"}
                        style={{
                          fontSize: "12px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#1877f2",
                          textDecoration: "none",
                          fontFamily: "Inter",
                        }}
                        onClick={() =>
                          handlePopupOpen(list, "linkedin")
                        }
                      >
                        {" "}
                        Click to View
                      </Link>
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography style={styleCss.headingsub}>
                      {list?.linkedin_account_type == 'pages' ? (
                        <img
                          src={list?.page_profile_image}
                          style={{
                            width: "14px",
                            verticalAlign: "middle",
                            marginRight: "5px",
                          }}
                        />
                      ) : (<img
                        src={list?.profile}
                        style={{
                          width: "14px",
                          verticalAlign: "middle",
                          marginRight: "5px",
                        }}
                      />)}
                      {list?.linkedin_account_type == 'pages' ? list?.page_name : list?.name}

                      {/* {{list?.linkedin_account_type} ? {list?.page_name} : {list?.name}} */}
                      <img
                        src={Likeimg2}
                        style={{
                          width: "14px",
                          verticalAlign: "middle",
                          marginLeft: "5px",
                        }}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px", textAlign: "center" }}>
                    <Typography style={styleCss.headingsub}>
                      {list?.linkedin_account_type == 'pages' ? 'Pages' : 'Account'}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px", textAlign: "center" }}>
                    <Typography style={styleCss.headingsub}>
                      {list?.post_type?.toLowerCase()}
                    </Typography>
                  </TableCell>

                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#27292f",
                        fontFamily: "Inter",
                      }}
                    >
                      {moment(list?.created_at).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color:
                          list?.post_status === "Published"
                            ? "green"
                            : "red",
                        fontFamily: "Inter",
                      }}
                    >
                      {list?.post_status}
                    </Typography>
                  </TableCell>
                  {/* <TableCell
                      sx={{ padding: "10px 3px", textAlign: "center" }}
                    >
                      <Link to={"/"}>
                        <DeleteOutlineOutlined
                          sx={{
                            color: "#27292f",
                            fontSize: "18px",
                            background: "#f3f3f3",
                            padding: "10px",
                            borderRadius: "5px",
                            padding: "8px",
                          }}
                        />
                      </Link>
                    </TableCell> */}
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "center",
                        color: '#27292f'
                        ,
                      }}
                    >
                      {list?.linkedin_account_type == "pages" ? list?.post_status === "Published" ? list?.parent_comments_count : '' : 'No Comment for Account'}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: '#27292f'
                        ,
                      }}
                    >
                      {list?.user_name}
                      <img
                        src={list?.user_profile}
                        style={{
                          width: "14px",
                          verticalAlign: "middle",
                          marginLeft: "5px",
                        }} />
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            ))}
        </>
      )
    } else if (mediaType === "youtube") {
      return (
        <>
          {lists?.length > 0 &&
            lists?.map((list, index) => (
              <>
                <TableRow key={index}>
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    {list?.youtube_video_url && (
                      <video
                        src={list?.youtube_video_url}
                        controls
                        style={{
                          width: "50px",
                          height: "35px",
                          padding: "5px",
                          cursor: "pointer",
                          position: "relative",
                          overflow: "hidden",
                        }}

                      />
                    )}
                    {/* {list?.post_social_media_image_url &&
                      list?.post_social_media_image_url.includes(
                        "["
                      )
                      ? JSON.parse(
                        (list?.youtube_video_url).replaceAll(
                          /\\/g,
                          "\\\\"
                        )
                      )
                        .slice(0, 1)
                        .map((video, index) => (
                          <>
                            <img
                              src={video}
                              style={{
                                width: "50px",
                                borderRadius: "5px",
                                height: "35px",
                                overflow: "hidden",
                                verticalAlign: "middle",
                              }}
                              key={index}
                            />
                          </>
                        ))
                      : list?.post_social_media_image_url && (
                        <img
                          src={
                            list?.post_social_media_image_url
                          }
                          style={{
                            width: "50px",
                            borderRadius: "5px",
                            height: "35px",
                            overflow: "hidden",
                            verticalAlign: "middle",
                          }}
                        />
                      )} */}
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography style={styleCss.tableheading}>
                      {list?.youtube_description}
                    </Typography>
                    <Typography style={styleCss.headingsub}>
                      <Link
                        to={"#"}
                        style={{
                          fontSize: "12px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#1877f2",
                          textDecoration: "none",
                          fontFamily: "Inter",
                        }}
                        onClick={() =>
                          handlePopupOpen(list, "youtube")
                        }
                      >
                        {" "}
                        Click to View
                      </Link>
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography style={styleCss.tableheading}>
                      {list?.profile && (
                        <img
                          src={list?.profile}
                          style={{
                            width: "14px",
                            verticalAlign: "middle",
                            marginRight: "5px",
                          }}
                        />
                      )}
                      {list?.screen_name}
                      <img
                        src={youtube}
                        style={{
                          width: "14px",
                          verticalAlign: "middle",
                          marginLeft: "5px",
                        }}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography style={styleCss.tableheading}>
                      Video
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#27292f",
                        fontFamily: "Inter",
                      }}
                    >
                      {moment(list?.created_at).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color:
                          list?.post_status === "Published"
                            ? "green"
                            : "red",
                        fontFamily: "Inter",
                      }}
                    >
                      {list?.post_status}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "center",
                        color: '#27292f'
                        ,
                      }}
                    >
                      {list?.post_status === "Published" ? list?.parent_comments_count : ''}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: '#27292f'
                        ,
                      }}
                    >
                      {list?.name}
                      <img
                        src={list?.user_profile}
                        style={{
                          width: "14px",
                          verticalAlign: "middle",
                          marginLeft: "5px",
                        }} />
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            ))}
        </>
      )
    } else if (mediaType === "twitter") {
      return (
        <>
          {lists?.length > 0 &&
            lists?.map((list, index) => (
              <>
                <TableRow key={index}>
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    {list?.youtube_video_url && (
                      <img
                        src={list?.youtube_video_url}
                        controls
                        style={{
                          width: "50px",
                          height: "35px",
                          padding: "5px",
                          cursor: "pointer",
                          position: "relative",
                          overflow: "hidden",
                        }}

                      />
                    )}
                    {/* {list?.post_social_media_image_url &&
                      list?.post_social_media_image_url.includes(
                        "["
                      )
                      ? JSON.parse(
                        (list?.youtube_video_url).replaceAll(
                          /\\/g,
                          "\\\\"
                        )
                      )
                        .slice(0, 1)
                        .map((video, index) => (
                          <>
                            <img
                              src={video}
                              style={{
                                width: "50px",
                                borderRadius: "5px",
                                height: "35px",
                                overflow: "hidden",
                                verticalAlign: "middle",
                              }}
                              key={index}
                            />
                          </>
                        ))
                      : list?.post_social_media_image_url && (
                        <img
                          src={
                            list?.post_social_media_image_url
                          }
                          style={{
                            width: "50px",
                            borderRadius: "5px",
                            height: "35px",
                            overflow: "hidden",
                            verticalAlign: "middle",
                          }}
                        />
                      )} */}
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography style={styleCss.tableheading}>
                      {list?.message}
                    </Typography>
                    <Typography style={styleCss.headingsub}>
                      <Link
                        to={"#"}
                        style={{
                          fontSize: "12px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#1877f2",
                          textDecoration: "none",
                          fontFamily: "Inter",
                        }}
                        onClick={() =>
                          handlePopupOpen(list, "twitter")
                        }
                      >
                        {" "}
                        Click to View
                      </Link>
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography style={styleCss.tableheading}>
                      {list?.profile && (
                        <img
                          src={list?.profile}
                          style={{
                            width: "14px",
                            verticalAlign: "middle",
                            marginRight: "5px",
                          }}
                        />
                      )}
                      {list?.account_name}
                      <img
                        src={Likeimg3}
                        style={{
                          width: "14px",
                          verticalAlign: "middle",
                          marginLeft: "5px",
                        }}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography style={styleCss.tableheading}>
                      {list?.post_type}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "10px 3px" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#27292f",
                        fontFamily: "Inter",
                      }}
                    >
                      {moment(list?.created_at).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color:
                          list?.post_status === "Published"
                            ? "green"
                            : "red",
                        fontFamily: "Inter",
                      }}
                    >
                      {list?.post_status}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ padding: "10px 3px", textAlign: "center" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: '#27292f'
                        ,
                      }}
                    >
                      {list?.name}
                      <img
                        src={list?.user_profile}
                        style={{
                          width: "14px",
                          verticalAlign: "middle",
                          marginLeft: "5px",
                        }} />
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            ))}
        </>
      )
    }
  }
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={styleCss.tablecell}
                sx={{ padding: "10px 15px !important" }}
              >
                Posts
              </TableCell>
              <TableCell style={styleCss.tablecell}></TableCell>
              {mediaType === "insta" || mediaType === "youtube" ? (
                <TableCell style={styleCss.tablecell}>Account</TableCell>
              ) : mediaType === "linkedin" ? <TableCell style={styleCss.tablecell}>Pages/Account</TableCell> : <TableCell style={styleCss.tablecell}>Pages</TableCell>}
              {mediaType === "linkedin" && (
                <TableCell style={styleCss.tablecell}>Type</TableCell>
              )}
              <TableCell style={styleCss.tablecell}>Post Type</TableCell>
              <TableCell style={styleCss.tablecell}>Date</TableCell>
              <TableCell style={styleCss.tablecell}>Status</TableCell>
              {mediaType === "fb" && (
                <TableCell style={styleCss.tablecell}>Reaction Count</TableCell>
              )}
              {mediaType === "fb" || mediaType === "insta" || mediaType === "linkedin" || mediaType === "youtube" ? (
                <TableCell style={styleCss.tablecell}>Comment Count</TableCell>
              ) : null}
              <TableCell style={styleCss.tablecell}>Posted By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData(mediaType, lists)}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
