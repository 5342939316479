import { Grid } from "@mui/material"
import styleCss from "../Style/style"
import Socialselect from "../SocialPost/socialSelect";
import SocialInbox from "./socialinbox";
import CommentsInbox from "./commentsinbox";
import ThreadsInbox from "./threads";
import { useFormik } from "formik";
import * as Yup from 'yup';
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { authpostAxios } from "../Utilities/commonAxios";
import { useDispatch } from "react-redux";
import { socialMediaLists } from "../Utilities/socialMediaLists";
import { useLocation } from 'react-router-dom';

const Index = () => {
    const location = useLocation();
    const { primaryKeyId,socialMediaId,mediaId } = location.state || {};
    // console.log("primaryKeyId", primaryKeyId);
    // console.log("socialMediaId",socialMediaId);
    // console.log("mediaId",mediaId);
    const dispatch = useDispatch()
    const [commentList, setCommentList] = useState([])
    const submitSocialForm = useFormik({
        enableReinitialize: true,
        validateOnChange: false,
        initialValues: {
            facebookSelectAll: false,
            facebookSelectedPages: [],
            instaSelectAll: false,
            instaSelectedAccounts: [],
            linkedInPageSelectAll: false,
            linkedInSelectedPages: [],
            linkedInAccountSelectAll: false,
            linkedInSelectedAccounts: [],
            youtubeAccountSelectAll: false,
            youtubeSelectedAccounts: [],
            twitterAccountSelectAll: false,
            twitterSelectedAccounts: []
        },
        validationSchema: Yup.object({
        }),
        onSubmit: async (values) => {
            console.log("values", values);
            // get fb comments
            // console.log(values);
            let fbComments = [];
            if (values.facebookSelectedPages.length > 0) {
                let fbCommentsResponse = await authpostAxios({
                    'url': `${process.env.REACT_APP_BACKENDURL}api/get-fb-comments`,
                    'data': {
                        'socialMediaId': 1,
                        'socialMediaPageId': ((values.facebookSelectedPages.length > 0) ? (_.join((_.map(values.facebookSelectedPages, data => data.social_media_page_id)), ',')) : (''))
                    }
                }, dispatch)
                if (fbCommentsResponse?.data?.data !== null) fbComments = fbCommentsResponse?.data?.data
            } else {
                fbComments = [];
            }
            // get insta comments
            let instaComments = [];
            if (values.instaSelectedAccounts.length > 0) {
                let instaCommentsResponse = await authpostAxios({
                    'url': `${process.env.REACT_APP_BACKENDURL}api/get-insta-comments`,
                    'data': {
                        'socialMediaId': 4,
                        'socialMediaAccountId': ((values.instaSelectedAccounts.length > 0) ? (_.join((_.map(values.instaSelectedAccounts, data => data.social_media_account_id)), ',')) : (''))
                    }
                }, dispatch)
                if (instaCommentsResponse?.data?.data !== null) instaComments = instaCommentsResponse?.data?.data
            } else {
                instaComments = []
            }
            // get linkedin comments
            let linkedinComments = [];
            if (values.linkedInSelectedPages.length > 0) {
                let linkedinCommentsResponse = await authpostAxios({
                    'url': `${process.env.REACT_APP_BACKENDURL}api/get-linkedin-comments`,
                    'data': {
                        'socialMediaId': 3,
                        'socialMediaPageId': ((values.linkedInSelectedPages.length > 0) ? (_.join((_.map(values.linkedInSelectedPages, data => data.social_media_page_id)), ',')) : (''))
                    }
                }, dispatch)
                if (linkedinCommentsResponse?.data?.data !== null) linkedinComments = linkedinCommentsResponse?.data?.data
            } else {
                linkedinComments = []
            }
            // get youtube comments
            let youtubeComments = [];
            if (values.youtubeSelectedAccounts.length > 0) {
                let youtubeCommentsResponse = await authpostAxios({
                    'url': `${process.env.REACT_APP_BACKENDURL}api/get-youtube-comment`,
                    'data': {
                        'socialMediaId': 5,
                        'socialMediaAccountId': ((values.youtubeSelectedAccounts.length > 0) ? (_.join((_.map(values.youtubeSelectedAccounts, data => data.social_media_account_id)), ',')) : (''))
                    }
                }, dispatch)
                if (youtubeCommentsResponse?.data?.data !== null) youtubeComments = youtubeCommentsResponse?.data?.data
            } else {
                youtubeComments = []
            }
            setCommentList([...fbComments, ...instaComments, ...linkedinComments, ...youtubeComments])
        }
    })
      useEffect(() => {
        if (primaryKeyId && socialMediaId) {
          let updatedValues = { ...submitSocialForm.values };
          if (socialMediaId === 1) {
            updatedValues.facebookSelectedPages = [{ social_media_id: socialMediaId, social_media_page_id: primaryKeyId }];
          } else {
            updatedValues.facebookSelectedPages = [];
          }
      
          if (socialMediaId === 4) {
            updatedValues.instaSelectedAccounts = [{ social_media_id: socialMediaId, social_media_account_id: mediaId }];
          } else {
            updatedValues.instaSelectedAccounts = [];
          }
      
          submitSocialForm.setValues(updatedValues);
          submitSocialForm.handleSubmit();
        }
      }, [primaryKeyId, socialMediaId, mediaId]);
      
    // get parent comment
    useEffect(() => {
        submitSocialForm.submitForm()
    }, [submitSocialForm.values.facebookSelectedPages, submitSocialForm.values.instaSelectedAccounts, submitSocialForm.values.linkedInSelectedPages, submitSocialForm.values.youtubeSelectedAccounts])
    const handleSubmitSelectedPageForm = useCallback(() => {
        submitSocialForm.submitForm()
    }, [])
    // get single comment & details
    const [singleCommentInputDetails, setSingleCommentInputDetails] = useState([])
    const [singleCommentDetails, setSingleCommentDetails] = useState([])
    const [commentType, setCommentType] = useState('')
    useEffect(() => {
        if (commentList.length === 0) {
            setSingleCommentDetails([])
            setCommentType('')
        }
    }, [commentList])
    const handleSingleComment = useCallback((commandDetails) => {
        setSingleCommentInputDetails(commandDetails)
        setCommentType(commandDetails.commandtitle)
    }, [singleCommentInputDetails])
    useEffect(() => {
        if (commentType !== "") {
            const getSingleCommentInputDetails = async () => {
                await authpostAxios({
                    'url': `${process.env.REACT_APP_BACKENDURL}api/get-${socialMediaLists[singleCommentInputDetails.socialMediaId]['url']}-single-comment`,
                    'data': singleCommentInputDetails
                }, dispatch).then(response => setSingleCommentDetails(response?.data?.data))
            }
            getSingleCommentInputDetails()
        } else {
            setSingleCommentDetails([])
        }
    }, [singleCommentInputDetails, commentType])
    // read post status
    const handleReadPost = async (postData) => {
        await authpostAxios({
            'url': `${process.env.REACT_APP_BACKENDURL}api/update-post-read-status`,
            'data': postData
        }, dispatch).then(response => {
            if (response?.data?.flag === 1) {
                submitSocialForm.submitForm()
                setSingleCommentDetails([])
                setCommentType('')
            }
        })
    }
    return (
        <>
            <Grid style={styleCss.commonpaddingtop}>
                <form onSubmit={submitSocialForm.handleSubmit}>
                    <Grid container item sx={{ border: 'solid 1px #edeced', borderRadius: '7px' }}>
                        <Grid md={2} sx={{
                            borderRight: '1px solid #edeced',
                        }}>
                            <Socialselect formik={submitSocialForm} />
                        </Grid>
                        <Grid md={3} sx={{
                            borderRight: '1px solid #edeced',
                        }}>
                            <SocialInbox commentList={commentList} handleSingleComment={handleSingleComment} handleReadPost={handleReadPost} />
                        </Grid>
                        <Grid md={4} sx={{
                            borderRight: '1px solid #edeced', height: '100vh', overflowY: 'scroll', '&::-webkit-scrollbar': {
                                width: '3px',
                            },
                            '&::-webkit-scrollbar-track': {
                                borderRadius: '10px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                background: '#ddd',
                                borderRadius: '10px',
                            },
                        }}>
                            <CommentsInbox singleCommentDetails={singleCommentDetails} commentType={commentType} handleSubmitSelectedPageForm={handleSubmitSelectedPageForm} />
                        </Grid>
                        <Grid md={3} sx={{
                            borderRight: '1px solid #edeced', maxHeight: '100vh', overflowY: 'scroll', '&::-webkit-scrollbar': {
                                width: '3px',
                            },
                            '&::-webkit-scrollbar-track': {
                                borderRadius: '10px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                background: '#ddd',
                                borderRadius: '10px',
                            },
                        }}>
                            {/* <ThreadsInbox /> */}
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </>
    )
}

export default Index