import { Box, Button, FormControlLabel, Grid, Typography } from "@mui/material";
import styleCss from "../Style/style";
import InboxIcon from "../img/inboxIcon.png";
import noImage from "../img/no-image.jpg";
import { Link } from "react-router-dom";
import _ from "lodash";
import { useEffect, useState } from "react";
import { dateDiff } from "../Utilities/customDate";
import { CheckCircleOutline } from "@mui/icons-material";
import { socialMediaLists } from "../Utilities/socialMediaLists";
import { readPostAlert } from "../Utilities/sweetAlert";

const SocialInbox = ({ commentList, handleSingleComment, handleReadPost }) => {
  const [parentCommentList, setParentCommentList] = useState([]);
  const [childCommentList, setChildCommentList] = useState([]);
  const changeReadPostConfirm = async (singleCommentData) => {
    let toReadConfirm = await readPostAlert();
    if (toReadConfirm.isConfirmed) {
      console.log('singleCommentData',singleCommentData)
      handleReadPost(singleCommentData);
    }
  };
  useEffect(() => {
    setParentCommentList(
      commentList !== null
        ? _.filter(commentList, ["commandtitle", "parent"])
        : []
    );
    setChildCommentList(
      commentList !== null
        ? _.filter(commentList, ["commandtitle", "Child"])
        : []
    );
  }, [commentList]);
  return (
    <>
      <Grid sx={{ padding: "6px 10px" }}>
        <Grid container sx={{ alignItems: "center" }}>
          <Grid md={8}>
            <Typography style={styleCss.Inboxstyle}> Inbox </Typography>
          </Grid>
          <Grid md={4} sx={{ textAlign: "end" }}>
            <img src={InboxIcon} />
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={{ borderBottom: "1px solid #edeced" }}></Grid>
      <Grid sx={{ padding: "10px" }}>
        <Grid
          container
          sx={{ alignItems: "center", marginBottom: "10px", marginTop: "10px" }}
        >
          <Grid md={6}>
            <Typography style={styleCss.Inboxstyle}> Parent Comment</Typography>
          </Grid>
          <Grid md={6} sx={{ textAlign: "end" }}>
            <Button
              variant="outlined"
              sx={{
                border: "1px solid #edeced",
                padding: "3px 12px",
                borderRadius: "50px",
                fontSize: "12px",
                color: "#706d70",
                textTransform: "capitalize",
              }}
            >
              Review all
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        className="to-review"
        sx={{
          maxHeight: "40vh",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: "3px",
          },
          "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#ddd",
            borderRadius: "10px",
          },
        }}
      >
        {parentCommentList &&
          parentCommentList?.map((list, index) => {
            let parent_comment_id;
            if (list.social_media_id === 1)
              parent_comment_id = list.topost_comment_id;
            if (
              list.social_media_id === 3 ||
              list.social_media_id === 4 ||
              list.social_media_id === 5
            )
              parent_comment_id = list.parent_comment_id;
            return (
              <>
                <Grid
                  sx={{ padding: "10px", marginBottom: "10px" }}
                  key={index}
                  onClick={() =>
                    handleSingleComment({
                      parentPostCommentId: parent_comment_id,
                      socialMediaId: list.social_media_id,
                      commandtitle: list.commandtitle,
                    })
                  }
                >
                  <Link to={"#"} style={{ textDecoration: "none" }}>
                    <div style={{ width: "100%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "nowrap",
                          p: 0,
                          position: "relative",
                        }}
                      >
                        <Grid sx={{ marginRight: "6px", position: "relative" }}>
                          <Grid sx={{ position: "relative" }}>
                            {list.social_media_id === 1 && (
                              <>
                                <img
                                  src={list?.url ? list?.url : noImage}
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50px",
                                    marginRight: "10px",
                                  }}
                                />
                                <img
                                  src={
                                    socialMediaLists[list.social_media_id][
                                      "icon"
                                    ]
                                  }
                                  style={{
                                    width: "14px",
                                    borderRadius: "50px",
                                    position: "absolute",
                                    bottom: 0,
                                    right: "3px",
                                  }}
                                />
                              </>
                            )}
                            {list.social_media_id === 3 && (
                              <>
                                <img
                                  src={
                                     noImage
                                  }
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50px",
                                    marginRight: "10px",
                                  }}
                                />
                                <img
                                  src={
                                    socialMediaLists[list.social_media_id][
                                      "icon"
                                    ]
                                  }
                                  style={{
                                    width: "14px",
                                    borderRadius: "50px",
                                    position: "absolute",
                                    bottom: 0,
                                    right: "3px",
                                  }}
                                />
                              </>
                            )}

                            {list.social_media_id === 4 && (
                              <>
                                <img
                                  src={
                                    list?.page_profile_image
                                      ? list?.page_profile_image
                                      : noImage
                                  }
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50px",
                                    marginRight: "10px",
                                  }}
                                />
                                <img
                                  src={
                                    socialMediaLists[list.social_media_id][
                                      "icon"
                                    ]
                                  }
                                  style={{
                                    width: "14px",
                                    borderRadius: "50px",
                                    position: "absolute",
                                    bottom: 0,
                                    right: "3px",
                                  }}
                                />
                              </>
                            )}
                            {list.social_media_id === 5 && (
                              <>
                                <img
                                  src={list?.page_profile_image
                                    ? list?.page_profile_image
                                    : noImage}
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50px",
                                    marginRight: "10px",
                                  }}
                                />
                                <img
                                  src={
                                    socialMediaLists[list.social_media_id][
                                      "icon"
                                    ]
                                  }
                                  style={{
                                    width: "14px",
                                    borderRadius: "50px",
                                    position: "absolute",
                                    bottom: 0,
                                    right: "3px",
                                  }}
                                />
                              </>
                            )}
                          </Grid>
                        </Grid>
                        <Grid sx={{ flexGrow: "1" }}>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: 500,
                              textAlign: "left",
                              color: "#27292f !important",
                            }}
                          >
                            {list.social_media_id === 1 && (
                              <>{list.comment_name}</>
                            )}
                            {list.social_media_id === 3 && (
                              <>{list.parent_profile}</>
                            )}
                            {(list.social_media_id === 4 ||
                              list.social_media_id === 5) && (
                              <>{list.profile_name}</>
                            )}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: 500,
                              textAlign: "left",
                              color: "#87888e !important",
                            }}
                          >
                            {(list.social_media_id === 1 ||
                              list.social_media_id === 3) && (
                              <>{dateDiff(list.date_comment_post)}</>
                            )}
                            {(list.social_media_id === 4 ||
                              list.social_media_id === 5) && (
                              <>{dateDiff(list.created_at)}</>
                            )}
                          </Typography>
                        </Grid>
                        <Grid>
                          <CheckCircleOutline
                            style={{
                              textAlign: "end",
                              color: "#27292f",
                              fontSize: "16px",
                            }}
                            onClick={() =>
                              changeReadPostConfirm({
                                parentPostCommentId: parent_comment_id,
                                socialMediaId: list.social_media_id,
                                commandtitle: list.commandtitle,
                              })
                            }
                          />
                        </Grid>
                      </Box>
                      <Grid container>
                        <Grid md={12}>
                          <Grid>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                fontWeight: 600,
                                textAlign: "left",
                                color: "#4b4c4f",
                                marginRight: "3px",
                                fontFamily: "Inter",
                              }}
                            >
                              {(list.social_media_id === 1 ||
                                list.social_media_id === 3) && (
                                <>{list.page_name}</>
                              )}
                              {(list.social_media_id === 4 ||
                                list.social_media_id === 5) && (
                                <>{list.profile_name}</>
                              )}
                            </Typography>
                          </Grid>
                          <Typography
                            sx={{
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: "normal",
                              textAlign: "left",
                              color: "#767e87",
                            }}
                          >
                            {" "}
                            Commented on:{" "}
                            <span style={{ color: "#27292f" }}>
                              {list.comments}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid md={12}>
                          <Typography
                            sx={{
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: "normal",
                              textAlign: "left",
                              color: "#4b4b4b",
                              whiteSpace: "nowrap",
                              width: "280px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          ></Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Link>
                </Grid>
                <Grid sx={{ borderTop: "1px solid #edeced" }}></Grid>
              </>
            );
          })}
      </Grid>
      <Grid sx={{ borderTop: "1px solid #edeced" }}></Grid>
      <Grid className="reviewed">
        <Grid sx={{ padding: "10px" }}>
          <Grid container sx={{ alignItems: "center", marginBottom: "10px" }}>
            <Grid md={6}>
              <Typography style={styleCss.Inboxstyle}>
                {" "}
                Child Comment
              </Typography>
            </Grid>
            <Grid md={6} sx={{ textAlign: "end" }}>
              <Button
                variant="outlined"
                sx={{
                  border: "1px solid #edeced",
                  padding: "3px 12px",
                  borderRadius: "50px",
                  fontSize: "12px",
                  color: "#706d70",
                  textTransform: "capitalize",
                }}
              >
                Review all
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          sx={{
            maxHeight: "40vh",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: "3px",
            },
            "&::-webkit-scrollbar-track": {
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#ddd",
              borderRadius: "10px",
            },
          }}
        >
          {childCommentList &&
            childCommentList?.map((list, index) => {
              let parent_comment_id;
              let child_comment_id='';
              if (list.social_media_id === 1) {
                parent_comment_id = list.topost_comment_id;
                // child_comment_id = list.topost_comment_id;
              }
              if (
                list.social_media_id === 3 ||
                list.social_media_id === 4 ||
                list.social_media_id === 5
              ){
                parent_comment_id = list.child_post_comment_id??list.parent_comment_id;
                // child_comment_id = list.child_post_comment_id;
              }
              return (
                <>
                  <Grid
                    sx={{ padding: "10px", marginBottom: "10px" }}
                    onClick={() =>
                      handleSingleComment({
                        parentPostCommentId: parent_comment_id,
                        socialMediaId: list.social_media_id,
                        commandtitle: list.commandtitle,
                      })
                    }
                  >
                    <Link
                      to={"#"}
                      style={{ textDecoration: "none" }}
                      key={index}
                    >
                      <div style={{ width: "100%" }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "nowrap",
                            p: 0,
                            position: "relative",
                          }}
                        >
                          <Grid
                            sx={{ marginRight: "6px", position: "relative" }}
                          >
                            <Grid sx={{ position: "relative" }}>
                              {list.social_media_id === 1 && (
                                <>
                                  <img
                                    src={list?.url ? list?.url : noImage}
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      borderRadius: "50px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  <img
                                    src={
                                      socialMediaLists[list.social_media_id][
                                        "icon"
                                      ]
                                    }
                                    style={{
                                      width: "14px",
                                      borderRadius: "50px",
                                      position: "absolute",
                                      bottom: 0,
                                      right: "3px",
                                    }}
                                  />
                                </>
                              )}
                              {list.social_media_id === 3 && (
                                <>
                                  <img
                                    src={
                                     noImage
                                    }
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      borderRadius: "50px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  <img
                                    src={
                                      socialMediaLists[list.social_media_id][
                                        "icon"
                                      ]
                                    }
                                    style={{
                                      width: "14px",
                                      borderRadius: "50px",
                                      position: "absolute",
                                      bottom: 0,
                                      right: "3px",
                                    }}
                                  />
                                </>
                              )}
                              {list.social_media_id === 4 && (
                                <>
                                  <img
                                    src={
                                      list?.page_profile_image
                                        ? list?.page_profile_image
                                        : noImage
                                    }
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      borderRadius: "50px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  <img
                                    src={
                                      socialMediaLists[list.social_media_id][
                                        "icon"
                                      ]
                                    }
                                    style={{
                                      width: "14px",
                                      borderRadius: "50px",
                                      position: "absolute",
                                      bottom: 0,
                                      right: "3px",
                                    }}
                                  />
                                </>
                              )}
                              {list.social_media_id === 5 && (
                                <>
                                  <img
                                    src={list?.page_profile_image
                                      ? list?.page_profile_image
                                      : noImage}
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      borderRadius: "50px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  <img
                                    src={
                                      socialMediaLists[list.social_media_id][
                                        "icon"
                                      ]
                                    }
                                    style={{
                                      width: "14px",
                                      borderRadius: "50px",
                                      position: "absolute",
                                      bottom: 0,
                                      right: "3px",
                                    }}
                                  />
                                </>
                              )}
                            </Grid>
                          </Grid>
                          <Grid sx={{ flexGrow: "1" }}>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                fontWeight: 600,
                                textAlign: "left",
                                color: "#4b4c4f",
                                marginRight: "3px",
                                fontFamily: "Inter",
                              }}
                            >
                              {list.social_media_id === 1 && (
                                <>{list.comment_name}</>
                              )}
                              {list.social_media_id === 3 && (
                                <>{list.parent_profile}</>
                              )}
                              {(list.social_media_id === 4 ||
                                list.social_media_id === 5) && (
                                <>{list.profile_name}</>
                              )}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "13px",
                                fontWeight: 500,
                                textAlign: "left",
                                color: "#87888e !important",
                              }}
                            >
                              {(list.social_media_id === 1 ||
                                list.social_media_id === 3) && (
                                <>{dateDiff(list.date_comment_post)}</>
                              )}
                              {(list.social_media_id === 4 ||
                                list.social_media_id === 5) && (
                                <>{dateDiff(list.created_at)}</>
                              )}
                            </Typography>
                          </Grid>
                          <Grid>
                            <CheckCircleOutline
                              style={{
                                textAlign: "end",
                                color: "#009688",
                                fontSize: "16px",
                              }}
                              onClick={() =>
                                changeReadPostConfirm({
                                  parentPostCommentId: parent_comment_id,
                                  socialMediaId: list.social_media_id,
                                  commandtitle: list.commandtitle,
                                })
                              }
                            />
                          </Grid>
                        </Box>
                        <Grid container>
                          <Grid>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                fontWeight: 600,
                                textAlign: "left",
                                color: "#4b4c4f",
                                marginRight: "3px",
                                fontFamily: "Inter",
                              }}
                            >
                              {(list.social_media_id === 1 ||
                                list.social_media_id === 3) && (
                                <>{list.page_name}</>
                              )}
                              {(list.social_media_id === 4 ||
                                list.social_media_id === 5) && (
                                <>{list.profile_name}</>
                              )}
                            </Typography>
                          </Grid>
                          <Grid md={12}>
                            <Typography
                              sx={{
                                fontFamily: "Inter",
                                fontSize: "12px",
                                fontWeight: "normal",
                                textAlign: "left",
                                color: "#767e87",
                              }}
                            >
                              {" "}
                              Commented on:{" "}
                              <span style={{ color: "#27292f" }}>
                                {list.comments}
                              </span>
                            </Typography>
                          </Grid>
                          <Grid md={12}>
                            <Typography
                              sx={{
                                fontFamily: "Inter",
                                fontSize: "12px",
                                fontWeight: "normal",
                                textAlign: "left",
                                color: "#4b4b4b",
                                whiteSpace: "nowrap",
                                width: "280px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            ></Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </Link>
                  </Grid>
                  <Grid sx={{ borderTop: "1px solid #edeced" }}></Grid>
                </>
              );
            })}
        </Grid>
      </Grid>
    </>
  );
};

export default SocialInbox;