import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { authpostAxios } from "../Utilities/commonAxios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { manageUserSession } from "../Reduxstore/authSlice";

export default function ChangePassword() {
  const dispatch = useDispatch();
  const submitLogout = async () => await dispatch(manageUserSession('logout'))
  const submitPasswordForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .min(8, "Password must be 8 characters")
        .max(8, "Password must be 8 characters")
        .required("Please Enter Old Password"),
      newPassword: Yup.string()
        .min(8, "Password must be 8 characters")
        .max(8, "Password must be 8 characters")
        .required("Please Enter New Password"),
      confirmPassword: Yup.string()
        .min(8, "Password must be 8 characters")
        .max(8, "Password must be 8 characters")
        .required("Please Enter Confirm Password")
        .oneOf([Yup.ref("newPassword")], "New/Confirm Password must match"),
    }),
    onSubmit: async (values,actions) => {
      if (!submitPasswordForm.dirty) {
        toast.error("No data has changed!");
      } else {
        let formData = {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        };
        let { data: userProfileResponse } = await authpostAxios(
          {
            url: `${process.env.REACT_APP_BACKENDURL}api/userchange-password`,
            data: formData,
          },
          dispatch
        );
        if (userProfileResponse.flag === 1) {
          actions.resetForm();
          toast.success(userProfileResponse.message);
          setTimeout(()=>{
            submitLogout()
          },3000)
        } else {
          toast.error(userProfileResponse.message);
        }
      }
    },
  });
  return (
    <Grid md={6} sx={{ mb: 2 }}>
      <Grid
        sx={{
          boxShadow: "#919eab4d 0 0 2px, #919eab1f 0 12px 24px -4px",
          border: "1px solid #ddd",
          padding: "25px",
          borderRadius: "5px",
        }}
      >
        <form onSubmit={submitPasswordForm.handleSubmit}>
          <Grid container item columnSpacing={2}>
            <Grid item md={12}>
              <TextField
                type="password"
                fullWidth
                label="Old Password"
                name="oldPassword"
                value={submitPasswordForm.values.oldPassword}
                onChange={submitPasswordForm.handleChange}
                id="oldpassword"
                size="small"
                sx={{ mb: 2 }}
                InputLabelProps={{
                  sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
                }}
                inputProps={{
                  sx: {
                    color: "#000000de",
                    fontSize: "15px",
                    fontWeight: 500,
                    fontFamily: "Inter",
                  },
                }}
              />
              {submitPasswordForm.errors.oldPassword ? (
                <Typography variant="body2" sx={{ color: "red", fontSize: 14 }}>
                  {submitPasswordForm.errors.oldPassword}
                </Typography>
              ) : null}
              <TextField
                type="password"
                fullWidth
                label="New Password"
                name="newPassword"
                value={submitPasswordForm.values.newPassword}
                onChange={submitPasswordForm.handleChange}
                id="newPassword"
                size="small"
                sx={{ mb: 2 }}
                InputLabelProps={{
                  sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
                }}
                inputProps={{
                  sx: {
                    color: "#000000de",
                    fontSize: "15px",
                    fontWeight: 500,
                    fontFamily: "Inter",
                  },
                }}
              />
              {submitPasswordForm.errors.newPassword ? (
                <Typography variant="body2" sx={{ color: "red", fontSize: 14 }}>
                  {submitPasswordForm.errors.newPassword}
                </Typography>
              ) : null}

              <TextField
                type="password"
                fullWidth
                label="Confirm Password"
                name="confirmPassword"
                value={submitPasswordForm.values.confirmPassword}
                onChange={submitPasswordForm.handleChange}
                id="newPassword"
                size="small"
                sx={{ mb: 2 }}
                InputLabelProps={{
                  sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
                }}
                inputProps={{
                  sx: {
                    color: "#000000de",
                    fontSize: "15px",
                    fontWeight: 500,
                    fontFamily: "Inter",
                  },
                }}
              />
              {submitPasswordForm.errors.confirmPassword ? (
                <Typography variant="body2" sx={{ color: "red", fontSize: 14 }}>
                  {submitPasswordForm.errors.confirmPassword}
                </Typography>
              ) : null}
            </Grid>

            <Grid item md={12}>
              <Grid sx={{ textAlign: "end" }}>
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "capitalize",
                    marginRight: "20px",
                    fontFamily: "Inter",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ textTransform: "capitalize", fontFamily: "Inter" }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}
