import { Checkbox, FormControlLabel, FormGroup, Grid, Typography, Button } from "@mui/material"
import styleCss from "../Style/style"
import Profilepictur from '../img/profilepicture.png'
import socialIcon from '../img/sub-icon-4.png'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { useEffect, useState } from "react";
import { authpostAxios } from "../Utilities/commonAxios";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Swal from 'sweetalert2';

const ImportLinkedPages = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    if (!localStorage?.getItem('linkedIniid')) {
        toast.error("Can't able to import pages!")
        navigate('/dashboard')
    }
    const [pageLists, setPageLists] = useState([])
    useEffect(() => {
        const importLinkedinPages = async () => {
            await authpostAxios({
                url: `${process.env.REACT_APP_BACKENDURL}api/get-import-linkedin-page`,
                data: { 'lastInsertedID': localStorage.getItem('linkedIniid') }
            }, dispatch).then(response => setPageLists(response?.data?.data))
        }
        importLinkedinPages()
    }, [])
    const submitImportForm = useFormik({
        enableReinitialize: true,
        validateOnChange: false,
        initialValues: {
            importSelectedPages: []
        },
        validationSchema: Yup.object({
            importSelectedPages: Yup.array().min(1, 'Select atleast one page!').required(1, 'Select atleast one page!')
        }),
        onSubmit: async (values) => {
            let { data: importResponse } = await authpostAxios({
                'url': `${process.env.REACT_APP_BACKENDURL}api/import-linkedin-page`, 'data': {
                    importSelectedPages: values.importSelectedPages
                }
            }, dispatch);
            if (importResponse.flag === 1) {
                localStorage.removeItem('linkedIniid')
                toast.success(importResponse.message)
                navigate('/dashboard')
            } else {
                toast.error(importResponse.message)
            }
        }
    })
    const handleImportChange = (pagelist) => {
        let checkValueExists = submitImportForm.values.importSelectedPages.findIndex((element) => element.id === pagelist.id)
        if (checkValueExists === -1) {
            submitImportForm.setFieldValue('importSelectedPages', [...submitImportForm.values.importSelectedPages, {
                "id": pagelist.id,
                "profile_id": pagelist.profile_id,
                "profile_url": pagelist.profile_url,
                "profile_name": pagelist.profile_name,
                "last_inserted_id": pagelist.last_inserted_id
            }])
        } else {
            submitImportForm.setFieldValue('importSelectedPages', submitImportForm.values.importSelectedPages.filter((element) => element.id !== pagelist.id))
        }
    }

    const handleClick = async () => {
        // Show SweetAlert2 confirmation dialog
        const result = await Swal.fire({
          title: 'Are you sure?',
          text: 'Do you dont need to import company profile?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, go!',
          cancelButtonText: 'Cancel'
        });
    
        if (result.isConfirmed) {
          navigate('/dashboard'); // Redirect to the dashboard route
        }
      };

    return (
        <>
            <form onSubmit={submitImportForm.handleSubmit}>
                <Grid style={styleCss.commonpaddingtop} sx={{ mb: 3 }}>
                    <Grid sx={{ border: '1px solid #ddd', padding: '10px', borderRadius: '5px', width: '25%', margin: '0 auto' }}>
                        <Grid container item columnSpacing={1} sx={{ mb: 1 }}>
                            {pageLists && pageLists?.map((pageList, index) => (
                                <>
                                    <Grid item md={2} sx={{ alignSelf: 'center' }} key={index}>
                                        <Grid sx={{ position: 'relative' }}>
                                            <img src={((pageList?.profile_url) ? (pageList?.profile_url) : (Profilepictur))} style={{
                                                borderRadius: '5px', width: '100%',
                                                borderRadius: '50px',
                                            }} />
                                            <img src={socialIcon} style={{ borderRadius: '5px', position: 'absolute', right: '0', width: '17px', bottom: '0' }} />
                                        </Grid>
                                    </Grid>
                                    <Grid item md={8} sx={{ alignSelf: 'center' }}>
                                        <Typography sx={{ fontSize: '13px', color: '#27292f!important', fontWeight: '600' }}>{pageList.profile_name}</Typography>
                                    </Grid>
                                    <Grid item md={2} sx={{ alignSelf: 'center', textAlign: 'end' }}>
                                        <FormGroup sx={{ '& .css-j204z7-MuiFormControlLabel-root': { marginRight: '0px', marginLeft: '0', } }}>
                                            <FormControlLabel control={<Checkbox onChange={() => handleImportChange(pageList)} checked={(submitImportForm?.values?.importSelectedPages) ? (submitImportForm?.values?.importSelectedPages.some((selectedPage) => selectedPage.id === pageList.id)) : ('')} />} />
                                        </FormGroup>
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                        {submitImportForm?.errors?.importSelectedPages && (
                            <Grid container sx={{ mt: 2 }}>
                                <Grid md={12} sx={{ marginTop: '10px' }}>
                                    <Typography className="error" sx={{ color: 'red', fontSize: '12px', padding: '10px', textAlign: 'center' }}>{submitImportForm?.errors?.importSelectedPages ? submitImportForm?.errors?.importSelectedPages : ''}</Typography>
                                </Grid>
                            </Grid>
                        )}
                        <Grid container sx={{ mt: 2 }}>
                            <Grid md={6} sx={{ textAlign: 'center' }}>
                                <Button type="submit" variant="contained" sx={{ textTransform: 'capitalize', fontFamily: 'inter' }}><CloudDownloadOutlinedIcon sx={{ marginRight: '5px' }} /> Import</Button>
                            </Grid>
                            <Grid md={6} sx={{ textAlign: 'center' }}>
                            <Button 
                            variant="contained" 
                            sx={{ 
                                textTransform: 'capitalize', 
                                fontFamily: 'inter',
                                backgroundColor: 'red',
                                color: 'white', // Optional: to ensure text is visible on red background
                                '&:hover': {
                                backgroundColor: 'darkred', // Optional: darker shade on hover
                                },
                                '&:active': {
                                backgroundColor: 'darkred', // Optional: same darker shade on click
                                }
                            }}
                            onClick={handleClick}
                            >
                            <CloudDownloadOutlinedIcon sx={{ marginRight: '5px' }} /> 
                            Cancel
                            </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default ImportLinkedPages