import {
    Grid, Typography, Button, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Paper, Dialog, DialogTitle,
    DialogContent, DialogActions, IconButton, Close,
    styled,
    TextField, FormControlLabel, Checkbox, FormControl, InputLabel, FormLabel, RadioGroup, Radio
}
    from "@mui/material";
import React, { useState, useEffect } from "react";
import styleCss from "../Style/style"
import Box from '@mui/material/Box';
import { authpostAxios } from "../Utilities/commonAxios";
import { Formik, Form, Field, useFormik, ErrorMessage } from 'formik';
import SliderRange from '@mui/material/Slider';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import '../Style/Loader.css'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AddIcon from '@mui/icons-material/Add';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";


const EditRole = () => {
    const { enRoleid } = useParams()
    const RoleId = atob(enRoleid)
    const navigate = useNavigate()
    const [state, setState] = React.useState({
        gilad: true,
        jason: false,
        antoine: false,
    });

    const [roleTypes, setRoleTypes] = useState([])
    useEffect(() => {
        const getRoleTypeAPI = async () => {
            console.log("getRoleTypeAPI")
            const response = await authpostAxios({
                url: `${process.env.REACT_APP_BACKENDURL}api/get-role-view`,
                data: { roleId: RoleId },
            });
            setRoleTypes(response.data.data[0])

        };
        getRoleTypeAPI()
    }, [RoleId]);
    const validationSchema = Yup.object({
        role_name: Yup.string().required('Please Enter the Role Name'),
        dashboard: Yup.string().required('Please select any one'),
        socialpost: Yup.string().required('Please select any one'),
        inbox: Yup.string().required('Please select any one'),
        readinbox: Yup.string().required('Please select any one'),
        calender: Yup.string().required('Please select any one'),
        publishpost: Yup.string().required('Please select any one'),
        budgertracker: Yup.string().required('Please select any one'),
        usercreation: Yup.string().required('Please select any one'),
        userrole: Yup.string().required('Please select any one'),
        manageaccount: Yup.string().required('Please select any one'),
        logout: Yup.string().required('Please select any one'),
    });


    const roleArray = roleTypes?.role_types

    const { gilad, jason, antoine } = state;
    const error = [gilad, jason, antoine].filter((v) => v).length !== 2;
    const getYesNo = (value) => (value ? 'Yes' : 'No');
    const submitSocialForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            role_name: (roleTypes) ? (roleTypes?.role_name) : (''),
            dashboard: roleArray && roleArray.some(role => role === 'Dashboard') ? 'yes' : 'no',
            socialpost: roleArray && roleArray.some(role => role === 'socialpost') ? 'yes' : 'no',
            inbox: roleArray && roleArray.some(role => role === 'Index') ? 'yes' : 'no',
            readinbox: roleArray && roleArray.some(role => role === 'Readinbox') ? 'yes' : 'no',
            calender: roleArray && roleArray.some(role => role === 'MyCalendar') ? 'yes' : 'no',
            publishpost: roleArray && roleArray.some(role => role === 'Publishpost') ? 'yes' : 'no',
            budgertracker: roleArray && roleArray.some(role => role === 'budgertracker') ? 'yes' : 'no',
            usercreation: roleArray && roleArray.some(role => role === 'UserCreation') ? 'yes' : 'no',
            userrole: roleArray && roleArray.some(role => role === 'UserRoles') ? 'yes' : 'no',
            manageaccount: roleArray && roleArray.some(role => role === 'ManageAccountPage') ? 'yes' : 'no',
            logout: roleArray && roleArray.some(role => role === 'logout') ? 'yes' : 'no',
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            if (!submitSocialForm.dirty) {
            } else {
                Swal.fire({
                    icon: 'question',
                    title: 'Confirm Submission',
                    text: 'Are you sure you want to save the role?',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, save it!',
                    cancelButtonText: 'No, cancel',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        // User confirmed, proceed with form submission
                        try {
                            let formData = {
                                role_name: values.role_name,
                                dashboard: values.dashboard,
                                socialpost: values.socialpost,
                                inbox: values.inbox,
                                readinbox: values.readinbox,
                                calender: values.calender,
                                publishpost: values.publishpost,
                                budgertracker: values.budgertracker,
                                usercreation: values.usercreation,
                                userrole: values.userrole,
                                manageaccount: values.manageaccount,
                                logout: values.logout,

                            };

                            console.log(formData);
                            let response = await authpostAxios({
                                url: `${process.env.REACT_APP_BACKENDURL}api/add-user-roles`,
                                data: formData,
                            });
                            console.log(response.data);
                            if (response.data.flag === 1) {
                                toast.success(response.data.message)
                                navigate('/roles')
                            } else {
                                toast.error(response.data.message)
                            }
                        } catch (error) {
                            console.error('Error submitting form:', error);
                            toast.error(error)
                        }
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        // User cancelled, do nothing or provide feedback
                        Swal.fire({
                            icon: 'info',
                            title: 'Cancelled',
                            text: 'The form submission was cancelled.',
                        });
                    }
                });

            }
        },
    });

    const handleClickOpen = async () => {
        navigate('/roles')
    }


    return (

        <Grid container style={styleCss.commonpaddingtop}>
            <Grid md={12} sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button variant="contained" onClick={handleClickOpen}>
                    view Roles
                </Button>
            </Grid>
            <form onSubmit={submitSocialForm.handleSubmit}>
                <Grid container xs={12} spacing={2} style={{ marginBottom: '10px' }}>
                    <Grid item xs={12} spacing={2}>
                        <FormControl fullWidth>
                            <TextField
                                label="Role Name"
                                id="role_name"
                                name="role_name"
                                value={submitSocialForm.values.role_name}
                                onChange={submitSocialForm.handleChange}
                                fullWidth
                                InputLabelProps={{
                                    sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
                                    shrink: true
                                }}
                            />
                        </FormControl>
                        <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.role_name}</Grid>
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table sx={{ border: 'unset' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontFamily: 'Inter', fontSize: '17px', fontWeight: '500' }}>Administrator Access </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left" sx={{ padding: '4px 16px' }}>
                                    <Typography sx={{ fontFamily: 'Inter', fontSize: '15px', fontWeight: '500' }}>
                                        Social Media Connection
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" sx={{ padding: '4px 16px' }}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="dashboard"
                                            value={submitSocialForm.values.dashboard}
                                            onChange={submitSocialForm.handleChange}
                                        >
                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                    <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.dashboard}</Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left" sx={{ padding: '4px 16px' }}>
                                    <Typography sx={{ fontFamily: 'Inter', fontSize: '15px', fontWeight: '500' }}>
                                        Social Media Post
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" sx={{ padding: '4px 16px' }}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="socialpost"
                                            value={submitSocialForm.values.socialpost}
                                            onChange={submitSocialForm.handleChange}
                                        >
                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                    <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.socialpost}</Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left" sx={{ padding: '4px 16px' }}>
                                    <Typography sx={{ fontFamily: 'Inter', fontSize: '15px', fontWeight: '500' }}>
                                        Smart Comments Inbox Unread
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" sx={{ padding: '4px 16px' }}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="inbox"
                                            value={submitSocialForm.values.inbox}
                                            onChange={submitSocialForm.handleChange}
                                        >
                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                    <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.inbox}</Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left" sx={{ padding: '4px 16px' }}>
                                    <Typography sx={{ fontFamily: 'Inter', fontSize: '15px', fontWeight: '500' }}>
                                        Smart Comments Inbox read
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" sx={{ padding: '4px 16px' }}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="readinbox"
                                            value={submitSocialForm.values.readinbox}
                                            onChange={submitSocialForm.handleChange}
                                        >
                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                    <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.readinbox}</Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left" sx={{ padding: '4px 16px' }}>
                                    <Typography sx={{ fontFamily: 'Inter', fontSize: '15px', fontWeight: '500' }}>
                                        Calender
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" sx={{ padding: '4px 16px' }}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="calender"
                                            value={submitSocialForm.values.calender}
                                            onChange={submitSocialForm.handleChange}
                                        >
                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                    <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.calender}</Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left" sx={{ padding: '4px 16px' }}>
                                    <Typography sx={{ fontFamily: 'Inter', fontSize: '15px', fontWeight: '500' }}>
                                        Social Media Post Listing
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" sx={{ padding: '4px 16px' }}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="publishpost"
                                            value={submitSocialForm.values.publishpost}
                                            onChange={submitSocialForm.handleChange}
                                        >
                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                    <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.publishpost}</Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left" sx={{ padding: '4px 16px' }}>
                                    <Typography sx={{ fontFamily: 'Inter', fontSize: '15px', fontWeight: '500' }}>
                                        Budget Tracker
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" sx={{ padding: '4px 16px' }}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="budgertracker"
                                            value={submitSocialForm.values.budgertracker}
                                            onChange={submitSocialForm.handleChange}
                                        >
                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                    <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.budgertracker}</Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left" sx={{ padding: '4px 16px' }}>
                                    <Typography sx={{ fontFamily: 'Inter', fontSize: '15px', fontWeight: '500' }}>
                                        User Creation
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" sx={{ padding: '4px 16px' }}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="usercreation"
                                            value={submitSocialForm.values.usercreation}
                                            onChange={submitSocialForm.handleChange}
                                        >
                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                    <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.usercreation}</Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left" sx={{ padding: '4px 16px' }}>
                                    <Typography sx={{ fontFamily: 'Inter', fontSize: '15px', fontWeight: '500' }}>
                                        User Role Creation
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" sx={{ padding: '4px 16px' }}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="userrole"
                                            value={submitSocialForm.values.userrole}
                                            onChange={submitSocialForm.handleChange}
                                        >
                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                    <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.userrole}</Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left" sx={{ padding: '4px 16px' }}>
                                    <Typography sx={{ fontFamily: 'Inter', fontSize: '15px', fontWeight: '500' }}>
                                        Manage Account view
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" sx={{ padding: '4px 16px' }}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="manageaccount"
                                            value={submitSocialForm.values.manageaccount}
                                            onChange={submitSocialForm.handleChange}
                                        >
                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                    <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.manageaccount}</Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left" sx={{ padding: '4px 16px' }}>
                                    <Typography sx={{ fontFamily: 'Inter', fontSize: '15px', fontWeight: '500' }}>
                                        logout Button
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" sx={{ padding: '4px 16px' }}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="logout"
                                            value={submitSocialForm.values.logout}
                                            onChange={submitSocialForm.handleChange}
                                        >
                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                    <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.logout}</Grid>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Grid container xs={12} spacing={2} style={{ marginTop: '5px', marginLeft: '5px' }}>
                    <Button type="submit" variant="contained" color="primary" style={{ marginTop: '16px' }}>
                        Save Role
                    </Button>
                </Grid>
            </form>
        </Grid>
    )
}

export default EditRole;