import * as React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import styleCss from "../Style/style";
import { Grid,Typography, } from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CompanyProfile from './companyProfile';
import MyProfile from './myProfile';
import ChangePassword from './changePassword';

export default function Myaccount(){
    const [value, setValue] = React.useState('1');
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Grid style={styleCss.commonpaddingtop}>
            <Grid container>
                <Box sx={{ width: '100%', }}>
                    <TabContext value={value}>
                        <Box sx={{}}>
                            <TabList onChange={handleTabChange} sx={{
                                '& .css-1aquho2-MuiTabs-indicator': {
                                    background: 'unset !important',
                                }, '& .MuiTab-textColorPrimary.Mui-selected': {
                                    background: '#f0f0f0 !important',
                                    padding: '12px',
                                    borderRadius: '5px',
                                }
                            }}>
                                <Tab label="company Profile" value="1" />
                                <Tab label="My Profile" value="2" />
                                <Tab label="Change Password" value="3" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Grid container>
                                <Grid md={12} sx={{ mb: 2, mt: 1 }}>
                                    <Typography sx={{
                                        fontSize: '17px',
                                        fontWeight: 600,
                                        textAlign: 'left',
                                        color: 'rgb(39, 41, 47)',
                                        fontFamily: 'Inter',
                                    }}>
                                        Company Profile
                                    </Typography>
                                </Grid>
                               <CompanyProfile/>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="2">
                            <Grid container>
                                <Grid md={12} sx={{ mb: 2, mt: 1 }}>
                                    <Typography sx={{
                                        fontSize: '17px',
                                        fontWeight: 600,
                                        textAlign: 'left',
                                        color: 'rgb(39, 41, 47)',
                                        fontFamily: 'Inter',
                                    }}>
                                        My Profile
                                    </Typography>
                                </Grid>
                               <MyProfile/>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="3">
                            <Grid container>
                                <Grid md={12} sx={{ mb: 2, mt: 1 }}>
                                    <Typography sx={{
                                        fontSize: '17px',
                                        fontWeight: 600,
                                        textAlign: 'left',
                                        color: 'rgb(39, 41, 47)',
                                        fontFamily: 'Inter',
                                    }}>
                                        Change Password
                                    </Typography>
                                </Grid>
                                <ChangePassword/>
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Grid>
        </Grid >
    );
}
