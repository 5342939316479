// toast
import { toast } from "react-toastify";
import { Navigate } from "react-router";
import { useSelector } from 'react-redux';

export const AuthMiddleware = (props) => {
  let isAuthenticated = useSelector(state => state.auth)
  if (Object.keys(isAuthenticated?.postItems).length > 0 && isAuthenticated.isLogin && isAuthenticated.postItems?.data?.userDetails && isAuthenticated.postItems?.data?.userToken) {
    return (
      <>
        {props.children}
      </>
    )
  } else if (isAuthenticated.isSessionExpired) {
    toast.error('Session has expired!');
    return (<Navigate to={{ pathname: "/login", state: { from: props.location } }} />)
  } else if (isAuthenticated.isLogout) {
    toast.success('Logout Successfully')
    return (<Navigate to={{ pathname: "/login", state: { from: props.location } }} />)
  } else {
    toast.error('Unauthorized access!');
    return (<Navigate to={{ pathname: "/login", state: { from: props.location } }} />)
  }
}

export const NonAuthMiddleware = (props) => {
  let isAuthenticated = useSelector(state => state.auth)
  if (Object.keys(isAuthenticated?.postItems).length > 0 && isAuthenticated.postItems?.data?.userDetails && isAuthenticated.postItems?.data?.userToken) {
    return (<Navigate to={{ pathname: "/dashboard", state: { from: props.location } }} />)
  } else {
    return (
      <>
        {props.children}
      </>
    )
  }
}