import { Grid, Typography } from "@mui/material"
import styleCss from "../Style/style"
import FacbookIcon from '../img/social-icon-1.svg'
import Socialmainimg from '../img/list-img.jpg'
import InstIconimg from '../img/social-icon-2.png'
import { authpostAxios } from "../Utilities/commonAxios";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import ListImage from '../img/list-img.jpg';

const ManageAccountPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [FbPageList, setFbPageList] = useState([])
    const [InstaAccountList, setInstaAccountList] = useState([])
    const [fbAdAccountList, setFbAdAccountList] = useState([])
    const getFbPageList = async () => {
        try {
            await authpostAxios({
                url: `${process.env.REACT_APP_BACKENDURL}api/get-fb-page-list`,
            }, dispatch).then(response => setFbPageList(response?.data?.data))
        } catch (error) {
            console.error('Error fetching App ID from API:', error);
        }
    }

    const getInstaAccountList = async () => {
        try {
            await authpostAxios({
                url: `${process.env.REACT_APP_BACKENDURL}api/get-insta-page-list`,
            }, dispatch).then(response => setInstaAccountList(response?.data?.data))
        } catch (error) {
            console.error('Error fetching App ID from API:', error);
        }
    }
    const getFbAdAccountList = async () => {
        try {
            await authpostAxios({
                url: `${process.env.REACT_APP_BACKENDURL}api/get-fb-ad-account`,
            }, dispatch).then(response => setFbAdAccountList(response?.data?.data))
        }
        catch (error) {
            console.error('Error fetching App ID from API:', error);
        }
    }

    useEffect(() => {
        getFbPageList()
        getInstaAccountList()
        getFbAdAccountList()
    }, []);
    return (
        <>
            <Grid style={styleCss.commonpaddingtop}>
                <Grid container>
                    <Grid md={12}>
                        <Typography sx={{
                            fontSize: "18px",
                            fontWeight: "600",
                            textAlign: "left",
                            color: "#27292f",
                            fontFamily: 'Inter',
                        }}>Social Media Pages and Accounts</Typography>
                    </Grid>
                </Grid>
                <Grid container item columnSpacing={2} sx={{ mt: 4, mb: 4 }}>
                    {fbAdAccountList && fbAdAccountList.length > 0 && (
                        <Grid item md={12}>
                            <Typography sx={{
                                fontSize: "14px",
                                fontWeight: "600",
                                textAlign: "left",
                                color: "#1e90ff",
                                fontFamily: 'Inter',
                                marginBottom: '20px',
                            }}>
                                FACEBOOK Ad ACCOUNTS
                            </Typography>
                        </Grid>
                    )}
                    {fbAdAccountList && fbAdAccountList?.map((list, index) => (
                        <Grid item md={4} key={index} sx={{ mb: 3 }}>
                            <Link to={`/view-account-post/${btoa(list.social_media_page_id)}/${btoa(list.social_media_id)}`} style={{ textDecoration: 'none' }}>
                                <Grid style={{ position: 'relative' }}>
                                    <img src={((list?.page_cover_image) ? (list?.page_cover_image) : (ListImage))} alt="" style={{ width: '100%', height: '150px', opacity: 0.8, objectFit: 'cover' }} />
                                    <Typography style={styleCss.innerposttextstyle}>
                                        {list?.account_name}
                                    </Typography>
                                    <Grid sx={{
                                        position: 'absolute', right: '13px', top: '11px', background: '#fff', padding: '10px',
                                        borderRadius: '50px',
                                        width: '30px',
                                        height: '30px',
                                        display: 'grid',
                                        placeContent: 'center',
                                        placeItems: 'center',
                                    }}>
                                        <img src={FacbookIcon} alt="" style={{ width: '10px' }} />
                                    </Grid>
                                    <Grid style={styleCss.cardfooter}>
                                        <Grid container>
                                            <Grid md={4} sx={{ textAlign: 'start' }}>
                                                <Link to="/" style={{ textDecoration: 'none', color: '#4b4f56', fontFamily: 'Inter', fontSize: '14px', fontWeight: '600' }}>List</Link>
                                            </Grid>
                                            <Grid md={4} sx={{ textAlign: 'end' }}>
                                                <Link to="/" style={{ textDecoration: 'none', color: '#4b4f56', fontFamily: 'Inter', fontSize: '14px', fontWeight: '600' }}>Disable webhook</Link>
                                            </Grid>
                                            <Grid md={4} sx={{ textAlign: 'end' }}>
                                                <Link to="/" style={{ textDecoration: 'none', color: '#4b4f56', fontFamily: 'Inter', fontSize: '14px', fontWeight: '600' }}>Get Post</Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
                <Grid container item columnSpacing={2} sx={{ mt: 4, mb: 4 }}>
                    {FbPageList && FbPageList.length > 0 && (
                        <Grid item md={12}>
                            <Typography sx={{
                                fontSize: "14px",
                                fontWeight: "600",
                                textAlign: "left",
                                color: "#1e90ff",
                                fontFamily: 'Inter',
                                marginBottom: '20px',
                            }}>
                                FACEBOOK PAGES
                           </Typography>
                        </Grid>
                    )}
                    {FbPageList && FbPageList?.map((list, index) => (
                        <Grid item md={4} key={index} sx={{ mb: 3 }}>
                            <Link to={`/view-account-post/${btoa(list.social_media_page_id)}/${btoa(list.social_media_id)}`} style={{ textDecoration: 'none' }}>
                                <Grid style={{ position: 'relative' }}>
                                    <img src={((list?.page_cover_image) ? (list?.page_cover_image) : (ListImage))} alt="" style={{ width: '100%', height: '150px', opacity: 0.8, objectFit: 'cover' }} />
                                    <Typography style={styleCss.innerposttextstyle}>
                                        {list?.page_name}
                                    </Typography>
                                    <Grid sx={{
                                        position: 'absolute', right: '13px', top: '11px', background: '#fff', padding: '10px',
                                        borderRadius: '50px',
                                        width: '30px',
                                        height: '30px',
                                        display: 'grid',
                                        placeContent: 'center',
                                        placeItems: 'center',
                                    }}>
                                        <img src={FacbookIcon} alt="" style={{ width: '10px' }} />
                                    </Grid>
                                    <Grid style={styleCss.cardfooter}>
                                        <Grid container>
                                            <Grid md={4} sx={{ textAlign: 'start' }}>
                                                <Link to="/" style={{ textDecoration: 'none', color: '#4b4f56', fontFamily: 'Inter', fontSize: '14px', fontWeight: '600' }}>List</Link>
                                            </Grid>
                                            <Grid md={4} sx={{ textAlign: 'end' }}>
                                                <Link to="/" style={{ textDecoration: 'none', color: '#4b4f56', fontFamily: 'Inter', fontSize: '14px', fontWeight: '600' }}>Disable webhook</Link>
                                            </Grid>
                                            <Grid md={4} sx={{ textAlign: 'end' }}>
                                                <Link to="/" style={{ textDecoration: 'none', color: '#4b4f56', fontFamily: 'Inter', fontSize: '14px', fontWeight: '600' }}>Get Post</Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Link>
                        </Grid>
                    ))}
                </Grid>

                

                <Grid container item columnSpacing={2} sx={{ mt: 4, mb: 4 }}>
                {InstaAccountList && InstaAccountList.length > 0 && (
                    <Grid item md={12}>
                        <Typography sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            textAlign: "left",
                            color: "#1e90ff",
                            fontFamily: 'Inter',
                            marginBottom: '20px',
                        }}>
                            INSTAGRAM PAGES
                        </Typography>
                    </Grid>
                )}
                    {InstaAccountList && InstaAccountList?.map((list, index) => (
                    <Grid item md={4} key={index}>
                        <Link to={`/view-account-post/${btoa(list.social_media_account_id)}/${btoa(list.social_media_id)}`} style={{ textDecoration: 'none' }}>
                            <Grid style={{ position: 'relative' }}>
                                <img src={Socialmainimg} alt="" style={{ width: '100%', height: '150px', opacity: 0.8 ,objectFit: 'cover' }} />
                                <Typography style={styleCss.innerposttextstyle}>
                                    {list?.screen_name}
                                </Typography>
                                <Grid sx={{
                                    position: 'absolute', right: '13px', top: '11px', background: '#fff', padding: '10px',
                                    borderRadius: '50px',
                                    width: '30px',
                                    height: '30px',
                                    display: 'grid',
                                    placeContent: 'center',
                                    placeItems: 'center',
                                }}>
                                    <img src={InstIconimg} alt="" style={{ width: '18px' }} />
                                </Grid>

                                    <Grid style={styleCss.cardfooter}>
                                        <Grid container>
                                            <Grid md={4} sx={{ textAlign: 'start' }}>
                                                <Link to="/" style={{ textDecoration: 'none', color: '#4b4f56', fontFamily: 'Inter', fontSize: '14px', fontWeight: '600' }}>List</Link>
                                            </Grid>
                                            <Grid md={4} sx={{ textAlign: 'end' }}>
                                                <Link to="/" style={{ textDecoration: 'none', color: '#4b4f56', fontFamily: 'Inter', fontSize: '14px', fontWeight: '600' }}>Disable webhook</Link>
                                            </Grid>
                                            <Grid md={4} sx={{ textAlign: 'end' }}>
                                                <Link to="/" style={{ textDecoration: 'none', color: '#4b4f56', fontFamily: 'Inter', fontSize: '14px', fontWeight: '600' }}>Get Post</Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Link>
                        </Grid>
                    ))}
                   
                </Grid>
            </Grid>
        </>
    )
}

export default ManageAccountPage