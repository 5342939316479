import Swal from "sweetalert2";

export const confirmationAlertwithButton = (confirmButtonText) => {
    return Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText
    })
}

export const confirmationAlert = (type) => {
    return Swal.fire({
        title: ((type === 1)?('Posted'):('Unable to post!')),
        text: ((type === 1)?('Your message has been posted'):('Unable to submit your post!')),
        icon: ((type === 1)?('Success'):('error')),
      })
}

export const removeVideoconfirmationAlert = (type,message) => {
    return Swal.fire({
        title: ((type === 1)?('Removed'):('Unable to remove!')),
        text: message,
        icon: ((type === 1)?('Success'):('error')),
      })
}

export const readPostAlert = () => {
    return Swal.fire({
        title: "Are you sure want to read this?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, read it!"
      });
}

export const errorCampaignAlert = (response) =>{
    return Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${response}`,
    });
}


export const confirmationAlertwithButtonForUserCreation = (text) => {
    return Swal.fire({
        title: "Are you sure?",
        text:  text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: 'Yes, save it!',
          cancelButtonText: 'No, cancel',
    })
}

export const confirmationAlertWithText = (type,text) => {
    return Swal.fire({
        title: 'Unable to post!',
        text: text,
        icon: 'error'
      })
}