import { Grid, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Close, styled, TextFiel } from "@mui/material"
import styleCss from "../Style/style"
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Edit from '@mui/icons-material/BorderColorOutlined';
import { authpostAxios } from "../Utilities/commonAxios";
import DeleteIcon from '@material-ui/icons/Delete';
import { Switch, FormControlLabel } from '@mui/material';
import { toast } from "react-toastify";
import UserAddIcon from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
import { confirmationAlertwithButtonForUserCreation, errorCampaignAlert } from '../Utilities/sweetAlert';



const ListUsers = () => {
    const navigate = useNavigate();
    const [userList, setUserList] = useState([]);
    const getUserListAPI = async () => {
        const response = await authpostAxios({
            url: `${process.env.REACT_APP_BACKENDURL}api/get-add-user`,
            data: '',
        });
        setUserList(response.data.data)
    };

    const handleEdit = async (user_id) => {
        navigate(`/edit-user/${btoa(user_id)}`)
    }
    const handleDelete = async (user_id) => {
        const confirmAlertResponse = await confirmationAlertwithButtonForUserCreation("Are you sure you want to delete the user?")
        if (confirmAlertResponse.isConfirmed) {
            const response = await authpostAxios({
                url: `${process.env.REACT_APP_BACKENDURL}api/del-user`,
                data: { user_id: user_id },
            })
            if (response.data.flag == 1) {
                toast.success(response.data.message);
                getUserListAPI()
            } else {
                await errorCampaignAlert(response.data.message)
            }
        }


    }
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
        navigate('/add_user')
    };


    const handleChangeActiveInactiveStatus = async (userId) => {
        const confirmAlertResponse = await confirmationAlertwithButtonForUserCreation("Are you sure you want to update the user status?")
        if (confirmAlertResponse.isConfirmed) {
            const response = await authpostAxios({
                url: `${process.env.REACT_APP_BACKENDURL}api/upd-user-status`,
                data: { user_id: userId },
            })
            if (response.data.flag == 1) {
                toast.success(response.data.message)
                getUserListAPI()
            } else {
                await errorCampaignAlert(response.data.message)
                return false;
            }
        }
    }

    useEffect(() => {
        getUserListAPI()
    }, []);


    return (
        <>
            <Grid sx={styleCss.commonpaddingtop}>
                <Grid container>
                    <Grid md={12} sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Button variant="contained"  startIcon={<AddIcon />} onClick={handleClickOpen}>
                            Add Users
                        </Button>
                    </Grid>
                </Grid>
                <Grid container sx={{ marginTop: '40px' }}>
                    <Grid md={12}>
                        <div style={{ width: '100%' }}>
                            <table className="styled-table">
                                <thead>
                                    <tr>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Role Type</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userList && userList.length != 0 &&
                                        userList?.map((user, index) => (
                                            <tr>
                                                <td>{(user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1))}</td>
                                                <td>{(user.last_name.charAt(0).toUpperCase() + user.last_name.slice(1))}</td>
                                                <td>{(user.role_name.charAt(0).toUpperCase() + user.role_name.slice(1))}</td>
                                                <td> <FormControlLabel
                                                    control={
                                                        <Switch  // (roles.active_inactive_status==1) ?  true : false
                                                            checked={(user.user_active_inactive_status == 1) ? true : false}
                                                            onChange={() => { handleChangeActiveInactiveStatus(user.id) }}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={user.user_active_inactive_status == 1 ? 'Active' : 'Inactive'}
                                                /></td>
                                                <td><Edit onClick={() => handleEdit(user.id)} style={{ color: '#4caf50', marginRight: '8px', cursor: 'pointer' }} />
                                                    <DeleteIcon onClick={() => handleDelete(user.id)} style={{ color: '#f44336', cursor: 'pointer' }} /></td>
                                            </tr>

                                        ))}

                                </tbody>
                            </table>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ListUsers;