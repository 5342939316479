import FacebookIcon from "../img/sub-icon-1.svg";
import TwitterIcon from "../img/sub-icon-3.png";
import LinkedInIcon from "../img/sub-icon-4.png";
import InstaIcon from "../img/sub-icon-2.png";
import YouTubeIcon from "../img/sub-icon-5.png";


export const socialMediaLists = {
  1: {
    url: "fb",
    icon: FacebookIcon,
  },
  2: {
    url: "twitter",
    icon: TwitterIcon,
  },
  3: {
    url: "linkedin",
    icon: LinkedInIcon,
  },
  4: {
    url: "insta",
    icon: InstaIcon,
  },
  5: {
    url: "youtube",
    icon: YouTubeIcon,
  }
};


 
