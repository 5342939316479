import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material"
import Campaign1 from "../img/facebook-campaign.png"
import styleCss from "../Style/style"
import { PieChart } from '@mui/x-charts/PieChart';
import LocationIcon1 from '../img/camplocation-icon1.png'
import LocationIcon2 from '../img/camplocation-icon2.png'
import LocationIcon3 from '../img/camplocation-icon3.png'
import WhatsupHeadicon from '../img/whatsup-headicon.png'
import MailHeadicon from '../img/mail-headicon.png'
import MessageHeadicon from '../img/message-headicon.png'
import SmartToyIcon from '@mui/icons-material/SmartToy';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { authpostAxios } from "../Utilities/commonAxios";
import moment from 'moment';


const fbAdsPosting = 0;
const pieParams = { height: 150, margin: { right: 5 } };
const palette1 = ['#1877F2', '#d9d9d9'];
const palette2 = ['#25D366', '#d9d9d9'];
const palette3 = ['#004f9f', '#d9d9d9'];
const palette4 = ['#4dacf4', '#d9d9d9'];
const palette5 = ['#167964', '#d9d9d9'];
const palette6 = ['#77943e', '#d9d9d9'];


const MAX = 10000;
const MIN = 0;
const marks = [
    {
        value: MIN,
        label: '',
    },
    {
        value: MAX,
        label: '',
    },
];

function PaiseToRupees(paise) {
    return paise / 100;
}
const BudgetlistView = () => {

    const { enbudgetid } = useParams()
    const budgetid = atob(enbudgetid);

    console.log(budgetid);

    const [detaillisting, setDetaillisting] = useState([]);

    const getdetaillistingFromAPI = async (budgetid) => {
        try {
            const response = await authpostAxios({
                url: `${process.env.REACT_APP_BACKENDURL}api/get-budget-details-view`,
                data: { budget_tracker_id: budgetid }
            });
            setDetaillisting(response.data?.data || []);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };

    useEffect(() => {
        getdetaillistingFromAPI(budgetid)
        console.log(detaillisting);
    }, []);

    const boundConvertIntoValues = (value) =>{
        if (value >= 1_000_000_000) {
            return (value / 1_000_000_000).toFixed(1) + 'B'; 
          } else if (value >= 1_000_000) {
            return (value / 1_000_000).toFixed(1) + 'M'; 
          } else if (value >= 1_000) {
            return (value / 1_000).toFixed(1) + 'K'; 
          } else {
            return value.toString(); 
          }
    }

        function PaiseToRupees(paise) {
        return paise / 100;
        }


    return (
        <>
            {detaillisting && detaillisting.length != 0 &&
                detaillisting?.map((listing, index) => (
                    <Grid style={styleCss.commonpaddingtop}>

                        <Grid container item columnSpacing={2} sx={{ mb: 2 }}>
                            {/* {fbAdsPosting == 0 && ( */}
                            {listing?.facebook_ad_posting_id && (
                                <Grid item md={3} sx={{ mb: 3 }}>

                                    <Grid sx={{ ...styleCss.CampaignBox, height: '750px' }}>
                                        <Typography sx={{
                                            fontFamily: 'Inter',
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                            fontStretch: 'normal',
                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                            letterSpacing: 'normal',
                                            textAlign: 'left',
                                            color: '#555',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}>Campaign Objective</Typography>
                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '1px', }}>
                                            Traffic
                                        </Typography>
                                        <Typography sx={{
                                            fontFamily: 'Inter',
                                            fontSize: '12px',
                                            fontWeight: 'normal',
                                            fontStretch: 'normal',
                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                            letterSpacing: 'normal',
                                            textAlign: 'left',
                                            color: '#000',
                                        }}>
                                            Drive visitors to your website or marketing landing pages.
                                        </Typography>
                                        <Grid sx={{
                                            padding: '12px 20px',
                                            backgroundColor: '#1877F2',
                                            marginTop: '15px'
                                        }}>
                                            <Typography sx={{
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                fontWeight: 500,
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#fff',
                                            }}><img src={Campaign1} style={{
                                                verticalAlign: 'middle', marginRight: '10px',
                                            }} /> {listing?.facebook_ad_final_name}</Typography>
                                        </Grid>

                                        <Grid sx={{ textAlign: 'center', marginTop: '15px', marginBottom: '12px' }}>
                                            <PieChart
                                                colors={palette1}
                                                series={[{ data: [{ value: listing?.users_lower_bound }, { value: listing?.users_upper_bound },] }]}
                                                {...pieParams}
                                            />
                                        </Grid>
                                        <Grid container>
                                            <Grid md={6} xs={6}>
                                                <Typography sx={{ fontSize: '12px', fontWeight: 'normal', color: '#000', textAlign: 'center', fontFamily: 'Inter' }}>
                                                    Users Lower Bound
                                                </Typography>
                                                <Typography sx={{
                                                    fontSize: '18px',
                                                    fontWeight: 'bold',
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'center',
                                                    color: '#1877F2',
                                                    fontFamily: 'Inter',
                                                    marginTop: '5px'
                                                }}>
                                                    {boundConvertIntoValues(listing?.users_lower_bound)}
                                                </Typography>
                                            </Grid>
                                            <Grid md={6} xs={6}>
                                                <Typography sx={{ fontSize: '12px', fontWeight: 'normal', color: '#000', textAlign: 'center', fontFamily: 'Inter' }}>
                                                    Users Upper Bound
                                                </Typography>
                                                <Typography sx={{
                                                    fontSize: '18px',
                                                    fontWeight: 'bold',
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'center',
                                                    color: '#1877F2',
                                                    fontFamily: 'Inter',
                                                    marginTop: '5px'
                                                }}>
                                                    {boundConvertIntoValues(listing?.users_upper_bound)}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid md={12} sx={{ borderBottom: '1px solid #d6d6d0', marginTop: '15px', marginBottom: '15px' }}></Grid>
                                        <Grid container>
                                            <Grid md={6} xs={6}>
                                                <Grid sx={{
                                                    padding: '10px',
                                                    borderRadius: '30px',
                                                    backgroundColor: '#1877F2',
                                                    width: '40px',
                                                    height: '40px',
                                                    display: 'grid',
                                                    placeContent: 'center',
                                                    marginBottom: '8px'
                                                }}>
                                                    <img src={LocationIcon1} style={{ width: '19px' }} />
                                                </Grid>
                                                <Typography sx={{
                                                    fontSize: '15px',
                                                    fontWeight: 'normal',
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'left',
                                                    color: '#555',
                                                    fontFamily: 'Inter',
                                                }}>Location</Typography>
                                                <Typography sx={{
                                                    fontFamily: 'Inter',
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    textAlign: 'left',
                                                    color: '#000',
                                                }}>
                                                    
                                                    {listing?.fb_ads_reach_location &&
                                                        JSON.parse(listing.fb_ads_reach_location).map((location, index) => (
                                                            <div key={index}>
                                                                {location?.f_name}
                                                            </div>
                                                        ))
                                                    }

                                                </Typography>
                                            </Grid>
                                            <Grid md={3} xs={6} sx={{ textAlign: 'center' }}>
                                                <Grid sx={{
                                                    padding: '10px',
                                                    borderRadius: '30px',
                                                    backgroundColor: '#1877F2',
                                                    width: '40px',
                                                    height: '40px',
                                                    display: 'grid',
                                                    placeContent: 'center',
                                                    marginBottom: '8px'
                                                }}>
                                                    <img src={LocationIcon2} style={{ width: '12px' }} />
                                                </Grid>
                                                <Typography sx={{
                                                    fontSize: '15px',
                                                    fontWeight: 'normal',
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'left',
                                                    color: '#555',
                                                    fontFamily: 'Inter',
                                                }}>Age</Typography>
                                                <Typography sx={{
                                                    fontFamily: 'Inter',
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    textAlign: 'left',
                                                    color: '#000',
                                                }}>
                                                    {[`${listing?.fb_targeting.age_min} - ${listing?.fb_targeting.age_max}`]}
                                                    {/* {age_min}-{age_max} */}
                                                </Typography>
                                            </Grid>
                                            <Grid md={3} xs={6}>
                                                <Grid sx={{
                                                    padding: '10px',
                                                    borderRadius: '30px',
                                                    backgroundColor: '#1877F2',
                                                    width: '40px',
                                                    height: '40px',
                                                    display: 'grid',
                                                    placeContent: 'center',
                                                    marginBottom: '8px'
                                                }}>
                                                    <img src={LocationIcon3} style={{ width: '19px' }} />
                                                </Grid>
                                                <Typography sx={{
                                                    fontSize: '15px',
                                                    fontWeight: 'normal',
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'left',
                                                    color: '#555',
                                                    fontFamily: 'Inter',
                                                }}>Gender</Typography>
                                                <Typography sx={{
                                                    fontFamily: 'Inter',
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    textAlign: 'left',
                                                    color: '#000',
                                                }}>
                                                    {listing?.fb_ads_reach_gender_type.toUpperCase()}
                                                </Typography>
                                            </Grid>
                                            <Grid md={6} xs={6} sx={{ marginTop: '20px' }}>
                                                <Grid sx={{
                                                    padding: '10px',
                                                    borderRadius: '30px',
                                                    backgroundColor: '#1877F2',
                                                    width: '40px',
                                                    height: '40px',
                                                    display: 'grid',
                                                    placeContent: 'center',
                                                    marginBottom: '8px'
                                                }}>
                                                    <img src={LocationIcon1} style={{ width: '19px' }} />
                                                </Grid>
                                                <Typography sx={{
                                                    fontSize: '15px',
                                                    fontWeight: 'normal',
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'left',
                                                    color: '#555',
                                                    fontFamily: 'Inter',
                                                }}>Date</Typography>
                                                <Typography sx={{
                                                    fontFamily: 'Inter',
                                                    fontSize: '12px',
                                                    fontWeight: 'bold',
                                                    textAlign: 'left',
                                                    color: '#000',
                                                }}>
                                                    {moment(listing?.ad_startdate).format('YYYY-MM-DD HH:mm:ss')}<br />
                                                    {moment(listing?.ad_enddate).format('YYYY-MM-DD HH:mm:ss')}
                                                    {/* {formatDate(ad_startdate)} <br />
                        {formatDate(ad_enddate)} */}
                                                </Typography>
                                            </Grid>


                                            <Grid md={6} xs={6} sx={{ marginTop: '20px' }}>
                                                <Grid sx={{
                                                    padding: '10px',
                                                    borderRadius: '30px',
                                                    backgroundColor: '#1877F2',
                                                    width: '40px',
                                                    height: '40px',
                                                    display: 'grid',
                                                    placeContent: 'center',
                                                    marginBottom: '8px'
                                                }}>
                                                    <img src={LocationIcon2} style={{ width: '12px' }} />
                                                </Grid>
                                                <Typography sx={{
                                                    fontSize: '15px',
                                                    fontWeight: 'normal',
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'left',
                                                    color: '#555',
                                                    fontFamily: 'Inter',
                                                }}>Budget</Typography>
                                                <Typography sx={{
                                                    fontFamily: 'Inter',
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    textAlign: 'left',
                                                    color: '#000',
                                                }}>
                                                    Rs. {PaiseToRupees(listing?.ad_budget)}
                                                 
                                                </Typography>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                </Grid>
                            )}
                            <>
                                {listing.is_whatsapp_checked && listing.is_whatsapp_checked == 1 && (
                                    <Grid item md={3} sx={{ mb: 3 }}>
                                        <Grid sx={{ ...styleCss.CampaignBox, height: '750px' }}>
                                            <Typography sx={{
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                fontWeight: 'normal',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#555',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}>Campaign Objective</Typography>
                                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '1px', }}>
                                                Traffic
                                            </Typography>
                                            <Typography sx={{
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                fontWeight: 'normal',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#000',
                                            }}>
                                                Drive visitors to your website or marketing landing pages.
                                            </Typography>
                                            <Grid sx={{
                                                padding: '12px 20px',
                                                backgroundColor: '#25D366',
                                                marginTop: '15px'
                                            }}>
                                                <Typography sx={{
                                                    fontFamily: 'Inter',
                                                    fontSize: '14px',
                                                    fontWeight: 500,
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'left',
                                                    color: '#fff',
                                                }}><img src={WhatsupHeadicon} style={{
                                                    verticalAlign: 'middle', marginRight: '10px',
                                                }} />
                                                    {/* {formikValues?.wb_campagin_name} */}
                                                    {listing?.whatsapp_campaign_name}
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ textAlign: 'center', marginTop: '15px', marginBottom: '12px' }}>
                                                <PieChart
                                                    colors={palette2}
                                                    // series={[{ data: [{ value: formikValues.whatsapp_budget }, { value: parseFloat((formikValues.whatsapp_budget / 0.85).toFixed(0)) }] }]}
                                                    series={[{ data: [{ value: listing?.whatsapp_budget_amount }, { value: listing?.approximate_whatsapp_send_message },] }]}
                                                    {...pieParams}
                                                />
                                            </Grid>
                                            <Grid container>
                                                <Grid md={6} xs={6}>
                                                    <Typography sx={{ fontSize: '12px', fontWeight: 'normal', color: '#000', textAlign: 'center', fontFamily: 'Inter' }}>
                                                        Cost Per Message
                                                    </Typography>
                                                    <Typography sx={{
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        fontStretch: 'normal',
                                                        fontStyle: 'normal',
                                                        lineHeight: 'normal',
                                                        letterSpacing: 'normal',
                                                        textAlign: 'center',
                                                        color: '#25D366',
                                                        fontFamily: 'Inter',
                                                        marginTop: '5px'
                                                    }}>
                                                        {listing?.cost_for_whatsapp}
                                                    </Typography>
                                                </Grid>
                                                <Grid md={6} xs={6}>
                                                    <Typography sx={{ fontSize: '12px', fontWeight: 'normal', color: '#000', textAlign: 'center', fontFamily: 'Inter' }}>
                                                        Appro. send whatsapp :
                                                    </Typography>
                                                    <Typography sx={{
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        fontStretch: 'normal',
                                                        fontStyle: 'normal',
                                                        lineHeight: 'normal',
                                                        letterSpacing: 'normal',
                                                        textAlign: 'center',
                                                        color: '#25D366',
                                                        fontFamily: 'Inter',
                                                        marginTop: '5px'
                                                    }}>
                                                        {/* {(formikValues.whatsapp_budget / 0.85).toFixed(0)} */}
                                                        {listing?.approximate_whatsapp_send_message}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid md={12} sx={{ borderBottom: '1px solid #d6d6d0', marginTop: '15px', marginBottom: '15px' }}></Grid>
                                            <Grid container>
                                                <Grid md={6} xs={6} sx={{ marginTop: '20px' }}>
                                                    <Grid sx={{
                                                        padding: '10px',
                                                        borderRadius: '30px',
                                                        backgroundColor: '#25D366',
                                                        width: '40px',
                                                        height: '40px',
                                                        display: 'grid',
                                                        placeContent: 'center',
                                                        marginBottom: '8px'
                                                    }}>
                                                        <img src={LocationIcon2} style={{ width: '12px' }} />
                                                    </Grid>
                                                    <Typography sx={{
                                                        fontSize: '15',
                                                        fontWeight: 'normal',
                                                        fontStretch: 'normal',
                                                        fontStyle: 'normal',
                                                        lineHeight: 'normal',
                                                        letterSpacing: 'normal',
                                                        textAlign: 'left',
                                                        color: '#555',
                                                        fontFamily: 'Inter',
                                                    }}>Budget</Typography>
                                                    <Typography sx={{
                                                        fontFamily: 'Inter',
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        textAlign: 'left',
                                                        color: '#000',
                                                        marginBottom: '10px',
                                                    }}>
                                                        {/* Rs. {formikValues.whatsapp_budget} */}
                                                        {listing?.whatsapp_budget_amount}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </>
                            <>
                                {listing.is_whatsapp_checked && listing.is_mail_checked == 1 && (
                                    <Grid item md={3} sx={{ mb: 3 }}>
                                        <Grid sx={{ ...styleCss.CampaignBox, height: '750px' }}>
                                            <Typography sx={{
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                fontWeight: 'normal',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#555',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}>Campaign Objective</Typography>
                                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '1px', }}>
                                                Traffic
                                            </Typography>
                                            <Typography sx={{
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                fontWeight: 'normal',
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#000',
                                            }}>
                                                Drive visitors to your website or marketing landing pages.
                                            </Typography>
                                            <Grid sx={{
                                                padding: '12px 20px',
                                                backgroundColor: '#004f9f',
                                                marginTop: '15px'
                                            }}>
                                                <Typography sx={{
                                                    fontFamily: 'Inter',
                                                    fontSize: '14px',
                                                    fontWeight: 500,
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'left',
                                                    color: '#fff',
                                                }}><img src={MailHeadicon} style={{
                                                    verticalAlign: 'middle', marginRight: '10px',
                                                }} />
                                                    {listing?.mail_campaign_name}
                                                    {/* {formikValues?.mail_campagin_name} */}
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ textAlign: 'center', marginTop: '15px', marginBottom: '12px' }}>
                                                {/* <PieChart
                                    colors={palette}
                                    series={[{
                                        data: [
                                            { name: 'Total Mail', value: formikValues.mail_budget },
                                            { name: 'Mail Budget', value: parseFloat((formikValues.mail_budget / 0.20).toFixed(0)) }
                                        ]
                                    }]}
                                    {...pieParams}
                                /> */}
                                                <PieChart
                                                    colors={palette3}
                                                    // series={[{ data: [{ value: formikValues.whatsapp_budget }, { value: parseFloat((formikValues.whatsapp_budget / 0.85).toFixed(0)) }] }]}
                                                    series={[{ data: [{ value: listing?.mail_budget_amount }, { value: listing?.approximate_mail_send_message },] }]}
                                                    {...pieParams}
                                                />
                                            </Grid>
                                            <Grid container>
                                                <Grid md={6} xs={6}>
                                                    <Typography sx={{ fontSize: '12px', fontWeight: 'normal', color: '#000', textAlign: 'center', fontFamily: 'Inter' }}>
                                                        Cost Per Mail
                                                    </Typography>
                                                    <Typography sx={{
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        fontStretch: 'normal',
                                                        fontStyle: 'normal',
                                                        lineHeight: 'normal',
                                                        letterSpacing: 'normal',
                                                        textAlign: 'center',
                                                        color: '#004f9f',
                                                        fontFamily: 'Inter',
                                                        marginTop: '5px'
                                                    }}>
                                                        {listing?.cost_for_mail}
                                                    </Typography>
                                                </Grid>
                                                <Grid md={6} xs={6}>
                                                    <Typography sx={{ fontSize: '12px', fontWeight: 'normal', color: '#000', textAlign: 'center', fontFamily: 'Inter' }}>
                                                        Appro. send Mail :
                                                    </Typography>
                                                    <Typography sx={{
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        fontStretch: 'normal',
                                                        fontStyle: 'normal',
                                                        lineHeight: 'normal',
                                                        letterSpacing: 'normal',
                                                        textAlign: 'center',
                                                        color: '#004f9f',
                                                        fontFamily: 'Inter',
                                                        marginTop: '5px'
                                                    }}>
                                                        {/* {(formikValues.mail_budget / 0.20).toFixed(0)} */}
                                                        {listing?.approximate_mail_send_message}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid md={12} sx={{ borderBottom: '1px solid #d6d6d0', marginTop: '15px', marginBottom: '15px' }}></Grid>
                                            <Grid container>

                                                <Grid md={6} xs={6} sx={{ marginTop: '20px' }}>
                                                    <Grid sx={{
                                                        padding: '10px',
                                                        borderRadius: '30px',
                                                        backgroundColor: '#004f9f',
                                                        width: '40px',
                                                        height: '40px',
                                                        display: 'grid',
                                                        placeContent: 'center',
                                                        marginBottom: '8px'
                                                    }}>
                                                        <img src={LocationIcon2} style={{ width: '12px' }} />
                                                    </Grid>
                                                    <Typography sx={{
                                                        fontSize: '15',
                                                        fontWeight: 'normal',
                                                        fontStretch: 'normal',
                                                        fontStyle: 'normal',
                                                        lineHeight: 'normal',
                                                        letterSpacing: 'normal',
                                                        textAlign: 'left',
                                                        color: '#555',
                                                        fontFamily: 'Inter',
                                                    }}>Budget</Typography>
                                                    <Typography sx={{
                                                        fontFamily: 'Inter',
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        textAlign: 'left',
                                                        color: '#000',
                                                        marginBottom: '10px'
                                                    }}>
                                                        {listing?.mail_budget_amount}
                                                        {/* {formikValues.mail_budget} */}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </>
                            {listing.is_whatsapp_checked && listing.is_sms_checked == 1 && (
                                <Grid item md={3} sx={{ mb: 3 }}>
                                    <Grid sx={{ ...styleCss.CampaignBox, height: '750px' }}>
                                        <Typography sx={{
                                            fontFamily: 'Inter',
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                            fontStretch: 'normal',
                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                            letterSpacing: 'normal',
                                            textAlign: 'left',
                                            color: '#555',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}>Campaign Objective</Typography>
                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '1px', }}>
                                            Traffic
                                        </Typography>
                                        <Typography sx={{
                                            fontFamily: 'Inter',
                                            fontSize: '12px',
                                            fontWeight: 'normal',
                                            fontStretch: 'normal',
                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                            letterSpacing: 'normal',
                                            textAlign: 'left',
                                            color: '#000',
                                        }}>
                                            Drive visitors to your website or marketing landing pages.
                                        </Typography>
                                        <Grid sx={{
                                            padding: '12px 20px',
                                            backgroundColor: '#4dacf4',
                                            marginTop: '15px'
                                        }}>
                                            <Typography sx={{
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                fontWeight: 500,
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#fff',
                                            }}><img src={MessageHeadicon} style={{
                                                verticalAlign: 'middle', marginRight: '10px',
                                            }} />
                                                {/* {formikValues?.sms_campagin_name} */}
                                                {listing?.sms_campaign_name}
                                            </Typography>
                                        </Grid>
                                        <Grid sx={{ textAlign: 'center', marginTop: '15px', marginBottom: '12px' }}>
                                            {/* <PieChart
                        colors={palette4}
                        series={[{ 
                            data: [
                                { name: 'Total Sms', value: formikValues.sms_budget }, 
                                { name: 'Sms Budget', value: parseFloat((formikValues.sms_budget / 0.15).toFixed(0)) }
                            ] 
                        }]}
                        {...pieParams}
                    /> */}
                                            <PieChart
                                                colors={palette4}
                                                // series={[{ data: [{ value: formikValues.whatsapp_budget }, { value: parseFloat((formikValues.whatsapp_budget / 0.85).toFixed(0)) }] }]}
                                                series={[{ data: [{ value: listing?.sms_budget_amount }, { value: listing?.approximate_sms_send_message },] }]}
                                                {...pieParams}
                                            />
                                        </Grid>
                                        <Grid container>
                                            <Grid md={6} xs={6}>
                                                <Typography sx={{ fontSize: '12px', fontWeight: 'normal', color: '#000', textAlign: 'center', fontFamily: 'Inter' }}>
                                                    Cost Per Sms
                                                </Typography>
                                                <Typography sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'center',
                                                    color: '#4dacf4',
                                                    fontFamily: 'Inter',
                                                    marginTop: '5px'
                                                }}>
                                                    {listing?.cost_for_sms}
                                                </Typography>
                                            </Grid>
                                            <Grid md={6} xs={6}>
                                                <Typography sx={{ fontSize: '12px', fontWeight: 'normal', color: '#000', textAlign: 'center', fontFamily: 'Inter' }}>
                                                    Appro. Send Sms
                                                </Typography>
                                                <Typography sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'center',
                                                    color: '#4dacf4',
                                                    fontFamily: 'Inter',
                                                    marginTop: '5px'
                                                }}>
                                                    {/* {(formikValues.sms_budget / 0.15).toFixed(0)} */}
                                                    {listing?.approximate_sms_send_message}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid md={12} sx={{ borderBottom: '1px solid #d6d6d0', marginTop: '15px', marginBottom: '15px' }}></Grid>
                                        <Grid container>

                                            <Grid md={6} xs={6} sx={{ marginTop: '20px' }}>
                                                <Grid sx={{
                                                    padding: '10px',
                                                    borderRadius: '30px',
                                                    backgroundColor: '#4dacf4',
                                                    width: '40px',
                                                    height: '40px',
                                                    display: 'grid',
                                                    placeContent: 'center',
                                                    marginBottom: '8px'
                                                }}>
                                                    <img src={LocationIcon2} style={{ width: '12px' }} />
                                                </Grid>
                                                <Typography sx={{
                                                    fontSize: '15',
                                                    fontWeight: 'normal',
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'left',
                                                    color: '#555',
                                                    fontFamily: 'Inter',
                                                }}>Budget</Typography>
                                                <Typography sx={{
                                                    fontFamily: 'Inter',
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    textAlign: 'left',
                                                    color: '#000',
                                                    marginBottom: '8px'
                                                }}>
                                                    {listing?.sms_budget_amount}
                                                    {/* {formikValues.sms_budget} */}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}

                            {listing.is_whatsapp_checked && listing.is_voicebot_checked == 1 && (

                                <Grid item md={3} sx={{ mb: 3 }}>
                                    <Grid sx={{ ...styleCss.CampaignBox, height: '750px' }}>
                                        <Typography sx={{
                                            fontFamily: 'Inter',
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                            fontStretch: 'normal',
                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                            letterSpacing: 'normal',
                                            textAlign: 'left',
                                            color: '#555',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}>Campaign Objective</Typography>
                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '1px', }}>
                                            Traffic
                                        </Typography>
                                        <Typography sx={{
                                            fontFamily: 'Inter',
                                            fontSize: '12px',
                                            fontWeight: 'normal',
                                            fontStretch: 'normal',
                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                            letterSpacing: 'normal',
                                            textAlign: 'left',
                                            color: '#000',
                                        }}>
                                            Drive visitors to your website or marketing landing pages.
                                        </Typography>
                                        <Grid sx={{
                                            padding: '12px 20px',
                                            backgroundColor: '#167964',
                                            marginTop: '15px'
                                        }}>
                                            <Typography sx={{
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                fontWeight: 500,
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#fff',
                                            }}
                                            ><SmartToyIcon style={{ fontSize: 21, verticalAlign: 'middle', marginRight: '10px' }} />
                                                {/* {formikValues?.voicebot_campagin_name} */}
                                                {listing?.voicebot_campaign_name}
                                            </Typography>
                                        </Grid>
                                        <Grid sx={{ textAlign: 'center', marginTop: '15px', marginBottom: '12px' }}>
                                            {/* <PieChart
                                        colors={palette5}
                                        series={[{
                                            data: [
                                                { name: 'Total Voice bot', value: formikValues.voice_bot_budget },
                                                { name: 'Voice bot Budget', value: parseFloat((formikValues.voice_bot_budget / 2.25).toFixed(0)) }
                                            ]
                                        }]}
                                        {...pieParams}
                                    /> */}
                                            <PieChart
                                                colors={palette5}
                                                series={[{ data: [{ value: listing?.voicebot_budget_amount }, { value: listing?.approximate_voicebot_send_message },] }]}
                                                {...pieParams}
                                            />
                                        </Grid>
                                        <Grid container>
                                            <Grid md={6} xs={6}>
                                                <Typography sx={{ fontSize: '12px', fontWeight: 'normal', color: '#000', textAlign: 'center', fontFamily: 'Inter' }}>
                                                    Cost Per Voice Bot
                                                </Typography>
                                                <Typography sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'center',
                                                    color: '#167964',
                                                    fontFamily: 'Inter',
                                                    marginTop: '5px'
                                                }}>
                                                    {listing?.cost_for_voicebot}
                                                </Typography>
                                            </Grid>
                                            <Grid md={6} xs={6}>
                                                <Typography sx={{ fontSize: '12px', fontWeight: 'normal', color: '#000', textAlign: 'center', fontFamily: 'Inter' }}>
                                                    Appro. calling voice bot :
                                                </Typography>
                                                <Typography sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'center',
                                                    color: '#167964',
                                                    fontFamily: 'Inter',
                                                    marginTop: '5px'
                                                }}>
                                                    {/* {(formikValues.voice_bot_budget / 2.25).toFixed(0)} */}
                                                    {listing?.approximate_voicebot_send_message}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid md={12} sx={{ borderBottom: '1px solid #d6d6d0', marginTop: '15px', marginBottom: '15px' }}></Grid>
                                        <Grid container>

                                            <Grid md={6} xs={6} sx={{ marginTop: '20px' }}>
                                                <Grid sx={{
                                                    padding: '10px',
                                                    borderRadius: '30px',
                                                    backgroundColor: '#167964',
                                                    width: '40px',
                                                    height: '40px',
                                                    display: 'grid',
                                                    placeContent: 'center',
                                                    marginBottom: '8px'
                                                }}>
                                                    <img src={LocationIcon2} style={{ width: '12px' }} />
                                                </Grid>
                                                <Typography sx={{
                                                    fontSize: '15',
                                                    fontWeight: 'normal',
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'left',
                                                    color: '#555',
                                                    fontFamily: 'Inter',
                                                }}>Budget</Typography>
                                                <Typography sx={{
                                                    fontFamily: 'Inter',
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    textAlign: 'left',
                                                    color: '#000',
                                                    marginBottom: '10px'
                                                }}>
                                                    {listing?.voicebot_budget_amount}
                                                    {/* {formikValues.mail_budget} */}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            {listing.is_whatsapp_checked && listing.is_ivr_checked == 1 && (
                                <Grid item md={3}>
                                    <Grid sx={{ ...styleCss.CampaignBox, height: '750px' }}>
                                        <Typography sx={{
                                            fontFamily: 'Inter',
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                            fontStretch: 'normal',
                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                            letterSpacing: 'normal',
                                            textAlign: 'left',
                                            color: '#555',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}>Campaign Objective</Typography>
                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '1px', }}>
                                            Traffic
                                        </Typography>
                                        <Typography sx={{
                                            fontFamily: 'Inter',
                                            fontSize: '12px',
                                            fontWeight: 'normal',
                                            fontStretch: 'normal',
                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                            letterSpacing: 'normal',
                                            textAlign: 'left',
                                            color: '#000',
                                        }}>
                                            Drive visitors to your website or marketing landing pages.
                                        </Typography>
                                        <Grid sx={{
                                            padding: '12px 20px',
                                            backgroundColor: '#77943e',
                                            marginTop: '15px'
                                        }}>
                                            <Typography sx={{
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                fontWeight: 500,
                                                fontStretch: 'normal',
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                color: '#fff',
                                            }}> <VoiceChatIcon style={{ fontSize: 21, verticalAlign: 'middle', marginRight: '10px' }} />
                                                {listing?.ivr_campaign_name}
                                                {/* {formikValues?.ivr_campagin_name} */}
                                            </Typography>
                                        </Grid>
                                        <Grid sx={{ textAlign: 'center', marginTop: '15px', marginBottom: '12px' }}>
                                            {/* <PieChart
                                    colors={palette}
                                    series={[{
                                        data: [
                                            { name: 'Total ivr', value: formikValues.voice_bot_budget },
                                            { name: 'ivr Budget', value: parseFloat((formikValues.voice_bot_budget / 2.50).toFixed(0)) }
                                        ]
                                    }]}
                                    {...pieParams}
                                /> */}
                                            <PieChart
                                                colors={palette6}
                                                // series={[{ data: [{ value: formikValues.whatsapp_budget }, { value: parseFloat((formikValues.whatsapp_budget / 0.85).toFixed(0)) }] }]}
                                                series={[{ data: [{ value: listing?.ivr_budget_amount }, { value: listing?.approximate_ivr_send_message },] }]}
                                                {...pieParams}
                                            />
                                        </Grid>
                                        <Grid container>
                                            <Grid md={6} xs={6}>
                                                <Typography sx={{ fontSize: '12px', fontWeight: 'normal', color: '#000', textAlign: 'center', fontFamily: 'Inter' }}>
                                                    Cost Per Ivr
                                                </Typography>
                                                <Typography sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'center',
                                                    color: '#77943e',
                                                    fontFamily: 'Inter',
                                                    marginTop: '5px'
                                                }}>
                                                    {listing?.cost_for_ivr}
                                                </Typography>
                                            </Grid>
                                            <Grid md={6} xs={6}>
                                                <Typography sx={{ fontSize: '12px', fontWeight: 'normal', color: '#000', textAlign: 'center', fontFamily: 'Inter' }}>
                                                    Appro. calling ivr :
                                                </Typography>
                                                <Typography sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'center',
                                                    color: '#77943e',
                                                    fontFamily: 'Inter',
                                                    marginTop: '5px'
                                                }}>
                                                    {/* {(formikValues.ivr_budget / 2.50).toFixed(0)} */}
                                                    {listing?.approximate_ivr_send_message}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid md={12} sx={{ borderBottom: '1px solid #d6d6d0', marginTop: '15px', marginBottom: '15px' }}></Grid>
                                        <Grid container>

                                            <Grid md={6} xs={6} sx={{ marginTop: '20px' }}>
                                                <Grid sx={{
                                                    padding: '10px',
                                                    borderRadius: '30px',
                                                    backgroundColor: '#77943e',
                                                    width: '40px',
                                                    height: '40px',
                                                    display: 'grid',
                                                    placeContent: 'center',
                                                    marginBottom: '8px'
                                                }}>
                                                    <img src={LocationIcon2} style={{ width: '12px' }} />
                                                </Grid>
                                                <Typography sx={{
                                                    fontSize: '15',
                                                    fontWeight: 'normal',
                                                    fontStretch: 'normal',
                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    letterSpacing: 'normal',
                                                    textAlign: 'left',
                                                    color: '#555',
                                                    fontFamily: 'Inter',
                                                }}>Budget</Typography>
                                                <Typography sx={{
                                                    fontFamily: 'Inter',
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    textAlign: 'left',
                                                    color: '#000',
                                                    marginBottom: '10px'
                                                }}>
                                                    {listing?.ivr_budget_amount}
                                                    {/* {formikValues.ivr_budget} */}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>

                    </Grid>
                ))}
        </>
    )
}

export default BudgetlistView