import React, { useState,useEffect  } from 'react';
import {
    Grid, Tooltip,Box, Typography,TextField, Table,Checkbox, TableBody, Button, TableCell, TableContainer, TableHead, TableRow, Paper, InputLabel, MenuItem, FormControl,FormControlLabel, Select,
} from "@mui/material";
import styleCss from "../Style/style";
import Listimg from '../img/list-img.jpg';
import { Link } from 'react-router-dom';
import Socialicon1 from '../img/sub-icon-1.svg';
import Likeimg1 from '../img/social-icon-2.png';
import Likeimg2 from '../img/sub-icon-4.png';
import Likeimg3 from '../img/sub-icon-3.png';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Socialselect from '../SocialPost/socialSelect';
import { Formik, Form, Field,useFormik,ErrorMessage } from 'formik';
 import * as Yup from 'yup';
import { authpostAxios } from "../Utilities/commonAxios";
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { CropLandscapeOutlined } from '@mui/icons-material';
import Stack from '@mui/material/Stack';


const initialValues = {
    fb_checkbox: false,
    fb_buget: '',
    fb_goal: '',
    lin_checkbox:false,
    lin_buget:'',
    lin_goal:'',
  };
/*
  const validationSchema = Yup.object({
    fb_buget: Yup.string().required('Please select your goal'),
});
*/

const validationSchema = Yup.object({
  fb_buget: Yup.number()
  .required('Facebook budget is required')
  .min(90, 'The amount must be greater than or equal to 90'),
  fb_checkbox: Yup.boolean().required('Facebook checkbox is required'),
  fb_goal: Yup.string().required('Facebook goal is required'),
  lin_checkbox: Yup.boolean().required('Linkedin checkbox is required'),
  //lin_buget: Yup.number().required('Linkedin budget is required'),
  lin_goal: Yup.string().required('Linkedin goal is required'),
});

  


const Adsummary = () => {

    const [SummaryData, setSummaryData] = useState([]);
    const [Summarycount, setSummarycount] = useState([]);

        const submitSocialForm = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
          if (!submitSocialForm.dirty) {
          } else {
            let formData = {
              fb_checkbox: values.fb_checkbox,
              fb_buget:values.fb_buget,
              fb_goal:values.fb_goal,
              lin_checkbox:values.lin_checkbox,
              lin_buget:values.lin_buget,
              lin_goal:values.lin_goal
            };
            try {
               
              let response = await authpostAxios({
                url: `${process.env.REACT_APP_BACKENDURL}api/get-fb-summary`,
                data: formData,
              });
              if (response.data.flag === 1) {
                 const summary_Data = response.data;
                 //console.log(summary_Data);

                 const socialMediaSummaryCount = Object.keys(summary_Data).filter(
                  (key) => key === 'facebookSummary' || key === 'linkedinSummary'
                ).length;

                console.log(socialMediaSummaryCount);


                 //const fbSummaryArray = [summary_Data?.facebookSummary?.data?.accoutCenterReaced, summary_Data?.facebookSummary?.data?.pageLike];
                  setSummarycount(socialMediaSummaryCount);
                  setSummaryData(summary_Data);
                //toast.success(response.data.message);
              } else {
                //toast.error(response.data.message);
              }
       
              //resetForm();
            } catch (error) {
              console.error('Error submitting form:', error);
              // Handle error accordingly, e.g., show error toast
             // toast.error('Failed to submit social form.');
            }
          }
        },
      });
      /*

      useEffect(() => {
        if (SummaryData.length > 0) {
          console.log(SummaryData);
          const listItems = SummaryData.map((value, index) => (
            console.log(value)
          ));
         
        }
      }, [SummaryData]);

    */


    const pieParams = { height: 200, margin: { right: 5 } };
    const palette = ['red', 'blue', 'green'];

      useEffect(() => {
        if (Summarycount > 0) { 
          console.log(SummaryData.linkedinSummary.data);
          //console.log(SummaryData);
        }
      }, [Summarycount]);


     
      // Using map to render a list of JSX elements
    

      
    return (
        <>

              <Grid style={styleCss.commonpaddingtop}>
              <form onSubmit={submitSocialForm.handleSubmit}>
                <Grid container item rowSpacing={1} sx={{ border: 'solid 1px #edeced', borderRadius: '7px' }}>
             
                    <Grid item xs={3} sx={{ padding: '10px'}}>
                        <Grid item xs={12} >
                            <FormControlLabel
                            control={
                            <Checkbox
                            name="fb_checkbox"
                            color="primary"
                            checked={submitSocialForm.values.fb_checkbox}
                            onChange={submitSocialForm.handleChange}
                            />
                            }
                            label="Facebook & Instagram"
                            />
                        </Grid>
                        {submitSocialForm.values.fb_checkbox && (
                        <Grid item xs={12} style={{ marginTop: 16 }}>
                        <TextField
                        label="Enter budget"
                        type="number"
                        color="primary"
                        size="small"
                        variant="filled"
                        name="fb_buget"
                        value={submitSocialForm.values.fb_buget}
                        onChange={submitSocialForm.handleChange}
                        fullWidth
                        />
                        <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.fb_buget}</Grid>
                        </Grid>
                        )}
                        {submitSocialForm.values.fb_checkbox && (
                        <Grid item xs={12} style={{ marginTop: 16 }}>
                        <FormControl variant="filled" size="small" color="primary" fullWidth>
                        <InputLabel>Choose Goal</InputLabel>
                        <Select name="fb_goal" value={submitSocialForm.values.fb_goal}
                        onChange={submitSocialForm.handleChange}>
                            <MenuItem value="" >
                            <em>None</em>
                            </MenuItem>
                            <MenuItem value="1">Get more Page likes</MenuItem>
                            <MenuItem value="2">Grow customer base</MenuItem>
                            <MenuItem value="3">Get more messages</MenuItem>
                            <MenuItem value="4">Get more calls</MenuItem>
                            <MenuItem value="5">Promote your business locally</MenuItem>
                            <MenuItem value="6">Get more website visitors</MenuItem>
                            {/* Add more options as needed */}
                        </Select>
                        </FormControl>
                        <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.fb_goal}</Grid>
                        </Grid>
                        )}
                    </Grid>

                    <Grid item xs={3}>
                      <Grid item xs={12} >
                          <FormControlLabel
                          control={
                          <Checkbox
                          name="lin_checkbox"
                          color="primary"
                          checked={submitSocialForm.values.lin_checkbox}
                          onChange={submitSocialForm.handleChange}
                          />
                          }
                          label="Linkedin"
                          />
                      </Grid>
                      {submitSocialForm.values.lin_checkbox && (
                        <Grid item xs={12} style={{ marginTop: 16 }}>
                        <TextField
                        label="Enter budget"
                        color="primary"
                        size="small"
                        variant="filled"
                        name="lin_buget"
                        type="number"
                        value={submitSocialForm.values.lin_buget}
                        onChange={submitSocialForm.handleChange}
                        error={submitSocialForm.touched.lin_buget && Boolean(submitSocialForm.errors.lin_buget)}
                        helperText={submitSocialForm.touched.lin_buget && submitSocialForm.errors.lin_buget}
                        fullWidth
                        />
                        </Grid>
                        )}
                        {submitSocialForm.values.lin_checkbox && (
                        <Grid item xs={12} style={{ marginTop: 16 }}>
                        <FormControl variant="filled" size="small" color="primary" fullWidth>
                        <InputLabel>Choose Goal</InputLabel>
                        <Select name="lin_goal" value={submitSocialForm.values.lin_goal}
                        onChange={submitSocialForm.handleChange}  error={submitSocialForm.touched.lin_goal && Boolean(submitSocialForm.errors.lin_goal)}>
                            <MenuItem value="" >
                            <em>None</em>
                            </MenuItem>
                            <MenuItem value="1">Brand Awareness</MenuItem>
                            <MenuItem value="2">Website Visits</MenuItem>
                        </Select>
                        </FormControl>
                        </Grid>
                        )}
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>
                </Grid>  
                <Grid item>
                <Button type="submit" variant="contained" color="primary" style={{ marginTop: '16px' }}>
                show result
                </Button>
                </Grid>  

                <Grid container item rowSpacing={1}>
                {submitSocialForm.values.fb_checkbox && (  
                <Grid item xs={4}  sx={{border: '1px solid #ccc',marginTop: '20px' }}>    
                  <Typography sx={{textAlign: 'center', color:'#1877f2', backgroundColor: '#f0f0f0',padding: '20px',fontWeight: 'bold',marginBottom:'10px'}}>Facebook {SummaryData?.facebookSummary?.data?.title}</Typography>
                  <PieChart
                    margin={{ top: 100, bottom: 100, left: 100, right:100 }}
                    series={[
                      {
                        data: [
                          { id: 0, value: `${SummaryData?.facebookSummary?.data?.accoutCentervalue}`, label: `${SummaryData?.facebookSummary?.data?.label_1} ${SummaryData?.facebookSummary?.data?.accoutCenterReaced}` },
                          { id: 1, value: `${SummaryData?.facebookSummary?.data?.pageLikevalue}`, label: `${SummaryData?.facebookSummary?.data?.label_2} ${SummaryData?.facebookSummary?.data?.pageaction}` },
                        ],
                      },
                    ]}
                    slotProps={{ // Consistent indentation
                      legend: {
                      direction: 'row',
                      position: { vertical: 'bottom', horizontal: 'middle' },
                      padding: 10,
                      },
                      }}
                    width={400}
                    height={400}
                    />
                </Grid>
                )}

                {submitSocialForm.values.lin_checkbox && (
                <Grid item xs={4}  sx={{border: '1px solid #ccc',marginTop: '20px' }}>    
                  <Typography sx={{textAlign: 'center', color:'#1877f2', backgroundColor: '#f0f0f0',padding: '20px',fontWeight: 'bold',marginBottom:'10px'}}>Linkedin {SummaryData.linkedinSummary.data.title}</Typography>
                  <PieChart
                  margin={{ top: 100, bottom: 100, left: 100, right:100 }}
                    series={[
                      {
                        data: [
                          { id: 0, value: `${SummaryData.linkedinSummary.data.accoutCentervalue}`, label: `${SummaryData.linkedinSummary.data.label_1} ${SummaryData.linkedinSummary.data.accoutCenterReaced}`,color: 'orange' },
                          { id: 1, value: `${SummaryData.linkedinSummary.data.pageLikevalue}`, label: `${SummaryData.linkedinSummary.data.label_2} ${SummaryData?.linkedinSummary.data.pageaction}`,color: 'green' },
                        ],
                      },
                    ]}
                    slotProps={{ // Consistent indentation
                      legend: {
                      direction: 'row',
                      position: { vertical: 'bottom', horizontal: 'middle' },
                      padding: 10,
                      },
                      }}
                    width={400}
                    height={400}
                  />
                </Grid>
                )}
                </Grid>    
                </form>
                </Grid>
{/*

    <Stack direction="row" width="100%" textAlign="center" spacing={2}>
      <Box flexGrow={1}>
        <Typography>Default</Typography>
        <PieChart
          margin={{ top: 100, bottom: 100, left: 100, right:100 }}
          series={[{ data: [{ value: 10 }, { value: 15 }, { value: 20 }] }]}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'top', horizontal: 'middle' },
              padding: 0,
            },
          }}
          {...pieParams}
        />
      </Box>
      <Box flexGrow={1}>
        <Typography>Palette</Typography>
        <PieChart
          colors={palette}
          series={[{ data: [{ value: 10 }, { value: 15 }, { value: 20 }] }]}
          {...pieParams}
        />
      </Box>
      <Box flexGrow={1}>
        <Typography>Item</Typography>
        <PieChart
          series={[
            { data: [{ value: 10, color: 'orange' }, { value: 15 }, { value: 20 }] },
          ]}
          {...pieParams}
        />
      </Box>
    </Stack>
    <PieChart
margin={{ top: 50, bottom: 2, left: 100, right: 100 }} // Proper indentation
series={[
{
data: [
{ id: 0, value: 10, label: 'series A' }, // Semicolons
{ id: 1, value: 15, label: 'series B' },
{ id: 2, value: 20, label: 'series C' },
],
},
]}
width={400}
height={200}
slotProps={{ // Consistent indentation
legend: {
direction: 'row',
position: { vertical: 'top', horizontal: 'middle' },
padding: 0,
},
}}
/>
     */}           
        </>
    )
}

export default Adsummary