import React, { useState, useEffect } from 'react';
import { Button } from "@mui/material";
import { authpostAxios } from '../Utilities/commonAxios';
import styleCss from '../Style/style';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { manageLoader } from '../Reduxstore/loaderSlice';
import { toast } from "react-toastify";


const clientId = '78v50ifo44tj3l'; // Your LinkedIn Application Client ID
const redirectUri = 'http://localhost:3000/dashboard'; // Your callback URL

const LinkedInLoginButton = ({ customer_key, callback }) => {

  console.log(customer_key);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogin = () => {
    console.log(customer_key);
    console.log(`${customer_key}`);
    console.log(callback);
    //const scopes = encodeURIComponent('openid profile email w_member_social r_liteprofile');
    const scopes = encodeURIComponent('r_liteprofile r_emailaddress w_member_social w_organization_social r_organization_social rw_organization_admin');
    window.location.href = `https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=${customer_key}&redirect_uri=${callback}&state=98765EeFWf45A53sdfKef4233&scope=${scopes}`;
    // console.log(`https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=${customer_key}&redirect_uri=${callback}&state=98765EeFWf45A53sdfKef4233&scope=${scopes}`);
  };

  const [code, setCode] = useState(null);
  const [state, setState] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    if (urlParams?.get('code') && urlParams?.get('state')) {
      dispatch(manageLoader(true))
      const manageURLData = () => {
        setCode(urlParams.get('code'));
        setState(urlParams.get('state'));
      }
      manageURLData()
    }
  }, [urlParams?.get('code'), urlParams?.get('state')]);

  useEffect(() => {
    if (code !== null && state !== null) {
      const fetchAppId = async () => {
        try {
          await authpostAxios({
            url: `${process.env.REACT_APP_BACKENDURL}api/linedinlogin`,
            data: { 'code': code, 'state': state }
          }, dispatch).then(response => {
            localStorage.removeItem('linkedIniid')
            localStorage.setItem('linkedIniid', response?.data?.data)
            if (response?.data?.flag == 1) {
              dispatch(manageLoader(false))
              navigate('/import-linkedin-pages')
            }else{
              dispatch(manageLoader(false))
              toast.success('Linkedin Account Added');
              navigate('/dashboard')
            }

          });
        } catch (error) {
          console.error('Error fetching App ID from API:', error);
        }
      };
      fetchAppId()
    }
  }, [code, state])


  const CustomLinkedinButton = () => (
    <Button variant='outlined' style={styleCss.socialbtn} onClick={handleLogin}>
      Connect
    </Button>
  );


  return (
    <CustomLinkedinButton />
  );

};

export default LinkedInLoginButton;