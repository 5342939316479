import * as React from 'react';
import {
    Box, Button, Grid, Tab, Tabs, Tooltip, Typography, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, Paper, Pagination
} from "@mui/material";
import styleCss from "../Style/style";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { useFormik } from "formik";
import * as Yup from 'yup';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BarChart } from '@mui/x-charts';
import Nodataimg from '../img/nodata.png';
import ReportSociallist from './Reportsociallist';



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const Publishpost = () => {
    const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
    const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
    const xLabels = [
        'Page A',
        'Page B',
        'Page C',
        'Page D',
        'Page E',
        'Page F',
        'Page G',
    ];
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const submitPostForm = useFormik({
        enableReinitialize: true,
        validateOnChange: false,
        initialValues: {},
        validationSchema: Yup.object({
        }),
        onSubmit: (values) => {
        }
    })

    return (
        <>
            <Grid style={styleCss.commonpaddingtop}>
                <Grid>
                    <Grid>
                        <Grid container item columnSpacing={2}>
                            <Grid item md={12}>
                                <Box sx={{ width: '100%', marginTop: '15px' }}>
                                    <CustomTabPanel value={value} index={0}>
                                        <Grid md={12} sx={{ marginBottom: '15px' }}>
                                            <ReportSociallist />
                                        </Grid>
                                    </CustomTabPanel>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Publishpost