import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  homeRoutes,
  publicRoutes,
  ssoRoutes,
  authRoutes,
} from "./Router/index";
import { NonAuthLayout, AuthLayout } from "./Layout/index";
import { AuthMiddleware, NonAuthMiddleware } from "./Router/auth";
import { HomeLayout } from "./Layout/home";
import ErrorPage from "./Error/404";
import { useSelector } from "react-redux";

const App = () => {
  let isAuthenticated = useSelector((state) => state.auth);
  console.log('isAuthenticated',isAuthenticated)
  let userAuthRoutesFromDB = [];
  userAuthRoutesFromDB = isAuthenticated?.postItems?.data?.userAuthRoutes || [];
  return (
    <>
      <BrowserRouter>
        <Routes>
          {homeRoutes.map((homeRoute, index) => (
            <Route
              path={homeRoute.path}
              element={<HomeLayout>{homeRoute.element}</HomeLayout>}
              key={index}
              exact={homeRoute.exact}
            />
          ))}
          {publicRoutes.map((nonAuthRoute, index) => (
            <Route
              path={nonAuthRoute.path}
              element={
                <NonAuthMiddleware>
                  <NonAuthLayout>{nonAuthRoute.element}</NonAuthLayout>
                </NonAuthMiddleware>
              }
              key={index}
              exact={nonAuthRoute.exact}
            />
          ))}
          {ssoRoutes.map((ssoRoute, index) => (
            <Route
              path={ssoRoute.path}
              element={ssoRoute.element}
              key={index}
              exact={ssoRoute.exact}
            />
          ))}
          {authRoutes.map((authRoute, index) => (
            <Route
              path={authRoute.path}
              element={
                <AuthMiddleware>
                  <AuthLayout>{authRoute.element}</AuthLayout>
                </AuthMiddleware>
              }
              key={index}
              exact={authRoute.exact}
            />
          ))}
          { /*
          {userAuthRoutesFromDB.length !== 0 &&
            userAuthRoutesFromDB?.map((userRoute, index) => {
              let componentIndex = authRoutes.findIndex((route) => route.name === userRoute.name);
              if (componentIndex !== -1) {
                return (
                  <Route
                    path={userRoute.path}
                    element={
                      <AuthMiddleware>
                        <AuthLayout>
                          {authRoutes[componentIndex]["element"]}
                        </AuthLayout>
                      </AuthMiddleware>
                    }
                    key={index}
                    exact={true}
                  />
                );
              }
            })}
            */}
          <Route path="*" element={<ErrorPage />} exact={true} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
