import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Profile from '../img/profilepicture.png'
import { Grid } from '@mui/material';
import styleCss from '../Style/style';
import { manageUserSession } from "../Reduxstore/authSlice";
import { useDispatch,useSelector } from "react-redux";
import { Link,useNavigate } from 'react-router-dom';

export default function AccountMenu() {
    const navigate = useNavigate();
    const authState = useSelector(state=>state.auth)
    let userDetails = authState?.postItems?.data?.userDetails;
    const dispatch = useDispatch();
    const submitLogout = async () => {
        let isUserLogout = await dispatch(manageUserSession('logout'))
        if(isUserLogout) navigate('/login');
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <React.Fragment>
            <Box sx={{ textAlign: 'center' }}>
                <Tooltip title="Profile">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ padding: '0px' }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Grid sx={{ display: 'flex' }}>
                            <img src={(userDetails.image_logo)?(userDetails.image_logo):(Profile)} style={styleCss.profilestyle} />
                            <Typography sx={{ fontSize: '13px', color: '#fff', display: 'flex', alignItems: 'end' }}>{`${userDetails.first_name} ${(userDetails.last_name !== null) ? (userDetails.last_name):("")}`}</Typography>
                        </Grid>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleClose}>
                    <Avatar /> <Typography sx={{ fontSize: '15px', display: 'flex', alignItems: 'end' }}>{`${userDetails?.first_name} ${userDetails?.last_name !== "" && userDetails?.last_name}`}</Typography>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Avatar />
                    <Link to="/myaccount" style={{color:'#000000de', textDecoration: 'none', fontFamily:'Inter'}}> <Typography sx={{ fontSize: '15px', display: 'flex', alignItems: 'end' }}>My account</Typography></Link>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    <Typography sx={{ fontSize: '15px', display: 'flex', alignItems: 'end' }}>Settings</Typography>
                </MenuItem>
                <MenuItem onClick={submitLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small"/>
                    </ListItemIcon>
                    <Typography sx={{ fontSize: '15px', display: 'flex', alignItems: 'end' }}> Logout</Typography>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}