import * as React from 'react';

import {
    Box, Button, Grid, Tab, Tabs, Tooltip, Typography, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, Paper, Pagination
} from "@mui/material";
import styleCss from "../Style/style";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BarChart } from '@mui/x-charts';
import Nodataimg from '../img/nodata.png';
import ReportSociallist from './Reportsociallist';
import { useState } from 'react';
import dayjs from 'dayjs';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const Publishpost = () => {
    const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
    const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
    const xLabels = [
        'Page A',
        'Page B',
        'Page C',
        'Page D',
        'Page E',
        'Page F',
        'Page G',
    ];
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [customDateStatus, setCustomDateStatus] = useState(0)
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    
        const handleDateChange = (newDate, field) => {
            setCustomDateStatus(1)
            if (field === 'startDate') {
                setStartDate(newDate);
                setEndDate(dayjs());
            } else if (field === 'endDate') {
                setEndDate(newDate);
            }
        };

        const restDateTime = ()=>{
            setStartDate(null);
            setEndDate(null);
            setCustomDateStatus(0)
        }
       
    return (
        <>
            <Grid style={styleCss.commonpaddingtop}>
                <Grid container item rowSpacing={1} sx={{ border: 'solid 1px #edeced', borderRadius: '7px' }}>
                    <Grid item sx={{ p: 3, marginTop: '10px' }}>
                        <Grid container item columnSpacing={2}>
                            <Grid item md={3} sx={{ mb: 2 }}>
                                <Box>
                                    <Tabs value={value} onChange={handleChange} sx={{
                                        background: '#f7f9fb',
                                        border: 'solid 1px #edeced',
                                        borderRadius: '50px',
                                        minHeight: '15px',
                                        height: '38px',
                                        padding: '0px',
                                        '& .css-1aquho2-MuiTabs-indicator': {
                                            background: 'unset',
                                            '& .css-1aquho2-MuiTabs-indicator': {
                                                background: 'unset !important',
                                            },
                                        },
                                        '& .css-1l0dw58-MuiTabs-root': {
                                            minHeight: '15px',
                                        }, '& .MuiTab-textColorPrimary.Mui-selected': {
                                            backgroundColor: '#f4eeee',
                                        }
                                    }}>
                                        <Tab label="Published Post" className='paddingcustom' />
                                        <Tab label="Audience" className='paddingcustom' />
                                    </Tabs>
                                </Box>
                            </Grid>
                            <Grid item md={3} sx={{ textAlign: 'end' }}></Grid>
                            <Grid item md={3} sx={{ textAlign: 'end' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker 
                                         value={startDate}
                                         onChange={(newDate) => handleDateChange(newDate, 'startDate')}
                                         format={"YYYY/MM/DD"}
                                         label="Start Date" 
                                        slotProps={{ textField: { size: 'small' } }}
                                        sx={{
                                            '& .MuiInputLabel-root': {
                                                fontSize: '13px',
                                                color: '#6a7b8f',
                                                fontFamily: 'Inter'
                                            }
                                        }}
                                        name="startDate"
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item md={3} sx={{ textAlign: 'end' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker 
                                        value={endDate}
                                        onChange={(newDate) => handleDateChange(newDate, 'endDate')}
                                        label="End Date"
                                        format={"YYYY/MM/DD"}
                                        slotProps={{ textField: { size: 'small' } }}
                                        // openTo="month"
                                        // views={['year', 'month', 'day']}

                                        sx={{
                                            fontSize: '13px',
                                            fontWeight: 'bold',
                                            textAlign: 'left',
                                            color: '#6a7b8f',
                                            fontFamily: 'Inter',
                                            '& .MuiInputLabel-root': {
                                                fontSize: '13px',
                                                color: '#6a7b8f',
                                                fontFamily: 'Inter',
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item md={12}>
                                <Box sx={{ width: '100%', marginTop: '15px' }}>
                                    <CustomTabPanel value={value} index={0}>
                                        <Grid md={12} sx={{ marginBottom: '15px' }}>
                                            <ReportSociallist  customeSelectStatus={customDateStatus} startDate={startDate} endDate={endDate}  restDateTime={restDateTime} />
                                        </Grid>
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={1}>
                                        <Grid container spacing={3} sx={{}}>
                                            <Grid item md={8} sx={{ display: 'flex', alignContent: 'stretch', }}>
                                                <Grid sx={{ border: '1px solid #ddd', borderRadius: '4px', width: '100%' }}>
                                                    <Typography sx={{
                                                        fontSize: '14px',
                                                        fontWeight: 600,
                                                        fontStretch: 'normal',
                                                        fontStyle: 'normal',
                                                        lineHeight: 2.14,
                                                        letterSpacing: 'normal',
                                                        textAlign: 'left',
                                                        color: '#000',
                                                        padding: '3px 10px',
                                                        fontFamily: 'Inter',
                                                    }}>Followers growth</Typography>
                                                    <Grid sx={{ borderBottom: '1px solid #ddd' }}></Grid>
                                                    <Grid sx={{ padding: '15px' }}>
                                                        <Grid item md={12} sx={{ display: 'grid', placeContent: 'center', height: '30vh', textAlign: 'center', placeItems: 'center', }}>
                                                            <img src={Nodataimg} alt="no data"/>
                                                            <Typography sx={{
                                                                fontSize: '15px',
                                                                fontWeight: 500,
                                                                color: '#27292f',
                                                                fontFamily: 'Inter',
                                                                marginTop: '10px',
                                                                marginBottom: '10px',
                                                            }}>
                                                                Whoops! looks like there’s no data.
                                                            </Typography>
                                                            <Typography sx={{
                                                                fontSize: '10px',
                                                                color: '#717277',
                                                                fontFamily: 'Inter',
                                                            }}>
                                                                Try selecting a different period
                                                            </Typography>
                                                        </Grid>
                                                        {/* <BarChart
                                                            height={250}
                                                            series={[
                                                                { data: pData, label: 'pv', id: 'pvId' },
                                                                { data: uData, label: 'uv', id: 'uvId' },
                                                            ]}
                                                            xAxis={[{ data: xLabels, scaleType: 'band' }]}
                                                        /> */}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item md={4} sx={{ display: 'flex', alignContent: 'stretch', }}>
                                                <Grid sx={{ border: '1px solid #ddd', borderRadius: '4px', width: '100%' }}>
                                                    <Typography sx={{
                                                        fontSize: '14px',
                                                        fontWeight: 600,
                                                        fontStretch: 'normal',
                                                        fontStyle: 'normal',
                                                        lineHeight: 2.14,
                                                        letterSpacing: 'normal',
                                                        textAlign: 'left',
                                                        color: '#000',
                                                        padding: '3px 10px',
                                                        fontFamily: 'Inter',
                                                    }}>Engagement</Typography>
                                                    <Grid sx={{ borderBottom: '1px solid #ddd' }}></Grid>
                                                    <Grid sx={{ padding: '15px' }}>
                                                        <BarChart
                                                            height={250}
                                                            series={[
                                                                { data: pData, label: 'pv', id: 'pvId' },
                                                                { data: uData, label: 'uv', id: 'uvId' },
                                                            ]}
                                                            xAxis={[{ data: xLabels, scaleType: 'band' }]}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} sx={{ mt: 1 }}>
                                            <Grid item md={8} sx={{ display: 'flex', alignContent: 'stretch', }}>
                                                <Grid sx={{ border: '1px solid #ddd', borderRadius: '4px', width: '100%' }}>
                                                    <Typography sx={{
                                                        fontSize: '14px',
                                                        fontWeight: 600,
                                                        fontStretch: 'normal',
                                                        fontStyle: 'normal',
                                                        lineHeight: 2.14,
                                                        letterSpacing: 'normal',
                                                        textAlign: 'left',
                                                        color: '#000',
                                                        padding: '3px 10px',
                                                        fontFamily: 'Inter',
                                                    }}>Geography</Typography>
                                                    <Grid sx={{ borderBottom: '1px solid #ddd' }}></Grid>
                                                    <Grid sx={{ padding: '15px' }}>
                                                        <BarChart
                                                            height={250}
                                                            series={[
                                                                { data: pData, label: 'pv', id: 'pvId' },
                                                                { data: uData, label: 'uv', id: 'uvId' },
                                                            ]}
                                                            xAxis={[{ data: xLabels, scaleType: 'band' }]}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item md={4} sx={{ display: 'flex', alignContent: 'stretch', }}>
                                                <Grid sx={{ border: '1px solid #ddd', borderRadius: '4px', width: '100%' }}>
                                                    <Typography sx={{
                                                        fontSize: '14px',
                                                        fontWeight: 600,
                                                        fontStretch: 'normal',
                                                        fontStyle: 'normal',
                                                        lineHeight: 2.14,
                                                        letterSpacing: 'normal',
                                                        textAlign: 'left',
                                                        color: '#000',
                                                        padding: '3px 10px',
                                                        fontFamily: 'Inter',
                                                    }}>Age / Gender Stats</Typography>
                                                    <Grid sx={{ borderBottom: '1px solid #ddd' }}></Grid>
                                                    <Grid sx={{ padding: '15px' }}>
                                                        <BarChart
                                                            height={250}
                                                            series={[
                                                                { data: pData, label: 'pv', id: 'pvId' },
                                                                { data: uData, label: 'uv', id: 'uvId' },
                                                            ]}
                                                            xAxis={[{ data: xLabels, scaleType: 'band' }]}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CustomTabPanel>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Publishpost