import { Container, Grid, List, ListItem, ListItemText, Menu, MenuItem } from "@mui/material"
import styleCss from '../Style/style';
import { Link } from "react-router-dom";
import InnerLogo from "../img/innerlogo.png";
import AccountMenu from "./profiledropdown";
import { NotificationsNoneOutlined } from '@mui/icons-material';
import { useSelector } from "react-redux";
import { authpostAxios } from "../Utilities/commonAxios";
import Profile from '../img/profilepicture.png'
//import React from 'react';
import { useDispatch } from "react-redux";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import GridOn from '@mui/icons-material/NotificationsNoneOutlined';
import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { green, pink, red, blue } from '@mui/material/colors';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import PlayArrow from '@mui/icons-material/PlayArrow';
import { Popper } from '@mui/material';
import { Tabs, Tab } from '@mui/material';
import Badge from '@mui/material/Badge';
import React, { useState, useEffect } from "react";
import { dateDiff,dateTimeDiff } from "../Utilities/customDate";
import { socialMediaLists } from "../Utilities/socialMediaLists";
import noImage from "../img/no-image.jpg";
import { useNavigate } from 'react-router-dom';



const Header = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [notificationSelect, setnotificationSelect] = useState(0);
    const [notificationLists, setNotificationLists] = useState([]);
    const authState = useSelector(state => state.auth)
    let userDetails = authState?.postItems?.data?.userDetails;
  

    const getNotificatioinFromAPI = async () => {
        await authpostAxios(
            {
                url: `${process.env.REACT_APP_BACKENDURL}api/get-comment-notification`,
                data: "",
            },
            dispatch
        ).then((response) => setNotificationLists(response?.data?.data)).catch((error) => console.log(error))
    };


    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickCommentView = (event, primaryKeyId, socialMediaId, mediaId) => {
        // setAnchorEl(event.currentTarget);
        setAnchorEl(null)
        navigate('/Inbox', { state: { primaryKeyId, socialMediaId, mediaId } });
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        getNotificatioinFromAPI()
      }, []);
    


    return (
        <>
            <Grid style={styleCss.Headerstyle}>
                <Container maxWidth="xl">
                    <Grid container>
                        <Grid item md={2} sx={{ marginTop: '3px' }}>
                            <Link to="/dashboard">
                                <img src={InnerLogo} />
                            </Link>
                        </Grid>
                        <Grid item md={3} sx={{ marginTop: '3px' }}>
                            {userDetails && (
                                <img src={userDetails?.companylogo} style={{
                                    width: '120px',
                                    height: '35px',
                                    objectFit: 'contain',
                                    overflow: 'hidden',
                                }} />
                            )}
                        </Grid>
                        <Grid item md={7}>
                            <List style={{ padding: '0px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                <ListItem style={styleCss.liststyle} onClick={handleClick} >
                                    <Badge badgeContent={notificationLists.length} color="error">
                                        <NotificationsNoneOutlinedIcon style={{ cursor: 'pointer' }} sx={{ color: '#fff', marginRight: '5px' }} />
                                    </Badge>
                                </ListItem>

                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    getContentAnchorEl={null}
                                    PaperProps={{ style: { width: '300px', maxHeight: '35vh', overflowY: 'auto' } }} // Set max height and overflow

                                >
                                    <Typography variant="subtitle1" sx={{ textAlign: 'center', px: 2, pt: 1 }}>Notification</Typography>
                                    <Divider />
                                    {notificationLists && notificationLists?.length > 0 &&
                                        notificationLists?.map((notifications, index) => (
                                            <MenuItem onClick={(event) => handleClickCommentView(event, notifications?.social_primary_id, notifications?.social_media_id, notifications?.media_id)} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: ((notifications?.is_read == 0) ? ('#000000') : ('#000000')), fontWeight: ((notifications?.is_read == 0) ? ('bold') : ('normal')) }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Grid style={{ position: "relative" }}>
                                                        <img
                                                            src={(notifications?.profile_url) ? (notifications?.profile_url) : (noImage)}
                                                            style={{
                                                                width: "30px",
                                                                height: "30px",
                                                                borderRadius: "50px",
                                                                marginRight: "10px",
                                                            }}
                                                        />
                                                        <img
                                                            src={(socialMediaLists[notifications?.social_media_id]['icon']) ? (socialMediaLists[notifications?.social_media_id]['icon']) : (noImage)}
                                                            style={{
                                                                width: "14px",
                                                                borderRadius: "50px",
                                                                position: "absolute",
                                                                bottom: 0,
                                                                left: "22px",
                                                            }}
                                                        />
                                                    </Grid>

                                                    {/* <Avatar sx={{ bgcolor: blue[500], width: '25px', height: '25px' }}>
                                                        <img src={notifications?.profile_url} sx={{ width: '10px', height: '10px' }} />
                                                    </Avatar> */}
                                                    <div style={{ marginLeft: '5px', display: 'flex', flexDirection: 'column' }}>
                                                        <Typography variant="body1" sx={{ fontSize: '9px', wordWrap: 'break-word' }}>{notifications?.profile_name}</Typography>
                                                        <Typography variant="body2" color="textSecondary" sx={{ fontSize: '11px', wordWrap: 'break-word' }}>{notifications.comments.length >25 ? `${(notifications.comments).substring(0, 25)}...etc` : notifications.comments}</Typography>
                                                    </div>
                                                </div>

                                                <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column' }}>
                                                    <Typography variant="body2" color="textSecondary" sx={{ fontSize: '10px', wordWrap: 'break-word' }}>{dateTimeDiff(notifications.date_comment_post)}</Typography>
                                                    
                                                </div>
                                            </MenuItem>
                                        ))}
                                    <Divider />

                                </Menu>
                                <ListItem style={styleCss.liststyle}>
                                    <AccountMenu />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </>
    )
}

export default Header