import { Button, Grid, TextField, Typography } from "@mui/material"
import React, { useState, useEffect } from "react";
import styleCss from "../Style/style"
import Box from '@mui/material/Box';
import { authpostAxios } from "../Utilities/commonAxios";
import { Formik, Form, Field, useFormik, ErrorMessage } from 'formik';
import SliderRange from '@mui/material/Slider';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import '../Style/Loader.css'
import dayjs from 'dayjs'; // Import Dayjs
import 'dayjs/locale/en'; 
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TextsmsIcon from '@material-ui/icons/Textsms';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';



function formatDate(timestamp) {
  const date = new Date(timestamp);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short'
  };
  return date.toLocaleDateString('en-US', options);
}


const Budgetlist = () => {

    const [budgettrackerList, setbudgettrackerList] = useState([]);

    const getbudgettrackerListFromAPI = async () => {
      try {
        const response = await authpostAxios({
          url: `${process.env.REACT_APP_BACKENDURL}api/get-budget-tracker`,
          data: {  }
        });
        setbudgettrackerList(response.data?.data || []);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

      useEffect(() => {
        getbudgettrackerListFromAPI()
        console.log(budgettrackerList);
      }, []);

    return (
        <Grid container style={styleCss.commonpaddingtop}>
<Grid item xs={6}>
  <h2 style={{ textAlign: 'left' }}>Budget Tracker List</h2>
</Grid>
<Grid item xs={6} style={{ textAlign: 'right' }}>
<Link to="/socialmediaads" style={{ textDecoration: 'none' }}>
  <Button variant="contained" startIcon={<AddIcon />}>
    Add Budget Tracker
  </Button>
</Link>
</Grid>

<Grid item xs={12}>
<div style={{ width: '100%' }}>
<table className="styled-table">
            <thead>
                <tr>
                    <th>S.No</th>
                    <th>Campaign</th>
                     <th>Details</th>
                    <th>Total Budget Allocated</th>
                    <th>Estimated Start Date</th>
                    <th>Estimated End Date</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {budgettrackerList.map((ad, index) => (
                <tr>
                    <td>{index + 1}</td>
                    <td>{ad.budget_tracker_name}</td>
                    <td>
                        {ad.is_fb_checked == 1 && (
                        <div className="icon-details">
                            <FacebookIcon style={{ color: '#3b5998', marginRight: '8px' }} />
                            <div className="details-text">
                                Total Messages Sent: 1000<br />
                                Cost per Message: $0.15
                            </div>
                        </div>
                        )}
                        {ad.is_lin_checked == 1 && (
                        <div className="icon-details">
                            <LinkedInIcon style={{ color: '#0077b5', marginRight: '8px' }} />
                            <div className="details-text">
                                Total Messages Sent: 1000<br />
                                Cost per Message: $0.15
                            </div>
                        </div>
                        )}
                         {ad.is_tw_checked == 1 && (
                        <div className="icon-details">
                            <TwitterIcon style={{ color: '#1da1f2', marginRight: '8px' }} />
                            <div className="details-text">
                                Total Messages Sent: 1000<br />
                                Cost per Message: $0.15
                            </div>
                        </div>
                         )}
                         {ad.is_whatsapp_checked == 1 && (
                        <div className="icon-details">
                            <WhatsAppIcon style={{ color: '#25d366', marginRight: '8px' }} />
                            <div className="details-text">
                            <Typography variant="body3">Cost per Message: Rs {ad.cost_for_whatsapp}</Typography><br />
                            <Typography variant="body3">Total Messages Sent: Rs {ad.whatsapp_budget_amount}</Typography><br />
                            <Typography variant="body3">Approximate sending unit: {ad.approximate_whatsapp_send_message}</Typography>
                            </div>
                        </div>
                        )}
                         {ad.is_sms_checked == 1 && (
                        <div className="icon-details">
                            <TextsmsIcon style={{ color: '#009688', marginRight: '8px' }} />
                            <div className="details-text">
                            <Typography variant="body3">Cost per Message: Rs {ad.cost_for_sms}</Typography><br />
                            <Typography variant="body3">Total Messages Sent: Rs {ad.sms_budget_amount}</Typography><br />
                            <Typography variant="body3">Approximate sending unit: {ad.approximate_sms_send_message}</Typography>
                            </div>
                        </div>
                        )}
                        {ad.is_mail_checked == 1 && (
                        <div className="icon-details">
                            <MailOutlineIcon style={{ color: '#f44336', marginRight: '8px' }} />
                            <div className="details-text">
                            <Typography variant="body3">Cost per Message: Rs {ad.cost_for_mail}</Typography><br />
                            <Typography variant="body3">Total Messages Sent: Rs {ad.mail_budget_amount}</Typography><br />
                            <Typography variant="body3">Approximate sending unit: {ad.approximate_mail_send_message}</Typography>
                            </div>
                        </div>
                        )}
                    </td>
                    <td>Rs {ad.total_budget}</td>
                    <td>{formatDate(ad.start_date)}</td>
                    <td>{formatDate(ad.end_date)}</td>
                    <td> <EditIcon style={{ color: '#4caf50', marginRight: '8px', cursor: 'pointer' }} />
                    <DeleteIcon style={{ color: '#f44336', cursor: 'pointer' }} /></td>
                </tr>
                ))}
            </tbody>
        </table>
</div>



      </Grid>
    </Grid>
    )
}

export default Budgetlist