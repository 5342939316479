import { Accordion, AccordionSummary, Tab, Tabs, Grid, Typography, Fade, AccordionDetails, Box, Checkbox, FormControlLabel, Button, Select, FormControl, InputLabel, MenuItem, TextField, Badge } from "@mui/material"
import React, { useState, useEffect } from "react";
import { ExpandMore, SearchOutlined } from '@mui/icons-material';
import Facebooksub from '../img/sub-icon-1.svg';
import Listimg from '../img/list-img.jpg';
import { Link } from "react-router-dom";
import Plusicon from '@mui/icons-material/Add';
import Instasub from '../img/sub-icon-2.png';
import Twittersub from '../img/sub-icon-3.png';
import Linkedinsub from '../img/sub-icon-4.png';
import Channelsub from '../img/sub-icon-5.png';
import Googlesub from '../img/sub-icon-6.png';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import 'quill-emoji/dist/quill-emoji.js';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import { authpostAxios, nonAuthpostAxios } from "../Utilities/commonAxios";
import { useDispatch } from "react-redux";
import FacebookLoginButton from '../Components/FacebookLoginButton';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const Socialselect = ({ formik }) => {
    const badgeCount = 5;
    const ibadgeCount = 3;
    const ybadgeCount = 2;
    const dispatch = useDispatch()
    const [facebookpageList, setFacebookpageList] = useState([]);
    const [expanded, setExpanded] = React.useState(false);
    const handleExpansion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };
    // facebook
    const getfacebookpageFromAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/get-fb-page`,
                data: {
                    socialMediaId: 1
                }
            }, dispatch).then(facebookpageResponse => setFacebookpageList(facebookpageResponse.data?.data))
    }
    const handleFacebookSelectAll = () => {
        let newList = [];
        if (!formik?.values?.facebookSelectAll) {
            facebookpageList && facebookpageList.map(facebookpageList => newList.push({
                'social_media_id': facebookpageList.social_media_id,
                'social_media_page_id': facebookpageList.social_media_page_id
            }))
        }
        formik?.setFieldValue('facebookSelectedPages', newList)
        formik?.setFieldValue('facebookSelectAll', !formik?.values?.facebookSelectAll);
    }
    const handleFacebookCheckboxChange = (social_media_id, social_media_page_id) => {
        const index = (formik?.values?.facebookSelectedPages).findIndex(selectedPage => selectedPage.social_media_page_id === social_media_page_id);
        if (index === -1) {
            formik?.setFieldValue('facebookSelectedPages', ([...(formik?.values?.facebookSelectedPages), { social_media_id, social_media_page_id }]))
        } else {
            formik?.setFieldValue('facebookSelectedPages', ((formik?.values?.facebookSelectedPages).filter(selectedPage => selectedPage.social_media_page_id !== social_media_page_id)));
        }
    }
    useEffect(() => {
        if (formik.values.facebookSelectedPages?.length === facebookpageList?.length && facebookpageList?.length !== 0) formik.setFieldValue('facebookSelectAll', true)
        else formik.setFieldValue('facebookSelectAll', false)
    }, [formik.values.facebookSelectedPages])
    // instagram - page
    const [instaPageList, setInstaPageList] = useState([]);
    const getinstaPageFromAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/get-insta-profile`,
                data: {
                    socialMediaId: 4
                }
            }, dispatch).then(response => setInstaPageList(response.data?.data))
    }
    const handleInstaSelectAll = () => {
        let newList = [];
        if (!formik.values.instaSelectAll) {
            instaPageList && instaPageList.map(list => newList.push({
                'social_media_id': list.social_media_id,
                'social_media_account_id': list.social_media_account_id
            }))
        }
        formik.setFieldValue('instaSelectedAccounts', newList)
        formik.setFieldValue('instaSelectAll', !formik.values.instaSelectAll);
    }
    const handleInstaCheckboxChange = (social_media_id, social_media_account_id) => {
        const index = (formik.values.instaSelectedAccounts).findIndex(selectedPage => selectedPage.social_media_account_id === social_media_account_id);
        if (index === -1) {
            formik.setFieldValue('instaSelectedAccounts', ([...(formik.values.instaSelectedAccounts), { social_media_id, social_media_account_id }]))
        } else {
            formik.setFieldValue('instaSelectedAccounts', ((formik.values.instaSelectedAccounts).filter(selectedPage => selectedPage.social_media_account_id !== social_media_account_id)));
        }
    }
    useEffect(() => {
        if (formik.values.instaSelectedAccounts?.length === instaPageList?.length && instaPageList?.length !== 0) formik.setFieldValue('instaSelectAll', true)
        else formik.setFieldValue('instaSelectAll', false)
    }, [formik.values.instaSelectedAccounts])
    // linked in - page
    const [linkedInPageList, setLinkedInPageList] = useState([]);
    const getLinkedInPageFromAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/get-linkedin-pages`,
                data: {
                    socialMediaId: 3
                }
            }, dispatch).then(response => setLinkedInPageList(response.data?.data))
    }
    const handleLinkedInPageSelectAll = () => {
        let newList = [];
        if (!formik.values.linkedInPageSelectAll) {
            linkedInPageList && linkedInPageList.map(list => newList.push({
                'social_media_id': list.social_media_id,
                'social_media_page_id': list.social_media_page_id
            }))
        }
        formik.setFieldValue('linkedInSelectedPages', newList)
        formik.setFieldValue('linkedInPageSelectAll', !formik.values.linkedInPageSelectAll);
    }
    const handleLinkedInPageCheckboxChange = (social_media_id, social_media_page_id) => {
        const index = (formik.values.linkedInSelectedPages).findIndex(selectedPage => selectedPage.social_media_page_id === social_media_page_id);
        if (index === -1) {
            formik.setFieldValue('linkedInSelectedPages', ([...(formik.values.linkedInSelectedPages), { social_media_id, social_media_page_id }]))
        } else {
            formik.setFieldValue('linkedInSelectedPages', ((formik.values.linkedInSelectedPages).filter(selectedPage => selectedPage.social_media_page_id !== social_media_page_id)));
        }
    }
    useEffect(() => {
        if (formik.values.linkedInSelectedPages?.length === linkedInPageList?.length && linkedInPageList?.length !== 0) formik.setFieldValue('linkedInPageSelectAll', true)
        else formik.setFieldValue('linkedInPageSelectAll', false)
    }, [formik.values.linkedInSelectedPages])
    // linked in - account
    const [linkedInAccountList, setLinkedInAccountList] = useState([]);
    const getLinkedInAccountFromAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/get-linkedin-profile`,
                data: {
                    socialMediaId: 3
                }
            }, dispatch).then(response => setLinkedInAccountList(response.data?.data))
    }
    const handleLinkedInAccountSelectAll = () => {
        let newList = [];
        if (!formik.values.linkedInAccountSelectAll) {
            linkedInAccountList && linkedInAccountList.map(list => newList.push({
                'social_media_id': list.social_media_id,
                'social_media_account_id': list.social_media_account_id
            }))
        }
        formik.setFieldValue('linkedInAccountSelectAll', !formik.values.linkedInAccountSelectAll);
        formik.setFieldValue('linkedInSelectedAccounts', newList)
    }
    const handleLinkedInAccountCheckboxChange = (social_media_id, social_media_account_id) => {
        const index = (formik.values.linkedInSelectedAccounts).findIndex(selectedPage => selectedPage.social_media_account_id === social_media_account_id);
        if (index === -1) {
            formik.setFieldValue('linkedInSelectedAccounts', ([...(formik.values.linkedInSelectedAccounts), { social_media_id, social_media_account_id }]))
        } else {
            formik.setFieldValue('linkedInSelectedAccounts', ((formik.values.linkedInSelectedAccounts).filter(selectedPage => selectedPage.social_media_account_id !== social_media_account_id)));
        }
    }
    useEffect(() => {
        if (formik.values.linkedInSelectedAccounts?.length === linkedInAccountList?.length && linkedInAccountList.length !== 0) formik.setFieldValue('linkedInAccountSelectAll', true)
        else formik.setFieldValue('linkedInAccountSelectAll', false)
    }, [formik.values.linkedInSelectedAccounts])
    // Youtube
    const [youtubeChannelList, setYoutubeChannelList] = useState([]);
    const getYoutubeChannelFromAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/get-youtube-account`,
                data: {
                    socialMediaId: 5
                }
            }, dispatch).then(response => setYoutubeChannelList(response.data?.data))
    }
    const handleYoutubeAccountSelectAll = () => {
        let newList = [];
        if (!formik.values.youtubeAccountSelectAll) {
            youtubeChannelList && youtubeChannelList.map(list => newList.push({
                'social_media_id': list.social_media_id,
                'social_media_account_id': list.social_media_account_id
            }))
        }
        formik.setFieldValue('youtubeAccountSelectAll', !formik.values.youtubeAccountSelectAll);
        formik.setFieldValue('youtubeSelectedAccounts', newList)
    }
    const handleYoutubeAccountCheckboxChange = (social_media_id, social_media_account_id) => {
        const index = (formik.values.youtubeSelectedAccounts).findIndex(selectedPage => selectedPage.social_media_account_id === social_media_account_id);
        if (index === -1) {
            formik.setFieldValue('youtubeSelectedAccounts', ([...(formik.values.youtubeSelectedAccounts), { social_media_id, social_media_account_id }]))
        } else {
            formik.setFieldValue('youtubeSelectedAccounts', ((formik.values.youtubeSelectedAccounts).filter(selectedPage => selectedPage.social_media_account_id !== social_media_account_id)));
        }
    }
    useEffect(() => {
        if (formik.values.youtubeSelectedAccounts?.length === youtubeChannelList?.length && youtubeChannelList.length !== 0) formik.setFieldValue('youtubeAccountSelectAll', true)
        else formik.setFieldValue('youtubeAccountSelectAll', false)
    }, [formik.values.youtubeSelectedAccounts])
    // Twitter
    const [twitterAccountList, setTwitterAccountList] = useState([]);
    const getTwitterAccountFromAPI = async () => {
        await authpostAxios(
            {
                'url': `${process.env.REACT_APP_BACKENDURL}api/get-twitter-account`,
                data: {
                    socialMediaId: 2
                }
            }, dispatch).then(response => setTwitterAccountList(response.data?.data))
    }
    const handleTwitterAccountSelectAll = () => {
        let newList = [];
        if (!formik.values.twitterAccountSelectAll) {
            twitterAccountList && twitterAccountList.map(list => newList.push({
                'social_media_id': list.social_media_id,
                'social_media_account_id': list.social_media_account_id
            }))
        }
        formik.setFieldValue('twitterAccountSelectAll', !formik.values.twitterAccountSelectAll);
        formik.setFieldValue('twitterSelectedAccounts', newList)
    }
    const handleTwitterAccountCheckboxChange = (social_media_id, social_media_account_id) => {
        const index = (formik.values.twitterSelectedAccounts).findIndex(selectedPage => selectedPage.social_media_account_id === social_media_account_id);
        if (index === -1) {
            formik.setFieldValue('twitterSelectedAccounts', ([...(formik.values.twitterSelectedAccounts), { social_media_id, social_media_account_id }]))
        } else {
            formik.setFieldValue('twitterSelectedAccounts', ((formik.values.twitterSelectedAccounts).filter(selectedPage => selectedPage.social_media_account_id !== social_media_account_id)));
        }
    }



    useEffect(() => {
        if (formik.values.twitterSelectedAccounts?.length === twitterAccountList?.length && twitterAccountList.length !== 0) formik.setFieldValue('twitterAccountSelectAll', true)
        else formik.setFieldValue('twitterAccountSelectAll', false)
    }, [formik.values.twitterSelectedAccounts])
    useEffect(() => {
        getfacebookpageFromAPI()
        getinstaPageFromAPI()
        getLinkedInPageFromAPI()
        getLinkedInAccountFromAPI()
        getYoutubeChannelFromAPI()
        getTwitterAccountFromAPI()
    }, []);





    return (
        <>
            <Grid container>
                <Grid md={12} sx={{ marginTop: '10px' }}>
                    <Typography className="error" sx={{ color: 'red', fontSize: '12px', padding: '10px', textAlign: 'center' }}>{formik?.errors?.checkPageSelection ? formik?.errors?.checkPageSelection : ''}</Typography>
                </Grid>
                <Grid md={12}>
                    
                </Grid>
            </Grid>
            <Grid sx={{
                maxHeight: '100vh', overflowY: 'scroll', '&::-webkit-scrollbar': {
                    width: '3px',
                },
                '&::-webkit-scrollbar-track': {
                    borderRadius: '10px',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#ddd',
                    borderRadius: '10px',
                },
            }}>
                <Accordion variant="0"
                    expanded={expanded}
                    onChange={handleExpansion}
                >

                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                            padding: '3px 7px', '& .css-1qqrcng-MuiPaper-root-MuiAccordion-root.Mui-expanded': { margin: '0px 0' },
                            '& .css-eqpfi5-MuiAccordionSummary-content': { margin: '12px 0px 0px' },
                            '& .css-r211ux-MuiPaper-root-MuiAccordion-root.Mui-expanded': { margin: '0px' },
                        }}
                    >
                        <div style={{ width: '100%', }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'nowrap',
                                    p: 0,
                                    position: 'relative'
                                }}
                            >
                                <Grid sx={{ marginRight: '6px', position: 'relative' }}>
                                    <Grid sx={{ position: 'relative' }}>
                                        <img src={Facebooksub} />
                                        <FormControlLabel control={<Checkbox checked={formik?.values?.facebookSelectAll} onClick={(e) => e.stopPropagation()} onChange={handleFacebookSelectAll} value={formik?.values?.facebookSelectAll} style={{ padding: 0, position: 'absolute', right: '0px', top: '-10px', }} />}
                                            sx={{ '& .MuiSvgIcon-root': { width: '18px', height: '18px', } }}
                                        />
                                    </Grid>
                                    <Badge
                                        badgeContent={facebookpageList.length} // Pass the count value to badgeContent
                                        color="error" // Choose your badge color
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        sx={{ top: '-39px', left: '128px' }}
                                    />
                                </Grid>
                                <Grid>
                                    <Typography sx={{
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        textAlign: 'left',
                                        color: '#27292f !important',
                                    }}>Facebook</Typography>
                                    <Typography sx={{
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        textAlign: 'left',
                                        color: '#87888e !important',
                                    }}>Pages</Typography>
                                </Grid>
                            </Box>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: '3px 7px', marginTop: '0px', }}>
                        {facebookpageList && facebookpageList?.map((page, index) => (
                            <div style={{ width: '100%', marginBottom: '10px' }} key={index}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'nowrap',
                                        p: 0,
                                    }}
                                >
                                    <Grid sx={{ marginRight: '7px' }}>
                                        <Grid sx={{ position: 'relative' }}>
                                            <img src={page.page_profile_image} style={{ width: '35px', height: '30px', borderRadius: '5px', overflow: 'hidden' }} />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        style={{ padding: 0, position: 'absolute', right: '-8px', top: '-9px', }}
                                                        checked={(formik?.values?.facebookSelectedPages) ? (formik?.values?.facebookSelectedPages).some(selectedPage => selectedPage.social_media_page_id === page.social_media_page_id) : ('')}
                                                        onChange={() => handleFacebookCheckboxChange(page.social_media_id, page.social_media_page_id)}
                                                    />
                                                }
                                                sx={{ '& .MuiSvgIcon-root': { width: '18px', height: '18px', } }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid sx={{ marginRight: '10px' }}>
                                        <Typography sx={{ fontSize: '12px', color: '#27292f', }}>
                                            {page.page_name}
                                        </Typography>
                                    </Grid>
                                    <Grid sx={{}}>
                                        <Typography sx={{ color: '#747782', fontSize: '12px', }}></Typography>
                                    </Grid>
                                </Box>
                            </div>
                        ))}
                        <Link to="/dashboard" style={{ textDecoration: 'none' }}>
                            <Typography sx={{ color: '#567cde', fontSize: '13px', }}> <Plusicon sx={{ verticalAlign: 'middle', width: '20px' }} /> Connect Page </Typography>
                        </Link>
                    </AccordionDetails>
                </Accordion>

                <Accordion variant="0">
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                        sx={{ padding: '3px 7px', '& .css-o4b71y-MuiAccordionSummary-content.Mui-expanded': { margin: '10px 0' } }}
                    >
                        <div style={{ width: '100%', }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'nowrap',
                                    p: 0,
                                    position: 'relative'
                                }}
                            >
                                <Grid sx={{ marginRight: '6px', position: 'relative' }}>
                                    <Grid sx={{ position: 'relative' }}>
                                        <img src={Instasub} />
                                        <FormControlLabel control={<Checkbox checked={formik.values.instaSelectAll} onClick={(e) => e.stopPropagation()} onChange={handleInstaSelectAll} value={formik.values.instaSelectAll} style={{ padding: 0, position: 'absolute', right: '0px', top: '-10px', }} />}
                                            sx={{ '& .MuiSvgIcon-root': { width: '18px', height: '18px', } }}
                                        />
                                    </Grid>
                                    <Badge
                                        badgeContent={instaPageList.length} // Pass the count value to badgeContent
                                        color="error" // Choose your badge col or
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        sx={{ top: '-39px', left: '128px' }}
                                    />
                                </Grid>
                                <Grid>
                                    <Typography sx={{
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        textAlign: 'left',
                                        color: '#27292f !important',
                                    }}>Instagram</Typography>
                                    <Typography sx={{
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        textAlign: 'left',
                                        color: '#87888e !important',
                                    }}>Accounts</Typography>
                                </Grid>
                            </Box>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: '3px 7px', marginTop: '0px' }}>
                        {instaPageList && instaPageList?.map((page, index) => (
                            <div style={{ width: '100%', marginBottom: '10px' }} key={index}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'nowrap',
                                        p: 0,
                                    }}
                                >
                                    <Grid sx={{ marginRight: '7px' }}>
                                        <Grid sx={{ position: 'relative' }}>
                                            <img src={(page.profile) ? (page.profile) : (Listimg)} style={{ width: '35px', height: '30px', borderRadius: '5px', overflow: 'hidden' }} />
                                            <FormControlLabel control={<Checkbox
                                                style={{ padding: 0, position: 'absolute', right: '-8px', top: '-9px', }}
                                                checked={(formik?.values?.instaSelectedAccounts) ? (formik?.values?.instaSelectedAccounts).some(selectedPage => selectedPage.social_media_account_id === page.social_media_account_id) : ('')} onChange={() => handleInstaCheckboxChange(page.social_media_id, page.social_media_account_id)}
                                            />}
                                                sx={{ '& .MuiSvgIcon-root': { width: '18px', height: '18px', } }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid sx={{ marginRight: '10px' }}>
                                        <Typography sx={{ fontSize: '12px', color: '#27292f', }}>
                                            {page.screen_name}
                                        </Typography>
                                    </Grid>
                                    <Grid sx={{}}>
                                        <Typography sx={{ color: '#747782', fontSize: '12px', }}></Typography>
                                    </Grid>
                                </Box>
                            </div>
                        ))}
                        <Link to="/dashboard" style={{ textDecoration: 'none' }}>
                            <Typography sx={{ color: '#567cde', fontSize: '13px', }}> <Plusicon sx={{ verticalAlign: 'middle', width: '20px' }} /> Connect Page </Typography>
                        </Link>
                    </AccordionDetails>
                </Accordion>

                <Accordion variant="0">
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel4-content"
                        id="panel4-header"
                        sx={{ padding: '3px 7px', '& .css-o4b71y-MuiAccordionSummary-content.Mui-expanded': { margin: '10px 0' }, margin: '0px !important' }}
                    >
                        <div style={{ width: '100%', }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'nowrap',
                                    p: 0,
                                    position: 'relative'
                                }}
                            >
                                <Grid sx={{ marginRight: '6px', position: 'relative' }}>
                                    <Grid sx={{ position: 'relative' }}>
                                        <img src={Linkedinsub} />
                                        <FormControlLabel control={<Checkbox checked={formik?.values?.linkedInPageSelectAll} onClick={(e) => e.stopPropagation()} onChange={handleLinkedInPageSelectAll} value={formik?.values?.linkedInPageSelectAll} style={{ padding: 0, position: 'absolute', right: '0px', top: '-10px', }} />}
                                            sx={{ '& .MuiSvgIcon-root': { width: '18px', height: '18px', } }}
                                        />
                                    </Grid>
                                    <Badge
                                        badgeContent={linkedInPageList.length} // Pass the count value to badgeContent
                                        color="error" // Choose your badge col or
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        sx={{ top: '-39px', left: '128px' }}
                                    />

                                </Grid>
                                <Grid>
                                    <Typography sx={{
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        textAlign: 'left',
                                        color: '#27292f !important',
                                    }}>Linkedin</Typography>
                                    <Typography sx={{
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        textAlign: 'left',
                                        color: '#87888e !important',
                                    }}>Pages</Typography>

                                </Grid>
                            </Box>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: '3px 7px', marginTop: '0px' }}>
                        {linkedInPageList && linkedInPageList?.map((page, index) => (
                            <div style={{ width: '100%', marginBottom: '10px' }} key={index}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'nowrap',
                                        p: 0,
                                    }}
                                >
                                    <Grid sx={{ marginRight: '7px' }}>
                                        <Grid sx={{ position: 'relative' }}>
                                            <img src={((page.page_profile_image) ? (page.page_profile_image) : (Listimg))} style={{ width: '35px', height: '30px', borderRadius: '5px', overflow: 'hidden' }} />
                                            <FormControlLabel control={<Checkbox style={{ padding: 0, position: 'absolute', right: '-8px', top: '-9px', }} checked={(formik?.values?.linkedInSelectedPages) ? (formik?.values?.linkedInSelectedPages).some(selectedPage => selectedPage.social_media_page_id === page.social_media_page_id) : ('')}
                                                onChange={() => handleLinkedInPageCheckboxChange(page.social_media_id, page.social_media_page_id)} />}
                                                sx={{ '& .MuiSvgIcon-root': { width: '18px', height: '18px', } }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid sx={{ marginRight: '10px' }}>
                                        <Typography sx={{ fontSize: '12px', color: '#27292f', }}>
                                            {page.page_name}
                                        </Typography>
                                    </Grid>
                                    {/* <Grid sx={{}}>
                                        <Typography sx={{ color: '#747782', fontSize: '12px', }}>100+</Typography>
                                    </Grid> */}
                                </Box>
                            </div>
                        ))}
                        <Link to="/dashboard" style={{ textDecoration: 'none' }}>
                            <Typography sx={{ color: '#567cde', fontSize: '13px', }}> <Plusicon sx={{ verticalAlign: 'middle', width: '20px' }} /> Connect Page </Typography>
                        </Link>
                    </AccordionDetails>
                </Accordion>

                <Accordion variant="0">
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel7-content"
                        id="panel7-header"
                        sx={{ padding: '3px 7px', '& .css-o4b71y-MuiAccordionSummary-content.Mui-expanded': { margin: '10px 0' }, margin: '0px !important' }}
                    >
                        <div style={{ width: '100%', }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'nowrap',
                                    p: 0,
                                    position: 'relative'
                                }}
                            >
                                <Grid sx={{ marginRight: '6px', position: 'relative' }}>
                                    <Grid sx={{ position: 'relative' }}>
                                        <img src={Linkedinsub} />
                                        <FormControlLabel control={<Checkbox checked={formik?.values?.linkedInAccountSelectAll} onClick={(e) => e.stopPropagation()} onChange={handleLinkedInAccountSelectAll} value={formik?.values?.linkedInAccountSelectAll} style={{ padding: 0, position: 'absolute', right: '0px', top: '-10px', }} />}
                                            sx={{ '& .MuiSvgIcon-root': { width: '18px', height: '18px', } }}
                                        />
                                    </Grid>
                                    <Badge
                                        badgeContent={linkedInAccountList.length} // Pass the count value to badgeContent
                                        color="error" // Choose your badge col or
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        sx={{ top: '-39px', left: '128px' }}
                                    />

                                </Grid>

                                <Grid>
                                    <Typography sx={{
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        textAlign: 'left',
                                        color: '#27292f !important',
                                    }}>Linkedin</Typography>
                                    <Typography sx={{
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        textAlign: 'left',
                                        color: '#87888e !important',
                                    }}>Accounts</Typography>
                                </Grid>
                            </Box>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: '3px 7px', marginTop: '0px' }}>
                        {linkedInAccountList && linkedInAccountList?.map((page, index) => (
                            <div style={{ width: '100%', marginBottom: '10px' }} key={index}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'nowrap',
                                        p: 0,
                                    }}
                                >
                                    <Grid sx={{ marginRight: '7px' }}>
                                        <Grid sx={{ position: 'relative' }}>
                                            <img src={(page.profile) ? (page.profile) : (Listimg)} style={{ width: '35px', height: '30px', borderRadius: '5px', overflow: 'hidden' }} />
                                            <FormControlLabel control={<Checkbox style={{ padding: 0, position: 'absolute', right: '-8px', top: '-9px', }} checked={(formik?.values?.linkedInSelectedAccounts) ? (formik?.values?.linkedInSelectedAccounts).some(selectedPage => selectedPage.social_media_account_id === page.social_media_account_id) : ('')}
                                                onChange={() => handleLinkedInAccountCheckboxChange(page.social_media_id, page.social_media_account_id)} />}
                                                sx={{ '& .MuiSvgIcon-root': { width: '18px', height: '18px', } }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid sx={{ marginRight: '10px' }}>
                                        <Typography sx={{ fontSize: '12px', color: '#27292f', }}>
                                            {page.name}
                                        </Typography>
                                    </Grid>
                                    {/* <Grid sx={{}}>
                                        <Typography sx={{ color: '#747782', fontSize: '12px', }}>100+</Typography>
                                    </Grid> */}
                                </Box>
                            </div>
                        ))}
                        <Link to="/dashboard" style={{ textDecoration: 'none' }}>
                            <Typography sx={{ color: '#567cde', fontSize: '13px', }}> <Plusicon sx={{ verticalAlign: 'middle', width: '20px' }} /> Connect Account </Typography>
                        </Link>
                    </AccordionDetails>
                </Accordion>

                <Accordion variant="0">
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        sx={{ padding: '3px 7px', '& .css-o4b71y-MuiAccordionSummary-content.Mui-expanded': { margin: '10px 0' }, margin: '0px !important' }}
                    >
                        <div style={{ width: '100%', }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'nowrap',
                                    p: 0,
                                    position: 'relative'
                                }}
                            >
                                <Grid sx={{ marginRight: '6px', position: 'relative' }}>
                                    <Grid sx={{ position: 'relative' }}>
                                        <img src={Twittersub} />
                                        <FormControlLabel control={<Checkbox checked={formik.values.twitterAccountSelectAll} onClick={(e) => e.stopPropagation()} onChange={handleTwitterAccountSelectAll} value={formik.values.twitterAccountSelectAll} style={{ padding: 0, position: 'absolute', right: '0px', top: '-10px', }} />}
                                            sx={{ '& .MuiSvgIcon-root': { width: '18px', height: '18px', } }}
                                        />
                                    </Grid>
                                    <Badge
                                        badgeContent={twitterAccountList.length} // Pass the count value to badgeContent
                                        color="error" // Choose your badge col or
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        sx={{ top: '-50px', left: '128px' }}
                                    />
                                </Grid>
                                <Grid>
                                    <Typography sx={{
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        textAlign: 'left',
                                        color: '#27292f !important',
                                    }}>Twitter</Typography>
                                    <Typography sx={{
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        textAlign: 'left',
                                        color: '#87888e !important',
                                    }}>Accounts</Typography>
                                </Grid>
                            </Box>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: '3px 7px', marginTop: '0px' }}>
                        {twitterAccountList && twitterAccountList?.map((page, index) => (
                            <div style={{ width: '100%', marginBottom: '10px' }} key={index}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'nowrap',
                                        p: 0,
                                    }}
                                >
                                    <Grid sx={{ marginRight: '7px' }}>
                                        <Grid sx={{ position: 'relative' }}>
                                            <img src={(page.profile) ? (page.profile) : (Listimg)} style={{ width: '35px', height: '30px', borderRadius: '5px', overflow: 'hidden' }} />
                                            <FormControlLabel control={<Checkbox style={{ padding: 0, position: 'absolute', right: '-8px', top: '-9px', }} checked={(formik?.values?.twitterSelectedAccounts) ? (formik?.values?.twitterSelectedAccounts).some(selectedPage => selectedPage.social_media_account_id === page.social_media_account_id) : ('')}
                                                onChange={() => handleTwitterAccountCheckboxChange(page.social_media_id, page.social_media_account_id)} />}
                                                sx={{ '& .MuiSvgIcon-root': { width: '18px', height: '18px', } }}
                                            />
                                        </Grid>
                                    </Grid>


                                    <Grid sx={{ marginRight: '10px' }}>
                                        <Typography sx={{ fontSize: '12px', color: '#27292f', }}>
                                            {page.name}
                                        </Typography>
                                    </Grid>
                                </Box>
                            </div>
                        ))}
                        <Link to="/dashboard" style={{ textDecoration: 'none' }}>
                            <Typography sx={{ color: '#567cde', fontSize: '13px', }}> <Plusicon sx={{ verticalAlign: 'middle', width: '20px' }} /> Connect Account  </Typography>
                        </Link>
                    </AccordionDetails>
                </Accordion>

                <Accordion variant="0">
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel5-content"
                        id="panel5-header"
                        sx={{ padding: '3px 7px', '& .css-o4b71y-MuiAccordionSummary-content.Mui-expanded': { margin: '10px 0' }, margin: '0px !important' }}
                    >
                        <div style={{ width: '100%', }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'nowrap',
                                    p: 0,
                                    position: 'relative'
                                }}
                            >

                                <Grid sx={{ marginRight: '6px', position: 'relative' }}>
                                    <Grid sx={{ position: 'relative' }}>
                                        <img src={Channelsub} />
                                        <FormControlLabel control={<Checkbox checked={formik?.values?.youtubeAccountSelectAll} onClick={(e) => e.stopPropagation()} onChange={handleYoutubeAccountSelectAll} value={formik?.values?.youtubeAccountSelectAll} style={{ padding: 0, position: 'absolute', right: '0px', top: '-10px', }} />}
                                            sx={{ '& .MuiSvgIcon-root': { width: '18px', height: '18px', } }}
                                        />
                                    </Grid>
                                    <Badge
                                        badgeContent={youtubeChannelList.length} // Pass the count value to badgeContent
                                        color="error" // Choose your badge color
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        sx={{ top: '-39px', left: '128px' }}
                                    />
                                </Grid>

                                <Grid>
                                    <Typography sx={{
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        textAlign: 'left',
                                        color: '#27292f !important',
                                    }}>Youtube</Typography>
                                    <Typography sx={{
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        textAlign: 'left',
                                        color: '#87888e !important',
                                    }}>Accounts</Typography>
                                </Grid>
                            </Box>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: '3px 7px', marginTop: '0px' }}>
                        {youtubeChannelList && youtubeChannelList?.map((page, index) => (
                            <div style={{ width: '100%', marginBottom: '10px' }} key={index}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'nowrap',
                                        p: 0,
                                    }}
                                >
                                    <Grid sx={{ marginRight: '7px' }}>
                                        <Grid sx={{ position: 'relative' }}>
                                            <img src={(page.profile) ? (page.profile) : (Listimg)} style={{ width: '35px', height: '30px', borderRadius: '5px', overflow: 'hidden' }} />
                                            <FormControlLabel control={<Checkbox style={{ padding: 0, position: 'absolute', right: '-8px', top: '-9px', }} checked={(formik?.values?.youtubeSelectedAccounts) ? (formik?.values?.youtubeSelectedAccounts).some(selectedPage => selectedPage.social_media_account_id === page.social_media_account_id) : ('')}
                                                onChange={() => handleYoutubeAccountCheckboxChange(page.social_media_id, page.social_media_account_id)} />}
                                                sx={{ '& .MuiSvgIcon-root': { width: '18px', height: '18px', } }}
                                            />
                                        </Grid>
                                    </Grid>


                                    <Grid sx={{ marginRight: '10px' }}>
                                        <Typography sx={{ fontSize: '12px', color: '#27292f', }}>
                                            {page.name}
                                        </Typography>
                                    </Grid>
                                </Box>
                            </div>
                        ))}
                        <Link to="/dashboard" style={{ textDecoration: 'none' }}>
                            <Typography sx={{ color: '#567cde', fontSize: '13px', }}> <Plusicon sx={{ verticalAlign: 'middle', width: '20px' }} /> Connect Account </Typography>
                        </Link>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </>
    )
}

export default Socialselect