import {
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Button,
} from "@mui/material";
//import { Link } from "react-router-dom"
import styleCss from "../Style/style";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { nonAuthpostAxios } from "../Utilities/commonAxios";

const Register = () => {
  const navigate = useNavigate();
  const registerForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: "",
      lastname: "",
      companyname: "",
      email: "",
      mobile: "",
      password: "",
      isTermsAgreed: false,
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Please Enter First Name"),
      lastname: Yup.string().required("Please Enter Last Name"),
      companyname: Yup.string().required("Please Enter Company"),
      email: Yup.string().email().required("Please Enter Email"),
      mobile: Yup.string()
        .matches(/^[0-9]{10}$/, "Mobile number must be valid")
        .min(10, "Mobile number must be at least 10 digits")
        .max(10, "Mobile number cannot be longer than 10 digits")
        .required("Please Enter Mobile number"),
      password: Yup.string().required("Please Enter Password"),
      isTermsAgreed: Yup.boolean()
        .oneOf([true], "Must agree to the terms of service and privacy policy")
        .required("Must agree to the terms of service and privacy policy"),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const { data: apiResponse } = await nonAuthpostAxios({
        url: `${process.env.REACT_APP_BACKENDURL}api/register`,
        data: {
          firstname: values.firstname,
          lastname: values.lastname,
          companyname: values.companyname,
          emailid: values.email,
          mobileno: values.mobile,
          userpassw: values.password
        },
      });
      if (apiResponse.flag === 1) {
        navigate("/login");
        toast.success(apiResponse.message);
      } else {
        toast.error(apiResponse.message);
      }
    },
  });
  return (
    <>
      <Grid>
        <Typography style={styleCss.WelcomText}>Welcome back,</Typography>
        <Typography style={styleCss.signText}>Register with us!</Typography>
      </Grid>
      <form onSubmit={registerForm.handleSubmit}>
        <Grid container item spacing={1}>
          <Grid item md={6}>
            <TextField
              id="firstname"
              label="First Name"
              name="firstname"
              onChange={registerForm.handleChange}
              value={registerForm.values.firstname}
              variant="outlined"
              size="small"
              fullWidth
              sx={{ mb: 1 }}
              InputLabelProps={{
                sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
              }}
              inputProps={{
                sx: { color: "#000000de", fontSize: "15px", fontWeight: 500 },
              }}
            />
            {registerForm.errors.firstname ? (
              <Typography variant="body2" sx={{ color: "red", fontSize: 14 }}>
                {registerForm.errors.firstname}
              </Typography>
            ) : null}
          </Grid>
          <Grid item md={6}>
            <TextField
              id="lastname"
              label="Last Name"
              name="lastname"
              onChange={registerForm.handleChange}
              value={registerForm.values.lastname}
              variant="outlined"
              size="small"
              fullWidth
              sx={{ mb: 1 }}
              InputLabelProps={{
                sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
              }}
              inputProps={{
                sx: { color: "#000000de", fontSize: "15px", fontWeight: 500 },
              }}
            />
            {registerForm.errors.lastname ? (
              <Typography variant="body2" sx={{ color: "red", fontSize: 14 }}>
                {registerForm.errors.lastname}
              </Typography>
            ) : null}
          </Grid>
          <Grid item md={12}>
            <TextField
              id="companyname"
              label="Company Name"
              name="companyname"
              onChange={registerForm.handleChange}
              value={registerForm.values.companyname}
              variant="outlined"
              size="small"
              fullWidth
              sx={{ mb: 1 }}
              InputLabelProps={{
                sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
              }}
              inputProps={{
                sx: { color: "#000000de", fontSize: "15px", fontWeight: 500 },
              }}
            />
            {registerForm.errors.companyname ? (
              <Typography variant="body2" sx={{ color: "red", fontSize: 14 }}>
                {registerForm.errors.companyname}
              </Typography>
            ) : null}
          </Grid>
          <Grid item md={12}>
            <TextField
              id="email"
              label="E-Mail"
              name="email"
              onChange={registerForm.handleChange}
              value={registerForm.values.email}
              variant="outlined"
              size="small"
              fullWidth
              sx={{ mb: 1 }}
              InputLabelProps={{
                sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
              }}
              inputProps={{
                sx: { color: "#000000de", fontSize: "15px", fontWeight: 500 },
              }}
            />
            {registerForm.errors.email ? (
              <Typography variant="body2" sx={{ color: "red", fontSize: 14 }}>
                {registerForm.errors.email}
              </Typography>
            ) : null}
          </Grid>
          <Grid item md={12}>
            <TextField
              id="mobile"
              label="Mobile"
              name="mobile"
              onChange={registerForm.handleChange}
              value={registerForm.values.mobile}
              variant="outlined"
              size="small"
              fullWidth
              sx={{ mb: 1 }}
              InputLabelProps={{
                sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
              }}
              inputProps={{
                sx: { color: "#000000de", fontSize: "15px", fontWeight: 500 },
              }}
            />
            {registerForm.errors.mobile ? (
              <Typography variant="body2" sx={{ color: "red", fontSize: 14 }}>
                {registerForm.errors.mobile}
              </Typography>
            ) : null}
          </Grid>
          <Grid item md={12}>
            <TextField
              id="password"
              type="password"
              name="password"
              onChange={registerForm.handleChange}
              value={registerForm.values.password}
              label="Password"
              variant="outlined"
              size="small"
              fullWidth
              sx={{}}
              InputLabelProps={{
                sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
              }}
              inputProps={{
                sx: { color: "#000000de", fontSize: "15px", fontWeight: 500 },
              }}
            />
            {registerForm.errors.password ? (
              <Typography variant="body2" sx={{ color: "red", fontSize: 14 }}>
                {registerForm.errors.password}
              </Typography>
            ) : null}
          </Grid>
          <Grid item md={12}>
            <FormGroup sx={{ position: "relative" }}>
              <FormControlLabel
                control={<Checkbox />}
                name="isTermsAgreed"
                onChange={registerForm.handleChange}
                checked={registerForm.values.isTermsAgreed}
              />
              <Grid
                className="agree-style"
                sx={{
                  position: "absolute",
                  right: "9px",
                  top: "9px",
                  color: "#858687",
                }}
              >
                I agree to all the &nbsp;
                <Link to="/" style={styleCss.linkstyle}>
                  terms of service{" "}
                </Link>{" "}
                and
                <Link to="/" style={styleCss.linkstyle}>
                  {" "}
                  privacy policy
                </Link>
                {registerForm.errors.isTermsAgreed ? (
                  <Typography
                    variant="body2"
                    sx={{ color: "red", fontSize: 14 }}
                  >
                    {registerForm.errors.isTermsAgreed}
                  </Typography>
                ) : null}
              </Grid>
            </FormGroup>
          </Grid>
          <Grid item md={12}>
            <Button variant="contained" type="submit" style={styleCss.SignBtn}>
              Sign up
            </Button>
          </Grid>
          <Grid item md={12}>
            <Typography style={styleCss.account}>
              Already have an account?
              <Link
                to="/login"
                style={{
                  fontWeight: "bold",
                  color: "#27292f",
                }}
              >
                {" "}
                Sign In{" "}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default Register;
