import { Container, Grid } from "@mui/material"
import Socialbox from "./connectsocial"


const Dashboard = () => {
    return (
        <>
            <Socialbox/>  
        </>
    )
}
export default Dashboard