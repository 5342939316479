import { Box, Grid, Tab, TextField, Typography, Button } from "@mui/material"
import styleCss from "../Style/style"
import React from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Socialleadstable from "./socialleadstable";

const Socialclientlead = () => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Grid style={styleCss.commonpaddingtop}>
                <Grid container item columnSpacing={2}>
                    <Grid item md={3} xs={6}>
                        <Grid sx={{ backgroundColor: '#ecf2ff', padding: '20px', borderRadius: '5px' }}>
                            <Typography sx={{ textAlign: 'center', color: '#5d87ff', fontSize: '17px', fontFamily: 'Inter' }}>
                                Facebook
                            </Typography>
                            <Typography sx={{ textAlign: 'center', color: '#5d87ff', fontSize: '18px', fontWeight: 600, fontFamily: 'Inter' }}>
                                100
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Grid sx={{ backgroundColor: '#fef5e5', padding: '20px', borderRadius: '5px' }}>
                            <Typography sx={{ textAlign: 'center', color: '#ffae1f', fontSize: '17px', fontFamily: 'Inter' }}>
                                Instagramleads
                            </Typography>
                            <Typography sx={{ textAlign: 'center', color: '#ffae1f', fontSize: '18px', fontWeight: 600, fontFamily: 'Inter' }}>
                                100
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Grid sx={{ backgroundColor: '#e6fffa', padding: '20px', borderRadius: '5px' }}>
                            <Typography sx={{ textAlign: 'center', color: '#13deb9', fontSize: '17px' }}>
                                Facebook
                            </Typography>
                            <Typography sx={{ textAlign: 'center', color: '#13deb9', fontSize: '18px', fontWeight: 600 }}>
                                100
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Grid sx={{ backgroundColor: '#fdede8', padding: '20px', borderRadius: '5px' }}>
                            <Typography sx={{ textAlign: 'center', color: '#fa896b', fontSize: '17px' }}>
                                Instagramleads
                            </Typography>
                            <Typography sx={{ textAlign: 'center', color: '#fa896b', fontSize: '18px', fontWeight: 600 }}>
                                100
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Box sx={{ width: '100%', border: '1px solid #ddd', marginTop: '30px', borderRadius: '5px', boxShadow: '#919eab4d 0 0 2px, #919eab1f 0 12px 24px -4px', }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} sx={{
                                    '& .MuiTab-root': {
                                        fontSize: '13px !important',
                                        fontWeight: 'bold !important',
                                        fontFamily: 'Inter !important',
                                    },
                                    '& .Mui-selected': {
                                        fontWeight: 'bold !important',
                                        fontFamily: 'Inter !important',
                                    },
                                }}>
                                    <Tab label="Facebookleads" value="1" />
                                    <Tab label="Instagramleads" value="2" />
                                    <Tab label="Googleleads" value="3" />
                                    <Tab label="webleads" value="4" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <Grid container item columnSpacing={2}>
                                    <Grid item md={5}>
                                        <TextField fullWidth label="From" id="fullWidth" size="small" />
                                    </Grid>
                                    <Grid item md={5}>
                                        <TextField fullWidth label="To" id="fullWidth" size="small" />
                                    </Grid>
                                    <Grid item md={2}>
                                        <Button
                                            variant="outlined"
                                            size="medium"
                                            sx={{ textTransform: 'capitalize', color: 'primary.main' }}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item md={12} sx={{ marginTop: '20px', alignItems: 'center' }}>
                                        <Socialleadstable />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value="2">
                                <Grid container item columnSpacing={2}>
                                    <Grid item md={5}>
                                        <TextField fullWidth label="From" id="fullWidth" size="small" />
                                    </Grid>
                                    <Grid item md={5}>
                                        <TextField fullWidth label="To" id="fullWidth" size="small" />
                                    </Grid>
                                    <Grid item md={2}>
                                        <Button
                                            variant="outlined"
                                            size="medium"
                                            sx={{ textTransform: 'capitalize', color: 'primary.main' }}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item md={12} sx={{ marginTop: '20px', alignItems: 'center' }}>
                                        <Socialleadstable />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value="3">
                                <Grid container item columnSpacing={2}>
                                    <Grid item md={5}>
                                        <TextField fullWidth label="From" id="fullWidth" size="small" />
                                    </Grid>
                                    <Grid item md={5}>
                                        <TextField fullWidth label="To" id="fullWidth" size="small" />
                                    </Grid>
                                    <Grid item md={2}>
                                        <Button
                                            variant="outlined"
                                            size="medium"
                                            sx={{ textTransform: 'capitalize', color: 'primary.main' }}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item md={12} sx={{ marginTop: '20px', alignItems: 'center' }}>
                                        <Socialleadstable />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value="4">
                                <Grid container item columnSpacing={2}>
                                    <Grid item md={5}>
                                        <TextField fullWidth label="From" id="fullWidth" size="small" />
                                    </Grid>
                                    <Grid item md={5}>
                                        <TextField fullWidth label="To" id="fullWidth" size="small" />
                                    </Grid>
                                    <Grid item md={2}>
                                        <Button
                                            variant="outlined"
                                            size="medium"
                                            sx={{ textTransform: 'capitalize', color: 'primary.main' }}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item md={12} sx={{ marginTop: '20px', alignItems: 'center' }}>
                                        <Socialleadstable />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Socialclientlead