import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styleCss from '../Style/style';



export default function Socialclientlead() {
    return (
        <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={styleCss.clientHead}>S.No</TableCell>
                        <TableCell style={styleCss.clientHead}>Social Media</TableCell>
                        <TableCell style={styleCss.clientHead}>Page Name</TableCell>
                        <TableCell style={styleCss.clientHead}> Campagin Name</TableCell>
                        <TableCell style={styleCss.clientHead}>Lead Name</TableCell>
                        <TableCell style={styleCss.clientHead}>Lead Mobile No</TableCell>
                        <TableCell style={styleCss.clientHead}>Lead Email Id</TableCell>
                        <TableCell style={styleCss.clientHead}>Lead Created Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    <TableRow>
                        <TableCell style={styleCss.clientsubHead}>1</TableCell>
                        <TableCell style={styleCss.clientsubHead}>Facebook</TableCell>
                        <TableCell style={styleCss.clientsubHead}>Edupointsolution</TableCell>
                        <TableCell style={styleCss.clientsubHead}>[16/05/2024] Promoting paytrav_led_from</TableCell>
                        <TableCell style={styleCss.clientsubHead}>Muthukumar</TableCell>
                        <TableCell style={styleCss.clientsubHead}>+9908765432 </TableCell>
                        <TableCell style={styleCss.clientsubHead}>samplemail@gmail.com </TableCell>
                        <TableCell style={styleCss.clientsubHead}>25-06-20024 04:51:23 </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={styleCss.clientsubHead}>2</TableCell>
                        <TableCell style={styleCss.clientsubHead}>Facebook</TableCell>
                        <TableCell style={styleCss.clientsubHead}>Edupointsolution</TableCell>
                        <TableCell style={styleCss.clientsubHead}>[16/05/2024] Promoting paytrav_led_from</TableCell>
                        <TableCell style={styleCss.clientsubHead}>Muthukumar</TableCell>
                        <TableCell style={styleCss.clientsubHead}>+9908765432 </TableCell>
                        <TableCell style={styleCss.clientsubHead}>samplemail@gmail.com </TableCell>
                        <TableCell style={styleCss.clientsubHead}>25-06-20024 04:51:23 </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}