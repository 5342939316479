import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDispatch } from "react-redux";
import { authpostAxios } from "../Utilities/commonAxios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { fileToBase64 } from "../Utilities/fileConversion";
import { useSelector } from 'react-redux';
import {updateUserProfile } from '../Reduxstore/authSlice';

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  padding: 1,
  boxSizing: "border-box",
  ObjectFit: "contain",
  placeItems: "center",
  color: "#000",
  paddingRight: "10px",
  fontWeight: 500,
  marginTop: 1.5,
};
const img = {
  display: "block",
  width: "80px",
  height: "80px",
  objectFit: "contain",
};

export default function MyProfile() {
 
  const dispatch = useDispatch();
  // initial company data
  const [profileDetails, setProfileDetails] = useState([]);
    const getUserProfileDetailsFromAPI = async ()=>{
      await authpostAxios(
        {
          'url': `${process.env.REACT_APP_BACKENDURL}api/userprofile-details`,
          'data': ""
      },dispatch).then(response=>setProfileDetails(response?.data?.data?.results[0]))
    }
  const submitProfileForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: (profileDetails)?(profileDetails?.first_name):(""),
      lastName: (profileDetails)?(profileDetails?.last_name):(""),
      email: (profileDetails)?(profileDetails?.email):(""),
      phoneNo: (profileDetails)?(profileDetails?.phone):(""),
      profilePicture: "",
      profilePictureExistPath: (profileDetails)?(profileDetails?.image_logo):("")
    },
    validationSchema: Yup.object({
      profilePicture: Yup.string().when(['profilePictureExistPath'],([profilePictureExistPath],schema)=>
        (profilePictureExistPath === "")?(schema.required("Please upload profile picture")):(schema)
      )
    }),
    onSubmit: async (values) => {
      if (!submitProfileForm.dirty) {
        toast.error("No data has changed!");
      } else {
        let { data: userProfileResponse } = await authpostAxios(
          {
              'url': `${process.env.REACT_APP_BACKENDURL}api/userprofile-register`,
              'data': {
                profilePicture:
                  values.profilePicture === ""
                    ? ""
                    : await fileToBase64(values.profilePicture),
                profilePictureExistPath:values.profilePictureExistPath
              }
          },dispatch)
          if (userProfileResponse.flag === 1) {
            submitProfileForm.setFieldValue('profilePicture', '')
            // getUserProfileDetailsFromAPI()
            dispatch(updateUserProfile(userProfileResponse.data));
            toast.success(userProfileResponse.message)
        } else {
            toast.error(userProfileResponse.message)
        }
      }
    }
  });
  // image preview
  const [selectedProfilePicture, setSelectedProfilePicture] = useState();
  useEffect(() => {
    getUserProfileDetailsFromAPI();
  },[])
  return (
    <Grid md={12} sx={{ mb: 2 }}>
      <Grid
        sx={{
          boxShadow: "#919eab4d 0 0 2px, #919eab1f 0 12px 24px -4px",
          border: "1px solid #ddd",
          padding: "25px",
          borderRadius: "5px",
        }}
      >
        <form onSubmit={submitProfileForm.handleSubmit}>
          <Grid container item columnSpacing={2}>
            <Grid item md={6}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                value={submitProfileForm.values.firstName}
                id="firstName"
                size="small"
                sx={{ mb: 2 }}
                InputLabelProps={{
                  sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
                  shrink:true,
                }}
                inputProps={{
                  sx: {
                    color: "#000000de",
                    fontSize: "15px",
                    fontWeight: 500,
                    fontFamily: "Inter",
                  },
                  readOnly:true
                }}
              />
              <TextField
                fullWidth
                label="Phone Number"
                name="phoneNo"
                value={submitProfileForm.values.phoneNo}
                id="phoneNo"
                size="small"
                sx={{ mb: 2 }}
                InputLabelProps={{
                  sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
                  shrink:true,
                }}
                inputProps={{
                  sx: {
                    color: "#000000de",
                    fontSize: "15px",
                    fontWeight: 500,
                    fontFamily: "Inter",
                  },
                  readOnly:true
                }}
              />
              <input
                type="file"
                name="profilePicture"
                id="profilePicture"
                style={{ display: "none" }}
                accept="image/*"
                onChange={(e) => {
                  submitProfileForm.setFieldValue(
                    "profilePicture",
                    e.currentTarget.files[0]
                  );
                  setSelectedProfilePicture(e.currentTarget.files[0]);
                }}
              />
              <Button
                component="label"
                htmlFor="profilePicture"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                fullWidth
                startIcon={<CloudUploadIcon />}
                sx={{
                  textTransform: "capitalize",
                  fontFamily: "Inter",
                  background: "#fff",
                  border: "1px solid #cfd7df",
                  color: "#6e747a",
                }}
                onChange={(e) => {
                  submitProfileForm.setFieldValue(
                    "profilePicture",
                    e.currentTarget.files[0]
                  );
                  setSelectedProfilePicture(e.currentTarget.files[0]);
                }}
              >
                Upload profile picture
              </Button>

              {selectedProfilePicture ? (
                <Grid sx={thumb}>
                  <img
                    src={URL.createObjectURL(selectedProfilePicture)}
                    style={img}
                  />
                </Grid>
              ) : (
                submitProfileForm.values.profilePictureExistPath && (
                  <Grid sx={thumb}>
                    <img
                      src={submitProfileForm.values.profilePictureExistPath}
                      style={img}
                    />
                  </Grid>
                )
              )}
              {submitProfileForm.errors.profilePicture ? (
                <Typography variant="body2" sx={{ color: "red", fontSize: 14 }}>
                  {submitProfileForm.errors.profilePicture}
                </Typography>
              ) : null}
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                value={submitProfileForm.values.lastName}
                id="lastName"
                size="small"
                sx={{ mb: 2 }}
                InputLabelProps={{
                  sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
                  shrink:true,
                }}
                inputProps={{
                  sx: {
                    color: "#000000de",
                    fontSize: "15px",
                    fontWeight: 500,
                    fontFamily: "Inter",
                  },
                  readOnly:true
                }}
              />
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={submitProfileForm.values.email}
                id="email"
                size="small"
                sx={{ mb: 2 }}
                InputLabelProps={{
                  sx: { color: "#92a2b1", fontSize: "15px", fontWeight: 400 },
                  shrink:true,
                }}
                inputProps={{
                  sx: {
                    color: "#000000de",
                    fontSize: "15px",
                    fontWeight: 500,
                    fontFamily: "Inter",
                  },
                  readOnly:true
                }}
              />
              {submitProfileForm.errors.email ? (
                <Typography variant="body2" sx={{ color: "red", fontSize: 14 }}>
                  {submitProfileForm.errors.email}
                </Typography>
              ) : null}
            </Grid>

            <Grid item md={12}>
              <Grid sx={{ textAlign: "end" }}>
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "capitalize",
                    marginRight: "20px",
                    fontFamily: "Inter",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ textTransform: "capitalize", fontFamily: "Inter" }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}
