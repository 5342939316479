import Dashboardicon1 from "../img/dashboard-icon-1.svg";
import Dashboardicon2 from "../img/dashboard-icon-2.svg";
import Dashboardicon3 from "../img/dashboard-icon-3.png";
import Dashboardicon4 from "../img/dashboard-icon-4.svg";
import Dashboardicon5 from "../img/dashboard-icon-5.svg";
import Dashboardicon6 from "../img/dashboard-icon-6.svg";
import Dashboardicon7 from "../img/dashboard-icon-7.png";
import Dashboardicon8 from "../img/dashboard-icon-8.png";
import Dashboardicon9 from "../img/dashboard-icon-9.png";
import readMessage from "../img/read-message.png";
import Editicon from '../img/editicon.png';
import addbudget from '../img/icon1.png';
import addUserIcon from '../img/icon2.png'




export const socialMediaLists = {
    'Dashboard': {
      icon: Dashboardicon1,
    },
    'Inbox': {
      icon: Dashboardicon2,
    },
    'Read Inbox': {
      icon: readMessage,
    },
    'MyCalendar': {
      icon: Dashboardicon3,
    },
    'Publish Post': {
      icon: Dashboardicon4,
    },
    'budger Tracker': {
      icon: addbudget,
    },
    'User Creation' :{
      icon : addUserIcon,
    },
    'Manage AccountPage': {
      icon: Dashboardicon7,
    },
    'User Roles': {
      icon: Dashboardicon6,
    },
    'logout': {
      icon: Dashboardicon9,
    },
    'Social Post': {
      icon: Editicon,
    }
  };