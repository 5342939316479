import { Grid, List, ListItem, Typography, Tooltip } from "@mui/material"
import styleCss from '../Style/style';
import Editicon from '../img/editicon.png';
import { Link } from "react-router-dom";
import Dashboardicon1 from "../img/dashboard-icon-1.svg";
import Dashboardicon2 from "../img/dashboard-icon-2.svg";
import Dashboardicon3 from "../img/dashboard-icon-3.png";
import Dashboardicon4 from "../img/dashboard-icon-4.svg";
import Dashboardicon5 from "../img/dashboard-icon-5.svg";
import Dashboardicon6 from "../img/dashboard-icon-6.svg";
import Dashboardicon7 from "../img/dashboard-icon-7.png";
import Dashboardicon8 from "../img/dashboard-icon-8.png";
import Dashboardicon9 from "../img/dashboard-icon-9.png";
import readMessage from "../img/read-message.png";
import { manageUserSession } from "../Reduxstore/authSlice";
import { useDispatch, useSelector } from "react-redux";
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { socialMediaLists } from '../Utilities/sideMenuList';





const Mainsidemenu = () => {
  const authState = useSelector(state => state.auth)
  let userAuthRoutes = authState?.postItems?.data?.userAuthRoutes;
  const route = userAuthRoutes.find(route => route.name === 'socialpost');

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const dispatch = useDispatch();
  const submitLogout = async () => await dispatch(manageUserSession('logout'))
  return (
    <>

      <Grid style={styleCss.sidemenustyle}>
        {/* <IconButton onClick={handleClick}>
          <img src={Editicon} alt="Edit icon" />
        </IconButton> */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {/*
        <Link to="/compose-post" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MenuItem onClick={handleClose}>
            <Typography sx={{
              fontSize: '12px',
              fontWeight: 'normal',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: 2.5,
              letterSpacing: 'normal',
              textAlign: 'center',
              color: '#a4a4a4',
            }}>
              Menu
            </Typography>
          </MenuItem>
        </Link>
        */}

          <Link to={"/compose-post"} style={{ textDecoration: 'none', color: 'inherit' }}>
            <MenuItem onClick={handleClose}>
              <Typography sx={{
                fontSize: '12px',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 2.5,
                letterSpacing: 'normal',
                textAlign: 'center',
                color: '#a4a4a4',
              }}>
                Social Media Post
              </Typography>
            </MenuItem>
          </Link>
          <Link to={"/socialmediaads"} style={{ textDecoration: 'none', color: 'inherit' }}>
            <MenuItem onClick={handleClose}>
              <Typography sx={{
                fontSize: '12px',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 2.5,
                letterSpacing: 'normal',
                textAlign: 'center',
                color: '#a4a4a4',
              }}>
                Facebook Ads
              </Typography>
            </MenuItem>
          </Link>

        </Menu>

        {/*
              <Link to={"/compose-post"} style={{textDecoration:'none',}}>
                <img src={Editicon} />
                <Typography sx={{
                    fontSize: '12px',
                    fontWeight: 'normal',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 2.5,
                    letterSpacing: 'normal',
                    textAlign: 'center',
                    color: '#a4a4a4',
                }}>Menu</Typography>
                </Link> */}

        <List sx={{ p: 0 }}>

          {userAuthRoutes.length !== 0 &&
            userAuthRoutes.map((userRoute, index) => (

              <ListItem
                key={index}
                sx={{ display: 'block', paddingLeft: '0px', paddingRight: '0px', textAlign: 'center', marginBottom: '-5px' }}
              >
                <Tooltip title={userRoute.name} placement="right">
                  {userRoute.name != "socialpost" && userRoute.name != "logout" && (
                    <Link to={userRoute.path}>
                      <img src={socialMediaLists[userRoute.name]?.icon} />
                    </Link>
                  )}

                  {userRoute.name === "logout" && (
                    <Link to={userRoute.path} onClick={submitLogout} >
                      <img src={socialMediaLists[userRoute.name]?.icon} />
                    </Link>
                  )}


                  {userRoute.name === "socialpost" && (
                    <Link to={userRoute.path} onClick={handleClick} >
                      <img src={socialMediaLists[userRoute.name]?.icon} />
                    </Link>
                  )}

                </Tooltip>

              </ListItem>
            ))}

        </List>

        {/* <List sx={{ p: 0 }}>

          <ListItem sx={{ display: 'block', paddingLeft: '0px', paddingRight: '0px', textAlign: 'center' }}>
            <Tooltip title="Dashboard" placement="top">
              <Link to={"/dashboard"}><img src={Dashboardicon1} /> </Link>
            </Tooltip>
          </ListItem>

          <ListItem sx={{ display: 'block', paddingLeft: '0px', paddingRight: '0px', textAlign: 'center' }}>
            <Tooltip title="Inbox" placement="right">
              <Link to={"/inbox"}><img src={Dashboardicon2} /> </Link>
            </Tooltip>
          </ListItem>
          <ListItem sx={{ display: 'block', paddingLeft: '0px', paddingRight: '0px', textAlign: 'center' }}>
            <Tooltip title="Readinbox" placement="right">
              <Link to={"/readinbox"}><img src={readMessage} /> </Link>
            </Tooltip>
          </ListItem>
          <ListItem sx={{ display: 'block', paddingLeft: '0px', paddingRight: '0px', textAlign: 'center' }}>
            <Tooltip title="Calendar" placement="right">
              <Link to={"/calendar"}><img src={Dashboardicon3} /> </Link>
            </Tooltip>
          </ListItem>
          <ListItem sx={{ display: 'block', paddingLeft: '0px', paddingRight: '0px', textAlign: 'center' }}>
            <Tooltip title="Publish post" placement="right">
              <Link to={"/publish-post"}><img src={Dashboardicon4} /> </Link>
            </Tooltip>
          </ListItem>
          <ListItem sx={{ display: 'block', paddingLeft: '0px', paddingRight: '0px', textAlign: 'center' }}>
            <Tooltip title="Publish report" placement="right">
              <Link to={"/publish-report"}><img src={Dashboardicon5} /> </Link>
            </Tooltip>
          </ListItem>
        </List> */}
        {/* <Typography sx={{
          fontSize: '12px',
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 2.5,
          letterSpacing: 'normal',
          textAlign: 'center',
          color: '#a4a4a4',
        }}>Social</Typography>
        <ListItem sx={{ display: 'block', paddingLeft: '0px', paddingRight: '0px', textAlign: 'center' }}>
          <Tooltip title="Manage account" placement="right">
            <Link to={"/manage-account"}><img src={Dashboardicon6} /> </Link>
          </Tooltip>
        </ListItem>
        <Typography sx={{
          fontSize: '12px',
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 2.5,
          letterSpacing: 'normal',
          textAlign: 'center',
          color: '#a4a4a4',
        }}>General</Typography>
        <ListItem sx={{ display: 'block', paddingLeft: '0px', paddingRight: '0px', textAlign: 'center' }}>
          <Tooltip title="" placement="top">
            <Link to={"#"}><img src={Dashboardicon7} /> </Link>
          </Tooltip>
        </ListItem>
        <ListItem sx={{ display: 'block', paddingLeft: '0px', paddingRight: '0px', textAlign: 'center' }}>
          <Tooltip title="Settings" placement="right">
            <Link to={"#"}><img src={Dashboardicon8} /> </Link>
          </Tooltip>
        </ListItem>
        <ListItem sx={{ display: 'block', paddingLeft: '0px', paddingRight: '0px', textAlign: 'center' }}>
          <Tooltip title="Logout" placement="right">
            <Link to={"#"} onClick={submitLogout}><img src={Dashboardicon9} /> </Link>
          </Tooltip>
        </ListItem> */}
      </Grid>
    </>
  )
}

export default Mainsidemenu