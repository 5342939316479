import { Grid } from "@mui/material"
import Header from "../Home/header";
import Footer from "../Home/footer";

export const HomeLayout = props => {
    return (
        <>
            <Grid>
                <Grid container>
                    <Grid item md={12} xs={12}>
                        <Header/>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        {props.children}
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Footer/>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}