import { Typography, Grid, Container } from "@mui/material"
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Roundicon from '@mui/icons-material/Lens';


const privacyStyle = {
    marginTop: '115px',
    marginBottom:'30px',
}


const Faq = () => {
    return (
        <>
            <Grid style={privacyStyle}>
                <Container>
                    <Grid container>
                        <Grid item md={12}>
                            <Typography sx={{ fontSize: '25px', fontWeight: 600, textAlign: 'left' }}>REGISTRATION</Typography>
                        </Grid>
                        <Grid item md={12} sx={{ mt: 2 }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, }}> What is DLT and how to register on the DLT platform? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography sx={{ fontSize: '15px', mb: 1 }}>DLT (Distributed Leader Technology) is a blockchain registration system. TRAI (Telecom Regulatory Authority of India) has given guidelines for SMS services. For using this service you have to register in DLT. The TRAI regulation will keep a record of the transaction of SMS services. </Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1 }}>It is mandatory to get register on DLT platforms to send messages. Without registering in DLT platforms you will not be able to send messages and it gets failed. The main aim of the DLT is to bring transparency to the service and avoid fraudulent practices and protect the customers.</Typography>
                                    <Typography sx={{ fontSize: '15px', fontWeight: 500, mb: 3, mt: 2 }}>DLT OPERATOR LIST </Typography>
                                    <table className="table border">
                                        <thead>
                                            <tr>
                                                <th className="border border-1">S.no</th>
                                                <th className="border border-1">Operator Name</th>
                                                <th className="border border-1">Registration Link</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>1</th>
                                                <td>Vodafone Idea</td>
                                                <td>https://www.vilpower.in/</td>
                                            </tr>
                                            <tr>
                                                <th>2</th>
                                                <td>Jio</td>
                                                <td>https://trueconnect.jio.com/#/</td>
                                            </tr>
                                            <tr>
                                                <th>3</th>
                                                <td>Airtel</td>
                                                <td>https://dltconnect.airtel.in/</td>
                                            </tr>
                                            <tr>
                                                <th>4</th>
                                                <td>BSNL</td>
                                                <td>https://www.ucc-bsnl.co.in/</td>
                                            </tr>
                                            <tr>
                                                <th>5</th>
                                                <td>MTNL</td>
                                                <td>https://www.ucc-mtnl.in/</td>
                                            </tr>
                                            <tr>
                                                <th>6</th>
                                                <td>TATA</td>
                                                <td>https://telemarketer.tatateleservices.com:8082/#/</td>
                                            </tr>
                                            <tr>
                                                <th>7</th>
                                                <td>Videocon</td>
                                                <td>https://smartping.live/entity/login</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, }}> TYPES OF DLT REGISTRATION</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}>For DLT registration register according to the need of the customers. Below are the types</Typography>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, }}>ENTITY:</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}>An Entity is a business unit recognized as a business service who are communicating with customers through SMS or Telemarketers.</Typography>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, }}>TELEMARKETERS</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}>A telemarketer is those who work for other companies communication needs through SMS are voice call.</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}>After the registration, you will receive the unique registration ID. This will be used during the DLT registration process.</Typography>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, }}>PRICE TO REGISTER ON DLT</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}>DLT registration charge is Rs 5900 to be paid for the telecom operator depending on the network.</Typography>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, }}>PROCESS FOR THE ENTITY REGISTRATION PROCESS:</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />  Choose enterprises</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />  Select DLT operator Name</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />  Enter PAN details</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />  With required details fill the form</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />  OTP verification to be done</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />  Email verification to be done</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />  Login to the portal for completing the process</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />  Upload the documents and fill in the details required</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />  After completing the registration you will get the registration number</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />  After 72 hours your account will be activated</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />  After activation you will receive an enterprise registration ID</Typography>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, }}>PROCESS FOR THE TELEMARKETER REGISTRATION PROCESS:</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> Choose telemarketer</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> Choose the Aggregator function</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> Select mobile operator</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> Enter PAN details</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> With required details fill the form</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> OTP verification to be done</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> Email verification to be done</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> Login to the portal for completing the process</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> Upload the documents and fill in the details required</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> After completing the registration you will get the registration number</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> After 72 hours your account will be activated</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> After activation you will receive a telemarketer registration ID.</Typography>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, }}>STEPS TO REGISTER ON THE DLT PLATFORM</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}> Follow the below steps to get register on DLT platforms</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> Visit the DLT platform and register portal</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> Sign up with an enterprise</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />  Enter Business PAN Number</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />  For login purposes create a username and password</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />  Verification is done with OTP</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />  Verification is done with business Email ID</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />  After completing the sign-up process. You were logged in</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> Complete the registration form with the required details and documents</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> Depending on the network pay the fee.</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> After registration you will receive the temporary registration number</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> After 48 hours your account will be activated</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> After activation you can log in to DLT platforms</Typography>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, }}>DOCUMENTS REQUIRED</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> Business PAN number</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> Address proof</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> GST certificates</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> Incorporation certificate</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> TAN certificate</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />  FSSAI license</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> Letter of Authorization</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> Company registration certificate</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />  DLT guidelines will be finished in various stages. The primary period of enrollment is to join and finish the enlistment cycle on the DLT stage. Subsequently, Unengaged demands each endeavor to enlist their business on the DLT stage for the smooth working of SMS promoting efforts.</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> For further queries contact our expert teams.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                >
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, }}> How to register or sign up for a Business Manager?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography sx={{ fontSize: '15px', mb: 1 }}>Business accounts and ad accounts are maintained securely by the Business Manager. A business manager account allows you to manage the pages and have permission access to the page to change or remove any accounts. We have given below the quick and easy methods for signing up for a Business Manager.</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1 }}>To register as a Facebook business manager you should have an account to show your business identity. If you don’t have a business page create the business page. If you are a partner for a business get access to that accounts. Follow the below steps for registering as a business manager,</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> For business manager sign up go to https://business.facebook.com/</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> Create an account</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> Enter your business name, choose a primary page and enter the email address and address of the business account</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> Add people to the business manager</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} /> Your Business Manager account is created.</Typography>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, }}>ADDING A PAGE TO BUSINESS MANAGER</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}>To manage your business manager page you need to add a Facebook page and you can manage your page activity with it. To add a page you must have a verified primary page and you must be an admin for the business page for more than seven days. Page added by you cannot be owned by any other business. If you want rights you can get rights from that page owner. Add your Instagram business account if you have it.</Typography>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, }}>STEPS TO ADD A PAGE</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />  In the sidebar find the icon for business settings</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />  Go to Business select assets  accounts  pages</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />  Click add in the menu</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />   Choose to add a page</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />  Enter the Facebook URL or page name</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}><Roundicon sx={{ width: '11px' }} />  Select add page  add people </Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}> The Page is created and you can access and manage it.</Typography>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, }}>HOW TO ADD AN AD ACCOUNT TO A BUSINESS MANAGER</Typography>
                                    <Typography sx={{ fontSize: '15px', mb: 1, mt: 1 }}>Adding an Ad account page to your business manager page can help in promoting your brand or business. A business manager can only be able to handle the Ad account. There are three ways to add an AD account to the Business Manager. </Typography>
                                    <Typography sx={{ fontSize: '16px', }}><Roundicon sx={{ width: '11px' }} />  To a business account add an ad account.</Typography>
                                    <Typography sx={{ fontSize: '16px', }}><Roundicon sx={{ width: '11px' }} />  Request access permission from the Business Manager admin for an ad account</Typography>
                                    <Typography sx={{ fontSize: '16px', }}><Roundicon sx={{ width: '11px' }} />  Create a new page for the Ad account</Typography>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, mt: 1, mb: 1 }}>STEPS TO ADD AN AD ACCOUNT</Typography>
                                    <Typography sx={{ fontSize: '16px', }}><Roundicon sx={{ width: '11px' }} /> Find the Business setting on the sidebar</Typography>
                                    <Typography sx={{ fontSize: '16px', }}><Roundicon sx={{ width: '11px' }} /> Click Accounts from the menu  choose Ad accounts  click add from the drop-down menu</Typography>
                                    <Typography sx={{ fontSize: '16px', }}><Roundicon sx={{ width: '11px' }} /> Select one option from the below,</Typography>
                                    <Typography sx={{ fontSize: '16px', }}><Roundicon sx={{ width: '11px' }} /> Add an ad account</Typography>
                                    <Typography sx={{ fontSize: '16px', }}><Roundicon sx={{ width: '11px' }} /> Request access</Typography>
                                    <Typography sx={{ fontSize: '16px', }}><Roundicon sx={{ width: '11px' }} /> Create a new account</Typography>
                                    <Typography sx={{ fontSize: '16px', }}><Roundicon sx={{ width: '11px' }} /> Enter the ad account ID to add an ad account or request access.</Typography>
                                    <Typography sx={{ fontSize: '16px', }}><Roundicon sx={{ width: '11px' }} />  Select people and set permission levels.</Typography>
                                    <Typography sx={{ fontSize: '16px', }}><Roundicon sx={{ width: '11px' }} />  Now your Ad account is created to your business manager account. You cannot delete the created Ad account.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, }}>How to register for a WhatsApp Business account?</Typography>

                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}><Roundicon sx={{ width: '11px' }} />  Below are the steps for creating the WhatsApp business account if you are a Business Solution provider (BSP),</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}><Roundicon sx={{ width: '11px' }} />  Create a WhatsApp Business account</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}><Roundicon sx={{ width: '11px' }} />  You can send messages on behalf of the end client</Typography>
                                    <Typography sx={{ fontSize: '15px', fontWeight: 500, }}>NOTE:</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}><Roundicon sx={{ width: '11px' }} />  If a business solution provider (BSP) is assisting your organization with the specialized integration of the WhatsApp Business stage, use the pre-installed sign stream to create your WhatsApp Business account.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}><Roundicon sx={{ width: '11px' }} />  If you're creating for yourself or your organization rather than for a client, go to the WhatsApp Developer documentation to create your WhatsApp Business account.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}><Roundicon sx={{ width: '11px' }} />  Create a WhatsApp Business account if your company uses the WhatsApp Business app. Before registering,</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}><Roundicon sx={{ width: '11px' }} />  Sign up business manager account</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}><Roundicon sx={{ width: '11px' }} />  Only the admin of the business manager will be allowed to register</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}><Roundicon sx={{ width: '11px' }} />  Business manager profile account should be completed and then create an account for the business.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}>To create a WhatsApp Business account follow these steps,</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}>Go to the WhatsApp account setting in your business settings</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}>Click Add</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}>Create a WhatsApp account.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}> In the Create WhatsApp account segment:</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}> Account name: Enter a name</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}> Messaging for: Select an account for yourself or a client from the drop-down menu</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}>  Have a business manager account.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}> Business Manager ID is in the Business Manager settings</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}> Time region: Select the time region where your business is found.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}> Money: Select the neighborhood cash of your business. The money you select should match the cash that you intend to pay your receipt in.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}> Payment options (Optional): Select payment options for your promotions. In the event that you don't have an ongoing credit extension for payment, you can set up one.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}> PO Number (Optional): Enter the purchase order (PO) number that appears on the invoice.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}> Click Done.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}> Set permission in the add people section</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}> Look for people whom you need to add to the record and pick them from the rundown.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}>  Select the authorization level to allot to every individual. You can choose:
                                        a Partial access permits individuals to manage telephone numbers and message layouts.
                                        b Full control to permit individuals to deal with the WhatsApp account</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}>
                                        Click Assign.
                                    </Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}>
                                        Your WhatsApp Business account is created and sent for review. On the settings page, you can check your account status.
                                    </Typography>
                                    <Typography sx={{ fontSize: '15px', fontWeight: 500, }}>NOTE:</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}>
                                        If you need to find additional ways to offer the Cloud API to your clients.
                                    </Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}>
                                        While you're messaging for a business, ensure that the business' profile is finished to installed right away.
                                    </Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}>
                                        You can access the WhatsApp Manager if your account is on pending also. After the status is activated you can add the phone numbers to send messages. After the approval only you can send or receive messages.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, }}>Is DLT registration mandatory and what is DLT sender ID?</Typography>

                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}><Roundicon sx={{ width: '11px' }} />  Yes, DLT registration is required by TRAI regulations. Companies must be registered on DLT platforms in order to send communication messages to customers.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}><Roundicon sx={{ width: '11px' }} />  The DLT Sender Id is a unique alphanumeric number that is used to identify who sent the message. It is displayed at the top of the text messages. It is an outbound message, and you cannot reply to it.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Grid item md={12}>
                                <Typography sx={{ fontSize: '20px', fontWeight: 600, textAlign: 'left', mt: 2, mb: 2 }}>GENERAL QUESTIONS ABOUT CARES</Typography>
                            </Grid>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, }}>What is the primary purpose of CARES? What services does CARES provide?</Typography>

                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}><Roundicon sx={{ width: '11px' }} /> CARES allows you to connect and communicate with loyal customers via digital platforms. You can connect loyal customers with personalized, relevant business conversations at the appropriate time. For customer retention and for upselling of products CARES service is used. </Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}><Roundicon sx={{ width: '11px' }} /> CARES services platforms via social media marketing, retail media marketing, and digital media marketing (Email, SMS, WhatsApp, RCS & Push notifications).</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, }}>What is a campaign?</Typography>

                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}>A campaign is a planned action to achieve a particular goal. Target the audiences to increase the growth in business. They collect information with relevant data and target specific audiences with different types of marketing campaigns.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, fontWeight: 500 }}>TYPES OF MARKETING CAMPAIGNS</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />  Creating brand awareness</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />  SEO marketing</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />  Social media campaign</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />  UGC (User-generated content) marketing</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />  Email campaign</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />  Public relations marketing campaign</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />  Product launch campaign</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />  Conversational marketing</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />  Video campaign</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}> A social media campaign involves marketing the business goals on social media platforms. A social media campaign help in improving the chance of achieving the goals. With social media campaign, you can achieve the following.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />  Brand awareness can be increased</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />  You can acquire leads</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />  Sales can be increased</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />  Customers can be acquired</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />  You can engage with the customers.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}> The campaign can build trust and helps to extremely achieve success.</Typography>


                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, }}>How to create a campaign in CARES?</Typography>

                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}>Our CARES campaign promotes your products and increases your business growth and revenue. Visit the CARES website for the promotion. Log in with your username and password and follow the below steps,</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, fontWeight: 500 }}> Go to the dashboard you can find out campaign menu</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />   Click campaign in a drop-down menu you can see the campaign and automated campaign</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />   Click the campaign button</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />   Add a new campaign for your business by adding the campaign name, scheduling the date for the campaign, selecting the target contact groups and target contacts, and adding this activity.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />   After adding the activity you will receive an activity details page.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />   Fill in the details, and select the campaign mode whether you campaign it through WhatsApp, Mail, and SMS.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />   Schedule the date and you can also add the expiry (cancel) details of the messages with the date and time.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />   You can create a template and if needed add remarks to it and submit the activity.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />   Your campaign is created manually.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}> After creating the campaign you can find options for editing it, deleting it, and also view the campaign analytics of the sent messages (Queue, sent, opened, clicked, bounced ) details.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}> If you want automated campaigns then select automated campaigns in the campaign button in the dashboard. In an automated campaign, you can send messages through WhatsApp, SMS, and Email for</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />  Anniversary wishes</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />  Birthday wishes</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />  Festival wishes.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}>  For sending messages in automated campaigns choose what service you need and fill in the required details and save it. Your campaign will run on the particular occasion automatically. These all are the methods to create the campaign in CARES.</Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, }}>What is the CARES template?</Typography>

                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}>The CARES template can be used according to the customer's needs, and the template can be customized for different kinds of platforms such as WhatsApp, mail, SMS, and FCM. We provide unique and dynamic template services for customers. With our CARES template, you can send transaction and promotional messages to customers by configuring the platforms such as WhatsApp, mail, SMS, and FCM</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}> Our WhatsApp template service is unique and you can configure it according to your needs and save it. You can be able to create template names and template types. In template types, you have options to send messages as text, images, documents, and video and location details and have options to upload them. In Email template options you can create templates and preview them before sending them. SMS templates can be created and saved. You can edit it and delete it according to your needs.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}> For the best dynamic and unique template select CARES template service.</Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, }}>Email Account Verification in Cares?</Typography>

                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}>Email account verification prevents emails from being sent to invalid and unreachable email accounts. Email verification is used to send high-quality content and improve email delivery rates. Elastic mail service is a mail relay service that prevents the sending or receiving fake or spam emails. It verifies whether the account is active and whether the contact information provided is correct. CARES offers email verification as a service.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, fontWeight: 500 }}>EMAIL ACCOUNT VERIFICATION IN CARES</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}>Before sending an email, our CARES Email account verification service performs several checks at multiple levels. The email verifier will send the email to SMTP for verification before sending it. SMTP handles the entire email delivery process. It verifies whether the email ID appears to be valid, checks the domain name, checks the configured email server and whether the mailbox will be able to receive correctly formatted mail.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}>The following are the most important reasons to validate email verifications:</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />  Checking whether the email address exists or not</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />  Avoiding mail bounces</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />  Safeguards the sender's or company's reputation</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />  Reduce the storage of invalid emails and CRM costs • Allow you to focus on the right prospectus rather than wasting time on invalid emails</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />  Fake mail will be reverted</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}><Roundicon sx={{ width: '11px' }} />  Increase your subscriber rate</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}>Our elastic email service is very secure and helpful, with SSL encryption, TSL, and API access for all applications. To verify email addresses, we use a machine-learning algorithm across multiple platforms. Our email verification service offers limited and unlimited plans based on the needs of the customer. Our service can validate more emails and path red-flagged messages to spam folders.</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: 1, }}>If you need an email verification service to clean up your contact list for your business, contact the CARES team. CARES will provide a service that is 100% guaranteed.</Typography>



                                </AccordionDetails>
                            </Accordion>
                            <Grid item md={12}>
                                <Typography sx={{ fontSize: '20px', fontWeight: 600, textAlign: 'left', mt: 2, mb: 2 }}>LOYALTY</Typography>
                            </Grid>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, }}>What is Customer retention?</Typography>

                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}>Customer retention refers to the process of encouraging customers to continue purchasing from the same brand rather than switching to a competitor's brand. Customer retention demonstrates a consistent brand service to its customers. Customer retention is a much more important process than acquiring new customers.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Grid item md={12}>
                                <Typography sx={{ fontSize: '20px', fontWeight: 600, textAlign: 'left', mt: 2, mb: 2 }}>PROMOTIONS</Typography>
                            </Grid>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, }}>How can customer engagement platforms help you drive growth?</Typography>

                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography sx={{ fontSize: '16px', mb: 1 }}>Customer engagement platforms increase brand awareness among customers. Through this platform, you can connect, share and inform others about the product or business to the customers. It interacts with customers through multiple platforms and on multiple devices to drive growth to your organization potentially and boost revenue.</Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </>
    )
}

export default Faq